export const AdminShareFavouriteReducer = (state = {}, action) => {
    switch (action.type) {
      case 'POST_ADMIN_SHARE_FAVOURITE_FILTER':
        return {
          ...state,
          adminShareFavouriteFilterStatus: action.payload,
        };
      case 'API_START':
        if (action.payload === 'POST_ADMIN_SHARE_FAVOURITE_FILTER_STATUS') {
          return {
            ...state,
            isLoadingAdminShareFavouriteFilterStatus: true,
          };
        } else {
          return state;
        }
      case 'API_END':
        if (action.payload === 'POST_ADMIN_SHARE_FAVOURITE_FILTER_STATUS') {
          return {
            ...state,
            isLoadingAdminShareFavouriteFilterStatus: false,
          };
        } else {
          return state;
        }
      default:
        return state;
    }
  };
  