import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, IconButton, Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';
import { GenericAutocomplete } from './genericAutocomplete';
import { AdminShareFavouriteFilterAction, AdminListToShareFavWithAction } from 'TAP/redux/actions';
import { Toaster } from './Toaster';
import { NUMBER_ARRAY, labels } from 'common/global/constants';

export const ShareFilterAdminSelectionPopup = ({
  open,
  handleClose,
  popupHeader,
  leftBtnText,
  rightBtnText,
  favName,
}) => {
  let redtheme = RedTheme;
  const screen = useScreen();
  const ADMIN = 'Admin';
  const [adminOptions, setAdminOptions] = useState([]);
  const [adminValues, setAdminValues] = useState([]);
  const [adminChecked, setAdminChecked] = useState(false);
  const [adminSwitch, setAdminSwitch] = useState(true);
  const [loggedInUserId, setLoggedInUserId] = useState('');
  const [shareButtonDisableStatus, setShareButtonDisableStatus] = useState(true);
  const [showSharedSuccessfullyToaster, setShowSharedSuccessfullyToaster] = useState(false);

  const dispatch = useDispatch();

  let getLoggedInUserDetails = useSelector((state) => state.tapUserDetailsRd);
  let adminList = useSelector((state) => state.adminListToShareFavWithRd?.adminList?.data);
  let sharedStatus = useSelector(
    (state) => state.adminShareFavouriteRd?.adminShareFavouriteFilterStatus?.data,
  );


  // Fetch List of admins on Load
  useEffect(() => {
    dispatch(AdminListToShareFavWithAction());
  }, []);

  useEffect(() => {
    if (sharedStatus === 'Favourite Shared Successfully !') {
      setShowSharedSuccessfullyToaster(true);
    }
  }, [sharedStatus]);

  useEffect(() => {
    if (adminList && adminList.length > 0) {
      // Filter self so that user can not share with self
      const filteredAdminList = adminList.filter((admin) => admin?.user_id !== loggedInUserId);
      setAdminOptions(filteredAdminList);
    }
  }, [adminList]);

  // Get Logged in User Details
  useEffect(() => {
    if (getLoggedInUserDetails && Object.keys(getLoggedInUserDetails).length) {
      setLoggedInUserId(getLoggedInUserDetails?.userDetails?.data[0].user_id);
    }
  }, [getLoggedInUserDetails]);

  // Enable and disable Share button based on if any admin has selected or not
  useEffect(() => {
    if (adminValues.length > 0) {
      setShareButtonDisableStatus(false);
    } else {
      setShareButtonDisableStatus(true);
    }
  }, [adminValues]);

  const onSuccessToasterClose = () => {
    setShowSharedSuccessfullyToaster(false);
  };

  const switchChange = (_e, type) => {
    if (type === ADMIN) {
      setAdminSwitch(!adminSwitch);
      setAdminChecked(!adminChecked);
    }
  };

  const getLabeledOptions = (option, type) => {
    if (type === ADMIN) {
      return `(${option.user_id}) ${option.full_name}`;
    }
  };

  const handleAdminStatusChange = (event, type) => {
    if (type === 'Admin') {
      setAdminValues(event);
    } else {
      //No valid request
      setAdminValues([]);
    }
  };

  const shareFavHandler = (favName, adminValues) => {
    const adminIdsToShareWith = adminValues.map((admin) => admin.user_email);
    // Share the fav with selected Admins
    dispatch(
      AdminShareFavouriteFilterAction({
        loggedInUserId: loggedInUserId,
        filterName: favName,
        shareTo: adminIdsToShareWith,
      }),
    );
    // Reset Selected Admins and related states
    setAdminValues([]);
    setAdminSwitch(true);
    setAdminChecked(false);
  };

  const resetSelectedAdmins = () => {
    setAdminValues([]);
    setAdminSwitch(true);
    setAdminChecked(false);
  };

  /* (AdminQuickFilter Autocomplete)This function will check if the item in the option list is equal to the item in the selected value 
  list, then it will return true so that it look highlighted in the Search option to indicate the end user 
  that this item is selected and also help in selection one item only one time not multiple time*/
  const getEqualityCheck = (option, value, type) => {
    return !!(
      type === 'Admin' &&
      (value === undefined || value === null || option?.user_id === value?.user_id)
    );
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: redtheme.palette.box.white,
          padding: screen.isMobile ? '12px' : '24px',
          gap: '36px',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          width: screen.isMobile ? '97vw' : '720px',
          height: screen.isMobile ? '426px' : '376px',
          borderRadius: '16px',
        }}
      >
        <IconButton
          className='btn-close-popup'
          sx={{
            width: '20.01px',
            height: '20px',
            position: 'absolute',
            top: '24px',
            right: '24px',
          }}
          onClick={() => {
            resetSelectedAdmins();
            setShowSharedSuccessfullyToaster(false);
            handleClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            width: screen.isMobile ? '100%' : '372px',
            height: screen.isMobile ? '300px' : '250px',
          }}
        >
          <Typography
            component='h4'
            variant='h4'
            sx={{
              fontSize: '31px',
              lineHeight: '32px',
              fontWeight: 700,
              marginTop: '10px',
              fontFamily: '"JohnsonDisplay-Bold"',
              width: screen.isMobile ? '330px' : '370px',
            }}
          >
            {popupHeader}
          </Typography>
          <GenericAutocomplete
            options={adminOptions}
            values={adminValues}
            switchChangeHandler={switchChange}
            checked={adminChecked}
            getLabeledOptions={getLabeledOptions}
            handleAdminStatusChange={handleAdminStatusChange}
            getEqualityCheck={getEqualityCheck}
            type={ADMIN}
            placeholder={
              adminSwitch
                ? adminValues?.length
                  ? `${adminValues.length} Search`
                  : `Search`
                : `${adminValues.length} Selected`
            }
            heading={!adminSwitch ? 'Selected Admin WWID / User Name' : 'Admin WWID / User Name'}
            switchIndicator={adminSwitch}
          />
        </Box>
        <Box
          sx={{
            width: screen.isMobile ? '330px' : '372px',
            height: '60px',
            gap: '8px',
            display: 'flex',
            alignItems: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <Button
            onClick={() => {
              resetSelectedAdmins();
              setShowSharedSuccessfullyToaster(false);
              handleClose();
            }}
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '16px',
              width: screen.isMobile ? '161px' : '182px',
              height: screen.isMobile ? '30px' : '40px',
              borderRadius: '8px',
              fontFamily: '"JohnsonText-Medium"',
              textTransform: 'none',
            }}
          >
            {leftBtnText}
          </Button>
          <Button
            onClick={() => {
              shareFavHandler(favName, adminValues);
            }}
            disabled={shareButtonDisableStatus}
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '16px',
              width: screen.isMobile ? '161px' : '182px',
              height: screen.isMobile ? '30px' : '40px',
              borderRadius: '8px',
              fontFamily: '"JohnsonText-Medium"',
              textTransform: 'none',
              color: shareButtonDisableStatus
                ? redtheme.palette.button.gray
                : redtheme.palette.button.white,
              bgcolor: shareButtonDisableStatus ? 'transparent' : redtheme.palette.primary.main,
              '&:hover': {
                color: redtheme.palette.button.white,
                bgcolor: redtheme.palette.primary.main,
                fontFamily: '"JohnsonText-Medium"',
              },
            }}
            variant='primary'
          >
            {rightBtnText}
          </Button>
        </Box>
        <Toaster
          onClose={onSuccessToasterClose}
          hideDuration={NUMBER_ARRAY.tenThousand}
          severity={'success'}
          show={showSharedSuccessfullyToaster}
          message={labels.shareFavouriteSuccessMessage}
        />
      </Box>
    </Modal>
  );
};
