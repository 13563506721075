import './App.css';
import {
  AUTHENTICATION_TIMEOUT,
  LOGIN_TIMEOUT,
  TOKEN_RENEWAL_TIME,
  NUMBER_ARRAY,
} from 'common/global/constants';
import { BlueTheme, RedTheme } from 'common/global';
import React, { StrictMode, useEffect, useState } from 'react';
import { loginRequest, msalConfig } from './common/authentication/authConfig';
import { useSelector } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { LogoutView } from 'common/pages/logoutView';
import { RouterConfig } from 'common/navigation';
import { ThemeProvider } from '@mui/material/styles';
import { logError } from 'TAP/utils/commonMethods';
import { InActiveStatusPopup } from 'TAP/components/InActiveStatusPopup';
import { TapGlobalMessages } from 'TAP/global';
function App() {
  const theme = [RedTheme, BlueTheme];
  let setThemeInState = useSelector((state) => {
    return state.themeSwitcherRd;
  });
  const themeSelected = setThemeInState?.selectedTheme?.themeType
    ? theme[setThemeInState.selectedTheme.themeType]
    : theme[0];

  let { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [showInactivityPopup, setShowInactivityPopup] = useState(false);
  const [showTokenExpiredPopup, setShowTokenExpiredPopup] = useState(false);

  const isLogout =
    window.location && (window.location.pathname || '').toLowerCase().includes('logout');

  const isDev =
    process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'dev';

  let userActivityTimeout;
  let redirectTimeout;

  const accessData = useSelector((state) => state?.commonRd);
  useEffect(() => {
    if (accessData?.isAccessDenied) {
      setShowTokenExpiredPopup(true);
    }
  }, [accessData]);

  useEffect(() => {
    return () => {
      setShowTokenExpiredPopup(false);
    };
  }, []);

  useEffect(() => {
    document.title = 'TAP';

    if (window.location && (window.location.search || '').toLowerCase().includes('logout=true')) {
      setTimeout(() => {
        setShowTokenExpiredPopup(false);
        //way to logout via query string
        localStorage.setItem('forcedLoggedOutHappened 1002', JSON.stringify('App 1'));
        instance.logout();
      }, NUMBER_ARRAY.sixThousand);
    }
  });

  useEffect(() => {
    if (!isAuthenticated) {
      if (isLogout) {
        //does not execute login redirect
      } else {
        setTimeout(() => {
          if (instance.getAllAccounts().length === 0) {
            instance
              .handleRedirectPromise()
              .then((_response) => {
                logError('response', _response);
              })
              .catch((err) => {
                logError('handle Redirect Promise not found', err);
              });

            if (!localStorage['redirectURI']) {
              localStorage['redirectURI'] = window.location.pathname;
            }
            instance.loginRedirect(loginRequest).catch((e) => {
              logError('login Redirect error found', e);
            });
          }
        }, AUTHENTICATION_TIMEOUT);
      }
    } else {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      const userName = instance.getAllAccounts()[0].username;
      window.msalInstance = instance;
      window.msalUserName = userName;

      if (localStorage['redirectURI'] && localStorage['redirectURI'] !== '') {
        let redirectURI = localStorage['redirectURI'];
        localStorage.removeItem('redirectURI');
        window.location.pathname = redirectURI;
      }

      // Set auto token renewal call on each 5 min
      setInterval(() => {
        let config = {
          scopes: loginRequest.scopes,
          authority: msalConfig.auth.authority,
          account: instance.getAllAccounts()[0],
        };
        let redRequest = {
          scopes: loginRequest.scopes,
          loginHint: userName,
        };
        instance
          .acquireTokenSilent(config)
          .then(
            (res) => {
              logError('Token Renewed...', isDev ? res : {});
            },
            (err) => {
              setShowTokenExpiredPopup(true);
              setTimeout(() => {
                setShowTokenExpiredPopup(false);
                localStorage.setItem(
                  'forcedLoggedOutHappened 1003',
                  JSON.stringify({ ...err, accounts: instance.getAllAccounts() }),
                );
                if (err instanceof InteractionRequiredAuthError) {
                  return instance.acquireTokenRedirect(redRequest);
                }
              }, NUMBER_ARRAY.sixThousand);
            },
          )
          .catch((error) => {
            setShowTokenExpiredPopup(true);
            setTimeout(() => {
              setShowTokenExpiredPopup(false);
              localStorage.setItem(
                'forcedLoggedOutHappened 1004',
                JSON.stringify({ ...error, accounts: instance.getAllAccounts() }),
              );
              if (error instanceof InteractionRequiredAuthError) {
                return instance.acquireTokenRedirect(redRequest);
              }
            }, NUMBER_ARRAY.sixThousand);
          });
      }, TOKEN_RENEWAL_TIME);

      setTimeout(() => {
        window.location.hash = '';
      }, LOGIN_TIMEOUT);
    }
    return () => {
      setShowTokenExpiredPopup(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    trackUserActivity();
    // empty dependency array- for the use effect to be triggered in every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackUserActivity = () => {
    window.addEventListener('load', resetTimer, true);
    window.addEventListener('mousemove', resetTimer, true);
    window.addEventListener('mousedown', resetTimer, true);
    window.addEventListener('touchstart', resetTimer, true);
    window.addEventListener('touchmove', resetTimer, true);
    window.addEventListener('click', resetTimer, true);
    window.addEventListener('keydown', resetTimer, true);
    window.addEventListener('scroll', resetTimer, true);
    window.addEventListener('wheel', resetTimer, true);
  };

  const removeEventListeners = () => {
    window.removeEventListener('load', resetTimer, true);
    window.removeEventListener('mousemove', resetTimer, true);
    window.removeEventListener('mousedown', resetTimer, true);
    window.removeEventListener('touchstart', resetTimer, true);
    window.removeEventListener('touchmove', resetTimer, true);
    window.removeEventListener('click', resetTimer, true);
    window.removeEventListener('keydown', resetTimer, true);
    window.removeEventListener('scroll', resetTimer, true);
    window.removeEventListener('wheel', resetTimer, true);
  };

  // After one minute of showing inactivity popup- logout user
  const handleUserInactivity = () => {
    setShowInactivityPopup(true);
    // Logout after 1 min of showing pop-up
    redirectTimeout = setTimeout(() => {
      handleLogout();
    }, 60000);
  };

  // Logout user
  const handleLogout = () => {
    localStorage.clear();
    // Clear all event listeners
    removeEventListeners();
    instance.logout();
  };

  // Reset timeout in case of any activity by user
  const resetTimer = () => {
    clearTimeout(userActivityTimeout);
    clearTimeout(redirectTimeout);
    // 45 mins of inactivity will trigger popup
    userActivityTimeout = setTimeout(handleUserInactivity, 2700000);
  };

  const handleClose = () => {
    setShowInactivityPopup(false);
  };

  const handleInActivePopupCloseEvent = () => {
    setShowTokenExpiredPopup(false);
  };

  return (
    <StrictMode>
      <>
        {isLogout ? (
          <LogoutView />
        ) : isAuthenticated ? (
          <>
            <ThemeProvider theme={themeSelected}>
              <CssBaseline />
              <RouterConfig />
            </ThemeProvider>
          </>
        ) : (
          <div>Please Wait...</div>
        )}
        <InActiveStatusPopup
          open={showInactivityPopup}
          handleClose={handleClose}
          msgBody={TapGlobalMessages.sessionGoingToExpireInactivity}
          title={TapGlobalMessages.userIdlePopupTitle}
          rightBtnText={TapGlobalMessages.extendButtonText}
          leftBtnText={''}
          rightBtnHandler={() => {
            // Reset the timer
            resetTimer();
            setShowInactivityPopup(false);
          }}
          leftBtnHandler={() => {}}
        />
        <InActiveStatusPopup
          open={showTokenExpiredPopup}
          handleClose={handleInActivePopupCloseEvent}
          msgBody={TapGlobalMessages.tokenExpiredText}
          title={TapGlobalMessages.tokenExpiredHeading}
          rightBtnText={TapGlobalMessages.okText}
          leftBtnText={''}
          rightBtnHandler={handleInActivePopupCloseEvent}
          leftBtnHandler={() => {}}
        />
      </>
    </StrictMode>
  );
}

export default App;
