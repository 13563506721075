/**
* TrainingPlanPopupReducer.
* This Reducer is used for Training Plan Data
* @param {object} state
* @param {string} action
* @returns {object}
*/
export const TrainingPlanPopupReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_TRAININGS_POPUP_DATA':
            return {
                ...state,
                trainingsPopupData: action.payload,
            };
        case 'GET_TRAININGS_SUGGESTIONS_DATA':
            return {
                ...state,
                trainingsSuggestionsForSearch: action.payload,
            };
        case 'POST_TRAINING_PLAN_POPUP_LARGE_REPORT_DOWNLOAD':
            return {
                ...state,
                trainingPopupLargeDownloadStatus: action.payload,
            };

        case 'API_START':
            if (action.payload === 'GET_TRAININGS_POPUP_DATA') {
                return {
                    ...state,
                    isLoadingTrainingsPopupData: true,
                };
            } else if (action.payload === 'GET_TRAININGS_SUGGESTIONS_IN_POPUP') {
                return {
                    ...state,
                    isLoadingTrainingsSuggestions: true,
                };
            } else if (action.payload === 'FETCH_TRAININGS_POPUP_LARGE_REPORT_DOWNLOAD') {
                return {
                    ...state,
                    isLoadingTrainingPopupLargeDownloadStatus: true,
                };
            } else {
                return state;
            }

        case 'API_END':
            if (action.payload === 'GET_TRAININGS_POPUP_DATA') {
                return {
                    ...state,
                    isLoadingTrainingsPopupData: false,
                };
            } else if (action.payload === 'GET_TRAININGS_SUGGESTIONS_IN_POPUP') {
                return {
                    ...state,
                    isLoadingTrainingsSuggestions: false,
                };
            } else if (action.payload === 'FETCH_TRAININGS_POPUP_LARGE_REPORT_DOWNLOAD') {
                return {
                    ...state,
                    isLoadingTrainingPopupLargeDownloadStatus: false,
                };
            } else {
                return state;
            }

        default:
            return state;
    }
};
