import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import { RedTheme } from 'common/global';
import CloseIcon from '@mui/icons-material/Close';

export const InActiveStatusPopup = ({
  open,
  handleClose,
  msgBody,
  title,
  rightBtnText,
  leftBtnText,
  rightBtnHandler,
}) => {
  let redtheme = RedTheme;

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: redtheme.palette.box.white,
            padding: '24px',
            gap: '36px',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: '420px',
            height: '316px',
            borderRadius: '16px',
          }}
        >
          <IconButton
            className='btn-close-popup'
            sx={{
              width: '20.01px',
              height: '20px',
              position: 'absolute',
              top: '24px',
              right: '24px',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '370px', height: '70px' }}>
            <Typography
              component='h4'
              variant='h4'
              sx={{
                fontSize: '31px',
                lineHeight: '32px',
                fontWeight: 700,
                marginTop: '50px',
                fontFamily: '"JohnsonDisplay-Bold"',
                width: '370px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box sx={{ width: '372px', height: '32px' }}>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '16px',
                fontWeight: 500,
                fontFamily: '"JohnsonText-Bold"',
                color: redtheme.palette.fontColor.gray_orange,
                width: '300px',
                display: 'inline',
              }}
            >
              {msgBody}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '372px',
              height: '40px',
              gap: '8px',
              marginTop: '48px',
            }}
          >
            {leftBtnText && (
              <Button
                onClick={handleClose}
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '16px',
                  width: '182px',
                  height: '40px',
                  borderRadius: '8px',
                  fontFamily: '"JohnsonText-Medium"',
                  textTransform: 'none',
                }}
              >
                {leftBtnText}
              </Button>
            )}
            {rightBtnText && (
              <Button
                onClick={rightBtnHandler}
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '16px',
                  width: '182px',
                  height: '40px',
                  borderRadius: '8px',
                  fontFamily: '"JohnsonText-Medium"',
                  textTransform: 'none',
                  color: redtheme.palette.button.white,
                  bgcolor: redtheme.palette.primary.main,
                  '&:hover': {
                    color: redtheme.palette.button.white,
                    bgcolor: redtheme.palette.primary.main,
                    fontFamily: '"JohnsonText-Medium"',
                  },
                }}
                variant='primary'
              >
                {rightBtnText}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
