import React from 'react';
import { Alert, Snackbar, styled } from '@mui/material';
import { RedTheme } from 'common/global';

const SnackbarBox = styled(Snackbar)({
  '&.info .MuiPaper-root': {
    backgroundColor: RedTheme.palette.info.main,
    color: RedTheme.palette.text.disabled,
  },
  '&.warning .MuiPaper-root': {
    backgroundColor: RedTheme.palette.warning.light,
    color: RedTheme.palette.text.secondary,
  },
  '&.warn .MuiPaper-root': {
    backgroundColor: RedTheme.palette.error.warning,
    color: RedTheme.palette.fontColor.black,
  },
  '&.success .MuiPaper-root': {
    backgroundColor: RedTheme.palette.success.main,
    color: RedTheme.palette.background.default,
  },
  '&.error .MuiPaper-root': {
    backgroundColor: RedTheme.palette.error.main,
    color: RedTheme.palette.background.default,
  },
  fontFamily: 'JohnsonText-Regular',
  fontSize: '14px',
  fontWeight: '700',
  [1300]: {
    width: '50%',
  },
  [480]: {
    width: '80%',
  },
  [368]: {
    width: '80%',
    margin: '0 auto',
  },
  '&.error .MuiAlert-icon': {
    color: RedTheme.palette.background.default,
  },
  '&.error .MuiAlert-action': {
    color: RedTheme.palette.background.default,
  },
  '&.info .MuiAlert-icon': {
    color: RedTheme.palette.text.disabled,
  },
  '&.info .MuiAlert-action': {
    color: RedTheme.palette.text.disabled,
  },
  '&.warning .MuiAlert-icon': {
    color: RedTheme.palette.warning.dark,
  },
  '&.warning .MuiAlert-action': {
    color: RedTheme.palette.text.primary,
  },
  '&.warn .MuiAlert-icon': {
    color: RedTheme.palette.fontColor.black,
  },
  '&.warn .MuiAlert-action': {
    color: RedTheme.palette.fontColor.black,
  },
  '&.success .MuiAlert-icon': {
    color: RedTheme.palette.success.main,
  },
  '&.success .MuiAlert-action': {
    color: RedTheme.palette.background.default,
  },
});

export const Toaster = ({onClose, hideDuration, message, severity, show,  isAlertPopup=false}) => {
  const handleCloseToast = () => {
    onClose(false);
  };
  return (
    <SnackbarBox
      className={severity}
      open={show}
      onClose={handleCloseToast}
      data-testid='toaster-message'
      autoHideDuration={hideDuration}
      {...(isAlertPopup ?{ sx: { marginBottom: '300px', marginLeft: '20px' } } : { anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })}
    >
      <Alert
        onClose={handleCloseToast}
        severity={severity === 'warn' ? 'warning' : severity}
        variant='filled'
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </SnackbarBox>
  );
};
