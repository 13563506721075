/**
* FilterPopUpDetailsReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const FilterPopUpDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_POPUP_FILTER_DATA':
      return {
        ...state,
        popupFilterData: action.payload,
      };
    case 'API_START':
      if (action.payload === 'FETCH_POPUPFILTERDATA_DATA') {
        return {
          ...state,
          isLoadingPopupFilterData: true,
        };
      } else {
        return state;
      }
    case 'API_END':
      if (action.payload === 'FETCH_POPUPFILTERDATA_DATA') {
        return {
          ...state,
          isLoadingPopupFilterData: false,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
