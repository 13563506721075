import { pagination } from 'TAP/styles/genericStyles/pagination';

export const AUTHENTICATION_TIMEOUT = 500;
export const YEARLY_VIEW = 'Yearly View';
export const QUARTERLY_VIEW = 'Quarterly View';
export const MONTHLY_VIEW = 'Monthly View';
export const YEARLY_VIEW_FILE_SUBNAME = 'Yearly_View';
export const QUARTERLY_VIEW_FILE_SUBNAME  = 'Quarterly_View';
export const MONTHLY_VIEW_FILE_SUBNAME  = 'Monthly_View';
export const LOGIN_TIMEOUT = 1500;
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE = 5;
export const EST_ONE_AM = 1;
export const nameValidationRegex = /^[0-9a-zA-Z(\-\s)]+$/; //this will admit letters, numbers and dashes
export const THERSHOLD_VALUE_REGEX =/([<>]=?|[<>])/;
export const NUMBER_PATTERN_REGEX =/^(100|[0-9]{1,2})$/;
export const INPUT_FIELD_VAL_REGEX =/^[a-zA-Z0-9_ ]{0,250}$/;
export const COMPLETED_ON_TIME_STATUS = 'Completed On Time';
export const USER = 'user';
export const MANAGER = 'manager';
export const COURSE_CODE = 'CourseCode';
export const TOTAL_NON_COMPLIANT = 'Total Non-Compliant';
export const TOTAL_AT_RISK = 'Total At Risk';
export const OVERALL_COMPLIANCE_PROFILE = 'adminView/actions/overallComplianceProfile';
export const OVERALL_BARDATA = 'adminView/actions/overallBarData';
/****
 * MY ORGANISATION CONST
 ****/
export const myOrgPerform = {
  one: 1,
  two: 2,
  three: 3,
  seven: 7,
  twelve: 12,
};
/****
 * GENERIC POP CONST
 ****/
export const genericPop = {
  twoHundred: 200,
  five: 5,
  ten: 10,
  fifteen: 15,
  nineHundred: 900,
  sevenHundred: 700,
};
/****
 * BAR CHART CONST
 ****/
export const barchart = {
  twoHundred: 200,
  hundred: 100,
  two: 2,
  three: 3,
  five: 5,
  ninetyfive: 95,
  eightyfive: 85,
  four: 4,
  fifteen: 15,
  threeHundred: 300,
  fiveHundred: 500,
  twentyeight: 28,
};
/****
 * DONUT CHART CONST
 ****/
export const donutchart = {
  twoHundred: 200,
  twoTwenty: 220,
  hundred: 100,
  twopointone: 2.1,
  two: 2,
  three: 3,
  five: 5,
  ninetyfive: 95,
  eightyfive: 85,
  four: 4,
  fifteen: 15,
  threeHundred: 300,
  fiveHundred: 500,
  twentyeight: 28,
  twofifty: 250,
  minustwo: -2,
  minusonethirty: -130,
  onethirty: 130,
  minussixty: -60,
  twoeighty: 280,
};
/****
 * CIRCLE TEXT CONST
 ****/
export const circlewithtext = {
  twoHundred: 200,
  eightHundred: 800,
};
/****
 * RENEWAL TIME CONST
 ****/
export const renewalTime = {
  five: 5,
  sixty: 60,
  thousand: 1000,
};

/****
 * number array constant
 ****/
export const NUMBER_ARRAY = {
  zeroPointTwo: 0.2,
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
  twelve: 12,
  fifteen: 15,
  twenty: 20,
  twentyOne: 21,
  twentyTwo: 22,
  twentyThree: 23,
  twentyFour: 24,
  thirty: 30,
  fifty: 50,
  fortyFive: 45,
  seventy: 70,
  eightyFive: 85,
  ninetyFive: 95,
  hundred: 100,
  oneHundredEighty: 180,
  twoHundredTwenty: 220,
  eight_hundred: 800,
  thousand: 1000,
  twoPointEight: 2.8,
  twoPointFive: 2.5,
  twoPointTwo: 2.2,
  minusTwo: -2,
  threePointFive: 3.5,
  twoFifty: 250,
  zeroPointEightySeven: 0.87,
  twoHundred: 200,
  fourHundred: 400,
  fourHundredOne: 401,
  fourHundredThree: 403,
  fourHundredFour: 404,
  fourHundredNine: 409,
  fourHundredTwenty: 420,
  fourHundredThirty: 430,
  fiveHundred: 500,
  fiveHundredTwo: 502,
  fiveHundredOne: 501,
  sevenHundred: 700,
  sevenHundredSixtyEight: 768,
  eightHundredThirty: 830,
  oneThousandTwentyFour: 1024,
  twoThousand: 2000,
  fiveThousand: 5000,
  sixThousand: 6000,
  tenThousand: 10000,
  elevenThousand: 11000,
  thirtyThousand: 30000,
};

export const labels = {
  errorHeader: 'Unexpected Error',
  errorSubHeader: 'Sorry for the inconvenience',
  errorBody:
    'Please try refreshing this page. If the problem persists, please close your browser and re-load, or you can logout the application also.',
  errorButtonText: 'Logout',
  unauthorizedHeader: 'No Access!',
  unauthorizedBody: "You don't have access to TAP",
  unauthorizedButtonText: 'Go To DTH',
  exportDataTableText: 'Export Data Table',
  scheduleReportText: 'Schedule Report',
  setAlert: 'Set Alert',
  shareFavouriteSuccessMessage: 'Favourite Shared Successfully !',
  validationErrorMessage: 'Please enter Alert name (max 250 char & alphanumeric and spaces, underscore only)',
  emailValidationMessage: 'Maximum 45 email IDs can be added.',
  thersholdValidationMessage: 'Please enter a number between 0 and 100.',
  thersholdWholeNumberValidationMessage: 'Threshold should be a whole number',
  setThersholdMessage: 'Set Compliance profile percentage threshold',
  muteAlertMessage: 'To unmute an alert you need to raise a IRIS Ticket',
  greaterThan: '> (Greater than)',
  lessThan: '< (Less than)',
  greaterThanOrEqualTo: '>= (Greater than equal to)',
  lessThanOrEqualTo: '<= (Less than equal to)',
  conflictMessageIfGreaterThan: 'The threshold value should be greater than',
  conflictMessageIfLesserThan: 'The threshold value should be less than',
  duplicateAlertName: ' Alert name already exists.'
};

export const EMERGE_URL = 'https://jc-qualize.jnj.com/emerge/employee';

export const TOKEN_RENEWAL_TIME = renewalTime.five * renewalTime.sixty * renewalTime.thousand;

export const PAGE_SIZES_ARRAY_GENERIC = [pagination.pg5, pagination.pg10, pagination.pg15];

export const PAGE_SIZES_ARRAY = [
  pagination.pg10,
  pagination.pg20,
  pagination.pg30,
  pagination.pg50,
  pagination.pg100,
];
