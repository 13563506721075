import apiAction from 'common/redux/actions/apiAction';
import { POST_TEAM_PERFORMANCE_DIRECT_REPORTEE_POPUP_DATA } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST POPUP LISTING DETAILS
 ****/
const getDirectReporteePopUpDetailsData = data => {
  return {
    type: 'GET_DIRECT_REPORTEE_POPUP_LISTING_DATA',
    payload: data,
  };
};

// This function requires to clear the state Value where we have same API with multiple place to avoid wrong data flow.
export const ClearPopupRecordForDirectReportee = (data = []) => {
  return getDirectReporteePopUpDetailsData(data);
}


export const DirectReporteePopupDetailsData = configParams => {
  const url = POST_TEAM_PERFORMANCE_DIRECT_REPORTEE_POPUP_DATA(configParams.urlStatus);
  return apiAction.postAPI({
    url,
    data: configParams,
    onSuccess: getDirectReporteePopUpDetailsData,
    onFailure: err =>  logError('Error occurred in fetching DirectReporteePopupDetailsData data:', err),
    label: 'FETCH_DIRECT_REPORTEE_LISTING_DATA',
  });
};

