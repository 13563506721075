
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Autocomplete, TextField } from '@mui/material';
import {
  TrainingViewIndividualUserData,
  TrainingViewIndividualUserCurriculumSuggestionsData,
  TrainingViewIndividualUserTrainingSuggestionsData,
} from 'TAP/redux/actions';
import { TrainingViewPopup } from 'TAP/components/trainingViewPopup';
import { GET_TRAININGVIEW_POPUP_REPORT } from 'TAP/redux/actions/apiPathConstants';
import { RedTheme,SelectedTheme } from 'common/global';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {ReactComponent as Info} from '../assets/images/info-circle.svg'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { ToolTipWrapper } from 'common/components';
import { TapGlobalMessages } from 'TAP/global';
import { RequestToAddOrRemoveTrainings } from 'TAP/components';
import { DataGrid } from '@mui/x-data-grid';
import { NUMBER_ARRAY, PAGE_SIZES_ARRAY } from 'common/global/constants';
import { downloadExcelReport, currentDate, currentTime } from 'TAP/utils/commonMethods';
import { CustomButton } from 'TAP/components/customButton';
import { useScreen } from 'common/hooks/useScreen';
import { tablePaginationClasses } from "@mui/material/TablePagination";
import { TrainingsLargeReportDownloadAction } from 'TAP/redux/actions/trainingPlanPopupViewAction';
import { Toaster } from 'TAP/components/Toaster';

export const TrainingPlanViewIndividualUser = () => {
  
  const theme = SelectedTheme();
  const [userID, setUserID] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [clickedCurriculumData, setClickedCurriculumData] = useState({
    curriculumID: null,
    curriculumTitle: null,
  });

  const [showLargeDataDownloadInfoMsg, setShowLargeDataDownloadInfoMsg] = useState(false);
  const [showLargeDataDownloadSuccessMsg, setShowLargeDataDownloadSuccessMsg] = useState(false);
  const [toasterFlow, setToasterFlow] = useState(false);

  const [curriculumTitleSearchString, setCurriculumTitleSearchString] = useState('');
  const [curriculumTitleSearchOptions, setCurriculumTitleSearchOptions] = useState([]);
  const [curriculumTitleSelectedSearchValue, setCurriculumTitleSelectedSearchValue] = useState('');

  const [trainingTitleSearchString, setTrainingTitleSearchString] = useState('');
  const [trainingTitleSearchOptions, setTrainingTitleSearchOptions] = useState([]);
  const [trainingTitleSelectedSearchValue, setTrainingTitleSelectedSearchValue] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    page: 0,
    pageSize: 10,
    sortField: 'curriculum_title',
    orderBy: 'ASC',
  });
  const [totalRecords,setTotalRecords] = useState(0); 
  const [sortingTooltip,setSortingTooltip] = useState(''); 
  const screen = useScreen();

  const CLICK_TO_SORT_ASCENDING = 'Click to sort ascending';
  const CLICK_TO_SORT_DESCENDING = 'Click to sort descending';

  const dispatch = useDispatch();

  let getLoggedInUserDetails = useSelector(state => state.tapUserDetailsRd);

  let trainingViewIndividualUserData = useSelector(state => state.trainingViewIndividualUserRd);

  let trainingViewIndividualUserSuggestionsData = useSelector(state => state.trainingViewIndividualUserSearchSuggestionsRd);

  const apiResponseOfLargeDataDownload = useSelector(
    (state) => state?.tapTrainingPlanPopupRd?.trainingPopupLargeDownloadStatus?.data,
  );

  useEffect(() => {
    if (
      apiResponseOfLargeDataDownload === TapGlobalMessages.trainingRequestApiSuccessMessage &&
      !showLargeDataDownloadInfoMsg &&
      toasterFlow === true
    ) {
      setShowLargeDataDownloadSuccessMsg(true);
    }
  }, [apiResponseOfLargeDataDownload, showLargeDataDownloadInfoMsg]);

  let redtheme = RedTheme
  /* Saving the currently logged In User ID in local state */
  useEffect(() => {
    setSortingTooltip(CLICK_TO_SORT_ASCENDING);

    if (getLoggedInUserDetails && Object.keys(getLoggedInUserDetails).length) {
      setUserID(getLoggedInUserDetails?.userDetails?.data[0].user_id);
    }
  }, [getLoggedInUserDetails]);

  /* Dispatching the action to load the TABLE DATA (filtered/unfiltered) */
  useEffect(() => {
    if (userID !== undefined && userID !== '') {
      setPageState(old => ({ ...old, isLoading: true }));

      let requestPayload;
      
      if (curriculumTitleSelectedSearchValue === '' && trainingTitleSelectedSearchValue === '') {
        requestPayload = {
          userId: userID,
          orderBy: pageState.orderBy===undefined?'ASC':pageState.orderBy,
          page: pageState.page,
          limit: pageState.pageSize,
          type: 'listing',
        };
      } else {
        requestPayload = {
          userId: userID,
          orderBy: pageState.orderBy===undefined?'ASC':pageState.orderBy,
          page: pageState.page,
          limit: pageState.pageSize,
          curriculum_title: curriculumTitleSelectedSearchValue,
          training_title: trainingTitleSelectedSearchValue,
          type: 'listing',
        };
      }
      
      dispatch(TrainingViewIndividualUserData(requestPayload));
    }
  }, [userID, curriculumTitleSelectedSearchValue, trainingTitleSelectedSearchValue, pageState.orderBy, pageState.page, pageState.pageSize]);

  /* Dispatching the action to load the CURRICULUM SEARCH SUGGESTIONS */
  useEffect(() => {
    if (userID !== undefined && userID !== '' && curriculumTitleSearchString !== '') {
        
      
      const requestPayload = {
        userId: userID,
        orderBy: pageState.orderBy===undefined?'ASC':pageState.orderBy,
        page: pageState.page,
        limit: pageState.pageSize,
        type: 'search',
        searchCurriculum: curriculumTitleSearchString,
        searchTraining: trainingTitleSelectedSearchValue?.length
          ? trainingTitleSelectedSearchValue
          : '',
      };
      dispatch(TrainingViewIndividualUserCurriculumSuggestionsData(requestPayload));
      
    }
  }, [userID, curriculumTitleSearchString, pageState.orderBy, pageState.page, pageState.pageSize]);

  /* Dispatching the action to load the TRAINING SEARCH SUGGESTIONS */
  useEffect(() => {
    if (userID !== undefined && userID !== '' && trainingTitleSearchString !== '') {
        
      const requestPayload = {
        userId: userID,
        orderBy: pageState.orderBy===undefined?'ASC':pageState.orderBy,
        page: pageState.page,
        limit: pageState.pageSize,
        type: 'search',
        searchCurriculum: curriculumTitleSelectedSearchValue?.length
          ? curriculumTitleSelectedSearchValue
          : '',
        searchTraining: trainingTitleSearchString,
      };
      dispatch(TrainingViewIndividualUserTrainingSuggestionsData(requestPayload));
      
    }
  }, [userID, trainingTitleSearchString, pageState.orderBy, pageState.page, pageState.pageSize]);

  /* Extracting the TABLE DATA from redux store (filtered/unfiltered) */
  useEffect(() => {
    if (trainingViewIndividualUserData && Object.keys(trainingViewIndividualUserData).length) {
      if (
        trainingViewIndividualUserData?.APIResponse?.data &&
        Object.keys(trainingViewIndividualUserData?.APIResponse?.data).length
      ) {
        
        setPageState(old => ({
            ...old,
            isLoading: false,
            data: trainingViewIndividualUserData.APIResponse.data.records,
          }));
          
          setTotalRecords(trainingViewIndividualUserData.APIResponse.data.totalRecords); 
        
      } else if (
        trainingViewIndividualUserData?.APIResponse?.data &&
        Object.keys(trainingViewIndividualUserData?.APIResponse?.data)?.length === 0
      ) {
        setPageState(old => ({
            ...old,
            isLoading: false,
            data: [],
          }));
        
        
        setTotalRecords(trainingViewIndividualUserData.APIResponse.data.totalRecords);
      } else {
        setPageState(old => ({
          ...old,
          isLoading: false,
          data: [],
        }));
      }
    } else {
      
      setPageState(old => ({
        ...old,
        isLoading: false,
        data: [],
      }));
      
    }
  }, [trainingViewIndividualUserData]);

  const removeDuplicateObjects = (array, property) => {
    const uniqueIds = [];

    return array.filter(element => {
      const isDuplicate = uniqueIds.includes(element[property]);

      if (!isDuplicate) {
        uniqueIds.push(element[property]);
        return true;
      }
      return false;
    });
   
  };

  /* Extracting the CURRICULUM & TRAINING SEARCH SUGGESTIONS data from redux store */
  useEffect(() => {
    if (
      trainingViewIndividualUserSuggestionsData &&
      Object.keys(trainingViewIndividualUserSuggestionsData).length
    ) {
      if (
        trainingViewIndividualUserSuggestionsData?.curriculumResponse?.data &&
        Object.keys(trainingViewIndividualUserSuggestionsData?.curriculumResponse?.data).length
      ) {
        const curriculumOptionsArray =
          trainingViewIndividualUserSuggestionsData.curriculumResponse.data.records;
        const uniqueCurriculumOptions = removeDuplicateObjects(
          curriculumOptionsArray,
          'curriculum_title',
        );
        setCurriculumTitleSearchOptions(uniqueCurriculumOptions);
      } else {
        setCurriculumTitleSearchOptions([]);
      }

      if (
        trainingViewIndividualUserSuggestionsData?.trainingResponse?.data &&
        Object.keys(trainingViewIndividualUserSuggestionsData?.trainingResponse?.data).length
      ) {
        const trainingOptionsArray =
          trainingViewIndividualUserSuggestionsData.trainingResponse.data.records;
        const uniqueTrainingOptions = removeDuplicateObjects(
          trainingOptionsArray,
          'training_title',
        );
        setTrainingTitleSearchOptions(uniqueTrainingOptions);
      } else {
        setTrainingTitleSearchOptions([]);
      }
    }
  }, [trainingViewIndividualUserSuggestionsData]);
  


  //TT Section 



 

  /* Called when the input search string in the CURRICULUM Search box changes */
  const onInputChangeOfCurriculumFilter = (_event, newValue, reason) => {
    /*-------When entering a new search string for CURRICULUM search box------*/
    /* 1. Remove previous clicked/selected value for CURRICULUM search box  */
    setCurriculumTitleSelectedSearchValue('');
    /* 2. Remove previous autosuggestions for CURRICULUM search box  */
    setCurriculumTitleSearchOptions([]);
    /* 3. DO NOT remove previous clicked/selected value for TRAINING search box,
              because we may want to combine the filter of both search boxes */
    /* 4. Remove previous autosuggestions for TRAINING search box  */
    setTrainingTitleSearchOptions([]);


    if (newValue !== undefined && newValue !== '') {
      setCurriculumTitleSearchString(newValue);
    } else {
      setCurriculumTitleSearchString('');
      setCurriculumTitleSearchOptions([]);
    }

    if (reason === 'reset' || reason === 'clear') {
      setCurriculumTitleSearchString('');
      setCurriculumTitleSearchOptions([]);
    }
  };

  const handleSortModelChange = useCallback(sortModel => {
    
    setPageState(old => ({
      ...old,
      sortField: sortModel[0]?.field,
      orderBy: sortModel[0]?.sort.toUpperCase(),
    }));

     if(sortModel[0]?.sort.toUpperCase() === 'ASC'){
      setSortingTooltip(CLICK_TO_SORT_DESCENDING);
    }else if(sortModel[0]?.sort.toUpperCase() === 'DESC'){
      setSortingTooltip(CLICK_TO_SORT_ASCENDING);
    }
  }, []);

  /* Called when the input search string in the TRAINING Search box changes */
  const onInputChangeOfTrainingFilter = (_event, newValue, reason) => {
    /*-------When entering a new search string for TRAINING search box------*/
    /* 1. Remove previous clicked/selected value for TRAINING search box  */
    setTrainingTitleSelectedSearchValue('');
    /* 2. Remove previous autosuggestions for TRAINING search box  */
    setTrainingTitleSearchOptions([]);
    /* 3. DO NOT remove previous clicked/selected value for CURRICULUM search box,
             because we may want to combine the filter of both search boxes */
    /* 4. Remove previous autosuggestions for CURRICULUM search box  */
    setCurriculumTitleSearchOptions([]);

    if (newValue !== undefined && newValue !== '') {
      setTrainingTitleSearchString(newValue);
    } else {
      setTrainingTitleSearchString('');
      setTrainingTitleSearchOptions([]);
    }

    if (reason === 'reset' || reason === 'clear') {
      setTrainingTitleSearchString('');
      setTrainingTitleSearchOptions([]);
    }
  };

  /* Called when user (clicks on/selects) a Curriculum/Training from list of suggestions in the Search box */
  const onChangeOfAutoCompleteFilter = (_event, newValue, reason, _details, type) => {
    if (reason === 'selectOption') {
      if (newValue && type === 'curriculum_title') {
        setCurriculumTitleSelectedSearchValue(newValue.curriculum_title);
      } else if (newValue && type === 'training_title') {
        setTrainingTitleSelectedSearchValue(newValue.training_title);
      }
    } else if (reason === 'clear') {
      if (!newValue && type === 'curriculum_title') {
        setCurriculumTitleSelectedSearchValue('');
      } else if (!newValue && type === 'training_title') {
        setTrainingTitleSelectedSearchValue('');
      }
    }
    setPageState(old => ({
      ...old,
      page: 0,
    }));
    setPaginationModel(old => ({
      ...old,
      page: 0,
    }));
  };

  const triggerDownloadsCompletedEvent = () => {
    setShowLargeDataDownloadInfoMsg(false);
  };

  const onSuccessToasterClose = () => {
    setShowLargeDataDownloadSuccessMsg(false);
    setToasterFlow(false);
  };

  /* Render the Curriculum Column Header */
  

  /* All required columns for the Ant Design Table */
  const antDesignTableColumns = [
    {
      field: 'curriculum_title',
      headerName: 'Curriculum Title',
      flex: !screen.isDesktop ? 0 : 5,
      width: !screen.isDesktop ?  1000 : 300,
      disableColumnMenu: true,
      renderCell: params => {
        const training_title = params.value || '';
        return (
          <Box sx={{display:'flex'}}>
                {!screen.isDesktop && <ToolTipWrapper title={training_title} placement='top' header='Curriculum Title'>
                      <Info
                                fontSize='medium'
                                sx={{ color: `${theme.palette.common.primaryMainBlue}`, marginBottom: '12px', marginLeft: '22px' }} />
                    </ToolTipWrapper>}
          <Typography 
            onClick = { () => 
              { 
                setOpenPopup(true);
                setClickedCurriculumData({
                  curriculumID: params.id,
                  curriculumTitle: params.value,
                });
              }
          }
          sx={{
            cursor: 'pointer',
            fontFamily: '"JohnsonText-Regular"',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '14px',
            color: redtheme.palette.box.black_gray, 
            paddingLeft: '10px',
          }}
          >{screen.isDesktop ? <ToolTipWrapper title={training_title} placement='top' header='Curriculum Title'>
                      {training_title}
                    </ToolTipWrapper> : training_title.length > 75 ? `${training_title.slice(0,75)}...`:training_title}
          </Typography>
          </Box>
        );
      },
    },
  ];

  /* This component is rendered when there's no Training View data for the user */
  
  const customNoDataOverlay = () => {
    let overlayMessage =
      curriculumTitleSelectedSearchValue === '' && trainingTitleSelectedSearchValue === ''
        ? 'No Curriculums assigned to you at the moment'
        : 'No Curriculums found for the filters applied';

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          background: redtheme.palette.box.very_light_grey ,
          fontWeight: '400',
          fontSize: '32px',
          lineHeight: '40px',
          fontFamily: 'JohnsonDisplay-Regular',
          color: redtheme.palette.fontColor.dark_grayish_blue,
          width: '100%',
          height: '100%',
        }}
        data-testid='noDataAvailable'
      >
        {overlayMessage}
      </Box>
    );
  };

  const trainingsDownloadExcelReport = () => {
    let fileName = `TrainingViewReport_${userID}_${currentDate}_${currentTime}.xlsx`;
    
    if (userID !== undefined) {
      let requestURL = `${
        process.env.REACT_APP_API_BASE_URL
      }${GET_TRAININGVIEW_POPUP_REPORT()}?userId=${userID}&curriculumId=0&apiType=MY_PLAN`;
      if (curriculumTitleSelectedSearchValue?.length) {
        requestURL =
          requestURL + `&curriculumTitle=${encodeURIComponent(curriculumTitleSelectedSearchValue)}`;
      }
      if (trainingTitleSelectedSearchValue?.length) {
        requestURL =
          requestURL + `&trainingTitle=${encodeURIComponent(trainingTitleSelectedSearchValue)}`;
      }
      downloadExcelReport(requestURL,fileName,null).catch((err) => {
        if (err?.response?.status === NUMBER_ARRAY.fourHundred) {
          dispatch(
            TrainingsLargeReportDownloadAction({
              userId: userID,
              curriculumTitle: curriculumTitleSelectedSearchValue,
              trainingTitle: trainingTitleSelectedSearchValue,
              apiType: 'MY_PLAN',
              searchUserId: '',
              curriculumId: 0
            }),
          );
          setShowLargeDataDownloadInfoMsg(true);
          setToasterFlow(true);
        }
      }); 
      
    }
  };

  return (
      <Box className='training-view-individual-user-parent-box' data-testid='training-view' sx={{marginTop: '-52px', background:redtheme.palette.box.white, padding:screen.isMobile ? '16px' : '0 72px'}}>
       <Box sx={{
        width:'100%'
       }}>
          <RequestToAddOrRemoveTrainings/>
       </Box>
        
        {!screen.isDesktop && pageState?.data && pageState?.data?.length ? (
            <Box
              className='tv-ind-user-download-wrapper'
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'end',
              }}
            >
            
              <CustomButton
                   width = '166px'
                   buttonText = 'Download Excel '
                   clickHandler = {trainingsDownloadExcelReport}
                   isIconToShow = {true}
                />
                <ToolTipWrapper
                            title={TapGlobalMessages.trainingPlanView.directReports.downloadExcelToolTip()}
                            placement='bottom-end'
                            header='How Excel Works?'
                        >
                            <HelpOutlineIcon
                                fontSize='medium'
                                sx={{ color: `${theme.palette.common.primaryMainBlue}`, marginBottom: '12px', marginLeft: '22px' }} />
                        </ToolTipWrapper>
            </Box>
          ) : null}
        <Box className='training-view-individual-user-search-box-parent'>
          <Box className='training-view-individual-user-curriculum-search-box' sx={{
            width: !screen.isDesktop ? '50%':'40%',
          }}>
            <Autocomplete
              data-testid='curriculum-title-searchbox'
              className='training-view-individual-user-curriculum-autocomplete'
              options={curriculumTitleSearchOptions}
              popupIcon={''}
              getOptionLabel={option => option.curriculum_title || ''}
              onInputChange={onInputChangeOfCurriculumFilter}
              onChange={(event, value, reason, details) =>
                onChangeOfAutoCompleteFilter(event, value, reason, details, 'curriculum_title')
              }
              renderInput={params => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    shrink: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={'Search'}
                  label={
                    <Typography
                      sx={{
                        fontSize: screen.isTablet ? '20px' : '16px',
                        lineHeight: '16px',
                        fontWeight: 500,
                        fontFamily: '"JohnsonText-Bold"',
                        color: 'rgba(33, 33, 33, 1)',
                        width: '728px',
                        height: screen.isTablet ? '22px' : '16px',
                        marginTop : screen.isTablet ? '2px' : NUMBER_ARRAY.zero
                      }}
                    >Curriculum Title </Typography>}
                  data-testid='curriculum-searchbox-input'
                  sx={{
                    borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                    fontFamily: '"JohnsonText-Medium"',
                    fontWeight: 450,
                    fontSize: '14px',
                    lineHeight: '16px',
                  }}
                  variant='standard'
                  margin='normal'
                />
              )}
              clearOnBlur
            />
          </Box>
          <Box className='training-view-individual-user-training-search-box'  sx={{
            width: !screen.isDesktop ? '50%':'40%',
          }}>
            <Autocomplete
              data-testid='training-title-searchbox'
              popupIcon={''}
              className='training-view-individual-user-training-autocomplete'
              options={trainingTitleSearchOptions}
              getOptionLabel={option => option.training_title}
              onInputChange={onInputChangeOfTrainingFilter}
              onChange={(event, value, reason, details) =>
                onChangeOfAutoCompleteFilter(event, value, reason, details, 'training_title')
              }
              renderInput={params => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    shrink: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={'Search'}
                  label={
                    <Typography
                      sx={{
                        fontSize: screen.isTablet ? '20px' : '16px',
                        lineHeight: '16px',
                        fontWeight: 500,
                        fontFamily: '"JohnsonText-Bold"',
                        color: 'rgba(33, 33, 33, 1)',
                        width: '728px',
                        height:  screen.isTablet ? '22px' : '16px',
                        marginTop : screen.isTablet ? '2px' : NUMBER_ARRAY.zero,
                      }}
                    >
                      Training Name
                    </Typography>
                  }
                  data-testid='training-searchbox-input'
                  className='training-view-individual-user-training-textfield'
                  sx={{
                    borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                    fontFamily: '"J&J CircularTT Black Web"',
                    fontWeight: 450,
                    fontSize: '14px',
                    lineHeight: '16px',
                  }}
                  variant='standard'
                  margin='normal'
                />
              )}
              clearOnBlur
            />
          </Box>
          {screen.isDesktop && pageState?.data && pageState?.data?.length ? (
            <Box
              className='tv-ind-user-download-wrapper'
              sx={{
                width: '18%',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'end',
              }}
            >
            
              <CustomButton
                   width = '166px'
                   buttonText = 'Download Excel '
                   clickHandler = {trainingsDownloadExcelReport}
                   isIconToShow = {true}
                />
            </Box>
          ) : null}
          {screen.isDesktop && <Box
                        sx={{
                            display: 'flex',
                            width: '3%',
                            alignItems: 'end',
                        }}
                    >
                        <ToolTipWrapper
                            title={TapGlobalMessages.trainingPlanView.directReports.downloadExcelToolTip()}
                            placement='bottom-end'
                            header='How Excel Works?'
                        >
                            <HelpOutlineIcon
                                fontSize='medium'
                                sx={{ color: `${theme.palette.common.primaryMainBlue}`, marginBottom: '12px', marginLeft: '22px' }} />
                        </ToolTipWrapper>
                    </Box>}
        </Box>
        <Box className='training-view-individual-user-table-parent-box' sx={{ width : '100%'}}>
          {pageState?.data && pageState?.data?.length ? 
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'curriculum_title', sort: 'asc' }],
              },
            }}
            rows={pageState.data}
            columns={antDesignTableColumns}
            getRowId={row => row?.curriculum_id}
            loading={pageState.isLoading}
            localeText={{columnHeaderSortIconLabel: sortingTooltip}}
            components={{
              noRowsOverlay: customNoDataOverlay,
            }}
            paginationMode='server'
            sortingMode='server'
            disableRowSelectionOnClick
            onSortModelChange={handleSortModelChange}
            rowCount={totalRecords}
            paginationModel={paginationModel}
            pageSizeOptions={PAGE_SIZES_ARRAY}
            onPaginationModelChange={newPage => {
              setPageState(old => ({
                ...old,
                page: newPage.page + 1,
                pageSize: newPage.pageSize,
              }));
              setPaginationModel(old => ({
                ...old,
                page: newPage.page,
                pageSize: newPage.pageSize,
              }));
            }}
            onPageSizeChange={newPageSize =>
              setPageState(old => ({ ...old, pageSize: newPageSize }))
            }
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell': {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '16px',
                fontFamily: '"JohnsonText-Medium"',
              },
              '& .MuiDataGrid-row:nth-of-type(odd)': { backgroundColor: '#00009908' },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: 16,
                fontWeight: 700,
                fontFamily: '"JohnsonText-Bold"',
              },
              '& .super-app.completed': {
                color:redtheme.palette.dataGrid.dark_lime_green ,
              },
              '& .super-app.overdue': {
                color: redtheme.palette.dataGrid.strong_red ,
              },
              '& .super-app.noDueDate': {
                color: redtheme.palette.dataGrid.strong_blue,
              },
              '& .super-app.inComplete': {
                color: redtheme.palette.dataGrid.strong_orange,
              },
              '& .MuiTablePagination-selectLabel': {
                fontFamily: '"JohnsonText-Regular"',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '16px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '& .MuiTablePagination-displayedRows': {
                fontFamily: '"JohnsonText-Medium"',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '16px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
                display: 'block',
              },
              '& .MuiTablePagination-select': {
                fontFamily: '"JohnsonText-Medium"',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '24px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
              '&.MuiDataGrid-root .MuiTablePagination-root .MuiTablePagination-input':{
                display : screen.isMobile ? 'revert' : 'block'                
               },
               '&.MuiDataGrid-root .MuiTablePagination-root' :{
                overflow : 'hidden'
              },
              '&.MuiDataGrid-root .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical':{
                overflowY : 'hidden'
              },
               '&.MuiDataGrid-root .MuiTablePagination-root .MuiTablePagination-selectLabel':{
                display :  screen.isMobile ? 'revert' : 'block'
               },
              [`& .${tablePaginationClasses.selectLabel}`]: {
                display: "block"
              },
              [`& .${tablePaginationClasses.input}`]: {
                display: "inline-flex"
              }
            }}
        /> : null
        }
        </Box>
        <Toaster
          onClose={triggerDownloadsCompletedEvent}
          hideDuration={NUMBER_ARRAY.tenThousand}
          severity={'warn'}
          show={showLargeDataDownloadInfoMsg}
          message={TapGlobalMessages.largeDownloadStartMsg}
        />
        <Toaster
          onClose={onSuccessToasterClose}
          hideDuration={NUMBER_ARRAY.thirtyThousand}
          severity={'success'}
          show={showLargeDataDownloadSuccessMsg}
          message={TapGlobalMessages.largeDownloadSuccessMsg}
        />
        <TrainingViewPopup
          data-testid='training-view-popup'
          userID={userID}
          curriculumID={clickedCurriculumData?.curriculumID}
          curriculumTitle={clickedCurriculumData?.curriculumTitle}
          open={openPopup}
          onPopupClose={() => setOpenPopup(false)}
        />
      </Box>

  );
};