import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';
import { RedTheme } from 'common/global';
import { NUMBER_ARRAY } from 'common/global/constants';
import { getDecodedBearerToken } from 'common/authentication/authToken';

export const NavigationMenu = () => {
  const [userRole, setUserRole] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const handleTabIndexChange = (_event, newValue) => {
    setCurrentTabIndex(newValue);
  };
  let redtheme = RedTheme;
  const tabIndexThree = 3;
  const [navLinks, setNavLinks] = useState([]);
  const userDetails = localStorage.getItem('userDetails');
  const getLoggedInUserDetails = useSelector((state) => state.tapUserDetailsRd);
  const [isNavMenuReady, setNavMenuReady] = useState(false);

  useEffect(() => {
    if (
      getLoggedInUserDetails &&
      Object.keys(getLoggedInUserDetails)?.length > NUMBER_ARRAY.zero &&
      getLoggedInUserDetails.userDetails?.data[NUMBER_ARRAY.zero]
    ) {
      setNavMenuReady(true);
    }
  }, [getLoggedInUserDetails]);

  useEffect(() => {
    const parseUserDetails = userDetails ? JSON.parse(userDetails) : '';
    const decodedBearerToken = getDecodedBearerToken();
    if (
      decodedBearerToken &&
      decodedBearerToken?.roles &&
      decodedBearerToken?.roles?.length > NUMBER_ARRAY.zero
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

    if (parseUserDetails && Object.keys(parseUserDetails)?.length > NUMBER_ARRAY.zero) {
      setUserRole(parseInt(parseUserDetails?.user_role));
      prepareNavList(parseInt(parseUserDetails?.user_role));
    } else {
      setUserRole(NUMBER_ARRAY.one);
      prepareNavList(NUMBER_ARRAY.one);
    }
  }, [userDetails]);

  const prepareNavList = (currUserRole) => {
    if (!currUserRole || currUserRole === NUMBER_ARRAY.zero) return;
    if (currUserRole === NUMBER_ARRAY.two || currUserRole === NUMBER_ARRAY.three) {
      setNavLinks([
        { name: 'My Performance', path: '/' },
        { name: `My Team's Performance`, path: '/myorganisation' },
        { name: 'Training Plan View', path: '/trainingview' },
      ]);
    } else {
      setNavLinks([
        { name: 'My Performance', path: '/' },
        { name: 'Training Plan View', path: '/trainingview' },
      ]);
    }
    localStorage.setItem('currUserRole', JSON.stringify(currUserRole));
  };

  const handleNavigate = function (path) {
    window.location.href = process.env.REACT_APP_REDIRECT_URI_TAP + path;
  };

  let handleRefreshNavState = (userCurrRole) => {
    const isUserRoleOne = userCurrRole && userCurrRole === NUMBER_ARRAY.one;
    if (isUserRoleOne) {
      switch (window.location.pathname) {
        case '/TAP/':
          setCurrentTabIndex(NUMBER_ARRAY.zero);
          break;
        case '/TAP/trainingview':
          setCurrentTabIndex(NUMBER_ARRAY.one);
          break;
        case '/TAP/adminview':
          setCurrentTabIndex(NUMBER_ARRAY.two);
          break;
        default:
          setCurrentTabIndex(NUMBER_ARRAY.zero);
          break;
      }
    }
    if (!isUserRoleOne) {
      switch (window.location.pathname) {
        case '/TAP/':
          setCurrentTabIndex(NUMBER_ARRAY.zero);
          break;
        case '/TAP/myorganisation':
          setCurrentTabIndex(NUMBER_ARRAY.one);
          break;
        case '/TAP/trainingview':
          setCurrentTabIndex(NUMBER_ARRAY.two);
          break;
        case '/TAP/adminview':
          setCurrentTabIndex(tabIndexThree);
          break;
        default:
          setCurrentTabIndex(NUMBER_ARRAY.zero);
          break;
      }
    }
  };

  useEffect(() => {
    handleRefreshNavState();
  }, [userRole]);

  return (
    <>
      <Box
        data-testid='nav'
        component='nav'
        className='tap-appbar-header2'
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: {
            xs: '0px 10px',
            sm: '0px 10px',
            md: '0px 42px',
            lg: '0px 72px',
            xl: '0px 72px',
          },
          height: '48px',
          background: redtheme.palette.box.white,
          marginTop: '1px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          fontFamily: '"JohnsonDisplay-Bold"',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs
              value={currentTabIndex}
              onChange={handleTabIndexChange}
              TabIndicatorProps={{
                sx: {
                  height: '3px',
                  borderRadius: '3px',
                  fontFamily: '"JohnsonDisplay-Bold"',
                },
              }}
              sx={{
                minHeight: '49px',
                fontFamily: '"JohnsonDisplay-Bold"',
                color: redtheme.palette.fontColor.black_gray,
                '& .Mui-selected': {
                  fontWeight: 700,
                  fontSize: '16px',
                  color: `${redtheme.palette.fontColor.black_gray} !important`,
                },
              }}
              aria-label='TAP Navigation'
            >
              {isNavMenuReady &&
                userRole !== NUMBER_ARRAY.zero &&
                navLinks?.length > NUMBER_ARRAY.zero &&
                navLinks?.map((link, index) => (
                  <Tab
                    key={index}
                    label={link.name}
                    data-testid={link.name}
                    textColor='blue'
                    onClick={() => handleNavigate(link.path)}
                    sx={{
                      textTransform: 'none',
                      borderBottom: 3,
                      borderColor: 'divider',
                      fontSize: '16px !important',
                      minHeight: '49px',
                      fontFamily: '"JohnsonText-Medium"',
                    }}
                  />
                ))}
              {isNavMenuReady && isAdmin && (
                <Tab
                  label={'Administrative View'}
                  data-testid={'Adminstrative View'}
                  textColor='blue'
                  onClick={() => handleNavigate('/adminview')}
                  sx={{
                    textTransform: 'none',
                    borderBottom: 3,
                    borderColor: 'divider',
                    fontSize: '16px !important',
                    minHeight: '49px',
                    fontFamily: '"JohnsonText-Medium"',
                  }}
                />
              )}
            </Tabs>
          </Box>
        </Box>
      </Box>
    </>
  );
};
