import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { RedTheme } from 'common/global';
import { CircleWithText, ToolTipWrapper, DropDownIcon } from 'common/components';
import { useScreen } from 'common/hooks/useScreen';
import { styled } from '@mui/material/styles';

const CustomButton = ({ text, color, positionStyles, onClick, disabled, defaultStyles, tileTypeText }) => (
  <Box component='span' sx={{ '@media (min-width: 2000px)': positionStyles }}>
    <Button
      sx={{
        color,
        fontSize: '10px',
        fontWeight: 900,
        ml: '-8px',
        mb: '10px',
        mt: defaultStyles.mt,
        fontFamily: '"JohnsonText-Bold"',
        bgcolor: 'transparent',
        '&:hover': { backgroundColor: 'transparent', boxShadow: '' },
        '&:focus': { outline: 'none', backgroundColor: 'transparent' },
        '@media (min-width: 2000px)': {
          ...(tileTypeText === 'COMING DUE' ? {
            position: 'absolute',
            bottom: '-29px',
            right: '252px',
            minWidth: '100px',
          } : {
            width: '120%',
          }),
        },
      }}
      disabled={disabled}
      onClick={onClick}
      data-testid='seeMoreButton'
    >
      {text}
    </Button>
  </Box>
);

const CustomSpan = styled('span')(({ theme, screen }) => ({
    color: theme?.palette?.fontColor?.black,
    display: 'inline-block',
    marginRight: screen.isTablet ? '0px' : '5px',
    marginTop: screen.isTablet ? '10px' : '0px',
    '@media (min-width: 2000px)': {
        marginTop: '0px',
        width: '85px',
    },
}));

export const TrainingTile = ({
  handleSelectedDaysChange,
  handlePopupButtonClick,
  progressData,
  toolTipHeaderText,
  toolTipTitleText,
  selectedDays,
  actionType = 'TRAININGS',
  tileTypeText = 'COMING DUE',
  fontcolor, 
  isComplianceAction
}) => {

  const redtheme = RedTheme;

// Decides Header of the Popup on click of See More
  let popupHeaderText = '';
  if (tileTypeText === 'COMING DUE'){
    (popupHeaderText = 'Coming Due Trainings')
  } else if (tileTypeText === 'AT RISK'){
    (popupHeaderText = 'Total At Risk')
  } else if (tileTypeText === 'NON-COMPLIANT'){
    (popupHeaderText = 'Total Non-Compliant')
  } else {
    (popupHeaderText = 'Overdue Trainings');
  }

  const screen = useScreen(); 

  let CircleWithTextbgcolor;
  if(tileTypeText === 'COMING DUE'  || tileTypeText === 'AT RISK'){
    CircleWithTextbgcolor = redtheme.palette.span.yellow ;
  } else {
    CircleWithTextbgcolor = redtheme.palette.dataGrid.strong_red ;
  }

  const buttonProps = {
    'COMING DUE': {
      text: 'See More',
      color: redtheme.palette.span.yellow,
      positionStyles: {
        position: 'relative',
        marginTop: '40px',
      },
      defaultStyles: {
        mt: '-18px',
      },
    },
    'AT RISK': {
      text: 'See More',
      color: redtheme.palette.span.yellow,
      positionStyles: {
        width: '120%',
        position: 'relative',
        top: '10%',
        right: '7rem',
        marginTop: '57px',
      },
      defaultStyles: {
        mt: '0px',
      },
    },
    'NON-COMPLIANT': {
      text: 'See More',
      color: redtheme.palette.span.strong_red,
      positionStyles: {
        width: '120%',
        position: 'relative',
        top: '10%',
        right: '11rem',
        marginTop: '57px',
      },
      defaultStyles: {
        mt: '0px',
      },
    },
    'OVERDUE': {
      text: 'See More',
      color: redtheme.palette.span.strong_red,
      positionStyles: {
        width: '120%',
        position: 'relative',
        top: '10%',
        right: '9.8rem',
        marginTop: '57px',
      },
      defaultStyles: {
        mt: '0px',
      },
    },
  };

  return (
    <Box
      sx={{
        height: '110px',
        // width: '49%',
        borderLeft: `3px solid ${redtheme.palette.info.borderBlue}`,
        background: redtheme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        justifyContent: screen.isMobile ? 'left' : 'center',
        paddingLeft: "28px",
        '@media (min-width: 2000px)': {
          paddingLeft: '75px',
          justifyContent: 'flex-start',
        },
      }}
    >
      <Stack
        direction='row'
        spacing={1.5}
        sx={{
          alignItems: 'center',   
          '@media (min-width: 2000px)': {
            marginLeft:'0px',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
          },
        }}
      >
        <CircleWithText
          id='cwt3'
          progressData={progressData}
          data={
            <ToolTipWrapper header={toolTipHeaderText} title={toolTipTitleText} placement='bottom'>
              {
                <Typography
                  variant='span'
                  component='span'
                  sx={{
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                    cursor: 'pointer',
                    //TODO Add Conditional color
                    color: fontcolor,
                  }}
                  data-testid='trainingsComingDueText'
                >
                  {progressData || 0}
                </Typography>
              }
            </ToolTipWrapper>
          }
          size={screen.isTablet ? '52' : '68'}
          fontWeight={screen.isTablet ? '700':'900'}
          fontSize={screen.isTablet ? '14':'16'}
          lineHeight={screen.isTablet ? '14':'16'}
          marginBottom='0'
          fontFamily='"JohnsonText-Medium"'
          bgColor={CircleWithTextbgcolor}
          fgColor={redtheme.palette.dataGrid.fontGrey}
          sx={{
            '@media (min-width: 2000px)': {
              width: '85px',
              height: '85px',
            },
          }}
        />
        <Stack
          direction='column'
          sx={{
            fontSize: '14px',
            lineHeight: '14px',
            width: tileTypeText === 'COMING DUE' ? screen.isTablet ? '120px' : '190px' : '116px',
            fontWeight: 500,
            justifyContent: 'center',
            fontFamily: '"JohnsonText-Medium"',
            '@media (min-width: 2000px)': {
              marginBottom:'12px !important',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
              width: '100%',
            },
          }}
        >
          <Box
            mt='25px'
            sx={{
              '@media (min-width: 2000px)': {
                marginTop: '0px',
              },
            }}>       
          {screen.isTablet && tileTypeText === 'NON-COMPLIANT' ? actionType + ' NON' : actionType}</Box>
          <Box
            sx={{
              '@media (min-width: 2000px)': {
                width: '240%',
              },
            }}
          >
            {screen.isTablet && tileTypeText === 'NON-COMPLIANT' ? 'COMPLIANT' : tileTypeText}
          </Box>
          {tileTypeText === 'COMING DUE' ? (
            <Box
              sx={{
                color: redtheme.palette.span.yellow,
                mt: '-10px',                
                '@media (min-width: 2000px)': {
                  display:'flex',
                  width: '276%',
                  marginTop: '25px',
                  marginRight: '-1rem',
                },
              }}
            >
              <CustomSpan screen={screen}>
                    IN THE NEXT{' '}
              </CustomSpan>
              <Box component='span' sx={{
                display: screen.isTablet ? 'block' : 'inline-block', padding: 0 ,
              '@media (min-width: 2000px)': {
                flexGrow: 1,
                position: 'relative',
                bottom: '12px',
                },
              }}>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'></InputLabel>
                <Select
                  defaultValue={7}
                  IconComponent={DropDownIcon}
                  disableUnderline
                  value={selectedDays}
                  onChange={handleSelectedDaysChange}
                  variant='standard'
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: '12px',
                      },
                    },
                  }}
                  sx={{
                    textDecoration: 'none',

                    '& .MuiSelect-select': {
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      fontFamily: '"JohnsonText-Medium"',
                      color: redtheme.palette.span.yellow,
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                      fontWeight: 900,
                    },
                  }}
                  data-testid='select-time'
                >
                  <MenuItem
                    value={3}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    3 DAYS
                  </MenuItem>
                  <MenuItem
                    value={7}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    7 DAYS
                  </MenuItem>
                  <MenuItem
                    value={15}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    15 DAYS
                  </MenuItem>
                  <MenuItem
                    value={30}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    1 MONTH
                  </MenuItem>
                  <MenuItem
                    value={90}
                    sx={{
                      fontFamily: '"JohnsonText-Bold"',
                      fontSize: '14px',
                    }}
                  >
                    3 MONTHS
                  </MenuItem>
                
                </Select>
              </Box>
            </Box>
          ) : (
            ''
          )}
          <CustomButton
              text={buttonProps[tileTypeText].text}
              color={buttonProps[tileTypeText].color}
              positionStyles={buttonProps[tileTypeText].positionStyles}
              defaultStyles={buttonProps[tileTypeText].defaultStyles}
              onClick={() => handlePopupButtonClick(popupHeaderText, isComplianceAction)}
              disabled={progressData <= 0}
              tileTypeText={tileTypeText}
            />
        </Stack>
      </Stack>
    </Box>
  );
};
