import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { RedTheme } from 'common/global';
import { ComplianceDonutChartTooltipBody } from 'TAP/components';
import PropTypes from 'prop-types';
import { NUMBER_ARRAY } from 'common/global/constants';
import { scaleOrdinal } from 'd3-scale';
import { arc, pie } from 'd3-shape';
import { useScreen } from 'common/hooks/useScreen';

let redtheme = RedTheme;

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: redtheme.palette.tooltip.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: redtheme.palette.tooltip.white,
    color: redtheme.palette.tooltip.black_gray,
    fontSize: '14px',
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.24)',
    border: `1px solid ${redtheme.palette.tooltip.white}`,
    borderRadius: '16px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '20px',
    gap: '8px',
  },
}));

export const ComplianceDonutChart = ({ data, colors, pageName, onDonutChartClicked }) => {
  const screen = useScreen();
  // Define the height and width of Donut Chart
  const width = screen.resolveStyles({
    mobile: 100,
    tablet: 80,
    desktop: 120,
  });
  const height = screen.resolveStyles({
    mobile: 100,
    tablet: 80,
    desktop: 120,
  });
  const radius = Math.min(width, height) / NUMBER_ARRAY.two;

  //These colors will be part of Donut Chart each representing a different kind of data
  const color = scaleOrdinal().range(colors);
  const V1 = pie().value((d) => d.value);
  const data_ready = V1(data);

  // This control the thickness of Donut
  const V2 = arc()
    .innerRadius(radius * NUMBER_ARRAY.zeroPointEightySeven)
    .outerRadius(radius);

  // The percentage that needs to be shown in the center of Donut Chart
  const compliancePercentage = data[0].value;

  return (
    <div id='donut-chart' style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <g
          data-testid="g-element"
          transform={`translate(${width / NUMBER_ARRAY.two},${height / NUMBER_ARRAY.two})`}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onDonutChartClicked(pageName, 'Overall Compliance Profile', '');
          }}
        >
          {data_ready.map((d, index) => (
            <BootstrapTooltip
              key={index}
              title={`${d.data.label}: ${d.data.value}%`}
              placement='top'
              arrow
            >
              <path
                data-testid="path-element"
                d={V2(d)}
                fill={color(d.data.color)}
                stroke='white'
                strokeWidth='0px'
                onClick={() => {
                  onDonutChartClicked(pageName, 'Overall Compliance Profile', d.data.label);
                }}
              />
            </BootstrapTooltip>
          ))}
          <BootstrapTooltip title={<ComplianceDonutChartTooltipBody />} placement='top' arrow>
            <text
              textAnchor='middle'
              dy='.3em'
              style={{
                fontSize: screen.resolveStyles({
                  mobile: '1.17em',
                  tablet: '1em',
                  desktop: '1.5em',
                }),
                fontWeight: 'bold',
                fill: 'black',
                cursor: 'pointer',
              }}
            >
              {compliancePercentage}%
            </text>
          </BootstrapTooltip>
        </g>
      </svg>
    </div>
  );
};

ComplianceDonutChart.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array,
};
