import React, { useEffect } from 'react';
import { select, selectAll, scaleBand, max, scaleLinear, axisLeft, axisBottom } from 'd3';
import _ from 'lodash';
import { RedTheme } from 'common/global';
import { barchart } from 'common/global/constants';
import { percent } from 'TAP/styles/genericStyles/percentage';
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';

let runningData;

let redtheme = RedTheme;

const barPadding = {
  Year: 0.83,
  Quarter: 0.82,
  Month: 0.68,
};

/**
 * getColor.
 * This Method is use to get color
 * @param {number} percentage
 * @returns {string}
 */
function getColor(percentage) {
  if (percentage >= percent.pr95) {
    return redtheme.palette.bar.green;
  } else if (percentage >= percent.pr85) {
    return redtheme.palette.bar.yellow;
  } else {
    return redtheme.palette.bar.red;
  }
}

function getType(percentage) {
  if (percentage >= percent.pr95) {
    return 'Compliance';
  } else if (percentage >= percent.pr85) {
    return 'At Risk';
  } else {
    return 'Non-Compliant';
  }
}

/**
 * subtractMonths.
 * This Method is use to subtract months
 * @param {number} months
 * @param {Date} date
 * @returns {Date}
 */
function subtractMonths(months, date = new Date()) {
  date = new Date(date.getFullYear(), date.getMonth(), 1);
  date.setMonth(date.getMonth() - months);
  return date;
}

/**
 * subtractYears.
 * This Method is use to subtract years
 * @param {number} years
 * @param {Date} date
 * @returns {Date}
 */
function subtractYears(years, date = new Date()) {
  date = new Date(date.getFullYear(), date.getMonth(), 1);
  date.setFullYear(date.getFullYear() - years);
  return date;
}

/**
 * getQuarter.
 * This Method is use to get Quarter
 * @param {Date} date
 * @returns {Date}
 */
function getQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / barchart.three + 1);
}

/**
 * subtractQuarters.
 * This Method is use to subtract quarters
 * @param {number} quarters
 * @param {Date} date
 * @returns {Date}
 */
function subtractQuarters(quarters, date = new Date()) {
  date = new Date(date.getFullYear(), date.getMonth(), 1);
  return subtractMonths(quarters * barchart.three, date);
}

const transformChartDataMonthAndQuater = (
  chartData,
  xVarName,
  yVarName,
  localMonth,
  transformedData,
) => {
  for (const element of chartData) {
    if (localMonth === element[xVarName]) {
      transformedData.push(element);
    } else {
      let item = {};
      item[xVarName] = localMonth;
      item[yVarName] = 0;
      transformedData.push(item);
    }
  }
};
const transformChartDataMonth = (chartData, xVarName, yVarName, localMonth, transformedData) => {
  transformChartDataMonthAndQuater(chartData, xVarName, yVarName, localMonth, transformedData);
};
const transformChartDataYear = (chartData, xVarName, yVarName, localYear, transformedData) => {
  for (const element of chartData) {
    if (localYear === parseInt(element[xVarName], 10)) {
      transformedData.push(element);
    } else {
      let item = {};
      item[xVarName] = localYear;
      item[yVarName] = 0;
      transformedData.push(item);
    }
  }
};
const transformChartDataQuater = (chartData, xVarName, yVarName, localQuarter, transformedData) => {
  transformChartDataMonthAndQuater(chartData, xVarName, yVarName, localQuarter, transformedData);
};
// For drill down feature transforming data from quarterly to monthly vice versa
const ChartMonthData = (chartData, xVarName, yVarName) => {
  let transformedData = [];
  for (let totalMonth = 5; totalMonth >= 0; totalMonth -= 1) {
    const localDate = subtractMonths(totalMonth);
    const month_data = localDate.toLocaleString('en-US', { month: 'short' });
    const year_data = localDate.getFullYear() % percent.pr100;

    let localMonth;
    localMonth = month_data + '-' + year_data;

    transformChartDataMonth(chartData, xVarName, yVarName, localMonth, transformedData);
  }
  chartData = transformedData;
  return chartData;
};
const ChartYearData = (chartData, xVarName, yVarName) => {
  let transformedData = [];
  for (let totalYear = 3; totalYear >= 0; totalYear -= 1) {
    const localDate = subtractYears(totalYear);
    const localYear = localDate.getFullYear();

    transformChartDataYear(chartData, xVarName, yVarName, localYear, transformedData);
  }
  chartData = transformedData;
  return chartData;
};
const ChartQuaterData = (chartData, xVarName, yVarName) => {
  let transformedData = [];
  for (let totalQuarter = 4; totalQuarter >= 0; totalQuarter -= 1) {
    const localDate = subtractQuarters(totalQuarter);
    const localQuarter = 'Q' + getQuarter(localDate) + '-' + localDate.getFullYear();

    transformChartDataQuater(chartData, xVarName, yVarName, localQuarter, transformedData);
  }
  chartData = transformedData;
  return chartData;
};
function transformChartData(chartData, xVarName, yVarName, color) {
  let result = [];
  let hasMatch;

  // If data not exist then add 0
  if (xVarName === 'Month') {
    chartData = ChartMonthData(chartData, xVarName, yVarName);
  } else if (xVarName === 'Year') {
    chartData = ChartYearData(chartData, xVarName, yVarName);
  } else if (xVarName === 'Quarter') {
    chartData = ChartQuaterData(chartData, xVarName, yVarName);
  } else {
    //Invalid Case
  }
  for (let chartItem of chartData) {
    hasMatch = false;
    for (const element of result) {
      let data = element;
      if (data[xVarName] === chartItem[xVarName]) {
        element[yVarName] = element[yVarName] + chartItem[yVarName];
        hasMatch = true;
        break;
      }
    }
    if (hasMatch === false) {
      let item = {};
      item[xVarName] = chartItem[xVarName];
      item[yVarName] = chartItem[yVarName];
      item['caption'] = chartItem[xVarName];
      item['title'] = chartItem[xVarName];
      if (color?.length) {
        item['color'] = color;
      }
      result.push(item);
    }
  }

  runningData = result;
}

// Generate the bars on behalf of transformed data
function buildBar(id, xVarName, yVarName, pageName, onBarChartClicked) {
  // Get the width of the container
  const containerWidth = parseInt(select(`#${id}`)?.style('width'), 10);
  svgWidth = containerWidth;

  selectAll('#' + id + ' svg').remove();
  let chart = select('#' + id);

  chart = chart
    // append svg element inside #chart
    .append('svg')
    .attr('class', 'trainingBarChartSvg')
    .style('width', containerWidth + 'px')
    .style('height', svgHeight + 'px');

  const height = svgHeight - margin.top - margin.bottom;
  const width = svgWidth - margin.left - margin.right;

  let xAry = runningData.map(function (el) {
    return el[xVarName];
  });

  // Define your labels based on the view
  const getXLabel = (labelText) => {
    if (screen.isDesktop) {
      return labelText;
    } else {
      return labelText[0] === 'Jan' || labelText[0] === 'Nov' ? labelText : [labelText[0]];
    }
  };

  // Create x & y axis scales
  let xScale = scaleBand()
    .domain(xAry)
    .rangeRound([0, xVarName === 'Month' && screen.isTablet ? '270' : width])
    .padding(barPadding[xVarName])
    .paddingOuter(0.1);

  let yMax = max(runningData, function (d) {
    return parseInt(d[yVarName]);
  });

  yMax = yMax > barchart.hundred ? yMax : barchart.hundred;
  let yScale = scaleLinear().domain([0, yMax]).range([height, 0]);

  // Create grid on y-axis
  chart
    .append('g')
    .attr('class', 'backgrid-y')
    .attr('transform', 'translate(' + margin.left + ',' + (margin.top + barchart.five) + ')')
    .call(axisLeft().scale(yScale).ticks(barchart.five).tickSize(-width, 0, 0).tickFormat(''))
    .attr('opacity', '0.1');

  // Append the bar
  let bar = chart
    .selectAll('.bar')
    .data(runningData)
    .enter()
    .append('g')
    .style('cursor', 'pointer')
    .attr('transform', function (d) {
      return 'translate(' + xScale(d[xVarName]) + ', ' + margin.top + ')';
    });

  // Create x-axis
  chart
    .append('g')
    .attr('class', 'axis axis-x')
    .attr(
      'transform',
      'translate(' + margin.left + ',' + (height + margin.top + barchart.five) + ')',
    )
    .call(
      axisBottom(xScale)
        .ticks(xAry.length)
        .tickFormat(function (_d, i) {
          if (xVarName === 'Month') {
            chart
              .selectAll('.axis-x .tick text') // Select the text elements in the x-axis
              .each(function (d, i) {
                const labelText = runningData[i]?.caption?.split('-'); // Split the label by hypen
                const screenLabelText = getXLabel(labelText);

                // Clear existing text content in the <text> element
                select(this).text(''); // Removing text content
                // Append new <tspan> elements for each line of text
                screenLabelText.forEach((line, index) => {
                  select(this) // Select the current text element
                    .append('tspan')
                    .attr('x', NUMBER_ARRAY.zero) // Align to center
                    .attr(
                      'dy',
                      index > 0
                        ? screen.isDesktop
                          ? NUMBER_ARRAY.ten
                          : NUMBER_ARRAY.twelve
                        : NUMBER_ARRAY.five,
                    )
                    .style('text-anchor', 'end')
                    .text(line);
                });
              });
          }

          if (!(xVarName === 'Quarter' && i === xAry.length - 1)) return runningData[i].caption;
          else return 'Current Q';
        }),
    )
    .style('font-size', '12px')
    .style('font-family', "'JohnsonText-Regular'");

  // Create y-axis
  chart
    .append('g')
    .attr('class', 'axis axis-y')
    .attr('transform', 'translate(' + margin.left + ',' + (margin.top + barchart.five) + ')')
    .call(axisLeft(yScale).ticks(barchart.four))
    .style('font-size', '12px')
    .style('font-family', "'JohnsonText-Regular'");

  select('#' + id)
    .selectAll('.domain')
    .attr('stroke', 'white');

  // Add events on all the bars
  bar
    .append('rect')
    .attr('class', 'complianceBar')
    .attr('y', function (d) {
      return yScale(d.Percentage) + margin.top - barchart.fifteen;
    })
    .attr('x', function () {
      return margin.left;
    });

  bar
    .selectAll('rect')
    // set width base on range on ordinal data
    .attr('width', xScale.bandwidth())
    .attr('height', function (d) {
      return height - yScale(d[yVarName]);
    });

  // Format all the bars
  bar
    .selectAll('rect')
    .transition()
    .delay(function (_d, i) {
      return i * barchart.threeHundred;
    })
    .duration(barchart.fiveHundred)
    .attr('fill', function (d, i) {
      return runningData[i].color ? runningData[i].color : getColor(d[yVarName]);
    })
    .attr('opacity', function (d) {
      return d['op'];
    });

  const tooltip = select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('padding', '10px')
    .style('font-size', '14px')
    .style('font-family', "'JohnsonText-Regular'")
    .style('color', '#FFFFFF')
    .style('background', 'black')
    .style('box-shadow', '0px 4px 24px 0px rgba(0, 0, 0, 0.24)')
    .style('border-radius', '11px')
    .style('pointer-events', 'none')
    .style('opacity', 0);

  // On hover to show the data
  bar
    .on('mouseover', function (event, d) {
      tooltip.transition().duration(barchart.twoHundred).style('opacity', 1.0);
      tooltip
        .html(`${d[xVarName]} (${d[yVarName]}%)`)
        .style(
          'left',
          event.pageX - tooltip.node().getBoundingClientRect().width / barchart.two + 'px',
        )
        .style(
          'top',
          event.pageY - tooltip.node().getBoundingClientRect().height - barchart.twentyeight + 'px',
        );
    })
    .on('mouseout', function () {
      tooltip.transition().duration(barchart.fiveHundred).style('opacity', 0);
    })
    .on('click', (e, d)=>{
      let month = d.Month !== undefined ? d.Month : '', querter = d.Quarter !== undefined ? d.Quarter : '', year = d.Year !== undefined ? d.Year+"" : ''; 
      onBarChartClicked(pageName, "Trainings Completed On Time", getType(d.Percentage), '' , month, querter, year)
    });
}

const margin = { top: 20, left: 30, bottom: 30, right: 10 };
let svgHeight, svgWidth, screen;

export const BarChart = ({
  id,
  setValue,
  width,
  height,
  data,
  level,
  color,
  pageName,
  onBarChartClicked,
}) => {
  svgHeight = height;
  svgWidth = width;
  screen = useScreen();

  useEffect(() => {
    const chartOptions = {
      xaxis: 'Quarter',
      xaxisl1: 'Month',
      xaxisl2: 'Year',
      yaxis: 'Percentage',
    };

    if (data) {
      let transformData = [];

      _.forEach(data, (item) => {
        let dataObject = {};
        let year = item['YEAR']?.toString().substring(barchart.two);

        dataObject[chartOptions.xaxisl2] = item['YEAR'];
        if (level === 'Quarter')
          dataObject[chartOptions.xaxis] = 'Q' + item['QUARTER'] + '-' + item['YEAR'];
        if (level === 'Month')
          dataObject[chartOptions.xaxisl1] = item['MONTH']
            ? item['MONTH'].toString().substring(0, barchart.three) + '-' + year
            : '';

        dataObject[chartOptions.yaxis] = item['DATA'];

        transformData.push(dataObject);
      });

      transformChartData(transformData, level, chartOptions.yaxis, color);
      buildBar(id, level, chartOptions.yaxis, pageName, onBarChartClicked);
    }
  }, [id, data, setValue, level]);

  window.addEventListener('resize', () => {
    buildBar(id, level, 'Percentage');
  });

  return <div id={id} className='chart barChart'></div>;
};
