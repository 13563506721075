import apiAction from 'common/redux/actions/apiAction';
import { POST_LARGE_DOWNLOAD_REPORT_URL, POST_DOWNLOAD_REPORT_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';
import { NUMBER_ARRAY } from 'common/global/constants';

export const getLargeReportDownload = (data) => {
  return {
    type: 'POST_LARGE_REPORT_DOWNLOAD',
    payload: data,
  };
};

export const SetLargeReporDownloadStateEmpty = (data = '') => {
  return {
    type: 'POST_LARGE_REPORT_DOWNLOAD',
    payload: data,
  };
};


export const TriggerLargeReportDownload = (data) => {
  if (data.status === NUMBER_ARRAY.fourHundred) {
    return {
      type: 'INIT_LARGE_REPORT_DOWNLOAD',
      payload: data,
    };
  } else {
    logError('Error occurred in fetching Report Data for download.');
  }
};

const getReportDownload = (data) => {
  return {
    type: 'POST_REPORT_DOWNLOAD',
    payload: data,
  };
};

export const LargeReportDownloadAction = (configParams) => {
  return apiAction.postAPI({
    url: POST_LARGE_DOWNLOAD_REPORT_URL(configParams.reportType, configParams.teamType),
    data: configParams,
    onSuccess: getLargeReportDownload,
    onFailure: (err) => logError('Error occurred in fetching Large Report Data for download.', err),
    label: 'FETCH_LARGE_REPORT_DOWNLOAD',
  });
};

export const ReportDownloadAction = (configParams) => {
  return apiAction.postAPI({
    url: POST_DOWNLOAD_REPORT_URL(configParams.reportType, configParams.teamType),
    data: configParams,
    onSuccess: getReportDownload,
    onFailure: (err) => TriggerLargeReportDownload(err),
    label: 'FETCH_REPORT_DOWNLOAD',
    responseType: 'blob',
  });
};
