/**
* PopUpDetailsReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const PopUpDetailsReducer = (state = {}, action) => {
    if (action.type === 'GET_POPUP_LISTING_DATA') {
      return {
        ...state,
        popupDetailsData: action.payload,
      };
    } else if (action.type === 'API_START') {
      if (action.payload === 'FETCH_MYPERFORMANCELISTING_DATA') {
        return {
          ...state,
          isLoadingPopupListingDataForMyOrg: true,
        };
      } else {
        return state;
      }
    } else if (action.type === 'API_END') {
      if (action.payload === 'FETCH_MYPERFORMANCELISTING_DATA') {
        return {
          ...state,
          isLoadingPopupListingDataForMyOrg: false,
        };
      } else {
        return state;
      }
    } else {
      return state;
    }
};
