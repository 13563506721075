import { Typography } from '@mui/material';
import { Box, Button, Grid, InputLabel } from '@mui/material';
import { RedTheme } from 'common/global';
import { DateRangePicker,ToolTipWrapper } from 'common/components';
import { TapGlobalMessages } from 'TAP/global';
import { CustomButton } from './customButton';
import { CustomDropDown } from './customDropDown';
import { Fragment, useEffect, useState } from 'react';
import { GenericAutocomplete } from './genericAutocomplete';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { UserInputPopup } from 'TAP/components/UserInputPopup';
import { FavDropDown } from './FavDropDown';
import { useScreen } from 'common/hooks/useScreen';
import { Divider } from 'antd';
import { labels, nameValidationRegex, NUMBER_ARRAY } from 'common/global/constants';
import { IOSSwitch } from 'common/components/switch';
import { ReactComponent as Info } from '../assets/images/info-icon-required-field.svg';

export const AdminQuickFilter = ({
  selectFav,
  quickFilterEditMode,
  dirtyCheckEditMode,
  handleDirtyCheckFavFilterName,
  disableAddToFavBtn,
  favList,
  AddFavoriteFilter,
  handleFavDelete,
  favName,
  saveFavHandler,
  getEqualityCheck,
  handleAdminStatusChange,
  legalEntityOptions,
  legalEntityValues,
  countryOptions,
  countryValues,
  workerTypeOptions,
  workerTypeValues,
  partnerCompanyOptions,
  partnerCompanyValues,
  resetSearch,
  applyFilterHandler,
  mrcNameOptions,
  mrcNameValues,
  courseNameOptions,
  courseSelectedOption,
  userOptions,
  userValues,
  managerOptions,
  managerValues,
  exportData,
  selectedDate,
  exportTrainingHandler,
  favUserInputValidation,
  inputFieldHeader,
  handleClose,
  handleHierarchySwitchSelect,
}) => {
  let redtheme = RedTheme;
  // Fetch MyComplianceStatus data and dashboard data from redux store
  // MyComplianceStatusvalues conditions for color
  // MyComplianceStatusvalues data

  const USER = 'user';
  const MANAGER = 'manager';
  const MRC = 'MRC';
  const COURSE_CODE = 'CourseCode';
  const LEGALENTITY = 'LegalEntity';
  const COUNTRY = 'Country';
  const WORKER_TYPE = 'WorkerType';
  const PARTNER_COMPANY = 'PartnerCompany';
  const DATE = 'Date';
  const FAVOURITE = 'Favourite';

  const [userSwitch, setUserSwitch] = useState(true);
  const [managerSwitch, setManagerSwitch] = useState(true);
  const [mrcSwitch, setMRCSwitch] = useState(true);
  const [userChecked, setUserChecked] = useState(false);
  const [managerChecked, setManagerChecked] = useState(false);
  const [mrcChecked, setMrcChecked] = useState(false);
  const [courseChecked, setCourseChecked] = useState(false);
  const [courseCodeSwitch, setCourseCodeSwitch] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [newFilterName, setNewFilterName] = useState(selectFav);
  const [editModeNameValidation, setEditModeNameValidation] = useState(false);
  const [isHierarchyTraceabilityOn, setIsHierarchyTraceabilityOn] = useState(false);

  const screen = useScreen();

  const secondaryButtonStyle = {
    paddingTop: '7px',
    width: screen.isTablet ? '60px' : '120px',
    height: '32px',
    borderRadius: '8px',
    marginTop: '15px',
    marginLeft: screen.resolveStyles({
      mobile: '0px',
      tablet: '10px',
      desktop: '20px',
    }),
    color: redtheme.palette.text.primary,
    border: `1px solid ${redtheme.palette.text.primary}`,
    bgcolor: redtheme.palette.background.default,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    fontFamily: '"JohnsonText-Medium"',
    '&:focus': { outline: 'none' },
  };

  const saveChangeBtnStyle = {
    paddingTop: '7px',
    width: '100%',
    height: '32px',
    borderRadius: '8px',
    marginLeft: screen.resolveStyles({
      mobile: '0px',
      tablet: '10px',
      desktop: '20px',
    }),
    marginTop: '15px',
    color: redtheme.palette.button.white,
    bgcolor: redtheme.palette.primary.main,
    textTransform: 'none',
    fontWeight: screen.isMobile ? 300 : 500,
    fontSize: screen.isMobile ? '14px' : '16px',
    lineHeight: screen.isMobile ? '14px' : '16px',
    fontFamily: '"JohnsonText-Medium"',
    '&:hover': {
      backgroundColor: redtheme.palette.primary.main,
      color: redtheme.palette.button.white,
      fontFamily: '"JohnsonText-Medium"',
    },
    '&:focus': { outline: 'none' },
  };

  const switchChange = (_e, type) => {
    if (type === USER) {
      setUserSwitch(!userSwitch);
      setUserChecked(!userChecked);
    } else if (type === MANAGER) {
      setManagerSwitch(!managerSwitch);
      setManagerChecked(!managerChecked);
    } else if (type === MRC) {
      setMRCSwitch(!mrcSwitch);
      setMrcChecked(!mrcChecked);
    } else if (type === COURSE_CODE){
      setCourseCodeSwitch(!courseCodeSwitch);
      setCourseChecked(!courseChecked);
    }
  };

  const handleHierarchySwitch = () => {
    setIsHierarchyTraceabilityOn(!isHierarchyTraceabilityOn);
    handleHierarchySwitchSelect()
  };

  const resetSwitch = () => {
    setUserSwitch(true);
    setManagerSwitch(true);
    setMRCSwitch(true);
    setCourseCodeSwitch(true);
    setUserChecked(false);
    setManagerChecked(false);
    setMrcChecked(false);
    setCourseChecked(false);

  };

  const getLabeledOptions = (option, type) => {
    if (type === USER) {
      return `(${option.user_id}) ${option.full_name}`;
    } else if (type === MANAGER) {
      return `(${option.manager_id}) ${option.manager_ful_lname}`;
    } else if (type === MRC) {
      return option.mrc !== undefined ? option.mrc : option;
    } else if (type === COURSE_CODE) {
      return `(${option.course_code}) ${option.training_title}`;
    }
  };

  const QuikFilterHeader = () => {
    return (
      <Typography
        component='h4'
        variant='h4'
        sx={{
          fontSize: screen.isMobile ? '20px' : '25px',
          lineHeight: screen.isMobile ? '24px' : '32px',
          fontWeight: 700,
          marginTop: screen.isTablet ? '18px' : '25px',
          fontFamily: '"JohnsonDisplay-Bold"',
        }}
      >
        {isEditingMode ? 'Edit filter' : 'Quick Filters'}
      </Typography>
    );
  };

  const ResetButton = () => {
    return (
      <Button
        data-testid='requestToAddOrRemoveTrainingBtn'
        onClick={() => {
          resetSearch(isEditingMode);
          resetSwitch();
        }}
        sx={secondaryButtonStyle}
      >
        Reset
      </Button>
    );
  };

  const ApplyFilterButton = () => {
    return (
      <Button
        data-testid='goToSummit'
        onClick={applyFilterHandler}
        disabled={isEditingMode}
        variant='contained'
        sx={{
          padding: '6px 14px',
          width: screen.isTablet ? '110px' : '120px',
          height: '32px',
          borderRadius: '8px',
          marginTop: '15px',
          marginLeft: screen.resolveStyles({
            mobile: '0px',
            tablet: '10px',
            desktop: '20px',
          }),
          color: redtheme.palette.background.default,
          bgcolor: redtheme.palette.text.primary,
          fontSize: '16px',
          lineHeight: '16px',
          textTransform: 'capitalize',
          fontWeight: 500,
          fontFamily: '"JohnsonText-Medium"',
          '&:hover': {
            backgroundColor: redtheme.palette.text.primary,
            color: redtheme.palette.background.default,
          },
        }}
      >
        Apply Filters
      </Button>
    );
  };

  const ExportDataButton = () => {
    return (
      <Fragment>
        <CustomButton
          // width='162px'
          isDisabled={isEditingMode}
          buttonText={labels.exportDataTableText}
          clickHandler={exportData}
        ></CustomButton>
      </Fragment>
    );
  };

  const ExportTrainingTableButton = () => {
    return (
      <CustomButton
        // width='175px'
        isDisabled={isEditingMode}
        buttonText='Export Training Table'
        clickHandler={exportTrainingHandler}
        marginLeft = {'5px'}
        marginBottom = { screen.isMobile ? '10px' : '0px'}
      />
    );
  };

  const handleInputFilterNameChange = (e) => {
    const nameFieldVal = e.currentTarget.value;
    if (nameFieldVal.match(nameValidationRegex)) {
      setEditModeNameValidation(false);
      setNewFilterName(nameFieldVal);
      // calling parent event to check dirty of name.
      handleDirtyCheckFavFilterName(nameFieldVal);
    } else {
      setEditModeNameValidation(true);
      setNewFilterName('');
    }
  };

  const handleEditSelect = () => {
    setIsEditingMode(!isEditingMode);
  };

  const FavDropdownSelect = () => {
    return (
      <FavDropDown
        options={favList}
        indicator={FAVOURITE}
        selectFav={selectFav}
        editModeNameValidation={editModeNameValidation}
        handleAdminStatusChange={handleAdminStatusChange}
        handleFavDelete={handleFavDelete}
        isEditingMode={isEditingMode}
        handleEditSelect={handleEditSelect}
        handleInputFilterNameChange={handleInputFilterNameChange}
      />
    );
  };

  const AddToFavouriteButton = () => {
    return (
      <Button
        data-testid='requestToAddFavouriteFilter'
        onClick={() => setOpenPopup(true)}
        disabled={disableAddToFavBtn}
        startIcon={<FavoriteBorderOutlinedIcon />}
        sx={{
          paddingTop: '7px',
          width: '171px',
          height: '32px',
          borderRadius: '8px',
          marginTop: '15px',
          // marginLeft: '20px',
          color: disableAddToFavBtn ? redtheme.palette.button.gray : redtheme.palette.text.primary,
          border: disableAddToFavBtn ? '' : `1px solid ${redtheme.palette.primary.main}`,
          backgroundColor: disableAddToFavBtn ? redtheme.palette.button.light_gray : 'transparent',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '16px',
          fontFamily: '"JohnsonText-Medium"',
          '&:focus': { outline: 'none' },
        }}
      >
        Add to Favourites
      </Button>
    );
  };

  const closeAndSaveButton = () => {
    return (
      <>
        <Button variant='contained' sx={secondaryButtonStyle} onClick={() => handleEditSelect()}>
          Cancel
        </Button>
        <Button
          variant='contained'
          disabled={dirtyCheckEditMode || editModeNameValidation}
          sx={saveChangeBtnStyle}
          onClick={() => saveFavHandler(newFilterName, isEditingMode)}
        >
          Save Changes
        </Button>
      </>
    );
  };

  const HeaderViewForNonMobile = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0px 0 0px',
            background: redtheme.palette.box.white,
            // padding: '0 16px 0 0px',
          }}
        >
          {QuikFilterHeader()}
          <Box>
            {!screen.isMobile ? (
              <>
                {ResetButton()}
                <>{ApplyFilterButton()}</>
                {ExportDataButton()}
                {ExportTrainingTableButton()}
              </>
            ) : (
              AddToFavouriteButton()
            )}
          </Box>
        </Box>
        {!screen.isMobile ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              margin: '0px 0 12px',
              background: redtheme.palette.box.white,
              padding: '0px',
            }}
          >
            <Box sx={{ width: '50%' }}>{FavDropdownSelect()}</Box>
            <Box sx={{display: 'inline-flex', }}>
              <IOSSwitch
                id='managerTraceAbility'
                onChange={handleHierarchySwitch}
                checked={isHierarchyTraceabilityOn}
                data-testid='managerTraceAbility'
              />
              <Typography sx={ { fontSize: '16px', padding: '0px 10px' }}>
                {TapGlobalMessages.includeHierarchyCaption}
              </Typography>
              <ToolTipWrapper
                title={TapGlobalMessages.hierarchyInfo}
                placement='top'
                header=''
              >
              <Info />
              </ToolTipWrapper>
            </Box>
            <Box sx={{ display: 'flex' }}>
              {isEditingMode ? closeAndSaveButton() : AddToFavouriteButton()}
            </Box>
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              {FavDropdownSelect()}
            </Grid>
            <Grid item xs={6}>
              {ExportDataButton()}
            </Grid>
            <Grid item xs={6}>
              {ExportTrainingTableButton()}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!quickFilterEditMode) {
      setIsEditingMode(false);
    }
  }, [quickFilterEditMode]);

  /**Set filter name here for quick filter in Edit mode. */
  useEffect(() => {
    if (selectFav !== '' && isEditingMode) {
      setNewFilterName(selectFav);
    }
  }, [selectFav, isEditingMode]);

  return (
    <>
      <Box
        sx={{
          width: 'fit-content',
          height: '930px',
        }}
      >
        {/*!screen.isMobile && */ HeaderViewForNonMobile()}
        <Grid container spacing={2}>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
          >
            <GenericAutocomplete
              options={userOptions}
              values={userValues}
              switchChangeHandler={switchChange}
              checked={userChecked}
              getLabeledOptions={getLabeledOptions}
              handleAdminStatusChange={handleAdminStatusChange}
              getEqualityCheck={getEqualityCheck}
              type={USER}
              placeholder={
                userSwitch
                  ? userValues?.length
                    ? `${userValues.length} Search`
                    : `Search`
                  : `${userValues.length} Selected`
              }
              heading={!userSwitch ? 'Selected WWID / User Name' : 'WWID / User Name'}
              switchIndicator={userSwitch}
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
          >
            <GenericAutocomplete
              options={managerOptions}
              values={managerValues}
              switchChangeHandler={switchChange}
              checked={managerChecked}
              getLabeledOptions={getLabeledOptions}
              handleAdminStatusChange={handleAdminStatusChange}
              getEqualityCheck={getEqualityCheck}
              type={MANAGER}
              placeholder={
                managerSwitch
                  ? managerValues?.length
                    ? `${managerValues.length} Search`
                    : `Search`
                  : `${managerValues.length} Selected`
              }
              heading={
                !managerSwitch ? 'Selected Manager WWID / User Name' : 'Manager WWID / User Name'
              }
              switchIndicator={managerSwitch}
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
            style={{display: 'none'}}
          >
            <GenericAutocomplete
              options={mrcNameOptions}
              values={mrcNameValues}
              switchChangeHandler={switchChange}
              checked={mrcChecked}
              getLabeledOptions={getLabeledOptions}
              handleAdminStatusChange={handleAdminStatusChange}
              getEqualityCheck={getEqualityCheck}
              type={MRC}
              placeholder={
                mrcSwitch
                  ? mrcNameValues?.length
                    ? `${mrcNameValues.length} Search`
                    : `Search`
                  : `${mrcNameValues.length} Selected`
              }
              heading={!mrcSwitch ? 'Selected MRC Name' : 'MRC Name'}
              switchIndicator={mrcSwitch}
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
            style={{ position: 'relative', width: '200px', display: 'none' }}
          >
            <CustomDropDown
              headerText='Legal Entity Name'
              selectedValues={legalEntityValues}
              handleAdminStatusChange={handleAdminStatusChange}
              indicator={LEGALENTITY}
              options={legalEntityOptions}
              width='100%'
              position='absolute'
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
            style={{ position: 'relative', width: '200px' }}
          >
            <CustomDropDown
              headerText='Country'
              selectedValues={countryValues}
              handleAdminStatusChange={handleAdminStatusChange}
              indicator={COUNTRY}
              options={countryOptions}
              width='100%'
              position='absolute'
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
            style={{ position: 'relative', width: '200px' }}
          >
            <CustomDropDown
              headerText='Worker Type'
              selectedValues={workerTypeValues}
              handleAdminStatusChange={handleAdminStatusChange}
              indicator={WORKER_TYPE}
              options={workerTypeOptions}
              width='100%'
              position='absolute'
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
            style={{ position: 'relative', width: '200px' }}
          >
            <CustomDropDown
              headerText='Partner Company'
              selectedValues={partnerCompanyValues}
              handleAdminStatusChange={handleAdminStatusChange}
              indicator={PARTNER_COMPANY}
              options={partnerCompanyOptions}
              width='100%'
              position='absolute'
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
            style={{  position: 'relative', width: '200px', paddingTop: '28px' }}
          >
            <InputLabel
              data-testid='date-search'
              sx={{
                position: 'absolute',
                paddingTop: screen.isTablet ? '9px' : '15px',
                fontSize: screen.isTablet ? '16px' : '12px',
                lineHeight: '12px',
                fontWeight: 500,
                fontFamily: '"JohnsonText-Bold"',
                color: 'rgba(33, 33, 33, 1)',
                height: screen.isTablet ? '28px' : NUMBER_ARRAY.zero,
                display:'contents'
              }}
            >
              Date Range
            </InputLabel>
            <DateRangePicker
              onChange={(e) => handleAdminStatusChange(e, DATE)}
              isAdmin={true}
              value={selectedDate}
              width = {'95%'}
              height = {'33px'}
              marginTop = {'1px'}
              padding = {'1px'}
            />
          </Grid>
          {/* Course Code */}
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
          >
            <GenericAutocomplete
              options={courseNameOptions}
              values={courseSelectedOption}
              switchChangeHandler={switchChange}
              checked={courseChecked}
              getLabeledOptions={getLabeledOptions}
              handleAdminStatusChange={handleAdminStatusChange}
              getEqualityCheck={getEqualityCheck}
              type={COURSE_CODE}
              placeholder={
                courseCodeSwitch
                  ? courseSelectedOption?.length
                    ? `${courseSelectedOption.length} Search`
                    : `Search`
                  : `${courseSelectedOption.length} Selected`
              }
              heading={!courseCodeSwitch ? 'Selected Course Name' : 'Course Title/Code'}
              switchIndicator={courseCodeSwitch}
            />
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 6,
              tablet: 4,
              desktop: 3,
            })}
            style={{ position: 'relative', width: '200px' }}
          >
            {screen.isMobile && ResetButton()}
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 6,
              tablet: 4,
              desktop: 3,
            })}
            style={{ position: 'relative', width: '200px' }}
          >
            {screen.isMobile && ApplyFilterButton()}
          </Grid>
          <Grid
            item
            xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}
            style={{ position: 'relative', width: '200px' }}
          >
            {screen.isMobile && (
              <Divider
                sx={{
                  marginBottom: '25px',
                  marginTop: '10px',
                  strokeWidth: 4.5,
                  border: '3px solid #E8E6E3',
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <UserInputPopup
        popupHeader='Add Filters to Favourite'
        inputFieldHeader={inputFieldHeader}
        placeholder='Enter Text'
        leftBtnText='Cancel'
        rightBtnText='Save'
        CharLimit='100 Characters'
        open={openPopup}
        handleClose={() => {
          setOpenPopup(false);
          handleClose();
        }}
        AddFavoriteFilter={AddFavoriteFilter}
        favName={favName}
        saveFavHandler={saveFavHandler}
        favUserInputValidation={favUserInputValidation}
      />
    </>
  );
};