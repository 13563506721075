import { RedTheme } from 'common/global';
import axios from 'axios';
import { EST_ONE_AM, NUMBER_ARRAY, labels } from 'common/global/constants';

const TRAINING_CRITERIA_LOW = 85;
const TRAINING_CRITERIA_HIGH = 95;

/**
 *
 * @param {string} aTime
 * @returns {string}
 * Ex:
 * Input: 2024-01-03T20:14:13.986Z
 * Output: 04 Jan 2024, 01:44:13 AM UTC
 */

export const currentDate = new Date().toISOString('en-US', { timeZone: 'UTC' }).split('T')[0];
export const currentTime = new Date()
  .toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  })
  .replace(/[^ apm\d]+/gi, '_');

export const formatTime = (refreshTimeStamp) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let formattedTimeStamp = '';

  let d = new Date(refreshTimeStamp);
  let lastRefreshedDate = d.toLocaleString('en-US', { day: '2-digit', timeZone: 'UTC' });
  let lastRefreshedMonth = monthNames[d.getMonth()];
  let lastRefreshYear = d.getFullYear();
  let lastRefreshTime = d.toLocaleTimeString('en-US', { timeZone: 'UTC' }).split(':');

  /**
   *Add zero if hour, minute or second is in single digit
   * Ex:
   * Input: 1:44:13
   * Output: 01:44:13
   */

  let formattedLastRefreshTime = lastRefreshTime
    .map((component) => (component.length === 1 ? `0${component}` : component))
    .join(':');

  formattedTimeStamp = `${lastRefreshedDate} ${lastRefreshedMonth} ${lastRefreshYear}, ${formattedLastRefreshTime} EST`;

  return formattedTimeStamp;
};

export const getTextColorForComplianceTable = (flag) => {
  let redtheme = RedTheme;
  if (flag === 'G') {
    return redtheme.palette.dataGrid.light_moderate_lime_green;
  } else if (flag === 'Y') {
    return redtheme.palette.dataGrid.yellow;
  } else if (flag === 'R') {
    return redtheme.palette.dataGrid.red;
  } else {
    return redtheme.dataGrid.very_dark_grey;
  }
};

export const getTextColor = (TrainingValue) => {
  let redtheme = RedTheme;
  const percentage = parseInt(TrainingValue, 10);
  if (percentage < TRAINING_CRITERIA_LOW) {
    return redtheme.palette.dataGrid.red;
  } else if (percentage >= TRAINING_CRITERIA_LOW && percentage < TRAINING_CRITERIA_HIGH) {
    return redtheme.palette.dataGrid.yellow;
  } else {
    return redtheme.palette.dataGrid.light_moderate_lime_green;
  }
};

export const getDownloadedExcelReportData = (responseBlob) => {
  try {
    const responseBlobData = JSON.parse(responseBlob);
    const responseBlobType = typeof responseBlobData;
    if (responseBlobType === 'string' && responseBlobData === 'No') {
      return false;
    } else if (responseBlobType === 'object') {
      return responseBlobData?.data && responseBlobData?.data?.length === NUMBER_ARRAY.zero;
    } else return false;
  } catch (e) {
    return false;
  }
};
export const downloadExcelReport = async (requestURL, fileName, params = {}) => {
  if (!params) {
    // Check if params are null or undefined; if so, construct params from the requestURL
    params = {}; // to construct from requestURL we need empty object
  }

  // generation of params from requestURL
  if (requestURL?.split('&')?.length > NUMBER_ARRAY.zero) {
    requestURL?.split('&')?.map((item, index) => {
      const obj = item?.split('=')[NUMBER_ARRAY.zero];
      const val = item?.split('=')[NUMBER_ARRAY.one];
      if (index !== NUMBER_ARRAY.zero && params) {
        // Storing decoded values as axios again encode the params
        params[decodeURIComponent(obj)] = decodeURIComponent(val);
      }
    });
    requestURL = requestURL?.split('&')[0];
  }
  let reponseType = '';
  const response = await axios.get(requestURL, {
    responseType: 'blob',
    params: params,
  });
  try {
    const responseBlob = new Blob([response?.data], { type: 'text/plain;charset=utf-8' });
    reponseType = responseBlob.text().then((responseBlob) => {
      if (getDownloadedExcelReportData(responseBlob)) {
        return 'NO_DATA_FOUND';
      } else {
        /**
         * Checking the data response length. So,if the response from api is "No" then it's length is 4
         * (Length include double quotes as well) and other wise it will go for else condition to download the Excel!
         */
        if (response?.data?.size === NUMBER_ARRAY.four) return 'LIMIT_EXCEED';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return '';
      }
    });
    return reponseType;
  } catch (error) {
    return error;
  }
};

export const getComplianceStatusTextColor = (complianceStatus) => {
  let redtheme = RedTheme;

  if (complianceStatus === 'Non Compliant') {
    return redtheme.palette.dataGrid.red;
  } else if (complianceStatus === 'At Risk') {
    return redtheme.palette.dataGrid.yellow;
  } else {
    return redtheme.palette.text.secondary;
  }
};

export const getColorAsPerIndicator = (indicator) => {
  let redtheme = RedTheme;

  if (indicator === 'R') {
    return redtheme.palette.dataGrid.red;
  } else if (indicator === 'Y') {
    return redtheme.palette.dataGrid.yellow;
  } else {
    return redtheme.palette.fontColor.dark_moderate_lime_green;
  }
};

export const statusTranform = (status) => {
  if (status === 'nonCompliant') {
    return 'Non Compliant';
  } else if (status === 'atRisk') {
    return 'At Risk';
  } else if (status === 'compliant') {
    return 'Compliant';
  } else {
    return status;
  }
};

export const getRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
};

export const logError = (msg, err) => {
  console.log(msg, err); //NOSONAR
};

export const formatThresoldValue = (thresol) => {
  let s = '';
  let t = '';
  let c = '';
  for (let i = 0; i < thresol.length; i++) {
    c = thresol.charAt(i);
    if (c >= '0' && c <= '9') {
      s = s + c;
    } else {
      t = t + c;
    }
  }
  if (t === '>') {
    t = '> (Greater than)';
  } else if (t === '<') {
    t = '< (Less than)';
  } else if (t === '>=') {
    t = '>= (Greater than equal to)';
  } else {
    t = '<= (Less than equal to)';
  }
  const thresoldValues = {
    thresold: t,
    thresoldVal: s,
  };
  return thresoldValues;
};

/**
 * getDimensionWithUnit
 * It will return actual unit
 * Ex:
 * dimension: 2
 * unit: px
 * Output: 2px
 */
export const getDimensionWithUnit = (dimension, unit) => {
  return dimension + unit;
};

export const formatUserField = (user) => {
  return user.split('(')[1].split(')')[0];
};

export const formatOperations = (operations) => {
  const formattedOperations = operations.map((operation) =>
    formatThresoldValue(operation.logicalOperator + operation.threshold),
  );

  const formattedThresholds = formattedOperations.map((op) => op.thresold).join(', ');
  const formattedThresholdValues = formattedOperations.map((op) => op.thresoldVal).join(', ');

  return { formattedThresholds, formattedThresholdValues };
};

/**
 * Method is repsonsible to get the measurement Id based on the environment.
 * @returns string - Measurement Id for Google Analytics
 */
export const getGAMeasurementId = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  switch (environment) {
    case 'dev':
      return 'LH5XVZZBZS';
    case 'qa':
      return 'WNNXZJN80E';
    case 'production':
      return 'YRE0KSHXMN';
    default:
      return 'LH5XVZZBZS';
  }
};

/**
 * This method will confirm if it is 1 AM EST or not.
 * As per the Business ask, the site should show 'Under Maintenance' kind of message rather than showing 'User Not found'.
 * User not found was visible as the CDC pipeline is first truncating the users table and then dumping data into it. At the time of
 * truncation, the users table was empty hence 'Unauthorized Access' was visible to the end users.
 * @returns boolean
 */
export const isTimeOfRunningCDCPipeline = () => {
  // Get current local time
  const localTime = new Date();
  const newYorkTime = localTime.toLocaleString('en-US', { timeZone: 'America/New_York' });
  const newYorkHour = parseInt(newYorkTime.split(' ')[1].split(':')[0]);
  return newYorkHour === EST_ONE_AM;
};

// Function to check for conflicts for thersholds
export const checkConflict = (operatorA, operatorB, valueA, valueB) => {
  switch (operatorA) {
    case labels.greaterThan:
      return (
        (operatorB === labels.lessThan && valueA >= valueB) ||
        (operatorB === labels.lessThanOrEqualTo && (valueA >= valueB))
      );
    case labels.lessThan:
      return (
        (operatorB === labels.greaterThan && valueA <= valueB) ||
        (operatorB === labels.greaterThanOrEqualTo && valueA <= valueB)
      );
    case labels.greaterThanOrEqualTo:
      return (
        (operatorB === labels.lessThan && valueA > valueB) ||
        (operatorB === labels.lessThanOrEqualTo && valueA >= valueB)
      );
    case labels.lessThanOrEqualTo:
      return (
        (operatorB === labels.greaterThan && (valueA <= valueB)) ||
        (operatorB === labels.greaterThanOrEqualTo && valueA <= valueB)
      );
    default:
      return false;
  }
};

// Function to create conflict messages for thershold
export const createConflictMessage = (operatorA, operatorB, valueA) => {
  switch (operatorA) {
    case labels.greaterThan:
      if (operatorB === labels.lessThan) return `${labels.conflictMessageIfGreaterThan} ${valueA}.`;
      if (operatorB === labels.lessThanOrEqualTo)
        return `${labels.conflictMessageIfGreaterThan} ${valueA}.`;
      break;
    case labels.lessThan:
      if (operatorB === labels.greaterThan)
        return `${labels.conflictMessageIfLesserThan} ${valueA}.`;
      if (operatorB === labels.greaterThanOrEqualTo)
        return `${labels.conflictMessageIfLesserThan} ${valueA}.`;
      break;
    case labels.greaterThanOrEqualTo:
      if (operatorB === labels.lessThan) return `${labels.conflictMessageIfGreaterThan} ${valueA}.`;
      if (operatorB === labels.lessThanOrEqualTo)
        return `${labels.conflictMessageIfGreaterThan} ${valueA}.`;
      break;
    case labels.lessThanOrEqualTo:
      if (operatorB === labels.greaterThan)
        return `${labels.conflictMessageIfLesserThan} ${valueA}.`;
      if (operatorB === labels.greaterThanOrEqualTo)
        return `${labels.conflictMessageIfLesserThan} ${valueA}.`;
      break;
    default:
      return '';
  }
};

//Function to check for single thershold conflicts
export const checkSingleThresholdConflict = (threshold, value) => {
  const errors = [];
  if (value.trim() !== '') {
    const numericValue = Number(value);
    if (
      (threshold === labels.lessThan || threshold === labels.lessThanOrEqualTo) &&
      numericValue === NUMBER_ARRAY.ZERO
    ) {
      errors.push(`${labels.conflictMessageIfGreaterThan} ${numericValue}.`);
    }
    if (
      (threshold === labels.greaterThan || threshold === labels.greaterThanOrEqualTo) &&
      numericValue === NUMBER_ARRAY.hundred
    ) {
      errors.push(`${labels.conflictMessageIfLesserThan} ${numericValue}.`);
    }
  }
  return errors;
};