import { SelectedTheme, RedTheme } from 'common/global';
import { Box, Typography, Link } from '@mui/material';
import React from 'react';
// import Link from '@mui/material/Link';
import { ReactComponent as JNJLogo } from 'common/assets/images/JNJ_Logo.svg';
import { ReactComponent as JNJMobileLogo } from 'common/assets/images/JNJ_LOGO_MOBILE.svg';
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';
/**
 * Footer.
 * Logo
 * FQA
 * Contact Us
 * Use JNJLogo
 * Use JNJ Logo Component
 * Use Box component
 * Typo component
 * Link Component
 * All Rights Reserved
 * @returns {JSX.Element}
 */
export const Footer = () => {
  let redtheme = RedTheme;
  const theme = SelectedTheme();
  const screen = useScreen();
  const footerLinks = [ 
    { name: 'FAQ', path: 'https://jnj.sharepoint.com/teams/KEY2/SitePages/TAP.aspx' },
    { name: 'Contact Us', path: 'https://jnj.sharepoint.com/sites/KEY/SitePages/TAP.aspx' },
  ];

  return (
    <>
      <Box
        component='footer'
        sx={{
          padding: screen.resolveStyles({ mobile: '0 16px', tablet: '0 24px', desktop: '0 72px' }),
          width: '100%',
          height: screen.isMobile ? '72px' : '100px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#FFFFFF',
          boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.12)',
          borderTop: '1px solid #D8D8D8',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: screen.resolveStyles({ mobile: '16px', tablet: '46px' }, 'tablet'),
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {screen.isMobile ? (
              <JNJMobileLogo fill={`${theme.palette.primary.main}`} height='37' width='87.91' />
            ) : (
              <JNJLogo fill={`${theme.palette.primary.main}`} height='24' width='257.91' />
            )}
          </Box>
          {screen?.isDesktop &&
            footerLinks.length > 0 &&
            footerLinks.map((link, index) => (
              <Typography
                variant='caption'
                key={index}
                sx={{
                  fontWeight: NUMBER_ARRAY.fiveHundred,
                  color: redtheme.palette.fontColor.black,
                  fontFamily: '"JohnsonText-Bold"',
                }}
              >
                <Link
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: screen.isMobile ? '12px' : '14px',
                    lineHeight: '16px',
                    whiteSpace: 'nowrap',
                  }}
                  href={link.path}
                  underline='none'
                  color={`${theme.palette.text.primary}`}
                  target='_blank'
                >
                  {link.name}
                </Link>
              </Typography>
            ))}
        </Box>
        <Box
          sx={{
            flexGrow: NUMBER_ARRAY.zero,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant='caption'
            sx={{
              fontSize: screen.isMobile ? '8px' : '10px',
              lineHeight: '12px',
              fontWeight: NUMBER_ARRAY.fiveHundred,
              color: redtheme.palette.fontColor.dark_grayish_blue,
              fontFamily: '"JohnsonText-Light"',
              whiteSpace: screen.isMobile ? 'nowrap' : 'initial',
            }}
          >
            All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </>
  );
};
