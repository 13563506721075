/**
* PopUpDetailsReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const DirectReporteePopUpDetailsReducer = (state = {}, action) => {
    if (action.type === 'GET_DIRECT_REPORTEE_POPUP_LISTING_DATA') {
      return {
        ...state,
        popupDetailsData: action.payload,
      };
    } else if (action.type === 'API_START') {
      if (action.payload === 'FETCH_DIRECT_REPORTEE_LISTING_DATA') {
        return {
          ...state,
          isLoadingPopupListingDataMyDirectReportee: true,
        };
      } else {
        return state;
      }
    } else if (action.type === 'API_END') {
      if (action.payload === 'FETCH_DIRECT_REPORTEE_LISTING_DATA') {
        return {
          ...state,
          isLoadingPopupListingDataMyDirectReportee: false,
        };
      } else {
        return state;
      }
    } else {
      return state;
    }
};
