import React, { Fragment, useState } from 'react';
import { DateRangePicker, ToolTip, ToolTipWrapper } from 'common/components';
import { GenericAutocomplete } from './genericAutocomplete';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
  Link,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Box
} from '@mui/material';
import { TapGlobalMessages } from 'TAP/global';
import { RedTheme, SelectedTheme } from 'common/global';
import CloseIcon from '@mui/icons-material/Close';
import { useScreen } from 'common/hooks/useScreen';
import { Stack } from '@mui/system';
import { CustomDropDown } from './customDropDown';
import { myOrgPerform, NUMBER_ARRAY } from 'common/global/constants';
import { fontsize } from 'TAP/styles/genericStyles/fontSize';
import { lineHeight } from 'TAP/styles/genericStyles/lineHeight';
import { textTransform } from 'TAP/styles/genericStyles/textTransform';
import { fontFamily } from 'TAP/styles/genericStyles/fontFamily';
import { PAGE_SIZES_ARRAY } from 'common/global/constants';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { AdminQuickFilterAction } from 'TAP/redux/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  AdminEditScheduleReportPopUp,
  AdminGetScheduledReport,
  AdminScheduleReportPopUp,
  AdminDeleteScheduleReportPopUp,
} from 'TAP/redux/actions/adminAction';
import { ReactComponent as EditIcon } from '../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/delete.svg';
import { ReactComponent as Info } from '../assets/images/info-icon-required-field.svg';
import { IOSSwitch } from 'common/components/switch';
import { Toaster } from './Toaster';
import moment from 'moment';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';
import ReadOnlyFieldDatePicker from './ReadOnlyDatePicker';

export const ScheduleReport = ({
  open,
  handleClose,
  getEqualityCheck,
  handleAdminStatusChange,
  legalEntityOptions,
  legalEntityValues,
  countryOptions,
  countryValues,
  partnerCompanyOptions,
  partnerCompanyValues,
  resetSearch,
  mrcNameOptions,
  mrcNameValues,
  userOptions,
  userValues,
  managerOptions,
  managerValues,
  selectedDate,
  isHierarchyOn,
  handleHierarchySwitchScheduleReportChange,
}) => {
  let redtheme = RedTheme;
  const dispatch = useDispatch();
  const theme = SelectedTheme();
  const screen = useScreen();
  const [userSwitch, setUserSwitch] = useState(true);
  const [userEmailSwitch, setuserEmailSwitch] = useState(true);
  const [managerSwitch, setManagerSwitch] = useState(true);
  const [mrcSwitch, setMRCSwitch] = useState(true);
  const [userChecked, setUserChecked] = useState(false);
  const [userEmailChecked, setuserEmailChecked] = useState(false);
  const [managerChecked, setManagerChecked] = useState(false);
  const [mrcChecked, setMrcChecked] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState(NUMBER_ARRAY.zero);
  const [audienceEmails, setAudienceEmails] = useState([]);
  const [activeButton, setActiveButton] = useState(myOrgPerform.two);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const stateAdminQuickFilter = useSelector((state) => state.adminQuickFilterRd, shallowEqual);
  const scheduledReport = useSelector((state) => state?.adminRd?.scheduledReport);
  const errScheduleReport = useSelector((state) => state?.adminRd?.errScheduleReport);
  const savedScheduleReport = useSelector((state) => state?.adminRd?.savedScheduleReport);

  const [quickFilterType, setQuickFilterType] = useState('');
  const [selectedUserEmailValues, setselectedUserEmailValues] = useState([]);

  const [workerTypeOption, setWorkerTypeOption] = useState([]);
  const [selectedWorkerType, setSelectedWorkerType] = useState([]);

  const [schedularName, setSchedularName] = useState('');
  const [isSchedularEditable, setIsSchedularEditable] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [editSelectedScheduleObj, setEditSelectedScheduleObj] = useState({});
  const [editSelectedFilterObj, setEditSelectedFilterObj] = useState({});

  /**Frequency Constants */
  const [weekDay, setWeekDay] = useState('');
  const [freqStartDate, setFreqStartDate] = useState('');
  const [freqEndDate, setFreqEndDate] = useState('');
  const [freqTime, setFreqTime] = useState('');
  const [dateOfMonth, setDateOfMonth] = useState(NUMBER_ARRAY.zero);
  const [freqNoChangeDate, setFreqNoChangeDate] = useState(false);

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(NUMBER_ARRAY.zero);
  const [isRequired, setIsRequired] = useState(true);
  const [monthDayArr, setMonthDayArr] = useState([]);
  const [reportNameError, setReportNameError] = useState(false);
  const [reportNameValidationMsg, setReportNameValidationMsg] = useState('');
  const [userEmailError, setUserEmailError] = useState(false);
  const [userEmailValidateErrMsg, setUserEmailValidateErrMsg] = useState('');

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  /**
   * Constants
   */
  const USER = 'user';
  const USER_MAIL = 'user_mail';
  const MANAGER = 'manager';
  const MRC = 'MRC';
  const LEGALENTITY = 'LegalEntity';
  const COUNTRY = 'Country';
  const PARTNER_COMPANY = 'PartnerCompany';
  const WORKER_TYPE = 'WorkerType';
  const DATE = 'Date';
  let DATEARRAY = ['1st', '2nd', '3rd'];
  const sentSuccessMessage = 'Report Scheduled successfully';
  const deletedSuccessMessage = 'Schedule report deleted successfully';
  const textFieldHeader = {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Johnson Text',
    color: redtheme.palette.text.primary,
  };
  const headingsFontStyles = {
    fontFamily: 'Johnson Heading',
  };
  const infoText = {
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Johnson Text',
    color: redtheme.palette.fontColor.gray_orange,
    marginLeft: '5px',
  };
  const tabStyles = {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: '"Johnson Text"',
    borderBottom: 3,
    borderColor: 'divider',
  };

  const handleHierarchySwitchScheduleReport = () => {
    handleHierarchySwitchScheduleReportChange()
  }

  const columns = [
    {
      field: 'report_name',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Stack>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Report Name
          </Typography>
        </Stack>
      ),
      flex: !screen.isDesktop ? 0 : 1,
      disableColumnMenu: true,
    },
    {
      field: 'recipients',
      width: screen.isMobile ? 200 : 300,

      renderHeader: () => (
        <Stack>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Recipients</Typography>
        </Stack>
      ),
      flex: !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        const userEmail = params.value || '';
        return <ToolTip title={userEmail} placement='top' header='Recipients' noOfLetters={52} />;
      },
    },
    {
      field: 'date_created',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Stack>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Date Created</Typography>
        </Stack>
      ),
      renderCell: (params) => {
        return moment(params?.value)?.format('DD-MMM-YYYY');
      },
      disableColumnMenu: true,
      flex: !screen.isDesktop ? 0 : 1,
    },
    {
      field: 'frequency',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Stack>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Frequency</Typography>
        </Stack>
      ),
      disableColumnMenu: true,
      flex: !screen.isDesktop ? 0 : 1,
    },
    {
      field: 'actions',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Stack>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Actions</Typography>
        </Stack>
      ),
      renderCell: (params) => {
        return (
          <Fragment>
            <Stack>
              <EditIcon
                id={`${params.row.id}_Edit`}
                onClick={() => handleScheduleEdit(params.row.id, params.hasFocus)}
              />
            </Stack>
            <Stack sx={{ padding: '0px 20px' }}>
              <DeleteIcon
                id={`${params.row.id}_Del`}
                onClick={() => handleScheduleDelete(params.row.id, params.hasFocus)}
              />
            </Stack>
          </Fragment>
        );
      },
      disableColumnMenu: true,
      flex: !screen.isDesktop ? 0 : 1,
    },
  ];

  /**
   * FILTERS_TRAINING_COMPLETED_ON_TIME.
   * Tarining completed on Time filters data & settings
   */
  const FREQUENCY_FILTER = {
    data: [
      { name: 'Daily', id: myOrgPerform.one, testId: 'btn-year' },
      { name: 'Week', id: myOrgPerform.two, testId: 'btn-quarterly' },
      { name: 'Month', id: myOrgPerform.three, testId: 'btn-month' },
    ],
    settings: {
      background: redtheme.palette.stack.orange,
      textTransform: textTransform.cap,
      fontFamily: fontFamily.light,
      fontWeight: 700,
      fontSize: fontsize.fs14,
      lineHeight: lineHeight.lH16,
    },
  };

  const onSuccessToasterClose = () => {
    setShowSuccessMsg(false);
  };

  const converetedTime = (dbTime) => {
    const time = new Date(dbTime);
    const hours = String(time.getUTCHours()).padStart(2, '0');
    const minutes = String(time.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const convertedDate = (dateToConvert) => {
    return new Date(dateToConvert).toLocaleDateString('en-US', { timeZone: 'UTC' }).toString();
  };

  /**
   * @description Get entire Email Object.
   * @param {*} userEmail
   * @returns
   */
  const audienceEmailFullObj = (userEmail) => {
    const oneAudience = audienceEmails?.find((audienceObj) => audienceObj.user_email === userEmail);
    return oneAudience;
  };

  const handleTabChange = (_event, newValue) => {
    setSelectedTabValue(newValue);
    setIsSchedularEditable(false);
    resetFields();
  };

  const handleScheduleEdit = (id, hasFocus) => {
    if (hasFocus) {
      const scheduledObj = scheduledReport?.data?.find((data) => data?.id === id);
      const filterObj = JSON.parse(scheduledObj?.dataJson);

      if (scheduledObj) {
        setEditSelectedScheduleObj(scheduledObj);
        setEditSelectedFilterObj(filterObj);
        setSelectedTabValue(NUMBER_ARRAY.zero);
        prefilledSchedularEditedValue(scheduledObj);
        Object.keys(filterObj).forEach((key) => {
          prefilFilteredEditedValue(key, filterObj[key]);
        });
        setIsSchedularEditable(true);
        setSelectedId(id);
      }
    }
  };

  const handleScheduleDelete = (selectedId, hasFocus) => {
    if (hasFocus) {
      dispatch(AdminDeleteScheduleReportPopUp({ id: selectedId })).then(() => {
        getScheduledReportAPI();
        setShowSuccessMsg(true);
        setSuccessMessage(deletedSuccessMessage);
      });
    }
  };

  const prefilledSchedularEditedValue = (scheduledObj) => {
    setSchedularName(scheduledObj.Name);
    let multipleEmialIds = scheduledObj.userEmails?.split(',');
    multipleEmialIds = multipleEmialIds.map((item) => {
      const audienceObj = audienceEmailFullObj(item);
      return {
        full_name: audienceObj.full_name,
        user_email: audienceObj.user_email,
        user_id: audienceObj.user_id,
      };
    });
    const userEmail = [...multipleEmialIds];
    setselectedUserEmailValues(userEmail);
    const activeFilter =
      scheduledObj.frequency === 'Daily'
        ? myOrgPerform.one
        : scheduledObj.frequency === 'Weekly'
        ? myOrgPerform.two
        : myOrgPerform.three;
    setActiveButton(activeFilter);
    setFreqStartDate(
      new Date(scheduledObj.schedulerStartDate)
        .toLocaleDateString('en-US', { timeZone: 'UTC' })
        .toString(),
    );
    if (scheduledObj.schedulerEndDate !== null) {
      setFreqEndDate(
        new Date(scheduledObj.schedulerEndDate)
          .toLocaleDateString('en-US', { timeZone: 'UTC' })
          .toString(),
      );
      setFreqNoChangeDate(true);
    } else {
      setFreqEndDate('');
      setFreqNoChangeDate(true);
    }
    setFreqTime(converetedTime(scheduledObj.time));
    setWeekDay(scheduledObj.weekDay);
    setDateOfMonth(scheduledObj.day);
  };

  const prefilFilteredEditedValue = (filteredObjKey, filteredValue) => {
    let type = '';
    const event = new Event('input', {
      bubbles: true, // Allow the event to bubble up
      cancelable: true, // Allow the event to be canceled
    });

    switch (filteredObjKey) {
      case 'country':
        type = COUNTRY;
        Object.defineProperty(event, 'target', {
          writable: false,
          value: {
            value: filteredValue, // Set the custom target value
          },
        });
        handleAdminStatusChange(event, type);
        break;
      case 'legalEntityName':
        type = LEGALENTITY;
        // Manually set the target value
        Object.defineProperty(event, 'target', {
          writable: false,
          value: {
            value: filteredValue, // Set the custom target value
          },
        });
        handleAdminStatusChange(event, type);
        break;
      case 'managerField':
        type = MANAGER;
        handleAdminStatusChange(filteredValue, type);
        break;
      case 'mrc':
        type = MRC;
        handleAdminStatusChange(filteredValue, type);
        break;
      case 'partnerCompany':
        type = PARTNER_COMPANY;
        Object.defineProperty(event, 'target', {
          writable: false,
          value: {
            value: filteredValue, // Set the custom target value
          },
        });
        handleAdminStatusChange(event, type);
        break;
      case 'userField':
        type = USER;
        handleAdminStatusChange(filteredValue, type);
        break;
      case 'workerType':
        type = WORKER_TYPE;
        setSelectedWorkerType(filteredValue);
        break;
      case 'dateRange':
        type = DATE;
        handleAdminStatusChange(filteredValue, type);
        break;
      default:
        break;
    }
  };

  const onChangeFilter = (buttonId) => {
    if (activeButton !== buttonId) {
      setActiveButton(buttonId);

      const startDate = dayjs(freqStartDate);
      const endDate = dayjs(freqEndDate);
      const daysCount = getDaysCountBasedOnActiveButton(buttonId);
      const futureMinEndDate = getFutureDate(startDate, daysCount);
      if (freqEndDate && endDate.isBefore(futureMinEndDate)) {
        setFreqEndDate(futureMinEndDate);
      }
    }
  };
  const switchChange = (_e, type) => {
    if (type === USER) {
      setUserSwitch(!userSwitch);
      setUserChecked(!userChecked);
    } else if (type === MANAGER) {
      setManagerSwitch(!managerSwitch);
      setManagerChecked(!managerChecked);
    } else if (type === MRC) {
      setMRCSwitch(!mrcSwitch);
      setMrcChecked(!mrcChecked);
    } else if (type === USER_MAIL) {
      setuserEmailSwitch(!userEmailSwitch);
      setuserEmailChecked(!userEmailChecked);
    }
  };

  const resetSwitch = () => {
    setUserSwitch(true);
    setuserEmailSwitch(true);
    setManagerSwitch(true);
    setMRCSwitch(true);
    setUserChecked(false);
    setuserEmailChecked(false);
    setManagerChecked(false);
    setMrcChecked(false);
  };

  const resetWorkerType = () => {
    setSelectedWorkerType([]);
  };

  const resetFields = () => {
    setSchedularName('');
    setselectedUserEmailValues([]);
    setFreqStartDate('');
    setFreqEndDate('');
    setDateOfMonth(0);
    setWeekDay('');
    setFreqTime('');
    setFreqNoChangeDate(false);
    resetSearch();
    resetWorkerType();
  };

  const getLabeledOptions = (option, type) => {
    if (type === USER) {
      return `(${option.user_id}) ${option.full_name}`;
    } else if (type === MANAGER) {
      return `(${option.manager_id}) ${option.manager_ful_lname}`;
    } else if (type === MRC) {
      return option.mrc !== undefined ? option.mrc : option;
    } else if (type === USER_MAIL) {
      return `(${option.user_id}) ${option.user_email}`;
    }
  };

  const getSelectedFilterValue = (filterName) => {
    let filterValue;
    if (filterName == 'Daily') filterValue = 'Daily';
    else if (filterName == 'Week') filterValue = 'Weekly';
    else if (filterName == 'Month') filterValue = 'Monthly';
    else filterValue = filterName;
    return filterValue;
  };

  const SelectFilter = () => {
    return (
      <Stack
        direction='row'
        spacing={1}
        sx={{
          padding: '0px',
          borderRadius: '40px',
          background: FREQUENCY_FILTER?.settings.background,
          width: '53%',
        }}
      >
        {FREQUENCY_FILTER?.data?.map((filter, index) => (
          <Button
            key={index}
            onClick={() => {
              onChangeFilter(filter.id);
            }}
            variant={activeButton === filter.id ? 'contained' : 'text'}
            sx={{
              borderRadius: '40px',
              border: activeButton === filter.id ? '2px solid #f7a299' : '',
              color:
                activeButton === filter.id ? 'primary' : `${theme.palette.fontColor.gray_orange}`,
            }}
            data-testid={filter.testId}
          >
            <Typography
              sx={{
                fontFamily: FREQUENCY_FILTER?.settings.fontFamily,
                fontWeight: FREQUENCY_FILTER?.settings.fontWeight,
                fontSize: FREQUENCY_FILTER?.settings.fontSize,
                lineHeight: FREQUENCY_FILTER?.settings.lineHeight,
                textTransform: FREQUENCY_FILTER?.settings.textTransform,
              }}
            >
              {getSelectedFilterValue(filter.name)}
            </Typography>
          </Button>
        ))}
      </Stack>
    );
  };

  const daySelectBox = () => {
    return (
      <Fragment>
        <Typography sx={textFieldHeader}>
          {`Select Day ${isRequiredField(weekDay) && activeButton === myOrgPerform.two ? '*' : ''}`}
        </Typography>
        <FormControl>
          <Select
            id='select-day'
            value={weekDay}
            onChange={(event) => setWeekDay(event.target.value)}
          >
            <MenuItem value=''>
              <em>Select</em>
            </MenuItem>
            <MenuItem value={'sunday'}>Sunday</MenuItem>
            <MenuItem value={'monday'}>Monday</MenuItem>
            <MenuItem value={'tuesday'}>Tuesday</MenuItem>
            <MenuItem value={'wedensday'}>Wedensday</MenuItem>
            <MenuItem value={'thursday'}>Thursday</MenuItem>
            <MenuItem value={'friday'}>Friday</MenuItem>
            <MenuItem value={'saturday'}>Saturday</MenuItem>
          </Select>
        </FormControl>
      </Fragment>
    );
  };

  const updatedDateArray = () => {
    let suffix = 'th';
    if (DATEARRAY.length !== NUMBER_ARRAY.thirty) {
      suffix =
        DATEARRAY.length + NUMBER_ARRAY.one === NUMBER_ARRAY.twentyOne
          ? 'st'
          : DATEARRAY.length + NUMBER_ARRAY.one === NUMBER_ARRAY.twentyTwo
          ? 'nd'
          : DATEARRAY.length + NUMBER_ARRAY.one === NUMBER_ARRAY.twentyThree
          ? 'rd'
          : 'th';
      DATEARRAY.push(`${DATEARRAY.length + NUMBER_ARRAY.one}${suffix}`);
      updatedDateArray();
    }
    setMonthDayArr(DATEARRAY);
  };

  const dateOfSelectedMonth = () => {
    return (
      <Fragment>
        <Typography sx={textFieldHeader}>
          {`Select Day  of Month${
            isRequiredField(dateOfMonth) && activeButton === myOrgPerform.three ? '*' : ''
          }`}
        </Typography>
        <FormControl>
          <Select
            id='select-date'
            value={dateOfMonth}
            onChange={(event) => setDateOfMonth(event.target.value)}
          >
            <MenuItem value={0}>Select Day</MenuItem>
            {monthDayArr.map((item, index) => (
              <MenuItem key={index} value={index + 1}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Fragment>
    );
  };

  const timeSelectBox = () => {
    return (
      <Fragment>
        <Typography sx={textFieldHeader}>{`Select Time ${
          isRequiredField(freqTime) ? '*' : ''
        }`}</Typography>
        <TextField
          id='time'
          type='time'
          value={freqTime}
          helperText="UTC Time Zone"
          sx={{
            '& .MuiFormHelperText-root': {
               fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
               fontWeight: '400',
               fontSize:' 0.60rem',
               lineHeight: '1.66',
               letterSpacing: '0.03333em',
               textAlign: 'left',
               margin: '3px 0px 0px',
               color: 'red'
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          onChange={(event) => setFreqTime(event.target.value)}
        />
      </Fragment>
    );
  };

  const frequencyDay = () => {
    return activeButton === myOrgPerform.one ? (
      <Stack>{daySelectBox(myOrgPerform.one)}</Stack>
    ) : activeButton === myOrgPerform.two ? (
      <Stack>{daySelectBox(myOrgPerform.two)}</Stack>
    ) : (
      <Stack>{dateOfSelectedMonth()}</Stack>
    );
  };

  const getFormattedDate = (date) => {
    const year = date.year();
    const month = String(date.month() + 1).padStart(2, '0');
    const selectedDate = String(date.date()).padStart(2, '0');
    const formatedDate = `${year}-${month}-${selectedDate}`;
    return formatedDate;
  };

  const getDaysCountBasedOnActiveButton = (index) => {
    let daysCount = 0;
    if (index === myOrgPerform.one) {
      daysCount = NUMBER_ARRAY.one; // Daily
    } else if (index === myOrgPerform.two) {
      daysCount = NUMBER_ARRAY.seven; // Weekly
    } else if (index === myOrgPerform.three) {
      daysCount = NUMBER_ARRAY.thirty; // Monthly
    } else {
      daysCount = -NUMBER_ARRAY.one;
    }
    return daysCount + NUMBER_ARRAY.one; // +1 to skip the current startdate
  };

  const getFutureDate = (date, daysToAdd) => {
    return dayjs(date).add(daysToAdd, 'day');
  };

  const frquencyStartDatePicker = () => {
    const today = dayjs().startOf('day');
    const minDate = today;
    const dateFormat = 'DD-MMM-YYYY';

    return (
      <Fragment>
        <Typography sx={textFieldHeader}>{`Start Date ${
          isRequiredField(freqStartDate) ? '*' : ''
        }`}</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ReadOnlyFieldDatePicker
            slotProps={{
              textField: {
                placeholder: dateFormat.toLowerCase(),
              },
            }}
            sx={{ width: '100%' }}
            minDate={minDate}
            onChange={(date) => {
              if (date.isValid() && !date.isBefore(minDate)) {
                const formatedDate = getFormattedDate(date);
                setFreqStartDate(formatedDate);
                // To make end date sync with start date
                const daysCount = getDaysCountBasedOnActiveButton(activeButton);
                const futureMinEndDate = getFutureDate(date, daysCount);
                const endDate = dayjs(freqEndDate);
                if (freqEndDate && endDate.isBefore(futureMinEndDate)) {
                  setFreqEndDate(futureMinEndDate);
                }
              }
            }}
            format={dateFormat}
            disablePast
            value={freqStartDate ? dayjs(freqStartDate) : null}
          />
        </LocalizationProvider>
      </Fragment>
    );
  };

  const frequencyEndDatePicker = () => {
    const today = dayjs().startOf('day');
    const startDate = dayjs(freqStartDate);
    const daysCount = getDaysCountBasedOnActiveButton(activeButton);
    const minDate = freqStartDate
      ? getFutureDate(startDate, daysCount)
      : getFutureDate(today, daysCount);
    const dateFormat = 'DD-MMM-YYYY';

    return (
      <Fragment>
        <Typography sx={textFieldHeader}>{`End Date`}</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ReadOnlyFieldDatePicker
            disabled={freqNoChangeDate}
            slotProps={{
              textField: {
                placeholder: dateFormat.toLowerCase(),
              },
            }}
            sx={{ width: '100%' }}
            minDate={minDate}
            onChange={(date) => {
              if (date.isValid() && !date.isBefore(minDate) && !freqNoChangeDate) {
                const year = date.year();
                const month = String(date.month() + 1).padStart(2, '0');
                const selectedDate = String(date.date()).padStart(2, '0');
                const formatedDate = `${year}-${month}-${selectedDate}`;
                setFreqEndDate(formatedDate);
              }
            }}
            format={dateFormat}
            disablePast
            value={freqEndDate && !freqNoChangeDate ? dayjs(freqEndDate) : null}
          />
        </LocalizationProvider>
      </Fragment>
    );
  };

  const viewScheduleTable = () => {
    return (
      <Stack>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'date_created', sort: 'desc' }],
            },
          }}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          paginationMode='client'
          sortingMode='client'
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          pageSizeOptions={PAGE_SIZES_ARRAY}
          rowCount={totalRecords}
          onPaginationModelChange={(newPage) => {
            setPaginationModel((old) => ({
              ...old,
              page: newPage.page,
              pageSize: newPage.pageSize,
            }));
          }}
          sx={{
            border: 'none',
            '& .MuiDataGrid-cell': {
              fontSize: '14px',
              fontWeight: 450,
              fontFamily: '"J&J CircularTT Book Web"',
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
              backgroundColor: 'rgba(0, 0, 153, 0.03)',
            },
            '& .MuiDataGrid-row:nth-of-type(odd):hover': {
              backgroundColor: redtheme.palette.dataGrid.very_light_grey,
            },
            '& .MuiDataGrid-columnHeaders': {
              fontSize: 16,
              fontFamily: '"J&J CircularTT Black Web"',
            },
            '& .MuiDataGrid-row.Mui-selected': { backgroundColor: redtheme.palette.dataGrid.white },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: redtheme.palette.dataGrid.white,
            },
            '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
              backgroundColor: 'rgba(0, 0, 153, 0.03)',
            },
            '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
              backgroundColor: redtheme.palette.dataGrid.very_light_grey,
            },
            '& .MuiDataGrid-rowCount': {
              fontWeight: 'bold',
            },
            '& .MuiTablePagination-selectLabel': {
              fontFamily: '"J&J CircularTT Black Web"',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              color: redtheme.palette.dataGrid.fontGrey,
            },
            '& .MuiTablePagination-displayedRows': {
              fontFamily: '"J&J CircularTT Black Web"',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              color: redtheme.palette.dataGrid.fontGrey,
            },
            '& .MuiTablePagination-select': {
              fontFamily: '"J&J CircularTT Black Web"',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '26px',
              fontStyle: 'normal',
              color: redtheme.palette.dataGrid.fontGrey,
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-cellContent': {
              pointerEvents: 'none',
              fontWeight: '400',
              fontSize: '1rem',
            },
          }}
        />
      </Stack>
    );
  };

  const getAdminQuickFilterAPI = (searchType) => {
    dispatch(
      AdminQuickFilterAction({
        keys: '',
        searchType: searchType,
        country: '',
        mrcName: '',
        legalEntity: '',
        patnarCompany: '',
        userIDNameArray: '',
        managerIDNameArray: '',
        dateRange: '',
      }),
    ).then(() => {
      setQuickFilterType(searchType);
    });
  };

  const getScheduledReportAPI = () => {
    dispatch(AdminGetScheduledReport());
  };

  const handleSelectAudienceChange = (event, type) => {
    if (type === USER_MAIL) {
      setselectedUserEmailValues(event);
    }
  };

  const handleEmailValidation = (event) => {
    const emailCount = event.length > NUMBER_ARRAY.fortyFive;
    setUserEmailError(emailCount);
    if (emailCount) {
      setUserEmailValidateErrMsg('Maximum 45 email IDs can be added.');
    } else {
      setUserEmailValidateErrMsg('');
    }
  };

  const handleWorkerType = (event) => {
    setSelectedWorkerType(event.target.value);
  };

  const getAudienceEmailEqualityCheck = (option, value, type) => {
    return (
      type === USER_MAIL &&
      (value === undefined || value === null || option?.user_id === value?.user_id)
    );
  };

  const handleScheduleReport = () => {
    dispatch(AdminScheduleReportPopUp(createScheduleReportSavePayload()));
  };

  const handleEditScheduledReport = (event) => {
    const selectedId = event.target.id.split('_')[0];
    dispatch(AdminEditScheduleReportPopUp(createScheduleReportSavePayload(selectedId)));
  };

  const getUserEmailList = () => {
    let selectedUserEmailLst = [];
    selectedUserEmailValues.forEach((item) => {
      selectedUserEmailLst.push(item.user_email);
    });
    return selectedUserEmailLst;
  };

  const getScheduledReportTableRows = (scheduledReport) => {
    return scheduledReport.map((scheduledItem) => {
      return {
        id: scheduledItem.id,
        report_name: scheduledItem.Name,
        recipients: scheduledItem.userEmails,
        date_created: scheduledItem.createdAt,
        frequency: scheduledItem.frequency,
      };
    });
  };

  const createScheduleReportSavePayload = (id = '') => {
    const payloadObj = {
      admin: {
        country: countryValues,
        mrc: mrcNameValues,
        legalEntityName: legalEntityValues,
        partnerCompany: partnerCompanyValues,
        userField: userValues,
        managerField: managerValues,
        workerType: selectedWorkerType,
        isHierarchyOn: isHierarchyOn
      },
      scheduler: {
        userEmails: getUserEmailList(),
        name: schedularName,
        frequency:
          activeButton === myOrgPerform.one
            ? 'Daily'
            : activeButton === myOrgPerform.two
            ? 'Weekly'
            : 'Monthly',
        day: activeButton === myOrgPerform.three ? dateOfMonth : null,
        weekDay: activeButton === myOrgPerform.two ? weekDay : '',
        time: freqTime,
        dataJson: JSON.stringify({
          country: countryValues,
          mrc: mrcNameValues,
          legalEntityName: legalEntityValues,
          partnerCompany: partnerCompanyValues,
          userField: userValues,
          managerField: managerValues,
          workerType: selectedWorkerType,
          dateRange: selectedDate,
        }),
        requester: 'Admin',
        startDate: freqStartDate,
        endDate: freqEndDate,
      },
    };
    payloadObj.id = id;
    return payloadObj;
  };

  const isRequiredField = (param) => {
    return (
      (typeof param == 'string' && param === '') ||
      (Array.isArray(param) && param?.length === 0) ||
      (typeof param === 'number' && param === 0)
    );
  };

  const endDateSwitchChangeHandler = () => {
    setFreqNoChangeDate(!freqNoChangeDate);
  };

  const handleNameChange = (event) => {
    if (!/^[a-zA-Z0-9_ ]{0,250}$/.test(event.target.value)) {
      setReportNameError(true);
      setReportNameValidationMsg('Please enter report name (max 250 char & alphanumeric and spaces,undersocre only)')
    } else {
      setReportNameError(false);
      setSchedularName(event.target.value);
      setReportNameValidationMsg('');
    }
  };
  /**Edit MOde Dirty check and validations */
  const filterMandateCheck = () => {
    return (
      countryValues.length !== 0 ||
      mrcNameValues.length !== 0 ||
      legalEntityValues.length !== 0 ||
      partnerCompanyValues.length !== 0 ||
      userValues.length !== 0 ||
      managerValues.length !== 0 ||
      selectedWorkerType.length !== 0 ||
      selectedDate.length !== 0
    );
  };

  const isFilterValuearraysEqual = (selectedValuesArr, editedFilterRecordArr) => {
    let isDiffValueArr = [];
    if (selectedValuesArr.length === 0 && editedFilterRecordArr.length === 0) return false;
    selectedValuesArr.map((value, index) => {
      if (typeof value === 'object') {
        isDiffValueArr.push(JSON.stringify(value) === JSON.stringify(editedFilterRecordArr[index]));
      } else if (typeof value === 'string') {
        isDiffValueArr.push(value === editedFilterRecordArr[index]);
      }
    });
    return isDiffValueArr.includes(false);
  };

  const dirtyCheckDateRange = () => {
    const dateFormatted = selectedDate.map((date) => date.format('YYYY-MM-DD'));
    const filterObjDate = editSelectedFilterObj.dateRange.map((date) => {
      const filteredDate = convertedDate(date);
      return filteredDate;
    });
    return isFilterValuearraysEqual(dateFormatted, filterObjDate);
  };

  const dirtyCheckQuickFilter = () => {
    return (
      isFilterValuearraysEqual(countryValues, editSelectedFilterObj.country) ||
      isFilterValuearraysEqual(legalEntityValues, editSelectedFilterObj.legalEntityName) ||
      isFilterValuearraysEqual(managerValues, editSelectedFilterObj.managerField) ||
      isFilterValuearraysEqual(mrcNameValues, editSelectedFilterObj.mrc) ||
      isFilterValuearraysEqual(partnerCompanyValues, editSelectedFilterObj.partnerCompany) ||
      isFilterValuearraysEqual(userValues, editSelectedFilterObj.userField) ||
      isFilterValuearraysEqual(selectedWorkerType, editSelectedFilterObj.workerType) ||
      dirtyCheckDateRange()
    );
  };

  const dirtySelectedAudienceChecks = () => {
    let isEmailChanged = [];
    const savedUserEmails = editSelectedScheduleObj.userEmails.split(',');
    selectedUserEmailValues.map((item) => {
      isEmailChanged.push(savedUserEmails.includes(item.user_email));
    });
    return isEmailChanged.includes(false);
  };

  const dirtyCheckEditMode = () => {
    if (
      schedularName !== editSelectedScheduleObj.Name ||
      (freqStartDate !== '' &&
        freqStartDate !== convertedDate(editSelectedScheduleObj.schedulerStartDate)) ||
      (freqEndDate !== '' &&
        freqEndDate !== convertedDate(editSelectedScheduleObj.schedulerEndDate)) ||
      dateOfMonth !== editSelectedScheduleObj.day ||
      weekDay !== editSelectedScheduleObj.weekDay ||
      dirtySelectedAudienceChecks() ||
      dirtyCheckQuickFilter()
    ) {
      return true;
    } else {
      false;
    }
  };
  /**Edit MOde Dirty check and validations */

  useEffect(() => {
    if (freqNoChangeDate) {
      setFreqEndDate('');
    }
  }, [freqNoChangeDate]);

  useEffect(() => {
    if (selectedTabValue === NUMBER_ARRAY.one && open) {
      getScheduledReportAPI();
    }
  }, [selectedTabValue, open]);

  useEffect(() => {
    if (activeButton === myOrgPerform.three) {
      updatedDateArray();
    }
  }, [activeButton]);

  useEffect(() => {
    if (open && (workerTypeOption.length === 0 || audienceEmails.length === 0)) {
      if (workerTypeOption.length === 0) {
        getAdminQuickFilterAPI('workertype');
      }
      if (audienceEmails.length === 0) {
        getAdminQuickFilterAPI('useremail');
      }
    }
  }, [open, workerTypeOption, audienceEmails]);

  useEffect(() => {
    if (!scheduledReport && open) {
      getScheduledReportAPI();
    } else if (scheduledReport && open) {
      const rowData = getScheduledReportTableRows(scheduledReport.data);
      setRows(rowData);
      setTotalRecords(scheduledReport?.data?.length);
    } else if (!open) {
      setSelectedTabValue(NUMBER_ARRAY.zero);
      setActiveButton(myOrgPerform.two);
      resetFields();
    }
  }, [scheduledReport, open]);

  useEffect(() => {
    if (workerTypeOption.length && audienceEmails.length) {
      setQuickFilterType('');
    }
  }, [workerTypeOption, audienceEmails]);

  useEffect(() => {
    if (quickFilterType === 'workertype' || quickFilterType === 'useremail') {
      if (
        Object.prototype.hasOwnProperty.call(
          stateAdminQuickFilter?.quickFilterDetails?.data[0],
          'worker_type',
        )
      ) {
        setWorkerTypeOption(stateAdminQuickFilter?.quickFilterDetails?.data);
      } else if (
        Object.prototype.hasOwnProperty.call(
          stateAdminQuickFilter?.quickFilterDetails?.data[0],
          'user_email',
        )
      ) {
        setAudienceEmails(stateAdminQuickFilter?.quickFilterDetails?.data);
      }
    }
  }, [stateAdminQuickFilter, quickFilterType]);

  useEffect(() => {
    if (
      schedularName !== '' &&
      selectedUserEmailValues.length > 0 &&
      freqStartDate !== '' &&
      (activeButton == myOrgPerform.one ||
        (activeButton === myOrgPerform.three && dateOfMonth !== 0) ||
        (activeButton === myOrgPerform.two && weekDay !== '')) &&
      freqTime !== '' &&
      filterMandateCheck() &&
      dirtyCheckEditMode()
    ) {
      setIsRequired(false);
    } else {
      setIsRequired(true);
    }
  }, [
    schedularName,
    selectedUserEmailValues,
    freqStartDate,
    freqEndDate,
    dateOfMonth,
    weekDay,
    freqTime,
    activeButton,
    countryValues,
    mrcNameValues,
    legalEntityValues,
    partnerCompanyValues,
    userValues,
    managerValues,
    selectedWorkerType,
    selectedDate,
  ]);

  useEffect(() => {
    if (errScheduleReport && errScheduleReport?.code === 'duplicate'){
      setReportNameValidationMsg(errScheduleReport?.message);
      setReportNameError(true);
    }
  }, [errScheduleReport])

  useEffect(() => {
    if (savedScheduleReport) {
      setTimeout(() => {
        setSelectedTabValue(NUMBER_ARRAY.one);
      }, NUMBER_ARRAY.fiveThousand);
      setShowSuccessMsg(true);
      setSuccessMessage(sentSuccessMessage);
      resetFields();
    }
  }, [savedScheduleReport])

  return (
    <Modal open={open}>
      <Stack
        sx={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: redtheme.palette.box.white,
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '1241px',
          height: '571px',
          borderRadius: '16px',
          overflow: 'auto',
        }}
      >
        <Stack sx={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
          <Typography
            sx={[
              headingsFontStyles,
              {
                fontSize: '31px',
                lineHeight: '12px',
                fontWeight: 700,
                marginTop: '5px',
                color: redtheme.palette.text.primary,
                textAlign: 'left',
              },
            ]}
          >
            Schedule Reports
          </Typography>
          <IconButton
            className='btn-close-popup'
            sx={{
              width: '20.01px',
              padding: '0',
              marginLeft: 'auto',
            }}
            onClick={(event) => {
              // setFreqEndDateErrorMessage('');
              handleClose(event);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Tabs
          value={selectedTabValue}
          onChange={handleTabChange}
          TabIndicatorProps={{
            sx: {
              height: '3px',
              borderRadius: '3px',
              textTransform: 'none',
            },
          }}
          sx={{
            minHeight: '49px',
            marginBottom: '25px',
            '& .Mui-selected': {
              fontWeight: 'bold',
              color: `${redtheme.palette.fontColor.black_gray} !important`,
            },
          }}
        >
          <Tab label='Set Schedule' sx={tabStyles}></Tab>
          <Tab label='View Schedules' sx={tabStyles}></Tab>
        </Tabs>
        {selectedTabValue === 0 ? (
          <Stack direction='row' sx={{ width: '100%', height: '100%' }}>
            <Stack sx={{ width: '50%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id='report-name'
                    label={`Report Name ${isRequiredField(schedularName) ? '*' : ''}`}
                    value={schedularName}
                    placeholder='Enter Text'
                    variant='standard'
                    sx={{
                      width: '50%',
                      '& .MuiInputLabel-standard': {
                        fontSize: '14px',
                        color: redtheme.palette.text.primary,
                        fontFamily: '"Johnson Text"',
                        lineHeight: '12px',
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '16px',
                      },
                    }}
                    error={reportNameError}
                    focused
                    helperText={
                      reportNameValidationMsg
                    }
                    onChange={(event) => handleNameChange(event)}
                    onBlur={() => {
                      setReportNameError(false);
                      setReportNameValidationMsg('');
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ position: 'relative', marginBottom: '10px' }}>
                  {audienceEmails.length > 0 && (
                    <GenericAutocomplete
                      heading={`Select Recipients ${
                        isRequiredField(selectedUserEmailValues) ? '*' : ''
                      }`}
                      options={audienceEmails}
                      values={selectedUserEmailValues}
                      switchChangeHandler={switchChange}
                      checked={userEmailChecked}
                      getLabeledOptions={getLabeledOptions}
                      handleAdminStatusChange={handleSelectAudienceChange}
                      handleValidation={handleEmailValidation}
                      validationError={userEmailError}
                      errorMessage={userEmailValidateErrMsg}
                      getEqualityCheck={getAudienceEmailEqualityCheck}
                      type={USER_MAIL}
                      placeholder={
                        userEmailSwitch
                          ? selectedUserEmailValues?.length
                            ? `${selectedUserEmailValues.length} Search`
                            : `Search`
                          : `${selectedUserEmailValues.length} Selected`
                      }
                      switchIndicator={userEmailSwitch}
                      isFullWidth={false}
                    />
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={textFieldHeader}>
                    {`Frequency ${isRequired ? '*' : ''}`}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {SelectFilter()}
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex' }}>
                  <IOSSwitch
                    checked={freqNoChangeDate}
                    onChange={() => endDateSwitchChangeHandler()}
                  />
                  <Typography sx={[textFieldHeader, { fontSize: '16px', padding: '0px 10px' }]}>
                    No End Date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {frquencyStartDatePicker()}
                </Grid>
                <Grid item xs={6} sx={{ paddingRight: '16px' }}>
                  {frequencyEndDatePicker()}
                </Grid>
                {activeButton !== myOrgPerform.one && (
                  <Grid item xs={6}>
                    {frequencyDay()}
                  </Grid>
                )}
                <Grid item xs={6}>
                  {timeSelectBox()}
                </Grid>
              </Grid>
            </Stack>
            <Stack
              sx={{
                width: '50%',
                borderLeft: '0.5px solid #C1BBB3',
                paddingLeft: '10px',
                display: 'flex',
              }}
            >
              <Stack direction={'row'} sx={{ justifyContent: 'space-between', padding: '0px' }}>
                <Typography
                  sx={[
                    headingsFontStyles,
                    { fontSize: fontsize.fs25, fontWeight: 700, lineHeight: '32px' },
                  ]}
                >
                  Quick Filters
                </Typography>
                <Link
                  component='button'
                  variant='body2'
                  color={redtheme.palette.info.dark}
                  sx={{ fontSize: '16px' }}
                  onClick={() => {
                    resetSwitch();
                    resetSearch();
                    resetWorkerType();
                  }}
                >
                  Reset Filters
                </Link>
              </Stack>
              {isRequired && (
                <Stack
                  direction={'row'}
                  sx={{ marginRight: 'auto', alignItems: 'center', marginBottom: '18px' }}
                >
                  <Info />
                  <Typography sx={infoText}>Anyone Filter selection mandatory.</Typography>
                </Stack>
              )}
              <Stack sx={{ marginBottom: '90px' }}>
              <Box sx={{display: 'inline-flex', }}>
              <IOSSwitch
                id='managerTraceAbility'
                onChange={handleHierarchySwitchScheduleReport}
                checked={isHierarchyOn}
                data-testid='managerTraceAbility'
              />
              <Typography sx={ { fontSize: '16px', padding: '0px 10px' }}>
              {TapGlobalMessages.includeHierarchyCaption}
              </Typography>
              <ToolTipWrapper
                title={TapGlobalMessages.hierarchyInfo}
                placement='top'
                header=''
              >
              <Info />
              </ToolTipWrapper>

              </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <GenericAutocomplete
                      options={userOptions}
                      values={userValues}
                      switchChangeHandler={switchChange}
                      checked={userChecked}
                      getLabeledOptions={getLabeledOptions}
                      handleAdminStatusChange={handleAdminStatusChange}
                      getEqualityCheck={getEqualityCheck}
                      type={USER}
                      placeholder={
                        userSwitch
                          ? userValues?.length
                            ? `${userValues.length} Search`
                            : `Search`
                          : `${userValues.length} Selected`
                      }
                      heading={!userSwitch ? 'Selected WWID / User Name' : 'WWID / User Name'}
                      switchIndicator={userSwitch}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GenericAutocomplete
                      options={managerOptions}
                      values={managerValues}
                      switchChangeHandler={switchChange}
                      checked={managerChecked}
                      getLabeledOptions={getLabeledOptions}
                      handleAdminStatusChange={handleAdminStatusChange}
                      getEqualityCheck={getEqualityCheck}
                      type={MANAGER}
                      placeholder={
                        managerSwitch
                          ? managerValues?.length
                            ? `${managerValues.length} Search`
                            : `Search`
                          : `${managerValues.length} Selected`
                      }
                      heading={
                        !managerSwitch
                          ? 'Selected Manager WWID / User Name'
                          : 'Manager WWID / User Name'
                      }
                      switchIndicator={managerSwitch}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{display: 'none'}}>
                    <GenericAutocomplete
                      options={mrcNameOptions}
                      values={mrcNameValues}
                      switchChangeHandler={switchChange}
                      checked={mrcChecked}
                      getLabeledOptions={getLabeledOptions}
                      handleAdminStatusChange={handleAdminStatusChange}
                      getEqualityCheck={getEqualityCheck}
                      type={MRC}
                      placeholder={
                        mrcSwitch
                          ? mrcNameValues?.length
                            ? `${mrcNameValues.length} Search`
                            : `Search`
                          : `${mrcNameValues.length} Selected`
                      }
                      heading={!mrcSwitch ? 'Selected MRC Name' : 'MRC Name'}
                      switchIndicator={mrcSwitch}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{display: 'none'}}>
                    <CustomDropDown
                      headerText='Legal Entity Name'
                      selectedValues={legalEntityValues}
                      handleAdminStatusChange={handleAdminStatusChange}
                      indicator={LEGALENTITY}
                      options={legalEntityOptions}
                      width='100%'
                      position='absolute'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomDropDown
                      headerText='Country'
                      selectedValues={countryValues}
                      handleAdminStatusChange={handleAdminStatusChange}
                      indicator={COUNTRY}
                      options={countryOptions}
                      width='100%'
                      position='absolute'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomDropDown
                      headerText='Partner Company'
                      selectedValues={partnerCompanyValues}
                      handleAdminStatusChange={handleAdminStatusChange}
                      indicator={PARTNER_COMPANY}
                      options={partnerCompanyOptions}
                      width='100%'
                      position='absolute'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomDropDown
                      headerText='Worker Type'
                      selectedValues={selectedWorkerType}
                      handleAdminStatusChange={handleWorkerType}
                      indicator={WORKER_TYPE}
                      options={workerTypeOption}
                      width='100%'
                      position='absolute'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel
                      data-testid='date-search'
                      sx={{
                        position: 'absolute',
                        paddingTop: screen.isTablet ? '9px' : '15px',
                        fontSize: screen.isTablet ? '16px' : '12px',
                        lineHeight: '12px',
                        fontWeight: 500,
                        fontFamily: '"JohnsonText-Bold"',
                        color: 'rgba(33, 33, 33, 1)',
                      }}
                    >
                      Date Range
                    </InputLabel>
                    <DateRangePicker
                      onChange={(e) => handleAdminStatusChange(e, DATE)}
                      isAdmin={true}
                      value={selectedDate}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Grid
                container
                sx={{
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  alignSelf: 'flex-end',
                  marginBottom: '10px',
                }}
              >
                {!isSchedularEditable ? (
                  <Button
                    onClick={handleScheduleReport}
                    sx={{
                      fontWeight: 500,
                      fontSize: '18px',
                      lineHeight: '16px',
                      width: 'fit-content',
                      borderRadius: '8px',
                      marginLeft: 'auto',
                      fontFamily: '"Johnson Text"',
                      textTransform: 'none',
                      color: redtheme.palette.button.white,
                      bgcolor: redtheme.palette.primary.main,
                      '&:hover': {
                        color: redtheme.palette.button.white,
                        bgcolor: redtheme.palette.primary.main,
                        fontFamily: '"JohnsonText-Medium"',
                      },
                    }}
                    variant='contained'
                    disabled={isRequired}
                  >
                    Schedule Report
                  </Button>
                ) : (
                  <Fragment>
                    <Stack sx={{ padding: '0px 20px' }}>
                      <Button
                        id={`${selectedId}_Save`}
                        onClick={handleEditScheduledReport}
                        sx={{
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '16px',
                          width: 'fit-content',
                          borderRadius: '8px',
                          marginLeft: 'auto',
                          fontFamily: '"JohnsonText-Medium"',
                          textTransform: 'none',
                          color: redtheme.palette.button.white,
                          bgcolor: redtheme.palette.primary.main,
                          '&:hover': {
                            color: redtheme.palette.button.white,
                            bgcolor: redtheme.palette.primary.main,
                            fontFamily: '"JohnsonText-Medium"',
                          },
                        }}
                        variant='contained'
                        disabled={isRequired}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Fragment>
                )}
              </Grid>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ height: '100%' }}>{viewScheduleTable()}</Stack>
        )}
        <Toaster
          onClose={onSuccessToasterClose}
          hideDuration={NUMBER_ARRAY.tenThousand}
          severity={'success'}
          show={showSuccessMsg}
          message={successMessage}
        />
      </Stack>
    </Modal>
  );
};
