
import apiAction from 'common/redux/actions/apiAction';
import {
    POST_ADMINVIEW_TRAINING_OVER_DUE_URL,
    POST_ADMINVIEW_TRAINING_UPCOMING_DUE_URL,
    POST_SAVE_SCHEDULE_REPORT_URL,
    POST_EDIT_SCHEDULE_REPORT_URL,
    POST_DELETE_SCHEDULE_REPORT_URL
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';
import { NUMBER_ARRAY } from 'common/global/constants';

const getAdminOverdueData = data => {
  return {
    type: 'GET_ADMIN_OVERDUE_DATA',
    payload: data,
  };
};

export const AdminOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_ADMINVIEW_TRAINING_OVER_DUE_URL(),
    data: configParams,
    onSuccess: getAdminOverdueData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_ADMINOVERDUE_DATA',
  });
};
const getAdminUpcomingOverdueData = data => {
  return {
    type: 'GET_ADMIN_UPCOMINGDUE_DATA',
    payload: data,
  };
};

export const AdminUpcomingOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_ADMINVIEW_TRAINING_UPCOMING_DUE_URL(),
    data: configParams,
    onSuccess: getAdminUpcomingOverdueData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_ADMINUPCOMINGDUE_DATA',
  });
};

const getAdminScheduleReportPopUp = data => {
  return {
    type: 'GET_ALL_SCHEDULED_REPORT_DATA',
    payload: data,
  };
};

const savedAdminScheduleReportPopUp = data => {
  return {
    type: 'SAVED_SCHEDULE_REPORT_DATA',
    payload: data,
  };
};


const errorOnSaveScheduleReport = (data) => {
  if (data?.status === NUMBER_ARRAY.fiveHundred) {
    return {
      type: 'ERR_SAVE_SCHEDULED_REPORT',
      payload: data,
    };
  } else {
    logError('Error Scheduled Report Data.:');
  }
};

export const AdminScheduleReportPopUp = configParams => {
  return apiAction.postAPI({
    url: POST_SAVE_SCHEDULE_REPORT_URL(),
    data: configParams,
    onSuccess: savedAdminScheduleReportPopUp,
    onFailure: err => errorOnSaveScheduleReport(err),
    label: 'SAVE_SCHEDULED_REPORT_DATA',
  })
}

export const AdminEditScheduleReportPopUp = configParams => {
  return apiAction.postAPI({
    url: POST_EDIT_SCHEDULE_REPORT_URL(configParams.id),
    data: configParams,
    onSuccess: savedAdminScheduleReportPopUp,
    onFailure: err => errorOnSaveScheduleReport(err),
    label: 'EDIT_SCHEDULED_REPORT_DATA',
  })
}

export const AdminDeleteScheduleReportPopUp = configParams => {
  return apiAction.deleteAPI({
    url: POST_DELETE_SCHEDULE_REPORT_URL(configParams.id),
    onSuccess: getAdminScheduleReportPopUp,
    onFailure: err => logError('Error Scheduled Report Data.:', err),
    label: 'DELETE_SCHEDULED_REPORT_DATA',
  })
}

export const AdminGetScheduledReport = () => {
  return apiAction.getAPI({
    url: POST_SAVE_SCHEDULE_REPORT_URL(),
    onSuccess: getAdminScheduleReportPopUp,
    onFailure: err => logError('Error Scheduled Report Data.:', err),
    label: 'GET_ALL_SCHEDULED_REPORT_DATA',
  })
}
