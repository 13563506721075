import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SelectedTheme, RedTheme } from 'common/global';
import { TapGlobalMessages } from 'TAP/global';
import { Box, Typography, Autocomplete, TextField, Grid } from '@mui/material';
import { ToolTipWrapper } from 'common/components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DataGrid } from '@mui/x-data-grid';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as Info } from '../assets/images/info-circle.svg';
import SearchIcon from '@material-ui/icons/Search';
import {
  TPVFilterManagerSingleTeamDataWWID,
  TPVFilterManagerSingleTeamDataCurriculumTitle,
  TPVFilterManagerSingleTeamDataTrainingTitle,
  DirectReporteeManagerSingleTeamDetails,
} from 'TAP/redux/actions';
import { TrainingViewPopup, RequestToAddOrRemoveTrainings } from 'TAP/components';
import { GET_TRAININGVIEW_POPUP_REPORT } from 'TAP/redux/actions/apiPathConstants';
import { LeaveIndicator } from 'TAP/components/leaveIndicator';
import { height } from 'TAP/styles/genericStyles/height';
import { NUMBER_ARRAY, PAGE_SIZES_ARRAY, barchart } from 'common/global/constants';
import {
  getRandomNumber,
  currentDate,
  currentTime,
  downloadExcelReport,
} from 'TAP/utils/commonMethods';
import { CustomButton } from 'TAP/components/customButton';
import { useScreen } from 'common/hooks/useScreen';
import { tablePaginationClasses } from '@mui/material/TablePagination';
import { TrainingsLargeReportDownloadAction } from 'TAP/redux/actions/trainingPlanPopupViewAction';
import { Toaster } from 'TAP/components/Toaster';

export const TrainingPlanViewManagerSingleTeam = () => {
  const [userId, setUserId] = useState('');
  const theme = SelectedTheme();
  let redtheme = RedTheme;
  //filters
  const [selectedWWID, setSelectedWWID] = useState('');
  const [selectedWWIDOptions, setSelectedWWIDOptions] = useState([]);
  const [selectedWWIDValue, setSelectedWWIDValue] = useState('');
  const [selectedCurriculumID, setSelectedCurriculumID] = useState('');
  const [selectedCurriculumIDOptions, setSelectedCurriculumIDOptions] = useState([]);
  const [selectedCurriculumIDValue, setSelectedCurriculumIDValue] = useState('');
  const [selectedTrainingTitle, setSelectedTrainingTitle] = useState('');
  const [selectedTrainingTitleOptions, setSelectedTrainingTitleOptions] = useState([]);
  const [selectedTrainingTitleValue, setSelectedTrainingTitleValue] = useState('');
  const [filterClearAll, setFilterClearAll] = useState('');
  const [paginationSizeChanger, setPaginationSizeChanger] = useState(false);
  const [sortingTooltip, setSortingTooltip] = useState('');
  const [showLargeDataDownloadInfoMsg, setShowLargeDataDownloadInfoMsg] = useState(false);
  const [showLargeDataDownloadSuccessMsg, setShowLargeDataDownloadSuccessMsg] = useState(false);
  const [toasterFlow, setToasterFlow] = useState(false);
  const screen = useScreen();

  const CLICK_TO_SORT_ASCENDING = 'Click to sort ascending';
  const CLICK_TO_SORT_DESCENDING = 'Click to sort descending';

  const [orderByUser, setOrderByUser] = useState('ASC');
  const [orderByCurriculum, setOrderByCurriculum] = useState('ASC');

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  //const [totalRecord,setTotalRecord] = useState(0);
  //const totalRecord = 0;

  const dispatch = useDispatch();

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    currentPage: 1,
    orderBy: 'ASC',
    teamMember: '',
    curriculumTitle: '',
    trainingTitle: '',
    expandedRows: [],
  });

  const triggerDownloadsCompletedEvent = () => {
    setShowLargeDataDownloadInfoMsg(false);
  };

  const onSuccessToasterClose = () => {
    setShowLargeDataDownloadSuccessMsg(false);
    setToasterFlow(false);
  };

  const apiResponseOfLargeDataDownload = useSelector(
    (state) => state?.tapTrainingPlanPopupRd?.trainingPopupLargeDownloadStatus?.data,
  );

  useEffect(() => {
    if (
      apiResponseOfLargeDataDownload === TapGlobalMessages.trainingRequestApiSuccessMessage &&
      !showLargeDataDownloadInfoMsg &&
      toasterFlow === true
    ) {
      setShowLargeDataDownloadSuccessMsg(true);
    }
  }, [apiResponseOfLargeDataDownload, showLargeDataDownloadInfoMsg]);

  let getLoggedInUserDetails = useSelector((state) => state.tapUserDetailsRd);

  useEffect(() => {
    setSortingTooltip(CLICK_TO_SORT_ASCENDING);

    if (getLoggedInUserDetails && Object.keys(getLoggedInUserDetails).length) {
      setUserId(getLoggedInUserDetails?.userDetails?.data[0].user_id);
    }
  }, [getLoggedInUserDetails]);

  let tpvDRSnglTeamStateData = useSelector(
    (state) => state.tapTrainingPlanViewDirectReporteeMrgSnglTeamRd,
  );

  const getWWIDFromSearch = (wWIDValue) => {
    if (wWIDValue && typeof wWIDValue === 'object') {
      return wWIDValue.user_id;
    } else {
      return '';
    }
  };

  const getCurriculumFromSearch = (curriculumIDValue) => {
    if (curriculumIDValue && typeof curriculumIDValue === 'object') {
      return curriculumIDValue.curriculum_title;
    } else {
      return '';
    }
  };

  const getTrainingTitleFromSearch = (trainingTitleValue) => {
    if (trainingTitleValue && typeof trainingTitleValue === 'object') {
      return trainingTitleValue.training_title;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (userId) {
      setPageState((old) => ({ ...old, isLoading: true }));
      const payloadUserLogedIn = {
        userId: userId,
        page: paginationSizeChanger ? 1 : pageState.page,
        limit: pageState.pageSize,
        orderByUser: orderByUser,
        orderByCurriculum: orderByCurriculum,
        teamMember: getWWIDFromSearch(selectedWWIDValue),
        curriculumTitle: getCurriculumFromSearch(selectedCurriculumIDValue),
        trainingTitle: getTrainingTitleFromSearch(selectedTrainingTitleValue),
        // SUGGESTION . LISTING
        apiType: 'LISTING',
        // USER , TRAINING , CURRICULUM
        searchType: '',
        searchCurriculum: '',
        searchTraining: '',
        searchUser: '',
      };
      dispatch(DirectReporteeManagerSingleTeamDetails(payloadUserLogedIn));
    }
  }, [
    userId,
    pageState.page,
    pageState.pageSize,
    orderByUser,
    orderByCurriculum,
    selectedWWIDValue,
    selectedCurriculumIDValue,
    selectedTrainingTitleValue,
  ]);

  const changeResponseKeyProperty = (records) => {
    let modifiedResponse = [];
    let count = 1;
    records.map((item) => {
      modifiedResponse.push({
        key: count++,
        userId: item.userId,
        full_name: item.full_name,
        leaveOfAbsence: item.leaveOfAbsence,
        curriculum: {
          id: item.curriculum.id,
          name: item.curriculum.name,
        },
        description: item.description,
      });
    });

    return modifiedResponse;
  };

  useEffect(() => {
    if (tpvDRSnglTeamStateData && Object.keys(tpvDRSnglTeamStateData).length) {
      if (tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.records.length) {
        let apiResponseModified = changeResponseKeyProperty(
          tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.records,
        );
        let expandedRowsKey = [];
        if (apiResponseModified?.length) {
          apiResponseModified.map((item) => expandedRowsKey.push(item.key));
        }
        if (expandedRowsKey?.length) {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: apiResponseModified,
            total:
              tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.totalRecords,
            expandedRows: expandedRowsKey,
          }));
        }
        setPaginationSizeChanger(false);
      } else if (
        tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.records.length === 0
      ) {
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.records,
          total: tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.totalRecords,
          expandedRows: [],
        }));
        setPaginationSizeChanger(false);
      } else {
        setPaginationSizeChanger(false);
      }
    }
  }, [tpvDRSnglTeamStateData]);

  const columnTitleStyle = {
    fontFamily: '"JohnsonText-Bold"',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
  };

  const [teamMemberID, setTeamMemberID] = useState(false);
  const [popUpCurriculumID, setPopUpCurriculumID] = useState(null);
  const [popUpcurriculumTitle, setPopUpcurriculumTitle] = useState(null);
  const [popUpCurriculumOpen, setPopUpCurriculumOpen] = useState(false);
  const handlePopupCurriculumClick = (record, item) => {
    setTeamMemberID(record);
    setPopUpCurriculumOpen(true);
    setPopUpCurriculumID(item?.id);
    setPopUpcurriculumTitle(item?.name);
  };

  const handleClosePOpup = () => {
    setPopUpCurriculumOpen(false);
  };

  const columns = [
    {
      field: 'full_name',
      headerName: 'Team Members',
      width: 180,
      disableColumnMenu: true,
      renderCell: (params) => {
        const training_title = params.value || '';
        return (
          <Box sx={{ marginRight: '0px' }}>
            <Typography
              sx={{
                fontFamily: '"JohnsonText-Regular"',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '14px',
                color: redtheme.palette.box.black_gray,
              }}
            >
              {training_title}
            </Typography>
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '14px',
                fontFamily: '"JohnsonText-Medium"',
                color: redtheme.palette.fontColor.gray,
              }}
            >
              {params.id}
            </Typography>
          </Box>
        );
      },

      renderHeader: () => (
        <Box>
          <Typography sx={columnTitleStyle}>Team Members</Typography>
        </Box>
      ),
    },
    {
      field: 'leaveOfAbsence',
      headerName: ' ',
      width: 330,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            {params.value === 'Y' ? (
              <Box sx={{ marginRight: '100px' }}>
                <LeaveIndicator />
              </Box>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Curriculum Title',
      width: 700,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            {params?.value.length != 0 ? (
              params?.value.map((item, index) => (
                <Box key={index} sx={{ display: 'flex' }}>
                  {!screen.isDesktop && (
                    <ToolTipWrapper title={item.name} placement='top' header='Curriculum Title'>
                      <Info
                        fontSize='medium'
                        sx={{
                          color: `${theme.palette.common.primaryMainBlue}`,
                          marginBottom: '12px',
                          marginLeft: '22px',
                        }}
                      />
                    </ToolTipWrapper>
                  )}
                  <Typography
                    component='div'
                    key={index}
                    sx={{
                      fontWeight: 500,
                      fontSize: '10px',
                      lineHeight: '10px',
                      fontFamily: '"JohnsonText-Medium"',
                      cursor: 'pointer',
                      display: 'block',
                      padding: index === 0 ? '0 0 16px 0' : '16px 0',
                    }}
                    onClick={() => handlePopupCurriculumClick(params.id, item)}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '10px',
                        lineHeight: '10px',
                        fontFamily: '"JohnsonText-Medium"',
                        cursor: 'pointer',
                        display: 'block',
                        overflow: 'hidden',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        marginLeft: '25px',
                      }}
                      onClick={() => handlePopupCurriculumClick(params.id, item)}
                    >
                      {screen.isDesktop ? (
                        <ToolTipWrapper title={item.name} placement='top' header='Curriculum Title'>
                          {item.name}
                        </ToolTipWrapper>
                      ) : (
                        item.name
                      )}
                    </Typography>
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                sx={{
                  color: redtheme.palette.fontColor.dark_grayish_blue,
                  fontWeight: 700,
                  fontSize: '10px',
                  lineHeight: '10px',
                  fontFamily: '"JohnsonText-Medium"',
                }}
              >
                {'No Curriculums assigned to the team member at the moment'}
              </Typography>
            )}
          </Box>
        );
      },

      renderHeader: () => (
        <Box>
          <Typography sx={columnTitleStyle}>Curriculum Title</Typography>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (
      filterClearAll &&
      !selectedWWIDValue &&
      !selectedCurriculumIDValue &&
      !selectedTrainingTitleValue
    ) {
      setPageState((old) => ({
        ...old,
        page: 1,
        expandedRows: [],
      }));
    }
  }, [filterClearAll]);

  const onChangeAutoCompleteFilter = (_event, value, reason, _details, type) => {
    if (reason === 'selectOption' && value) {
      if (type === 'wwid') {
        setSelectedWWIDValue(value);
      } else if (type === 'curriculumtitle') {
        setSelectedCurriculumIDValue(value);
      } else if (type === 'trainingtitle') {
        setSelectedTrainingTitleValue(value);
      } else {
        setFilterClearAll(false);
      }
    } else if (reason === 'clear' && !value) {
      if (type === 'wwid') {
        setSelectedWWIDValue('');
      } else if (type === 'curriculumtitle') {
        setSelectedCurriculumIDValue('');
      } else if (type === 'trainingtitle') {
        setSelectedTrainingTitleValue('');
      } else {
        setSelectedWWIDOptions([]);
        setSelectedCurriculumIDOptions([]);
        setSelectedTrainingTitleOptions([]);
        setFilterClearAll(Math.floor(getRandomNumber() * barchart.hundred));
      }
    } else {
      setSelectedWWIDValue('');
      setSelectedCurriculumIDValue('');
      setSelectedTrainingTitleValue('');
      setFilterClearAll('');
      setSelectedWWIDOptions([]);
      setSelectedCurriculumIDOptions([]);
      setSelectedTrainingTitleOptions([]);
    }
  };

  const handleAutoCompleteChange = (_event, newValue, reason, type) => {
    if (!newValue && (reason === 'reset' || reason === 'clear')) {
      if (type === 'wwid') {
        setSelectedWWID('');
      } else if (type === 'curriculumtitle') {
        setSelectedCurriculumID('');
      } else if (type === 'trainingtitle') {
        setSelectedTrainingTitle('');
      } else {
        setSelectedWWID('');
        setSelectedCurriculumID('');
        setSelectedTrainingTitle('');
      }
    } else if (newValue?.length && reason != 'reset') {
      if (type === 'wwid') {
        setSelectedWWID(newValue);
      } else if (type === 'curriculumtitle') {
        setSelectedCurriculumID(newValue);
      } else if (type === 'trainingtitle') {
        setSelectedTrainingTitle(newValue);
      } else {
        setSelectedWWID('');
        setSelectedCurriculumID('');
        setSelectedTrainingTitle('');
      }
    } else {
      setSelectedWWID('');
      setSelectedCurriculumID('');
      setSelectedTrainingTitle('');
    }
  };

  //Filter by WWID
  useEffect(() => {
    if (selectedWWID) {
      const payloadUserLogedIn = {
        userId: userId,
        orderBy: 'ASC',
        page: 0,
        limit: 10,
        teamMember: '',
        curriculumTitle: '',
        trainingTitle: '',
        // SUGGESTION . LISTING
        apiType: 'SUGGESTION',
        // USER , TRAINING , CURRICULUM
        searchType: 'USER',
        searchCurriculum: selectedCurriculumIDValue.curriculum_title
          ? selectedCurriculumIDValue.curriculum_title
          : '',
        searchTraining: selectedTrainingTitleValue.training_title
          ? selectedTrainingTitleValue.training_title
          : '',
        searchUser: selectedWWID,
      };
      dispatch(TPVFilterManagerSingleTeamDataWWID(payloadUserLogedIn));
    }
  }, [selectedWWID]);

  let filterDirectReporteeTemMemberData = useSelector(
    (state) => state.tapTrainingPlanViewMngSnglTeamWWIDFltrRd,
  );

  //Set filter on search, paste wwid or name
  useEffect(() => {
    if (
      filterDirectReporteeTemMemberData &&
      Object.keys(filterDirectReporteeTemMemberData).length
    ) {
      if (
        filterDirectReporteeTemMemberData?.tpvmanagerSingleTeamFilterByWWID?.data?.records &&
        filterDirectReporteeTemMemberData?.tpvmanagerSingleTeamFilterByWWID?.data?.records.length
      )
        setSelectedWWIDOptions(
          filterDirectReporteeTemMemberData?.tpvmanagerSingleTeamFilterByWWID?.data?.records,
        );
    } else {
      setSelectedWWIDOptions([]);
    }
  }, [filterDirectReporteeTemMemberData]);

  //Filter by Curriculum Title
  useEffect(() => {
    if (selectedCurriculumID) {
      const payloadUserLogedIn = {
        userId: userId,
        orderBy: 'ASC',
        page: 0,
        limit: 10,
        teamMember: '',
        curriculumTitle: '',
        trainingTitle: '',
        // SUGGESTION . LISTING
        apiType: 'SUGGESTION',
        // USER , TRAINING , CURRICULUM
        searchType: 'CURRICULUM',
        searchCurriculum: selectedCurriculumID,
        searchTraining: selectedTrainingTitleValue.training_title
          ? selectedTrainingTitleValue.training_title
          : '',
        searchUser: selectedWWIDValue.user_id ? selectedWWIDValue.user_id : '',
      };
      dispatch(TPVFilterManagerSingleTeamDataCurriculumTitle(payloadUserLogedIn));
    }
  }, [selectedCurriculumID]);

  let filterDirectReporteeCurriculumData = useSelector(
    (state) => state.tapTrainingPlanViewMngSnglTeamCurriculumFltrRd,
  );

  //load curriculum suggestion
  // const setDefaultCurriculumIDOptions = (optData) => {
  //     //let count = 1;
  //     let curriculumTitleFilterRecords = [];
  //     (optData && optData.length) ? optData.forEach((item) => {
  //         curriculumTitleFilterRecords.push({
  //             id: `${Math.floor(Math.random() * 100)}_${item.curriculum_title}`,
  //             label: item.curriculum_title,
  //         });
  //     }) : [];
  //     // if (curriculumTitleFilterRecords.length > 10) {
  //     //     curriculumTitleFilterRecords = curriculumTitleFilterRecords.splice(0, 20);
  //     // }
  //     setSelectedCurriculumIDOptions(curriculumTitleFilterRecords);
  // };

  //Set filter on search, paste curriculum title
  useEffect(() => {
    if (
      filterDirectReporteeCurriculumData &&
      Object.keys(filterDirectReporteeCurriculumData).length
    ) {
      if (
        filterDirectReporteeCurriculumData?.tpvmanagerSingleTeamFilterByCurriculum?.data?.records &&
        filterDirectReporteeCurriculumData?.tpvmanagerSingleTeamFilterByCurriculum?.data?.records
          .length
      )
        setSelectedCurriculumIDOptions(
          filterDirectReporteeCurriculumData?.tpvmanagerSingleTeamFilterByCurriculum?.data?.records,
        );
    } else {
      setSelectedCurriculumIDOptions([]);
    }
  }, [filterDirectReporteeCurriculumData]);

  //Filter by Training Title
  useEffect(() => {
    if (selectedTrainingTitle) {
      const payloadUserLogedIn = {
        userId: userId,
        orderBy: 'ASC',
        page: 0,
        limit: 10,
        teamMember: '',
        curriculumTitle: '',
        trainingTitle: '',
        // SUGGESTION, LISTING
        apiType: 'SUGGESTION',
        // USER , TRAINING , CURRICULUM
        searchType: 'TRAINING',
        searchCurriculum: selectedCurriculumIDValue.curriculum_title
          ? selectedCurriculumIDValue.curriculum_title
          : '',
        searchTraining: selectedTrainingTitle,
        searchUser: selectedWWIDValue.user_id ? selectedWWIDValue.user_id : '',
      };
      dispatch(TPVFilterManagerSingleTeamDataTrainingTitle(payloadUserLogedIn));
    }
  }, [selectedTrainingTitle]);

  let filterDirectReporteeTrainingData = useSelector(
    (state) => state.tapTrainingPlanViewMngSnglTeamTrainingFltrRd,
  );

  //Set filter on search, paste training title
  useEffect(() => {
    if (filterDirectReporteeTrainingData && Object.keys(filterDirectReporteeTrainingData)?.length) {
      if (
        filterDirectReporteeTrainingData?.tpvmanagerSingleTeamFilterByCurriculum?.data?.records &&
        filterDirectReporteeTrainingData?.tpvmanagerSingleTeamFilterByCurriculum?.data?.records
          .length
      )
        setSelectedTrainingTitleOptions(
          filterDirectReporteeTrainingData?.tpvmanagerSingleTeamFilterByCurriculum?.data?.records,
        );
    } else {
      setSelectedTrainingTitleOptions([]);
    }
  }, [filterDirectReporteeTrainingData]);

  const CustomNoDataOverlay = () => {
    const overlayMessage =
      selectedWWIDValue === '' &&
      selectedCurriculumIDValue === '' &&
      selectedTrainingTitleValue === ''
        ? 'No Team Members assigned to you at the moment'
        : 'No Team Members found for the filters applied';

    return <Box className='training-view-no-data-overlay'>{overlayMessage}</Box>;
  };
  const handleSortModelChange = useCallback((sortModel) => {
    if (sortModel[0]?.field === 'full_name') {
      setOrderByUser(sortModel[0]?.sort.toUpperCase());
    } else if (sortModel[0]?.field === 'description') {
      setOrderByCurriculum(sortModel[0]?.sort.toUpperCase());
    }
    setPageState((old) => ({
      ...old,
      sortField: sortModel[0]?.field,
      orderBy: sortModel[0]?.sort.toUpperCase(),
    }));

    if (sortModel[0]?.sort.toUpperCase() === 'ASC') {
      setSortingTooltip(CLICK_TO_SORT_DESCENDING);
    } else if (sortModel[0]?.sort.toUpperCase() === 'DESC') {
      setSortingTooltip(CLICK_TO_SORT_ASCENDING);
    }
  }, []);

  const trainingsDownloadExcelReport = () => {
    let fileName = `TrainingViewReport_${userId}_Team_${currentDate}_${currentTime}.xlsx`;

    if (userId !== undefined) {
      let requestURL = `${
        process.env.REACT_APP_API_BASE_URL
      }${GET_TRAININGVIEW_POPUP_REPORT()}?userId=${userId}&curriculumId=0&apiType=MY_TEAM_PLAN`;
      if (selectedWWIDValue?.user_id?.length) {
        requestURL = requestURL + `&searchUserId=${selectedWWIDValue?.user_id}`;
      }
      if (selectedCurriculumIDValue?.curriculum_title?.length) {
        requestURL =
          requestURL +
          `&curriculumTitle=${encodeURIComponent(selectedCurriculumIDValue?.curriculum_title)}`;
      }
      if (selectedTrainingTitleValue?.training_title?.length) {
        requestURL =
          requestURL +
          `&trainingTitle=${encodeURIComponent(selectedTrainingTitleValue?.training_title)}`;
      }
      downloadExcelReport(requestURL, fileName, null).catch((err) => {
        if (err?.response?.status === NUMBER_ARRAY.fourHundred) {
          dispatch(
            TrainingsLargeReportDownloadAction({
              userId: userId,
              curriculumTitle: selectedCurriculumIDValue?.curriculum_title ?? "",
              trainingTitle: selectedTrainingTitleValue?.training_title ?? "",
              apiType: 'MY_TEAM_PLAN',
              searchUserId: selectedWWIDValue?.user_id ?? "",
              curriculumId: 0
            }),
          );
          setShowLargeDataDownloadInfoMsg(true);
          setToasterFlow(true);
        }
      }); 
    }
  };

  return (
    <>
      {/* <ShowLoader></ShowLoader> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '-52px',
          padding: !screen.isDesktop ? '16px' : '0 72px',
          background: redtheme.palette.box.white,
        }}
      >
        <RequestToAddOrRemoveTrainings />
        {!screen.isDesktop && pageState.data && pageState.data.length ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
            <CustomButton
              width='166px'
              buttonText='Download Excel '
              clickHandler={trainingsDownloadExcelReport}
              isIconToShow={true}
            />
            <ToolTipWrapper
              title={TapGlobalMessages.trainingPlanView.directReports.downloadExcelToolTip()}
              placement='bottom-end'
              header='How Excel Works?'
            >
              <HelpOutlineIcon
                fontSize='medium'
                sx={{
                  color: `${theme.palette.common.primaryMainBlue}`,
                  marginBottom: '12px',
                  marginLeft: '22px',
                }}
              />
            </ToolTipWrapper>
          </Box>
        ) : null}

        <Box
          data-testid='trainingplanviewcontainer'
          sx={{
            display: 'flex',
            marginBottom: '20px',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={!screen.isDesktop ? (screen.isTablet ? 4 : 6) : 3.8}>
              <Autocomplete
                data-testid='tvwwid'
                options={selectedWWIDOptions}
                popupIcon={''}
                //freeSolo
                getOptionLabel={(option) => option.search_value || ''}
                // value={selectedWWIDValue}
                onChange={(event, value, reason, details) => {
                  onChangeAutoCompleteFilter(event, value, reason, details, 'wwid');
                }}
                onInputChange={(event, value, reason) =>
                  handleAutoCompleteChange(event, value, reason, 'wwid')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      shrink: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={'Search'}
                    label={
                      <Typography
                        sx={{
                          fontSize: screen.isTablet ? '20px' : '16px',
                          lineHeight: '16px',
                          fontWeight: 500,
                          fontFamily: '"JohnsonText-Bold"',
                          color: 'rgba(33, 33, 33, 1)',
                          width: '728px',
                          height: screen.isTablet ? '22px' : '16px',
                          paddingTop: screen.isTablet ? '3px' : NUMBER_ARRAY.zero,
                        }}
                      >
                        WWID / User Name
                      </Typography>
                    }
                    sx={{
                      borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                    }}
                    variant='standard'
                    margin='normal'
                  />
                )}
                sx={{ mr: 2, width: '100%' }}
                clearOnBlur
              />
            </Grid>
            {screen.isDesktop && (
              <Grid item xs={!screen.isDesktop ? 0 : 0.5}>
                <Box sx={{ paddingTop: '30px' }}>
                  <ToolTipWrapper
                    title={TapGlobalMessages.trainingPlanView.directReports.searchByTMOrWWIDInfoTooltip()}
                    placement='top'
                    header='How Search Works?'
                  >
                    <HelpOutlineIcon
                      fontSize='medium'
                      sx={{
                        color: `${theme.palette.common.primaryMainBlue}`,
                        marginBottom: '12px',
                      }}
                    ></HelpOutlineIcon>
                  </ToolTipWrapper>
                </Box>
              </Grid>
            )}
            <Grid item xs={!screen.isDesktop ? (screen.isTablet ? 4 : 6) : 3.8}>
              <Autocomplete
                data-testid='tvcurriculum'
                popupIcon={''}
                options={selectedCurriculumIDOptions}
                getOptionLabel={(option) => option.curriculum_title || ''}
                onChange={(event, value, reason, details) => {
                  onChangeAutoCompleteFilter(event, value, reason, details, 'curriculumtitle');
                }}
                onInputChange={(event, value, reason) =>
                  handleAutoCompleteChange(event, value, reason, 'curriculumtitle')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      shrink: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={'Search'}
                    label={
                      <Typography
                        sx={{
                          fontSize: screen.isTablet ? '20px' : '16px',
                          lineHeight: '16px',
                          fontWeight: 500,
                          fontFamily: '"JohnsonText-Bold"',
                          color: 'rgba(33, 33, 33, 1)',
                          width: '728px',
                          height: screen.isTablet ? '22px' : '16px',
                          paddingTop: screen.isTablet ? '3px' : NUMBER_ARRAY.zero,
                        }}
                      >
                        Curriculum Title
                      </Typography>
                    }
                    sx={{
                      borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                    }}
                    variant='standard'
                    margin='normal'
                  />
                )}
                sx={{ mr: 2, width: '100%' }}
                clearOnBlur
              />
            </Grid>
            <Grid item xs={!screen.isDesktop ? (screen.isTablet ? 4 : 12) : 3.8}>
              <Autocomplete
                data-testid='tvtraining'
                popupIcon={''}
                options={selectedTrainingTitleOptions}
                getOptionLabel={(option) => option.training_title || ''}
                onChange={(event, value, reason, details) => {
                  onChangeAutoCompleteFilter(event, value, reason, details, 'trainingtitle');
                }}
                onInputChange={(event, value, reason) =>
                  handleAutoCompleteChange(event, value, reason, 'trainingtitle')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      shrink: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={'Search'}
                    label={
                      <Typography
                        sx={{
                          fontSize: screen.isTablet ? '20px' : '16px',
                          lineHeight: '16px',
                          fontWeight: 500,
                          fontFamily: '"JohnsonText-Bold"',
                          color: 'rgba(33, 33, 33, 1)',
                          width: '728px',
                          height: screen.isTablet ? '25px' : '16px',
                          paddingTop: screen.isTablet ? '3px' : NUMBER_ARRAY.zero,
                        }}
                      >
                        Training Name
                      </Typography>
                    }
                    sx={{
                      borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                    }}
                    variant='standard'
                    margin='normal'
                  />
                )}
                sx={{ mr: 2, width: '100%' }}
                clearOnBlur
              />
            </Grid>
          </Grid>

          {screen.isDesktop && pageState.data && pageState.data.length ? (
            <Box sx={{ width: '14%', display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
              <CustomButton
                width='166px'
                buttonText='Download Excel '
                clickHandler={trainingsDownloadExcelReport}
                isIconToShow={true}
              />
            </Box>
          ) : null}

          {screen.isDesktop && (
            <Box
              sx={{
                display: 'flex',
                width: '3%',
                alignItems: 'end',
              }}
            >
              <ToolTipWrapper
                title={TapGlobalMessages.trainingPlanView.directReports.downloadExcelToolTip()}
                placement='bottom-end'
                header='How Excel Works?'
              >
                <HelpOutlineIcon
                  fontSize='medium'
                  sx={{
                    color: `${theme.palette.common.primaryMainBlue}`,
                    marginBottom: '12px',
                    marginLeft: '22px',
                  }}
                />
              </ToolTipWrapper>
            </Box>
          )}
        </Box>
        <Box sx={{ width: '100%' }}>
          {tpvDRSnglTeamStateData &&
          Object.keys(tpvDRSnglTeamStateData).length &&
          tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.records &&
          Object.keys(tpvDRSnglTeamStateData?.directReporteeMrgerSnglTeamAPIResponse?.data?.records)
            .length &&
          pageState.expandedRows.length ? (
            <Box
              data-testid='trainingViewRoleManagerTable'
              className='training-view-plan-singleteam'
              sx={{
                width: '100%',
              }}
            >
              <DataGrid
               data-testid = 'trainingViewRoleManagerDataGrid'
                rows={pageState.data}
                columns={columns}
                getRowId={(row) => row?.userId}
                loading={pageState.isLoading}
                localeText={{ columnHeaderSortIconLabel: sortingTooltip }}
                components={{
                  noRowsOverlay: CustomNoDataOverlay,
                }}
                //rowHeight={600}
                getRowHeight={(row) => row?.model?.description.length * height.h48}
                paginationMode='server'
                sortingMode='server'
                disableRowSelectionOnClick
                onSortModelChange={handleSortModelChange}
                rowCount={pageState.total}
                paginationModel={paginationModel}
                pageSizeOptions={PAGE_SIZES_ARRAY}
                onPaginationModelChange={(newPage) => {
                  setPageState((old) => ({
                    ...old,
                    page: newPage.page + 1,
                    pageSize: newPage.pageSize,
                  }));
                  setPaginationModel((old) => ({
                    ...old,
                    page: newPage.page,
                    pageSize: newPage.pageSize,
                  }));
                }}
                onPageSizeChange={(newPageSize) =>
                  setPageState((old) => ({ ...old, pageSize: newPageSize }))
                }
                sx={{
                  border: 'none',
                  '& .MuiDataGrid-cell': {
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                  },
                  '& .MuiDataGrid-row:nth-of-type(odd)': { backgroundColor: '#00009908' },
                  '& .MuiDataGrid-columnHeaders': {
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: '"JohnsonText-Bold"',
                  },
                  '& .super-app.completed': {
                    color: redtheme.palette.dataGrid.dark_lime_green,
                  },
                  '& .super-app.overdue': {
                    color: redtheme.palette.dataGrid.strong_red,
                  },
                  '& .super-app.noDueDate': {
                    color: redtheme.palette.dataGrid.strong_blue,
                  },
                  '& .super-app.inComplete': {
                    color: redtheme.palette.dataGrid.strong_orange,
                  },
                  '& .MuiTablePagination-selectLabel': {
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    color: redtheme.palette.fontColor.very_dark_grey,
                  },
                  '& .MuiTablePagination-displayedRows': {
                    fontFamily: '"JohnsonText-Medium"',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    fontStyle: 'normal',
                    color: redtheme.palette.fontColor.very_dark_grey,
                    display: 'block',
                  },
                  '& .MuiTablePagination-select': {
                    fontFamily: '"JohnsonText-Medium"',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '24px',
                    fontStyle: 'normal',
                    color: redtheme.palette.fontColor.very_dark_grey,
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: 'none !important',
                  },
                  [`& .${tablePaginationClasses.selectLabel}`]: {
                    display: 'block',
                  },
                  [`& .${tablePaginationClasses.input}`]: {
                    display: 'inline-flex',
                  },
                }}
              />
            </Box>
          ) : (
            <DataGrid
              data-testid = 'tpv-datgrid-nodisplay'
              rows={[]}
              columns={columns}
              getRowId={(row) => row?.userId}
              loading={pageState.isLoading}
              localeText={{ columnHeaderSortIconLabel: sortingTooltip }}
              components={{
                noRowsOverlay: CustomNoDataOverlay,
              }}
              //rowHeight={600}
              getRowHeight={(row) => row?.model?.description.length * height.h48}
              paginationMode='server'
              sortingMode='server'
              disableRowSelectionOnClick
              onSortModelChange={handleSortModelChange}
              rowCount={pageState.total}
              paginationModel={paginationModel}
              pageSizeOptions={PAGE_SIZES_ARRAY}
              onPaginationModelChange={(newPage) => {
                setPageState((old) => ({
                  ...old,
                  page: newPage.page + 1,
                  pageSize: newPage.pageSize,
                }));
                setPaginationModel((old) => ({
                  ...old,
                  page: newPage.page,
                  pageSize: newPage.pageSize,
                }));
              }}
              onPageSizeChange={(newPageSize) =>
                setPageState((old) => ({ ...old, pageSize: newPageSize }))
              }
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell': {
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  fontFamily: '"JohnsonText-Medium"',
                },
                '& .MuiDataGrid-row:nth-of-type(odd)': { backgroundColor: '#00009908' },
                '& .MuiDataGrid-columnHeaders': {
                  fontSize: 16,
                  fontWeight: 700,
                  fontFamily: '"JohnsonText-Bold"',
                },
                '& .super-app.completed': {
                  color: redtheme.palette.dataGrid.dark_lime_green,
                },
                '& .super-app.overdue': {
                  color: redtheme.palette.dataGrid.strong_red,
                },
                '& .super-app.noDueDate': {
                  color: redtheme.palette.dataGrid.strong_blue,
                },
                '& .super-app.inComplete': {
                  color: redtheme.palette.dataGrid.strong_orange,
                },
                '& .MuiTablePagination-selectLabel': {
                  fontFamily: '"JohnsonText-Regular"',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '16px',
                  fontStyle: 'normal',
                  color: redtheme.palette.fontColor.very_dark_grey,
                },
                '& .MuiTablePagination-displayedRows': {
                  fontFamily: '"JohnsonText-Medium"',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '16px',
                  fontStyle: 'normal',
                  color: redtheme.palette.fontColor.very_dark_grey,
                },
                '& .MuiTablePagination-select': {
                  fontFamily: '"JohnsonText-Medium"',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '16px',
                  fontStyle: 'normal',
                  color: redtheme.palette.fontColor.very_dark_grey,
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
              }}
            />
          )}
        </Box>
        <Toaster
          onClose={triggerDownloadsCompletedEvent}
          hideDuration={NUMBER_ARRAY.tenThousand}
          severity={'warn'}
          show={showLargeDataDownloadInfoMsg}
          message={TapGlobalMessages.largeDownloadStartMsg}
        />
        <Toaster
          onClose={onSuccessToasterClose}
          hideDuration={NUMBER_ARRAY.thirtyThousand}
          severity={'success'}
          show={showLargeDataDownloadSuccessMsg}
          message={TapGlobalMessages.largeDownloadSuccessMsg}
        />
      </Box>
      {popUpCurriculumID ? (
        <TrainingViewPopup
          data-testid = 'traingingViewPopupId'
          userID={teamMemberID}
          curriculumID={popUpCurriculumID}
          curriculumTitle={popUpcurriculumTitle}
          open={popUpCurriculumOpen}
          onPopupClose={() => handleClosePOpup()}
        ></TrainingViewPopup>
      ) : (
        ''
      )}
    </>
  );
};
