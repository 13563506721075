import React from 'react';
import Box from '@mui/material/Box';

export const NonCompliantTooltipBody = () => {
  return (
    <Box>
      <Box>
        This view shows individuals from your Organisation that are out of compliance and in which
        area.
      </Box>
      <br />
      <Box>
        See Training Actions{' > '}Trainings Overdue for details on actionable past due trainings.
      </Box>
    </Box>
  );
};
