import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  Grid,
  Button,
  Box,
  TextField,
} from '@mui/material';
import { RedTheme } from 'common/global';
import { DropDownIcon } from 'common/components';
import { ReactComponent as CrossIcon } from 'common/assets/images/CrossIcon.svg';
import { DeletePopup } from 'TAP/components/DeletePopup';
import { useState } from 'react';
import { ShareFilterAdminSelectionPopup } from './ShareFilterAdminSelectionPopup';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { TapGlobalMessages } from 'TAP/global';

export const FavDropDown = ({
  options,
  indicator,
  selectFav,
  editModeNameValidation,
  handleAdminStatusChange,
  handleFavDelete,
  isEditingMode,
  handleEditSelect,
  handleInputFilterNameChange,
}) => {
  let redtheme = RedTheme;
  const [openPopup, setOpenPopup] = useState(false);
  const [deleteFilterName, setDeleteFiltername] = useState('');
  const [openShareFilterPopup, setOpenShareFilterPopup] = useState(false);
  const [favouriteFilterToShare, setFavouriteFilterToShare] = useState('');

  const handleDeletePopup = (e, value) => {
    setDeleteFiltername(value);
    e.stopPropagation(); //Stop the parent action
  };

  const handleShareFavourite = (e, value) => {
    setFavouriteFilterToShare(value);
    e.stopPropagation();
  };

  return (
    <>
      <FormControl
        variant='standard'
        data-testid='status_filter'
        sx={{ width: '100%', mt: 2, fontFamily: '"JohnsonText-Medium"' }}
      >
        {isEditingMode ? (
          <TextField
            id='edit-field'
            defaultValue={selectFav}
            variant='standard'
            inputProps={{ maxLength: 100, 'aria-label': 'description' }}
            onChange={(event) => handleInputFilterNameChange(event)}
            error={editModeNameValidation}
            helperText={editModeNameValidation ? TapGlobalMessages.textFieldValidationMessage : ''}
          />
        ) : (
          <Select
            IconComponent={DropDownIcon}
            displayEmpty={true}
            value={selectFav}
            onChange={(e) => handleAdminStatusChange(e, indicator)}
            renderValue={(selected) => {
              if (selected === undefined || selected?.length === 0) {
                return (
                  <Typography
                    sx={{
                      color: redtheme.palette.fontColor.gray_orange,
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    Select Saved Favourite
                  </Typography>
                );
              }
              return selected;
            }}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {options?.map((obj, index) => (
              <MenuItem key={index} value={obj.filterName}>
                <Grid container gap={1}>
                  <Grid item xs={(obj.sharedBy !== '' && obj.sharedBy) ? 6 : 8}>
                    {obj.filterName}
                  </Grid>
                  <Grid item xs={obj.sharedBy !== '' ? 5.65 : 3.65}>
                    {obj.sharedBy !== '' ? (
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography
                          sx={{ fontFamily: '"JohnsonText-Medium"', fontSize: '14px' }}
                        >{`Shared by ${obj.sharedBy}`}</Typography>
                      </Box>
                    ) : (
                      <Box display='flex'>
                        <>
                          <Button
                            onClick={() => handleEditSelect()}
                            startIcon={<ModeEditOutlinedIcon />}
                            sx={{ color: 'black' }}
                          />

                          <Button
                            onClick={(e) => {
                              handleShareFavourite(e, obj.filterName);
                              setOpenShareFilterPopup(true);
                            }}
                            startIcon={<ShareOutlinedIcon sx={{ color: 'black' }} />}
                          />
                        </>

                        <Button
                          onClick={(e) => {
                            handleDeletePopup(e, obj.filterName);
                            setOpenPopup(true);
                          }}
                          startIcon={<CrossIcon />}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <DeletePopup
        popupHeader='Are you sure you want to delete the "favourite filter"?'
        SubHeader="This item will be deleted permanently. You can't undo this action."
        leftBtnText='Cancel'
        rightBtnText='Delete'
        open={openPopup}
        deleteFilterName={deleteFilterName}
        handleFavDelete={handleFavDelete}
        handleClose={() => setOpenPopup(false)}
      />
      <ShareFilterAdminSelectionPopup
        popupHeader='Share Favourite Filters'
        leftBtnText='Cancel'
        rightBtnText='Share'
        open={openShareFilterPopup}
        favName={favouriteFilterToShare}
        handleClose={() => {
          setOpenShareFilterPopup(false);
          setFavouriteFilterToShare('');
        }}
      />
    </>
  );
};
