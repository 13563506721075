export const AdminListToShareFavWithReducer = (state = {}, action) => {
    switch (action.type) {
      case 'GET_ADMIN_LIST':
        return {
          ...state,
          adminList: action.payload,
        };
      case 'API_START':
        if (action.payload === 'GET_ADMIN_LIST_STATUS') {
          return {
            ...state,
            isLoadingAdminList: true,
          };
        } else {
          return state;
        }
      case 'API_END':
        if (action.payload === 'GET_ADMIN_LIST_STATUS') {
          return {
            ...state,
            isLoadingAdminList: false,
          };
        } else {
          return state;
        }
      default:
        return state;
    }
  };
  