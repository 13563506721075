import { createTheme } from '@mui/material/styles';

// A custom theme for this app

export const RedTheme = createTheme({
  palette: {
    type: 'red',
    mode: 'light',
    primary: {
      main: '#EB1700',
    },
    secondary: {
      main: '#CA001B',
    },
    text: {
      primary: '#212121',
      secondary: '#333333',
      disabled: '#D8D8D8',
      hint: '#00A0DF',
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#FBEAEA',
    },
    warning: {
      main: '#F5AC25',
      dark: '#F37820',
      light: '#FDF0E6',
    },
    info: {
      main: '#00A0DF',
      light: '#E6F3FA',
      dark: '#1C75BC',
      borderBlue: '#004685',
    },
    success: {
      main: '#6EBD44',
      light: '#EAF2EA',
      dark: '#0B8314',
    },
    divider: '#D8D8D8',
    background: {
      default: '#fff',      
    },
    common: {
      primaryMainBlue: '#000099'
    },
     //added on Dec 4
     fontColor:{
      gray: '#A39992',
      dark_grayish_blue  : '#888B8D',
      black_gray: '#333333',
      dark_grayish_orange :'#A39992',
      very_dark_grey : '#212121',
      dark_moderate_lime_green : '#359942',
      light_moderate_lime_green : '#6EBD44',
      gray_orange : '#81766F',
      dark_blue : '#003479',
      black :'#000000',
      strong_red : '#CA001B',
      white : '#FFFFFF',
    },
    styleCircle:{
      dark_moderate_lime_green : '#359942',
      very_dark_grey : '#212121',
      bright_orange : '#F5AC25',
      strong_red : '#CA001B',
      white : '#fff'
    },
    box :{
      very_dark_grey : '#212121',
      white : '#fff',
      dark_grayish_blue  : '#888B8D',
      black_gray: '#333333',
      light_gray : '#f2f2f2',
      very_light_grey : '#F4F4F4',
      strong_red : '#CA001B',
      red : '#D32F2F',
      light_grayish_orange: '#F9F8F7',
      dark_grayish_orange:'#A39992',
      gray: '#63666A',
      green:'green',
      lightPink: '#FCDDEC',   
    },
    stack: {
      very_light_grey : '#F4F4F4',
      orange: '#F1EFED'

    },
    button :{
      strong_red : '#CA001B',     
      white : '#FFFFFF',
      black :'#000000',
      pure_red :'#EB1700',
      gray: '#63666A',
      dark_grey : '#212121',
      light_gray: '#D8D8D8',
      dark_lime_green : '#0B8314',
      black_gray: '#333333',
      
    },
    dataGrid :{
      very_dark_grey : '#212121',
      fontGrey : '#212121',
      very_light_grey : '#F4F4F4',
      strong_orange  :'#BE531C',
      dark_lime_green : '#0B8314',
      white : '#FFFFFF',
      strong_red : '#CA001B',
      light_gray : '#D8D8D8',
      strong_blue : '#1C75BC',
      yellow: '#F5AC25',
      dark_moderate_lime_green : '#359942',
      light_moderate_lime_green : '#6EBD44',
      red : '#C62828',
    },
    appBar :{
      white : '#FFFFFF',
    },
    span : {
      strong_red : '#CA001B',
      vivid_orange : '#F37820',
      dark_lime_green : '#0B8314',
      yellow : '#F5AC25',
    },
    tab :{
      black :'#000000',
    },
    bar : {
      red: '#C62828',
      yellow: '#F5AC25',
      green: '#6CC24A',
    },
    dateRangePicker :{
      dark_grey : '#212121',
      gray_orange : '#81766F',
    },
    tooltip : {
      white : '#FFFFFF',
      dark_blue : '#000099',
      black :'#000000',
      black_gray: '#333333',
    },
    link :{
      white : '#FFFFFF',
      strong_red : '#CA001B',
      black : '#000'
    },
    donutChart :{
      red : '#C62828',
      light_red : '#FBEAEA',
      yellow: '#F5AC25',
      light_orange  : '#FDF0E6',
      green: '#6CC24A',
      light_green: '#EAF2EA',
    }
  },
  typography: {
    fontFamily: ['"JohnsonText-Regular"'].join(','),
    h1: {
      fontSize: 40,
      lineHeight: '52px',
      fontWeight: 900,
      fontFamily: '"JohnsonDisplay-Bold"',
    },
    h2: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 900,
      fontFamily: '"JohnsonDisplay-Bold"',
    },
    h3: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: '32px',
      fontFamily: '"JohnsonText-Medium"',
    },
    h4: {
      fontSize: 20,
      fontWeight: 900,
      lineHeight: '28px',
    },
    h5: {
      fontSize: 16,
      fontWeight: 900,
      lineHeight: '24px',
    },
    h6: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: '20px',
    },
  }
});

export const BlueTheme = createTheme({
  palette: {
    type: 'blue',
    mode: 'light',
    primary: {
      main: '#000099',
    },
    secondary: {
      main: '#19857b',
    },
    text: {
      primary: '#212121',
      secondary: '#333333',
      disabled: '#D8D8D8',
      hint: '#00A0DF',
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#FBEAEA',
    },
    warning: {
      main: '#F5AC25',
      dark: '#F37820',
      light: '#FDF0E6',
    },
    info: {
      main: '#00A0DF',
      light: '#E6F3FA',
      dark: '#1C75BC',
    },
    success: {
      main: '#6EBD44',
      light: '#EAF2EA',
      dark: '#0B8314',
    },
    divider: '#D8D8D8',
    background: {
      default: '#fff',
    }
  },
  typography: {
    fontFamily: ['"JohnsonText-Light","Roboto","Arial","sans-serif"'].join(','),
    h1: {
      fontSize: 40,
      lineHeight: '52px',
      fontWeight: 900,
      fontFamily: '"J&J CircularTT Black Web"',
    },
    h2: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 900,
      fontFamily: '"J&J CircularTT Black Web"',
    },
    h3: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: '32px',
      fontFamily: '"J&J CircularTT Black Web"',
    },
    h4: {
      fontSize: 20,
      fontWeight: 900,
      lineHeight: '28px',
    },
    h5: {
      fontSize: 16,
      fontWeight: 900,
      lineHeight: '24px',
    },
    h6: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: '20px',
    },
  }
});