import { Button } from '@mui/material';
import { RedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';

export const CustomSetAlertButton = ({ clickHandler, isOpen, disabled = false }) => {
  let redtheme = RedTheme;
  const screen = useScreen();

  return (
    <Button
      sx={{
        paddingTop: '7px',
        width: screen.isTablet ? '110px' : '120px',
        height: '32px',
        borderRadius: '8px',
        marginTop: '15px',
        marginLeft: screen.resolveStyles({
          mobile: '0px',
          tablet: '10px',
          desktop: '20px',
        }),
        color: redtheme.palette.background.default,
        bgcolor: redtheme.palette.text.primary,
        fontSize: '16px',
        lineHeight: '16px',
        textTransform: 'capitalize',
        fontWeight: 500,
        fontFamily: '"JohnsonText-Medium"',
        '&:hover': {
          backgroundColor: redtheme.palette.text.primary,
          color: redtheme.palette.background.default,
        },
      }}
      onClick={() => clickHandler(!isOpen)}
      variant='contained'
      disabled={disabled}
    >
      Set Alerts
    </Button>
  );
};
