import * as React from 'react';
import { Button, MenuItem, Menu} from "@mui/material"
import { RedTheme } from 'common/global';
import { ReactComponent as Download } from 'common/assets/images/New_Downld.svg';
import { useScreen } from "common/hooks/useScreen";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
import { labels } from 'common/global/constants';

export const CustomButton = ({
  width,
  buttonText,
  clickHandler,
  isIconToShow = false,
  isDisabled = false,
  marginLeft,
  marginBottom
}) => {
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 170,
      marginLeft:4,
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
        position: 'relative'
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      // this is fixed for JFSK-2518 for visiblity of button need to check
      // ...theme.applyStyles('dark', {
      //   color: theme.palette.grey[300],
      // }),
    },
  }));

  const screen = useScreen(); 
  let redtheme = RedTheme;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    clickHandler(event);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (<>
    <Button
      data-testid='requestToAddOrRemoveTrainingBtn'
      onClick={buttonText === labels.exportDataTableText ? handleClick :clickHandler}
      aria-controls="btn-menu"
      aria-haspopup="true"
      disabled={isDisabled}
      endIcon={buttonText === labels.exportDataTableText && <KeyboardArrowDownIcon />}
      variant='contained'
      sx={{
        paddingTop: '7px',
        width: { width },
        height: '32px',
        borderRadius: '8px',
        marginLeft: screen.resolveStyles({
          mobile:  marginLeft || '0px',
          tablet: '10px',
          desktop: '20px',
        }),
        marginTop: '15px',
        marginBottom : marginBottom,
        color: redtheme.palette.button.white,
        bgcolor: redtheme.palette.primary.main,
        textTransform: 'none',
        fontWeight: screen.isMobile ? 300 : 500,
        fontSize: screen.isMobile ? '14px' : '16px',
        lineHeight: screen.isMobile ? '14px' : '16px',
        fontFamily: '"JohnsonText-Medium"',
        '&:hover': {
          backgroundColor: redtheme.palette.primary.main,
          color: redtheme.palette.button.white,
          fontFamily: '"JohnsonText-Medium"',
        },
        '&:focus': { outline: 'none' },
      }}
    >
      {buttonText}
      {isIconToShow ? <Download /> : null}
    </Button>
    {buttonText === labels.exportDataTableText && <StyledMenu id="btn-menu" MenuListProps={{
      'aria-labelledby': 'btn-menu',
    }}
      onClose={handleCloseMenu}
      anchorEl={anchorEl}
      open={open}>
      <MenuItem onClick={handleClose} disableRipple>
        Instant Report
      </MenuItem>
      <MenuItem onClick={handleClose} disableRipple>
        Schedule Report
      </MenuItem>
    </StyledMenu>
    }
  </>
   
  )
}