import { Box, Typography, IconButton, Tabs, Tab, Grid } from '@mui/material';
import { Modal } from '@mui/material';
import { RedTheme } from 'common/global';
import CloseIcon from '@mui/icons-material/Close';
import { useScreen } from 'common/hooks/useScreen';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights';
import { useEffect, useState } from 'react';
import { SetAlerts } from './SetAlerts';
import { ViewAlerts } from './ViewAlerts';
import { labels, NUMBER_ARRAY } from 'common/global/constants';
import { formatOperations } from 'TAP/utils/commonMethods';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteAlerts,
  MyTeamsPerformanceSetAlerts,
  AdminViewSetAlerts,
  DeleteAdminAlerts,
} from 'TAP/redux/actions/alertAction';
import { THERSHOLD_VALUE_REGEX } from 'common/global/constants';
import { ConfirmationPopup } from './ConfirmationPopup';
import { TapGlobalMessages } from 'TAP/global';

export const CustomSetAlertPopup = ({
  clickHandler,
  open,
  showManagerAutocomplete,
  isAdmin = false,
}) => {
  const dispatch = useDispatch();
  let redtheme = RedTheme;
  const screen = useScreen();
  const [screenIndex, setScreenIndex] = useState(NUMBER_ARRAY.zero);
  const [emailValue, setEmailValue] = useState([]);
  const [thresold, setThresold] = useState('');
  const [tresoldVal, setTresoldVal] = useState('');
  const [alert, setAlert] = useState('');
  const [peopleLeader, setPeopleLeader] = useState([]);
  const [logInUserDetails, setLogInUserDetails] = useState('');
  const [isAlertEditState, setIsAlertEditState] = useState(false);
  const [userIdOnEdit, setUserIdOnEdit] = useState('');
  const [oldAlertName, setOldAlertName] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState(null);
  const [resetForm, setResetForm] = useState(false);
  const alerts = useSelector((state) => state?.alertRd)
  const errorAlert = alerts?.errorCreateAlert?.data;
  const alertData = alerts?.createAlert?.data;
  const adminAlertData = alerts?.createAdminAlert?.data;
  const editAlerts = alerts?.editedAlert?.data;
  const editedAdminAlerts = alerts?.adminEditedAlerts?.data;

  useEffect(() => {
    if(errorAlert) {
      setScreenIndex(NUMBER_ARRAY.one);
      setResetForm(false);
    }
  },[errorAlert]);

  useEffect(() => {
    if (alertData) {
      setResetForm(true);
      setScreenIndex(NUMBER_ARRAY.zero);
    }
  }, [alertData]);

  useEffect(() => {
    if (editAlerts || editedAdminAlerts) {
      setResetForm(true);
    }
  }, [editAlerts, editedAdminAlerts]);

  useEffect(() => {
    if(isAdmin) {
      if(adminAlertData) {
        setResetForm(true);
        setScreenIndex(NUMBER_ARRAY.zero);
      }
    }
  },[adminAlertData, isAdmin]);

  const getUserEmailList = (selectedUserEmailValues = []) => {
    let selectedUserEmailLst = [];
    selectedUserEmailValues.forEach((item) => {
      selectedUserEmailLst.push(item?.user_email || item?.manager_user_email);
    });
    return selectedUserEmailLst.join(',');
  };

  const handleTabChange = (_event, newValue) => {
    setScreenIndex(newValue);
    setEmailValue([]);
    setThresold('');
    setTresoldVal('');
    setAlert('');
    setPeopleLeader([]);
  };

  const handelAlertSubmit = (
    alertName,
    selectedThresholds,
    thresholdValues,
    selectedUserEmailValues,
    selectedManagerEmailValue,
    isAlertEditState,
  ) => {
    const userId = isAlertEditState ? userIdOnEdit : '';
    const validOperatorsRegex = THERSHOLD_VALUE_REGEX;
    const validSelectedThresholds = Array.isArray(selectedThresholds) ? selectedThresholds : [];

    const operations = validSelectedThresholds
      .map((operator, index) => {
        const matchedOperator = operator.match(validOperatorsRegex);

        if (matchedOperator && thresholdValues[index]) {
          const cleanedOperator = matchedOperator[0].split(' ')[NUMBER_ARRAY.zero];

          return {
            operator: cleanedOperator,
            threshold: Number(thresholdValues[index]),
          };
        }
        return null;
      })
      .filter(Boolean);

    const commonPayload = {
      Name: isAlertEditState ? oldAlertName : alertName,
      Type: 'custom',
      cc: getUserEmailList(selectedUserEmailValues) || null,
      bcc: null,
      operations: JSON.stringify(operations),
    };

    if (isAlertEditState && alertName !== oldAlertName) {
      commonPayload.newName = alertName;
    }

    const payload = showManagerAutocomplete
      ? { ...commonPayload, to: getUserEmailList(selectedManagerEmailValue) }
      : { ...commonPayload, to: logInUserDetails?.user_email };

    const action = showManagerAutocomplete ? AdminViewSetAlerts : MyTeamsPerformanceSetAlerts;
    dispatch(action(payload, userId));
    setIsAlertEditState(false);
  };

  const editHandler = (editedAlert) => {
    editedAlert = editedAlert[0];
    if (editedAlert) {
      setScreenIndex(NUMBER_ARRAY.one);
      const to = editedAlert?.to?.split(',') || editedAlert.to;
      const cc = editedAlert?.cc?.split(',') || editedAlert.cc || null;
      const alert_name = editedAlert?.Name || '';
      const operations = JSON.parse(JSON.parse(JSON.stringify(editedAlert?.operations)));
      setOldAlertName(alert_name);

      setEmailValue(cc);
      setAlert(alert_name);
      setPeopleLeader(to);
      setUserIdOnEdit(editedAlert.Id);
      setIsAlertEditState(true);
      const { formattedThresholds, formattedThresholdValues } = formatOperations(operations);
      setThresold(formattedThresholds);
      setTresoldVal(formattedThresholdValues);
    }
  };

  const deleteData = (userId) => {
    setAlertToDelete(userId);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (isAdmin) {
      dispatch(DeleteAdminAlerts(alertToDelete));
    } else {
      dispatch(DeleteAlerts(alertToDelete));
    }
    setOpenConfirmDialog(false);
    setAlertToDelete(null);
  };

  useEffect(() => {
    const userDetails = localStorage.getItem('userDetails');
    if (userDetails && Object.keys(userDetails).length) {
      setLogInUserDetails(JSON.parse(userDetails));
    }
  }, []);

  const onClosePopup = () => {
    setScreenIndex(NUMBER_ARRAY.zero);
    setEmailValue([]);
    setThresold('');
    setTresoldVal('');
    setAlert('');
    setPeopleLeader([]);
    clickHandler(false);
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: redtheme.palette.box.white,
            padding: screen.isMobile ? '16px' : '10px 46px 10px',
            gap: '48px',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: screen.resolveStyles({
              mobile: '360px',
              tablet: '818px',
              desktop: '90%',
            }),
            height: '80%',
            borderRadius: '16px',
          }}
        >
          <IconButton
            data-testid='btn-close'
            className='btn-close-popup'
            sx={{
              position: 'absolute',
              top: '10px',
              right: '30px',
            }}
            onClick={onClosePopup}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant='h2'
            component='div'
            sx={{ weight: fontWeightStyles.fw9.fontWeight, fontFamily: '"JohnsonDisplay-Bold"' }}
          >
            {labels.setAlert}
          </Typography>
          <br />
          <Grid container>
            <Grid item xs={NUMBER_ARRAY.ten}>
              <Tabs
                value={screenIndex}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  sx: {
                    height: '3px',
                    borderRadius: '3px',
                    textTransform: 'none',
                  },
                }}
                sx={{
                  minHeight: '49px',
                  '& .Mui-selected': {
                    fontWeight: 'bold',
                    color: `${redtheme.palette.fontColor.black_gray} !important`,
                  },
                }}
              >
                <Tab
                  label='View Alerts'
                  data-testid='myDirectReports'
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: fontWeightStyles.fw7.fontWeight,
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                    borderBottom: NUMBER_ARRAY.three,
                    borderColor: 'divider',
                  }}
                ></Tab>
                <Tab
                  label='Set Alerts'
                  data-testid='myOrganizationTab'
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: fontWeightStyles.fw7.fontWeight,
                    lineHeight: '16px',
                    fontFamily: '"JohnsonText-Medium"',
                    borderBottom: NUMBER_ARRAY.three,
                    borderColor: 'divider',
                  }}
                ></Tab>
              </Tabs>
            </Grid>
          </Grid>
          {screenIndex === NUMBER_ARRAY.one ? (
            <SetAlerts
              handelAlertSubmit={handelAlertSubmit}
              emailValue={emailValue}
              thresold={thresold}
              tresoldVal={tresoldVal}
              alert={alert}
              isAlertEditState={isAlertEditState}
              peopleLeader={peopleLeader}
              showManagerAutocomplete={showManagerAutocomplete}
              resetForm={resetForm} 
            />
          ) : (
            <ViewAlerts editHandler={editHandler} deleteData={deleteData} isAdmin={isAdmin}/>
          )}
        </Box>
      </Modal>
      <ConfirmationPopup
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        title={TapGlobalMessages.deleteAlertPopupMessages.title}
        message={TapGlobalMessages.deleteAlertPopupMessages.message}
        rightButtonText={TapGlobalMessages.deleteAlertPopupMessages.rightButtonText}
        leftButtonText={TapGlobalMessages.deleteAlertPopupMessages.leftButtonText}
      />
    </>
  );
};
