import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box, Grid } from '@mui/material';
import { RedTheme } from 'common/global';
import { tablePaginationClasses } from '@mui/material';
import clsx from 'clsx';
import { useScreen } from 'common/hooks/useScreen';
import { IOSSwitch } from 'common/components/switch';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { SelectedTheme, BlueTheme } from 'common/global';
import { ReactComponent as Edit } from '../assets/images/edit.svg';
import { ReactComponent as Delete } from '../assets/images/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditAlerts,
  MyTeamsPerformaceGetAlerts,
  EditAdminAlerts,
} from 'TAP/redux/actions/alertAction';
import { Fragment, useEffect, useState } from 'react';
import {
  MyTeamsPerformaceMuteAlerts,
  AdminViewGetAllerts,
  AdminMuteAlerts,
} from 'TAP/redux/actions/alertAction';
import { NUMBER_ARRAY } from 'common/global/constants';
import { labels } from 'common/global/constants';
import { Toaster } from './Toaster';

export const ViewAlerts = ({ editHandler, deleteData, isAdmin }) => {
  const alerts = useSelector((state) => state?.alertRd);
  const alertData = alerts?.alerts?.data || [];
  const muteAlertAction = alerts?.muteAlerts?.data;
  const editedAlert = alerts?.editedAlert?.data;
  const editedAdminAlerts = alerts?.adminEditedAlerts?.data;
  const deleteAlert = alerts?.deleteAlert?.data;
  const muteAdminAlerts = alerts?.adminMuteAlerts?.data;
  const deleteAdminAlerts = alerts?.adminDeletedAlerts?.data;
  const newAlert = alerts?.createAlert?.data;
  const newAdminAlert = alerts?.createAdminAlert?.data;
  const adminAlerts = alerts?.adminAlerts?.data || [];
  const [isEditState, setIsEditState] = useState(false);
  const dispatch = useDispatch();
  let redtheme = RedTheme;
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const boxButtonStyle = (isDisabled) => ({
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': isDisabled ? {} : { backgroundColor: redtheme.palette.box.lightPink },
    padding: '8px',
    borderRadius: '4px',
    opacity: isDisabled ? 0.5 : 1,pointerEvents:isDisabled ? "none" : "auto"
  });

  const buttonStyle = {
    color: 'inherit',
    '&:hover': {
      '& svg': {
        stroke: redtheme.palette.button.pure_red,
      },
    },
  };

  const fetchAlerts = () => {
    dispatch(MyTeamsPerformaceGetAlerts());
  };

  const fetchAdminAlerts = () => {
    dispatch(AdminViewGetAllerts());
  };

  useEffect(() => {
    if (isAdmin) {
      fetchAdminAlerts();
    } else {
      fetchAlerts();
    }
  }, [deleteAdminAlerts, newAdminAlert, deleteAlert, newAlert]);

  useEffect(() => {
    if (editedAlert && isEditState) {
      editHandler(editedAlert);
      setIsEditState(!isEditState);
    }
  }, [editedAlert]);

  useEffect(() => {
    if (editedAdminAlerts && isEditState) {
      editHandler(editedAdminAlerts);
      setIsEditState(!isEditState);
    }
  }, [editedAdminAlerts]);

  const selectedAlerts = isAdmin ? adminAlerts : alertData;
  const transformedData = Array.isArray(selectedAlerts)
    ? selectedAlerts.map((alert) => {
        let operations = [];
        if (alert.operations) {
          operations = JSON.parse(alert.operations);
        }
        const formattedThreshold =
          operations.length === NUMBER_ARRAY.one
            ? `${operations[NUMBER_ARRAY.zero].logicalOperator} ${operations[NUMBER_ARRAY.zero].threshold}`
            : operations
                .map((op, index) => {
                  const nextOp = operations[index + NUMBER_ARRAY.one];
                  return nextOp ? `${op.threshold}${op.logicalOperator}` : op.threshold;
                })
                .join(' ');

        return {
          id: alert.Id,
          alert_name: alert.Name,
          audience: alert.to,
          threshold: formattedThreshold,
          alert_type: alert.Type,
          isActive: alert.IsActive,
        };
      })
    : [];

  const handleEditClick = (recordId) => {
    if (isAdmin) {
      dispatch(EditAdminAlerts(recordId));
    } else {
      dispatch(EditAlerts(recordId));
    }
    setIsEditState(true);
  };

  const handleDeleteClick = (rowId) => {
    deleteData(rowId);
  };

  const screen = useScreen();

  const theme = SelectedTheme();
  let complementTheme = theme.palette.type === 'red' ? BlueTheme : RedTheme;

  const switchTheme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: RedTheme.palette.button.white,
          },
          colorPrimary: {
            '&.Mui-checked': {
              color: complementTheme.palette.primary.main,
            },
          },
          track: {
            backgroundColor: '#C1BBB3',
            '.Mui-checked.Mui-checked + &': {
              backgroundColor: '#888B8D',
            },
          },
        },
      },
    },
  });

  const columns = [
    {
      field: 'alert_name',
      width: screen.resolveStyles({
        mobile: NUMBER_ARRAY.oneHundredEighty,
        tablet: NUMBER_ARRAY.oneHundredEighty,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography
            sx={{ fontFamily: '"JohnsonText-Bold"', color: redtheme.palette.text.secondary }}
          >
            Alert Name
          </Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? NUMBER_ARRAY.zero : NUMBER_ARRAY.one,
      disableColumnMenu: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          overdue: ['complete overdue'].includes(params.value.toLowerCase()),
        });
      },
      renderCell: (params) => (
        <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
          {params.value}
        </Box>
      ),
    },
    ...(isAdmin
      ? [
          {
            width: screen.resolveStyles({
              mobile: NUMBER_ARRAY.twoHundredTwenty,
              tablet: NUMBER_ARRAY.twoHundredTwenty,
              desktop: null,
            }),
            field: 'audience',
            renderHeader: () => (
              <Box>
                <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Audience</Typography>
              </Box>
            ),
            flex: !screen.isDesktop ? NUMBER_ARRAY.zero : NUMBER_ARRAY.two,
            disableColumnMenu: true,
            renderCell: (params) => (
              <Typography sx={{ fontFamily: '"JohnsonText-Regular"', color: '#C62828' }}>
                {params.value}
              </Typography>
            ),
          },
        ]
      : []),
    {
      field: 'threshold',
      width: screen.resolveStyles({
        mobile: NUMBER_ARRAY.oneHundredEighty,
        tablet: NUMBER_ARRAY.oneHundredEighty,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Threshold</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? NUMBER_ARRAY.zero : NUMBER_ARRAY.one,
      disableColumnMenu: true,
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: 'alert_type',
      width: screen.resolveStyles({
        mobile: NUMBER_ARRAY.oneHundredEighty,
        tablet: NUMBER_ARRAY.oneHundredEighty,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Alert Type</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? NUMBER_ARRAY.zero : NUMBER_ARRAY.one,
      disableColumnMenu: true,
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: '',
      width: screen.resolveStyles({
        mobile: NUMBER_ARRAY.oneHundredEighty,
        tablet: NUMBER_ARRAY.oneHundredEighty,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Actions</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? NUMBER_ARRAY.zero : NUMBER_ARRAY.one,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isStandardAlert = params.row.alert_type === 'standard';
        return (
          <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
            <Grid container spacing={NUMBER_ARRAY.zero}>
              <Fragment>
                <Grid item xs={NUMBER_ARRAY.six}>
                  <Box
                    sx={boxButtonStyle(isStandardAlert)}
                    onClick={() => {
                      if (isStandardAlert) return;
                      handleEditClick(params.row.id);
                    }}
                  >
                    <Edit data-testid="edit-icon" style={buttonStyle} disabled={isStandardAlert} />
                  </Box>
                </Grid>
                <Grid item xs={NUMBER_ARRAY.six}>
                  <Box
                    sx={boxButtonStyle(isStandardAlert)}
                    onClick={() => {
                      if (isStandardAlert) return;
                      handleDeleteClick(params.row.id);
                    }}
                  >
                    <Delete data-testid="delete-icon" style={buttonStyle} disabled={isStandardAlert} />
                  </Box>
                </Grid>
              </Fragment>
            </Grid>
          </Box>
        );
      },
    },
    {
      field: 'mute',
      width: screen.resolveStyles({
        mobile: NUMBER_ARRAY.oneHundredEighty,
        tablet: NUMBER_ARRAY.oneHundredEighty,
        desktop: null,
      }),
      sortable: false,
      renderHeader: () => <Box></Box>,
      flex: !screen.isDesktop ? NUMBER_ARRAY.zero : NUMBER_ARRAY.one,
      disableColumnMenu: true,

      renderCell: (params) => {
        const [isMuted, setIsMuted] = useState(!params.row.isActive);

        useEffect(() => {
          if (muteAlertAction) {
            const alertData = muteAlertAction.find((alert) => alert.Id === params.row.id);
            if (alertData) {
              setIsMuted(!alertData.IsActive);
            }
          }
        }, [muteAlertAction, params.row.id]);

        useEffect(() => {
          if (muteAdminAlerts) {
            const adminAlertData = muteAdminAlerts.find((alert) => alert.Id === params.row.id);
            if (adminAlertData) {
              setIsMuted(!adminAlertData.IsActive);
            }
          }
        }, [muteAdminAlerts, params.row.id]);

        const handleMuteChange = (id) => {
          if (!params.row.isActive) return;
          setIsMuted(true);
          if (isAdmin) {
            dispatch(AdminMuteAlerts({}, id));
          } else {
            dispatch(MyTeamsPerformaceMuteAlerts({}, id));
          }
        };
        return (
          <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
            <Grid container>
              <Grid item xs={NUMBER_ARRAY.eight}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '14px',
                    fontWeight: NUMBER_ARRAY.fiveHundred,
                    fontFamily: '"JohnsonText-Bold"',
                    color: 'rgba(33, 33, 33, 1)',
                    height: '16px',
                    paddingTop: '3px',
                  }}
                >
                  Mute
                </Typography>
              </Grid>
              <Grid item xs={NUMBER_ARRAY.four}>
                <Box>
                  <ThemeProvider theme={switchTheme}>
                    <IOSSwitch
                      id='statusSwitch'
                      onChange={() => handleMuteChange(params.row.id)}
                      disabled={!params.row.isActive}
                      data-testid='switch-button'
                      checked={isMuted}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: '70%' }}>
        <Toaster
          message={labels.muteAlertMessage}
          severity='warning'
          show={show}
          onClose={handleClose}
          isAlertPopup={true}
        />
      <DataGrid
        disableVirtualization={process.env.NODE_ENV === 'test'} // virtualization disabled only during tests - generating all columns during tests
        initialState={{
          sorting: {
            sortModel: [{ field: 'alert_type', sort: 'desc' }],
          },
        }}
        rows={transformedData}
        columns={columns}
        getRowId={(row) => row.id}
        sortingMode='client'
        paginationMode='client'
        disableRowSelectionOnClick
        pageSizeOptions={[NUMBER_ARRAY.five, NUMBER_ARRAY.ten, NUMBER_ARRAY.fifteen]}
        sx={{
          border: 'none',
          height: '90%',
          marginTop: '50px',
          '& .MuiDataGrid-cell': {
            fontSize: '14px',
            fontWeight: 450,
            fontFamily: '"J&J CircularTT Book Web"',
          },
          '& .MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: redtheme.palette.dataGrid.hoverGrey,
            },
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 153, 0.03)',
          },
          '& .MuiDataGrid-row:nth-of-type(odd):hover': {
            backgroundColor: redtheme.palette.dataGrid.very_light_grey,
          },
          '& .MuiDataGrid-columnHeaders': {
            fontSize: 16,
            fontFamily: '"J&J CircularTT Black Web"',
          },
          '& .MuiDataGrid-row.Mui-selected': { backgroundColor: redtheme.palette.dataGrid.white },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: redtheme.palette.dataGrid.white,
          },
          '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 153, 0.03)',
          },
          '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
            backgroundColor: redtheme.palette.dataGrid.very_light_grey,
          },
          '& .MuiDataGrid-rowCount': {
            fontWeight: 'bold',
          },
          '& .MuiTablePagination-selectLabel': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '& .MuiTablePagination-displayedRows': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '& .MuiTablePagination-select': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '26px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cellContent': {
            pointerEvents: 'none',
            fontWeight: '400',
            fontSize: '1rem',
          },
          [`& .${tablePaginationClasses.selectLabel}`]: {
            display: 'block',
          },
          [`& .${tablePaginationClasses.input}`]: {
            display: 'inline-flex',
          },
        }}
      />
    </Box>
  );
};
