import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  formatTime,
  getComplianceStatusTextColor,
  getDimensionWithUnit,
  downloadExcelReport,
  getGAMeasurementId,
  getColorAsPerIndicator,
  statusTranform,
} from 'TAP/utils/commonMethods';
import { ToolTipWrapper, ToolTip } from 'common/components';
import { SelectedTheme, BlueTheme, RedTheme } from 'common/global';
import GenericPopup from 'common/popup';
import { TapGlobalMessages } from 'TAP/global';
import { ShowLoader } from 'TAP/global/showLoader';
import { ActionButtonWrapper } from 'TAP/components/ActionButtonWrapper';
import { GraphTile } from 'TAP/components/graphTile';
import { LeaveIndicator } from 'TAP/components/leaveIndicator';
import { NonCompliantTooltipBody, AtRiskTooltipBody } from 'TAP/components';
import {
  AdminViewCompletedStatusDetails,
  AdminOverdueData,
  AdminUpcomingOverdueData,
  AdminComplianceData,
  AdminNonCompliantData,
  AdminAtRiskData,
  AdminQuickFilterAction,
  AdminFavouriteFilterAction,
  AdminManageFilterListAction,
  AdminManageFilterLoadAction,
  AdminManageFilterDeleteAction,
  AdminDownloadData,
  getLargeReportDownload,
} from 'TAP/redux/actions';
import { labels, myOrgPerform } from 'common/global/constants';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights';
import { IOSSwitch } from 'common/components/switch';
import { padding } from 'TAP/styles/genericStyles/padding';
import { gap } from 'TAP/styles/genericStyles/gap';
import { width } from 'TAP/styles/genericStyles/width';
import { height } from 'TAP/styles/genericStyles/height';
import { fontsize } from 'TAP/styles/genericStyles/fontSize';
import { lineHeight } from 'TAP/styles/genericStyles/lineHeight';
import { textTransform } from 'TAP/styles/genericStyles/textTransform';
import { fontFamily } from 'TAP/styles/genericStyles/fontFamily';
import { spacing } from 'TAP/styles/genericStyles/spacing';
import { AdminQuickFilter } from 'TAP/components/AdminQuickFilter';
import { TrainingExportPopup } from 'TAP/components/TrainingExportPopup';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';
import { Toaster } from 'TAP/components/Toaster';
import { Spin } from 'antd';
import { ScheduleReport } from 'TAP/components/ScheduleReportPopup';
import moment from 'moment';
import { CustomSetAlertButton } from 'TAP/components/CustomSetAlertButton';
import { CustomSetAlertPopup } from 'TAP/components/CustomSetAlertPopup';

const switchStateValues = {
  onTime: { label: 'On Time' },
  overall: { label: 'Overall' },
};

const statusMapper = {
  'Overdue Trainings': 'adminView/trainings/totalOverDue',
  'Coming Due Trainings': 'adminView/trainings/upcomingDue',
  'Total Non-Compliant': 'adminView/actions/nonCompliant',
  'Total At Risk': 'adminView/actions/atRisk',
  'Overall Compliance Profile': 'adminView/actions/overallComplianceProfile',
  'Job Description': 'adminView/actions/overallBarData',
  'Summary of Experience': 'adminView/actions/overallBarData',
  'Individual Training Plan': 'adminView/actions/overallBarData',
  'Training Completed On Time': 'adminView/actions/overallBarData',
  'Training Completed Overall': 'adminView/actions/overallBarData',
  'Trainings Completed On Time': 'adminView/trainings/overallTrainingProfile',
};
const complianceFlag = {
  'Job Description': 'JD',
  'Summary of Experience': 'SOE',
  'Individual Training Plan': 'ITP',
  'Training Completed On Time': 'TCOT',
  'Training Completed Overall': 'TCOP',
};
const monthIndex = {
  Jan: '1',
  Feb: '2',
  Mar: '3',
  Apr: '4',
  May: '5',
  Jun: '6',
  Jul: '7',
  Aug: '8',
  Sep: '9',
  Oct: '10',
  Nov: '11',
  Dec: '12',
  '': '',
};

const timeRangeValues = { year: 'Year', quarter: 'Quarter', month: 'Month' };
let redtheme = RedTheme;

const largeDownloadSuccessMsg =
  'The report is ready and an email will arrive shortly with a link to the report location where you can access and download.';
const largeDownloadStartMsg =
  'Record count is greater than 100K. You will receive an email with a link to access the report.';

export const AdminView = () => {
  const [initializer, setInitializer] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [trainingOverdueData, setTrainingOverdueData] = useState('');
  const [trainingUpcomingDueData, setTrainingUpcomingDueData] = useState('');
  const [totalNonCompliantData, setTotalNonCompliantData] = useState('');
  const [totalAtRiskData, setTotalAtRiskData] = useState('');
  const [selectedDays, setSelectedDays] = useState(myOrgPerform.seven);
  const [trainingState, setTrainingState] = useState(switchStateValues.onTime);
  const [switchState, setSwitchState] = useState(switchStateValues.overall);
  const [activeFilter, setActiveFilter] = useState(myOrgPerform.two);
  const [timeRangeState, setTimeRangeState] = useState(timeRangeValues.month);
  const [myOrgCompletedData, setMyOrgCompletedData] = useState(0);
  const [logInUserDetails, setLogInUserDetails] = useState('');
  const screen = useScreen();
  const [openPopup, setOpenPopup] = useState(false);
  const [isGenericPopupOpenForDonutChart, setIsGenericPopupOpenForDonutChart] = useState(false);
  const [popupHeader, setPopupHeader] = useState('');
  const [popupButtons, setPopupButtons] = useState([]);
  const [donutChartAutocompleteConfigs, setDonutChartAutocompleteConfigs] = useState([]);
  const [popUserId, setPopUserId] = useState('');
  const [popupStatus, setPopupStatus] = useState('');
  const [popUpName, setPopUpName] = useState('');
  const [autocompleteConfigs, setAutocompleteConfigs] = useState([]);
  const [complianceautocompleteConfigs, setcomplianceautocompleteConfigs] = useState([]);
  const [isCheckboxSelection, setIsCheckboxSelection] = useState(false);
  const [isCompliantPopup, setIsCompliantPopup] = useState(false);
  const [legalEntityOptions, setLegalEntityOptions] = useState([]);
  const [legalEntityValues, setLegalEntityValues] = useState([]);
  const [legalEntity, setLegalEntity] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryValues, setCountryValues] = useState([]);
  const [country, setCountry] = useState([]);
  const [adminDateRange, setAdminDateRange] = useState([]);
  const [quickFilterDateRange, setQuickFilterDateRange] = useState([]);
  const [workerTypeOptions, setWorkerTypeOptions] = useState([]);
  const [workerTypeValues, setWorkerTypeValue] = useState([]);
  const [workerType, setWorkerType] = useState([]);
  const [partnerCompanyOptions, setPartnerCompanyOptions] = useState([]);
  const [partnerCompanyValues, setPartnerCompanyValue] = useState([]);
  const [partnerCompany, setPartnerCompany] = useState([]);
  const [mrcNameOptions, setMrcNameOptions] = useState([]);
  const [mrcNameValues, setMrcNameValue] = useState([]);
  const [courseNameOptions, setCourseNameOptions] = useState([]);
  const [courseSelectedOption, setCourseSelectedOption] = useState([]);
  const [mrcNameValuesFormatted, setMrcNameValueFormatted] = useState([]);
  const [mrcName, setmrcName] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [userValues, setUserValue] = useState([]);
  const [user, setUser] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [managerValues, setManagerValue] = useState([]);
  const [manager, setManager] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeButton, setActiveButton] = useState(myOrgPerform.three);
  const [favName, setFavName] = useState('');
  const [dirtyCheckFavFilterName, setDirtyCheckFavFilterName] = useState('');
  const [favNamePayload, setFavNamePayload] = useState('');
  const [selectFav, setSelectFav] = useState('');
  const [favList, setFavList] = useState([]);
  const [deletefav, setDeleteFav] = useState('');
  const [disableAddToFavBtn, setDisableAddToFavBtn] = useState(true);
  const [favUserInputValidation, setFavUserInputValidation] = useState(false);
  const [inputFieldHeader, setInputFieldHeader] = useState('');
  const [openExportPopup, setOpenExportPopup] = useState(false);
  const [openExportTablePopup, setOpenExportTablePopup] = useState(false);
  const [openExportPopupContent, setOpenExportPopupContent] = useState('');
  const [showLargeDataDownloadInfoMsg, setShowLargeDataDownloadInfoMsg] = useState(false);
  const [showLargeDataDownloadSuccessMsg, setShowLargeDataDownloadSuccessMsg] = useState(false);
  const [showDownloadLoader, setShowDownloadLoader] = useState(false);
  const [AVCompleteStatus, setAVCompleteStatus] = useState(false);
  const [isSetAlertPopupOpen, setIsSetAlertPopupOpen] = useState(false);
  const [complianceElements, setComplianceElements] = useState('');
  const [chartYear, setChartYear] = useState('');
  const [chartMonth, setChartMonth] = useState('');
  const [chartQuerter, setChartQuerter] = useState('');
  const [quickFilterEditMode, setQuickFilterEditMode ] = useState(false);
  const [dirtyCheckEditMode, setDirtyCheckEditMode] = useState(false);
  const [isHierarchyTraceabilityOn, setIsHierarchyTraceabilityOn] = useState(0);
  const [isHierarchyTraceabilityOnInScheduleReport, setIsHierarchyTraceabilityOnInScheduleReport] = useState(0);

  const WORKER_TYPE = 'WorkerType';
  const PARENT_COMPANY = 'parentCompany';
  const COUNTRY = 'country';
  const LEGAL_ENTITY = 'legalEntity';
  const COURSE_CODE = 'courseCode';
  const MRC_NAME = 'MRCName';
  const WWID_USER_NAME = 'wwidUsername';
  const WWID_MANAGER_NAME = 'wwidManagername';
  const favFilterName = 'Favourite Filter Name';
  const favUserInputErrorMsg = 'Only Alphanumeric, hyphens and spaces are allowed';

  const lastRefreshed = () => {
    if (logInUserDetails && Object.keys(logInUserDetails).length) {
      return formatTime(logInUserDetails?.last_refresh_date);
    }
  };

  const isFetchingAVCompleteStatus = useSelector(
    (state) => state.tapAdminViewCompleteStatusRd?.isLoadingAdminViewCSReducer,
  );
  useEffect(() => {
    setAVCompleteStatus(isFetchingAVCompleteStatus);
  }, [isFetchingAVCompleteStatus]);

  useEffect(() => {
    ReactGA.initialize(`G-${getGAMeasurementId()}`);
    ReactGA.send({ hitType: 'pageview', page: '/TAP/adminview', title: 'Administrative View' });
  }, []);
  useEffect(() => {
    setInputFieldHeader(favFilterName);

    if (!initializer) {
      const userDetails = localStorage.getItem('userDetails');
      if (userDetails && Object.keys(userDetails).length) {
        setLogInUserDetails(JSON.parse(userDetails));
      }

      setInitializer(true);
    }
  }, [initializer]);

  /**
   * handleButtonClick.
   * Once user click on tab it will update data accordingly
   * Use Stack Component
   * Use Typography Component
   * @param {string} button
   * @param {string} timeRange
   */
  const handleButtonClick = (button) => {
    if (activeButton !== button) {
      setActiveButton(button);
    }
  };

  /* (AdminQuickFilter Autocomplete)This function will check if the item in the option list is equal to the item in the selected value 
  list, then it will return true so that it look highlighted in the Search option to indicate the end user 
  that this item is selected and also help in selection one item only one time not multiple time*/
  const getEqualityCheck = (option, value, type) => {
    if (
      type === 'user' &&
      (value === undefined || value === null || option?.user_id === value?.user_id)
    ) {
      return true;
    } else if (
      type === 'manager' &&
      (value === undefined || value === null || option?.manager_id === value?.manager_id)
    ) {
      return true;
    } else if (
      type === 'MRC' &&
      (value === undefined || value === null || option?.mrc === value?.mrc)
    ) {
      return true;
    } else if (
      type === 'CourseCode' && 
      ((value === undefined || value === null || option?.training_id === value?.training_id))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleAdminStatusChange = (event, type) => {
    if (type === 'LegalEntity') {
      setLegalEntityValues(event.target.value);
    } else if (type === 'Country') {
      setCountryValues(event.target.value);
    } else if (type === 'WorkerType') {
      setWorkerTypeValue(event.target.value);
    } else if (type === 'PartnerCompany') {
      setPartnerCompanyValue(event.target.value);
    } else if (type === 'Date') {
      if (event?.length && event[0]?.length) {
        setAdminDateRange(event);
        const dateRangeParentFormatted = event ? event.map((date) => dayjs(date)) : null;
        setSelectedDate(dateRangeParentFormatted);
      } else {
        setAdminDateRange([]);
        setSelectedDate([]);
      }
    } else if (type === 'CourseCode') {
      setCourseSelectedOption(event);
    } else if (type === 'MRC') {
      const data = event ? event.map((item) => item.mrc) : [];
      setMrcNameValueFormatted(data);
      setMrcNameValue(event);
    } else if (type === 'user') {
      setUserValue(event);
    } else if (type === 'manager') {
      setManagerValue(event);
    } else if (type === 'Favourite') {
      setSelectFav(event.target.value);
    } else {
      //No valid request
      setLegalEntityValues([]);
      setCountryValues([]);
      setWorkerTypeValue([]);
      setPartnerCompanyValue([]);
      setAdminDateRange([]);
      setMrcNameValueFormatted([]);
      setMrcNameValue([]);
      setUserValue([]);
      setCourseSelectedOption([]);
      setManagerValue([]);
      setSelectFav('');
    }
  };

  const resetSearch = (isEditingMode = false) => {
    setLegalEntityValues([]);
    setCountryValues([]);
    setWorkerTypeValue([]);
    setPartnerCompanyValue([]);
    setAdminDateRange([]);
    setMrcNameValueFormatted([]);
    setMrcNameValue([]);
    setUserValue([]);
    setManagerValue([]);
    setLegalEntity([]);
    setCountry([]);
    setWorkerType([]);
    setPartnerCompany([]);
    setQuickFilterDateRange([]);
    setmrcName([]);
    setUser([]);
    setManager([]);
    setCourseSelectedOption([]);
    handleClearDateRange();
    if(!isEditingMode) {
      setSelectFav('');
    }
  };

  const handleHierarchTraceabilityChange =() => {
    const currStatus = isHierarchyTraceabilityOn;
    setIsHierarchyTraceabilityOn(currStatus === 0 ? 1 : 0);
    setIsHierarchyTraceabilityOnInScheduleReport(currStatus === 0 ? 1 : 0);
  };

  const handleHierarchySwitchScheduleReportChange = () => {
    setIsHierarchyTraceabilityOnInScheduleReport(isHierarchyTraceabilityOnInScheduleReport === 0 ? 1 : 0)
  }

  const handleClearDateRange = () => {
    setSelectedDate(null);
  };
  const exportData = (event) => {
    if (event.currentTarget.textContent === labels.scheduleReportText) {
      setOpenExportTablePopup(true);
      setIsHierarchyTraceabilityOnInScheduleReport(isHierarchyTraceabilityOn);
    } else {
      prepareExport(
        'adminData',
        'adminView/quickFilterExport/report?popUpType=drill',
        getExportParam(),
      );
    }
  };

  const exportTraining = () => {
    prepareExport(
      'adminTrainingData',
      `adminView/quickFilterExportTrainingTables/report?popUpType=drill&courseCode=${courseSelectedOption?.length > NUMBER_ARRAY.zero
         ? JSON.stringify(courseSelectedOption) : ''}`,
      getExportParam(),
    );
  };

  const prepareExport = async (name, url, param) => {
    setShowDownloadLoader(true);
    const currDate = new Date().toISOString('en-US', { timeZone: 'UTC' }).split('T')[0];
    const currTime = new Date()
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
      })
      .replace(/[^ apm\d]+/gi, '_');
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    let fileName = `${name}_${currDate}_${currTime}.xlsx`;
    const requestURL = `${baseURL}${url}`;
    const params = param;
    let result = '';
    await downloadExcelReport(requestURL, fileName, params)
      .then((res) => {
        result = res;
        setShowDownloadLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === NUMBER_ARRAY.fourHundred) {
          setShowDownloadLoader(false);
          dispatch(
            AdminDownloadData({
              reportType: isCompliantPopup ? 'complianceProfile' : 'trainingProfile',
              days: selectedDays,
              trainingTitle: '',
              userName: logInUserDetails.userName,
              startDate: params.startDate,
              endDate: params.endDate,
              dataOf: '',
              country: params.country,
              mrc: params.mrc,
              legalEntityName: params.legalEntityName,
              partnerCompany: params.partnerCompany,
              userField: params.userField,
              managerField: params.managerField,
              userArray: '',
              exclUserArray: '',
              isHierarchyOn: isHierarchyTraceabilityOn,
            }),
          );
          setShowLargeDataDownloadInfoMsg(true);
        } else {
          setShowDownloadLoader(false);
          setOpenExportPopupContent('No data available for report');
        }
      });
    if (result === 'LIMIT_EXCEED') {
      setShowDownloadLoader(false);
      setOpenExportPopupContent(
        'Export file is greater than 100K rows. Exit this message to return to the Quick Filters and provide further filters to reduce the file size.',
      );
      setOpenExportPopup(true);
    } else if (result === 'NO_DATA_FOUND') {
      setShowDownloadLoader(false);
      setOpenExportPopupContent('No data available for report');
      setOpenExportPopup(true);
    }
  };

  const getExportParam = () => {
    return {
      country: countryValues?.length ? JSON.stringify(countryValues) : '',
      mrc: mrcNameValues?.length ? JSON.stringify(mrcNameValues) : '',
      legalEntityName: legalEntityValues?.length ? JSON.stringify(legalEntityValues) : '',
      workerType: workerTypeValues?.length ? JSON.stringify(workerTypeValues) : '',
      partnerCompany: partnerCompanyValues?.length ? JSON.stringify(partnerCompanyValues) : '',
      userField: userValues?.length ? JSON.stringify(userValues) : '',
      managerField: managerValues?.length ? JSON.stringify(managerValues) : '',
      startDate: quickFilterDateRange[0],
      endDate: quickFilterDateRange[1],
      isHierarchyOn: isHierarchyTraceabilityOn,
    };
  };

  const onDonutChartClicked = (
    pageName,
    ComplianceStatus,
    type,
    label = '',
    month = '',
    querter = '',
    year = '',
  ) => {
    setIsGenericPopupOpenForDonutChart(true);
    setPopUserId(logInUserDetails?.user_id);
    handlePopupButtonClick(
      label === '' ? ComplianceStatus : label.trim(),
      ComplianceStatus === 'Overall Compliance Profile' || ComplianceStatus === 'Overall Bar Data',
    );
    setComplianceElements(complianceFlag[label]);
    setChartMonth(monthIndex[month.split('-')[0]]);
    setChartYear(year);
    if (month !== '') {
      setChartYear('20' + month.split('-')[1]);
    }
    setChartQuerter(querter.split('-')[0].charAt(1) + '');

    if (querter !== '') {
      setChartYear(querter.split('-')[1]);
    }
  };

  const applyFilterHandler = () => {
    setLegalEntity(legalEntityValues);
    setCountry(countryValues);
    setWorkerType(workerTypeValues);
    setPartnerCompany(partnerCompanyValues);
    setQuickFilterDateRange(adminDateRange);
    setmrcName(mrcNameValues);
    setUser(userValues);
    setManager(managerValues);
  };

  const dispatch = useDispatch();

  //Data from the redux states
  let dashboardData = useSelector((state) => state.adminRd);
  let complianceActionsData = useSelector((state) => state.adminComplianceActionsRd);
  let quickFilter = useSelector((state) => state.adminQuickFilterRd);
  let quickFilterData = quickFilter?.quickFilterDetails;
  let postFilterData = useSelector((state) => state.adminFavouriteFilterRd);
  let saveFilterList = useSelector((state) => state.adminManageFilterListRd);
  let saveFilterSelectedLoad = useSelector((state) => state.adminManageFilterLoadRd);
  let selectedLoadData = saveFilterSelectedLoad?.manageFilterDetails?.data[0]?.input_json;
  let saveFilterDelete = useSelector((state) => state.adminManageFilterDeleteRd);

  const clearSetValues = () => {
    setLegalEntityValues([]);
    setCountryValues([]);
    setWorkerTypeValue([]);
    setPartnerCompanyValue([]);
    setAdminDateRange([]);
    setMrcNameValueFormatted([]);
    setMrcNameValue([]);
    setUserValue([]);
    setManagerValue([]);
    setLegalEntity([]);
    setCountry([]);
    setWorkerType([]);
    setPartnerCompany([]);
    setQuickFilterDateRange([]);
    setmrcName([]);
    setUser([]);
    setManager([]);
  };

  const filteredvalue = postFilterData?.favouriteFilterDetails?.data;

  //Save Favourite filter name (Response : 1).
  useEffect(() => {
    if (filteredvalue === 'Filter Name Already Exists !') {
      alert('Filter already exists');
    } else if (filteredvalue === 'Filter Saved Successfully !') {
      setFavNamePayload('');
      alert('Saved Succesfully!');
    } else if (filteredvalue === 'Filter edited successfully!'){
      setSelectFav(favNamePayload);
      setFavNamePayload('');
      alert(filteredvalue);
    } else if (filteredvalue === "Duplicate filter found!") {
      alert(filteredvalue);
    } else if (filteredvalue === 'Filter limit exceeds!') {
      alert('You have reached the maximum limit of saving 50 filters!');
    }
  }, [postFilterData, filteredvalue]);

  //Load the List of favourite Filters (Response : 2).
  useEffect(() => {
    if (saveFilterList !== undefined) {
      setFavList(saveFilterList?.manageFilterDetails?.data);
    } else {
      setFavList([]);
    }
  }, [saveFilterList]);

  //On selecting the favourite Filters (Response : 3).
  useEffect(() => {
    let selectedLoadDataObject = {};
    if (selectedLoadData) {
      selectedLoadDataObject = JSON.parse(selectedLoadData);
    }
    if (selectedLoadDataObject?.filterName !== undefined && selectFav.length !== 0) {
      const data = selectedLoadDataObject?.mrc
        ? selectedLoadDataObject.mrc.map((item) => item.mrc)
        : [];
      selectedLoadDataObject?.legalEntityName !== ''
        ? setLegalEntityValues(selectedLoadDataObject?.legalEntityName)
        : setLegalEntityValues([]);
      selectedLoadDataObject?.country !== ''
        ? setCountryValues(selectedLoadDataObject?.country)
        : setCountryValues([]);
      selectedLoadDataObject?.workerType
        ? setWorkerTypeValue(selectedLoadDataObject?.workerType)
        : setWorkerTypeValue([]);
      selectedLoadDataObject?.partnerCompany !== ''
        ? setPartnerCompanyValue(selectedLoadDataObject?.partnerCompany)
        : setPartnerCompanyValue([]);
      selectedLoadDataObject?.dateRange !== ''
        ? setAdminDateRange(selectedLoadDataObject?.dateRange)
        : setAdminDateRange([]);
      selectedLoadDataObject?.mrc !== ''
        ? setMrcNameValueFormatted(data)
        : setMrcNameValueFormatted([]);
      selectedLoadDataObject?.mrc !== ''
        ? setMrcNameValue(selectedLoadDataObject?.mrc)
        : setMrcNameValue([]);
      selectedLoadDataObject?.userField !== ''
        ? setUserValue(selectedLoadDataObject?.userField)
        : setUserValue([]);
      selectedLoadDataObject?.managerField !== ''
        ? setManagerValue(selectedLoadDataObject?.managerField)
        : setManagerValue([]);
      selectedLoadDataObject?.legalEntityName !== ''
        ? setLegalEntity(selectedLoadDataObject?.legalEntityName)
        : setLegalEntity([]);
      selectedLoadDataObject?.country !== ''
        ? setCountry(selectedLoadDataObject?.country)
        : setCountry([]);
      selectedLoadDataObject?.workerType !== ''
        ? setWorkerType(selectedLoadDataObject?.workerType)
        : setWorkerType([]);
      selectedLoadDataObject?.partnerCompany !== ''
        ? setPartnerCompany(selectedLoadDataObject?.partnerCompany)
        : setPartnerCompany([]);
      selectedLoadDataObject?.dateRange !== ''
        ? setQuickFilterDateRange(selectedLoadDataObject?.dateRange)
        : setQuickFilterDateRange([]);
      selectedLoadDataObject?.mrc !== '' ? setmrcName(selectedLoadDataObject?.mrc) : setmrcName([]);
      selectedLoadDataObject?.userField !== ''
        ? setUser(selectedLoadDataObject?.userField)
        : setUser([]);
      selectedLoadDataObject?.managerField !== ''
        ? setManager(selectedLoadDataObject?.managerField)
        : setManager([]);
      selectedLoadDataObject?.courseCode !== ''
        ? setCourseSelectedOption(selectedLoadDataObject?.courseCode)
        : setCourseSelectedOption([]);
    } else {
      clearSetValues();
    }
  }, [selectedLoadData, selectFav]);

  useEffect(() => {
    const dateRangeParentFormatted = adminDateRange
      ? adminDateRange.map((date) => dayjs(date))
      : null;
    setSelectedDate(dateRangeParentFormatted);
  }, [adminDateRange]);

  //Deleting the favourite Filters (Response : 4).
  useEffect(() => {
    if (
      saveFilterDelete &&
      saveFilterDelete?.manageFilterDetails?.data === 'Deleted Successfully !'
    ) {
      const newFilter = favList.filter((item) => item.filterName !== deletefav);
      setFavList(newFilter);
      setDeleteFav('');
    }
  }, [saveFilterDelete]);

  //set selectfav value in first time to dirtycheckfor name in Edit scenario.
  useEffect(() => {
    if(selectFav !== ''){
      setDirtyCheckFavFilterName(selectFav);
    }
  }, [selectFav])

  const quickFilterApply = (
    searchType,
    mrcName,
    legalEntity,
    country,
    workerType,
    patnarCompany,
    userIDNameArray,
    managerIDNameArray,
    courseCodeSelectedArray
  ) => {
    dispatch(
      AdminQuickFilterAction({
        keys: '',
        searchType: searchType,
        country: searchType !== COUNTRY && country?.length ? country : '',
        mrcName: searchType !== MRC_NAME && mrcName?.length ? mrcName : '',
        legalEntity: searchType !== LEGAL_ENTITY && legalEntity?.length ? legalEntity : '',
        workerType:
          searchType !== WORKER_TYPE && workerType?.length > NUMBER_ARRAY.zero ? workerType : '',
        patnarCompany:
          searchType !== PARENT_COMPANY && patnarCompany?.length > NUMBER_ARRAY.zero
            ? patnarCompany
            : '',
        userIDNameArray:
          searchType !== WWID_USER_NAME && userIDNameArray?.length ? userIDNameArray : '',
        managerIDNameArray: managerIDNameArray?.length ? managerIDNameArray : '',
        dateRange: adminDateRange?.length ? adminDateRange : '',
        courseCode: searchType !== COURSE_CODE && courseCodeSelectedArray?.length ? courseCodeSelectedArray : ''
      }),
    );
  };
useEffect(() => {
    if (
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      quickFilterApply(
        WORKER_TYPE,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
      quickFilterApply(
        PARENT_COMPANY,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
      quickFilterApply(
        COUNTRY,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
      quickFilterApply(
        LEGAL_ENTITY,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
      quickFilterApply(
        MRC_NAME,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
      quickFilterApply(
        WWID_USER_NAME,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
      quickFilterApply(
        WWID_MANAGER_NAME,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
      quickFilterApply(
        COURSE_CODE,
        mrcNameValuesFormatted,
        legalEntityValues,
        countryValues,
        workerTypeValues,
        partnerCompanyValues,
        userValues,
        managerValues,
        courseSelectedOption
      );
    }
  }, [
    logInUserDetails,
    countryValues,
    legalEntityValues,
    workerTypeValues,
    partnerCompanyValues,
    mrcNameValuesFormatted,
    userValues,
    managerValues,
  ]);

  //Disable the Add to Favourite Button.
  const isAnyFilterParameterSelected = () => {
    return (
      userValues?.length !== 0 ||
      mrcNameValues?.length !== 0 ||
      managerValues?.length !== 0 ||
      legalEntityValues?.length !== 0 ||
      countryValues?.length !== 0 ||
      workerTypeValues?.length > NUMBER_ARRAY.zero ||
      partnerCompanyValues?.length !== 0 || courseSelectedOption?.length !== 0 ||
      (selectedDate !== null && selectedDate?.length !== 0)
    );
  };

  useEffect(() => {
    setDisableAddToFavBtn(!isAnyFilterParameterSelected());
  }, [
    logInUserDetails,
    countryValues,
    legalEntityValues,
    workerTypeValues,
    partnerCompanyValues,
    mrcNameValues,
    userValues,
    managerValues,
    selectedDate,
    courseSelectedOption
  ]);

  //Show Favourite name whenever text xhange.
  const AddFavoriteFilter = (value) => {
    const regex = /^[0-9a-zA-Z(\-\s)]+$/; //this will admit letters, numbers and dashes
    if (value.match(regex) || value === '') {
      setFavUserInputValidation(false);
      setInputFieldHeader(favFilterName);
    } else {
      setFavUserInputValidation(true);
      setInputFieldHeader(favUserInputErrorMsg);
    }
    setFavName(value);
  };
  const handleClose = () => {
    setFavUserInputValidation(false);
    setInputFieldHeader(favFilterName);
  };
  //Save Favourite filter name (Trigger : 1).
  const saveFavHandler = (value, isEditMode = false) => {
    const regex_hyphen = /^[(\-)]+$/; // save filter should not allow only hyphen

    if (saveFilterList?.manageFilterDetails?.data.length < 50) {
      if (value?.match(regex_hyphen)) {
        alert('Enter the  valid filter name');
      }
      // save filter should not allow empty string
      else if (value?.trim().length === 0) {
        alert('Enter the filter name');
      } else if (value !== favNamePayload || (isEditMode && value !== favNamePayload)) {
        setQuickFilterEditMode(isEditMode);
        setFavNamePayload(value);
      } else if (value === favNamePayload && !isEditMode) {
        alert('Filter already exists');
      }
    } else {
      alert('You have reached the maximum limit of saving 50 filters! ');
    }
  };

  const convertedDate = (dateToConvert) => {
    return new Date(dateToConvert).toLocaleDateString('en-US', { timeZone: 'UTC' }).toString();
  };
  /**Dirty check logic */
  const handleDirtyCheckFavFilterName = (newFavFilterName) => {
    setDirtyCheckFavFilterName(newFavFilterName);
  }

  const dirtyCheckDateRange = (editSelectedFilterObj) => {
    const dateFormatted = selectedDate?.map((date) => date.format('YYYY-MM-DD')) ?? [];
    const filterObjDate = Array.from(editSelectedFilterObj.dateRange).map((date) => {
      const filteredDate = convertedDate(date);
      return filteredDate;
    });
    return isFilterValuearraysEqual(dateFormatted, filterObjDate);
  };

  const isFilterValuearraysEqual = (selectedValuesArr, editedFilterRecordArr) => {
    let isDiffValueArr = [];
    if (selectedValuesArr.length === 0 && editedFilterRecordArr?.length === 0) return false;
    selectedValuesArr.map((value, index) => {
      if (typeof value === 'object') {
        isDiffValueArr.push(JSON.stringify(value) === JSON.stringify(editedFilterRecordArr[index]));
      } else if (typeof value === 'string') {
        isDiffValueArr.push(value === editedFilterRecordArr[index]);
      }
    });
    return isDiffValueArr.includes(false);
  };

  const dirtyCheckOnEditModeFilterChange = () => {
    const editSelectedFilterObj = JSON.parse(selectedLoadData);
    return (
      isFilterValuearraysEqual(countryValues, editSelectedFilterObj.country) ||
      isFilterValuearraysEqual(legalEntityValues, editSelectedFilterObj.legalEntityName) ||
      isFilterValuearraysEqual(managerValues, editSelectedFilterObj.managerField) ||
      isFilterValuearraysEqual(mrcNameValues, editSelectedFilterObj.mrc) ||
      isFilterValuearraysEqual(partnerCompanyValues, editSelectedFilterObj.partnerCompany) ||
      isFilterValuearraysEqual(userValues, editSelectedFilterObj.userField) ||
      isFilterValuearraysEqual(workerTypeValues, editSelectedFilterObj.workerType) || 
      isFilterValuearraysEqual(courseSelectedOption, editSelectedFilterObj.courseCode) ||
      dirtyCheckDateRange(editSelectedFilterObj) || (selectFav !== dirtyCheckFavFilterName)
    );
  }
  /**Dirty check logic */
 
  useEffect(() => {
    if (
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== '' &&
      favNamePayload !== ''
    ) {
      if (selectFav && selectFav != favNamePayload && !quickFilterEditMode) {
        setSelectFav(favNamePayload);
      }else if(quickFilterEditMode){
        setSelectFav('');
      }

      const payload = {
        userId: logInUserDetails?.user_id,
        filterName: quickFilterEditMode ? selectFav : favNamePayload,
        newFilterName: quickFilterEditMode && selectFav !== dirtyCheckFavFilterName ? favNamePayload : '',
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        workerType: workerTypeValues?.length > NUMBER_ARRAY.zero ? workerTypeValues : '',
        partnerCompany: partnerCompanyValues?.length ? partnerCompanyValues : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
        dateRange: adminDateRange?.length ? adminDateRange : '',
        courseCode: courseSelectedOption?.length ? courseSelectedOption : ''
      };
      
      dispatch(AdminFavouriteFilterAction(payload));
    }
  }, [favNamePayload, quickFilterEditMode]);

  //Payload to show the Favourite List (Trigger : 2).
  useEffect(() => {
    if (
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      const dataPayloadList = {
        apiType: 'LIST',
      };
      dispatch(AdminManageFilterListAction(dataPayloadList));
    }
  }, [logInUserDetails, deletefav, postFilterData, saveFilterDelete]);

  //Payload to Load selected fav item values (Trigger : 3)
  useEffect(() => {
    if (
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== '' &&
      selectFav !== ''
    ) {
      const dataPayloadLoad = {
        apiType: 'LOAD',
        filterName: selectFav,
      };
      dispatch(AdminManageFilterLoadAction(dataPayloadLoad));
      setQuickFilterEditMode(false);
    }
  }, [logInUserDetails, selectFav]);

  useEffect(() => {
    if (selectedLoadData) {
      setDirtyCheckEditMode(!dirtyCheckOnEditModeFilterChange());
    }
  }, [selectedLoadData,
    countryValues,
    mrcNameValues,
    legalEntityValues,
    partnerCompanyValues,
    userValues,
    managerValues,
    workerTypeValues,
    selectedDate, 
    dirtyCheckFavFilterName, courseSelectedOption])

  //Delete favourite name fron the List of favourites (Trigger : 4)
  const handleFavDelete = (value) => {
    if (selectFav === value) {
      setSelectFav('');
    }
    setDeleteFav(value);
  };

  //Payload to Delete the fav item.
  useEffect(() => {
    if (
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== '' &&
      deletefav !== ''
    ) {
      const dataPayloadDelete = {
        apiType: 'DELETE',
        filterName: deletefav,
      };
      dispatch(AdminManageFilterDeleteAction(dataPayloadDelete));
    }
  }, [logInUserDetails, deletefav]);

  let AdminCompletionStatusData = useSelector((state) => state.tapAdminViewCompleteStatusRd);
  let AdminTrainingComplianceData = useSelector((state) => state.adminComplianceProfileRd);

  const theme = SelectedTheme();
  let complementTheme = theme.palette.type === 'red' ? BlueTheme : RedTheme;

  const switchTheme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: RedTheme.palette.button.white,
          },
          colorPrimary: {
            '&.Mui-checked': {
              // Controls checked color for the thumb
              color: complementTheme.palette.primary.main,
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            backgroundColor: '#C1BBB3',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              backgroundColor: '#888B8D',
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    if (
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      let payload = {
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        workerType: workerTypeValues?.length > NUMBER_ARRAY.zero ? workerTypeValues : '',
        partnerCompany: partnerCompanyValues?.length ? partnerCompanyValues : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
        dateRange: adminDateRange?.length ? adminDateRange : '',
      };
      dispatch(AdminOverdueData({...payload, courseCode: courseSelectedOption?.length ? courseSelectedOption : ''}));
      dispatch(AdminComplianceData(payload));
      dispatch(AdminNonCompliantData(payload));
      dispatch(AdminAtRiskData(payload));
    }
  }, [
    logInUserDetails,
    country,
    legalEntity,
    partnerCompany,
    quickFilterDateRange,
    mrcName,
    user,
    manager,
  ]);

  useEffect(() => {
    setIsDisabled(true);

    if (
      trainingState.label !== undefined &&
      trainingState.label !== '' &&
      timeRangeState !== undefined &&
      timeRangeState !== '' &&
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      const payload = {
        status: 'Completed ' + trainingState.label,
        level: timeRangeState,
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        workerType: workerTypeValues?.length > NUMBER_ARRAY.zero ? workerTypeValues : '',
        partnerCompany:
          partnerCompanyValues?.length > NUMBER_ARRAY.zero ? partnerCompanyValues : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
        dateRange: adminDateRange?.length ? adminDateRange : '',
        courseCode: courseSelectedOption?.length ? courseSelectedOption : ''
      };
      dispatch(AdminViewCompletedStatusDetails(payload));
    }
  }, [
    logInUserDetails,
    trainingState.label,
    timeRangeState,
    country,
    legalEntity,
    workerType,
    partnerCompany,
    quickFilterDateRange,
    mrcName,
    user,
    manager,
  ]);

  const handleSelectedDaysChange = (event) => {
    const days = event.target.value;
    setSelectedDays(days);
  };

  useEffect(() => {
    if (
      selectedDays !== undefined &&
      logInUserDetails !== undefined &&
      logInUserDetails?.user_id !== undefined &&
      logInUserDetails?.user_id !== ''
    ) {
      const payloadUser = {
        days: selectedDays,
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        workerType: workerTypeValues?.length > NUMBER_ARRAY.zero ? workerTypeValues : '',
        partnerCompany:
          partnerCompanyValues?.length > NUMBER_ARRAY.zero ? partnerCompanyValues : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
        dateRange: adminDateRange?.length ? adminDateRange : '',
        courseCode: courseSelectedOption?.length ? courseSelectedOption : ''
      };
      dispatch(AdminUpcomingOverdueData(payloadUser));
    }
  }, [
    logInUserDetails,
    selectedDays,
    country,
    legalEntity,
    workerType,
    partnerCompany,
    quickFilterDateRange,
    mrcName,
    user,
    manager,
  ]);

  useEffect(() => {
    if (dashboardData !== undefined) {
      setTrainingOverdueData(dashboardData?.trainingOverdueData?.data);
      setTrainingUpcomingDueData(dashboardData?.trainingUpcomingdueData?.data);
    } else {
      setTrainingOverdueData('');
      setTrainingUpcomingDueData('');
    }
  }, [dashboardData]);

  useEffect(() => {
    if (quickFilterData?.searchType && quickFilterData?.data?.length >= NUMBER_ARRAY.zero) {
      if (quickFilterData?.searchType === 'parentCompany') {
        setPartnerCompanyOptions(quickFilterData?.data);
        // Need to remove once API integration happen for Course code
      } else if (quickFilterData?.searchType === 'WorkerType') {
        setWorkerTypeOptions(quickFilterData?.data);
      } else if (quickFilterData?.searchType === 'country') {
        setCountryOptions(quickFilterData?.data);
      } else if (quickFilterData?.searchType === 'legalEntity') {
        setLegalEntityOptions(quickFilterData?.data);
      } else if (quickFilterData?.searchType === 'MRCName') {
        setMrcNameOptions(quickFilterData?.data);
      } else if (quickFilterData?.searchType === 'wwidUsername') {
        setUserOptions(quickFilterData?.data);
      } else if (quickFilterData?.searchType === 'wwidManagername') {
        setManagerOptions(quickFilterData?.data);
      } else if (quickFilterData?.searchType === 'courseCode') {
        setCourseNameOptions(quickFilterData?.data);
      }
    }
  }, [quickFilterData]);

  useEffect(() => {
    if (complianceActionsData !== undefined) {
      setTotalNonCompliantData(
        complianceActionsData?.nonCompliantData?.data[0]?.totalNonCompliantCount,
      );
      setTotalAtRiskData(complianceActionsData?.atRiskData?.data[0]?.totalAtRiskCount);
    } else {
      setTotalNonCompliantData('');
      setTotalAtRiskData('');
    }
  }, [complianceActionsData]);

  useEffect(() => {
    setIsDisabled(false);
    setMyOrgCompletedData(
      AdminCompletionStatusData !== undefined
        ? AdminCompletionStatusData?.adminViewCompleteStatusAPIResponse?.data
        : 0,
    );
  }, [AdminCompletionStatusData]);

  const handleSwitch = () => {
    setMyOrgCompletedData(0);
    if (switchState === switchStateValues.onTime) {
      setTrainingState(switchStateValues.onTime);
      setSwitchState(switchStateValues.overall);
    } else {
      setTrainingState(switchStateValues.overall);
      setSwitchState(switchStateValues.onTime);
    }
  };

  /**
   * handleFilter.
   * This handler update the data as per child component click event
   * @param {string} button
   * @param {string} timeRange
   */
  const handleFilter = (buttom, timeRange) => {
    setActiveFilter(buttom);
    setTimeRangeState(timeRange);
  };
  useEffect(() => {
    // Prepare dynamic autocomplete configurations
    const configs = [
      {
        label: 'WWID / Team Member',
        options: [],
        getOptionLabel: (option) => option,
        minWidth: screen.isMobile ? '320px' : '210px',
        mr: 2,
      },
      {
        label: 'Training Name',
        options: [],
        getOptionLabel: (option) => option,
        minWidth: screen.isMobile ? '320px' : '330px',
        mr: 2,
      },
      // Add more configurations as needed
    ];
    setAutocompleteConfigs(configs);
  }, []);

  useEffect(() => {
    // Prepare dynamic autocomplete configurations
    const complianceConfigs = [
      {
        label: 'WWID / Team Member',
        options: [],
        getOptionLabel: (option) => option,
        minWidth: '210px',
        mr: 2,
      },

      // Add more configurations as needed
    ];
    setcomplianceautocompleteConfigs(complianceConfigs);
  }, []);

  useEffect(() => {
    // Prepare dynamic autocomplete configurations
    const configs = [
      {
        label: 'Worker Type',
        options: workerTypeOptions,
        getOptionLabel: (option) => option,
        minWidth: '100px',
        mr: 2,
      },
      {
        label: 'Partner Company',
        options: partnerCompanyValues,
        getOptionLabel: (option) => option,
        minWidth: '100px',
        mr: 2,
      },
      // Add more configurations as needed
    ];
    setDonutChartAutocompleteConfigs(configs);
  }, []);

  const handlePopupButtonClick = (value, compliant) => {
    setIsCompliantPopup(compliant);
    setPopupStatus('drill');
    setPopUserId(logInUserDetails?.user_id);
    setPopUpName(logInUserDetails?.full_name);
    setOpenPopup(true);
    // Set the desired header value
    setPopupHeader(value);
    setIsCheckboxSelection(true);

    setPopupButtons([
      {
        label: 'Send Reminder',
      },
      {
        label: 'Download Excel',
      },
      // Add more button objects as needed
    ]);
  };

  const apiResponseOfAdminLargeDataDownload = useSelector(
    (state) => state.adminLargeReportDownloadRd?.APIResponse?.data,
  );

  const triggerDownloadsCompletedEvent = () => {
    setShowLargeDataDownloadInfoMsg(false);
  };

  const onSuccessToasterClose = () => {
    setShowLargeDataDownloadSuccessMsg(false);
  };

  useEffect(() => {
    if (
      apiResponseOfAdminLargeDataDownload === 'Request raised successfully' &&
      showLargeDataDownloadInfoMsg === false &&
      isGenericPopupOpenForDonutChart === false &&
      openPopup === false
    ) {
      setShowLargeDataDownloadSuccessMsg(true);
      setTimeout(() => {
        dispatch(getLargeReportDownload(''));
      });
    }
  }, [apiResponseOfAdminLargeDataDownload, showLargeDataDownloadInfoMsg]);

  const columns = [
    {
      field: 'full_name',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Team Member
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.box.gray,
              fontWeight: fontWeightStyles.fw7.fontWeight,
            }}
          >
            WWID
          </Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 1,
      disableColumnMenu: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          overdue: ['complete overdue'].includes(params.value.toLowerCase()),
        });
      },
      renderCell: (params) => {
        return (
          <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
            <span> {params.value}</span>
            <Box sx={{ color: redtheme.palette.box.gray }}>
              <Box sx={{ display: 'inline-flex', gap: '10px' }}>
                {params?.row?.user_id}
                {params.row?.leave_of_absence === 'Y' && <LeaveIndicator />}
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'training_title',
      width: screen.resolveStyles({
        mobile: 220,
        tablet: 220,
        desktop: null,
      }),

      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Training Name</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        const training_title = (
          <Typography sx={{ fontFamily: '"JohnsonText-Regular"' }}>{params.value || ''}</Typography>
        );
        return (
          <ToolTip title={training_title} placement='top' header='Training Name' noOfLetters={50} />
        );
      },
    },
    {
      field: 'transcript_due_date',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Due Date</Typography>
          {!isGenericPopupOpenForDonutChart && (
            <Typography
              variant='subtitle2'
              sx={{
                color: redtheme.palette.box.gray,
                fontWeight: fontWeightStyles.fw7.fontWeight,
                fontFamily: '"JohnsonText-Bold"',
              }}
            >
              {popupHeader === 'Overdue Trainings' ? 'Days Overdue' : 'Coming Due'}
            </Typography>
          )}
        </Box>
      ),
      disableColumnMenu: true,
      flex: !screen.isDesktop ? 0 : 1,
      renderCell: (params) => {
        if (isGenericPopupOpenForDonutChart) {
          return (
            <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
              <span> {params?.value ? moment(params?.value)?.format('DD-MMM-YYYY') : ''}</span>
              <Box
                sx={{
                  color: params?.row?.transcript_status_with_days_count
                    ?.toLowerCase()
                    ?.includes('late')
                    ? redtheme.palette.box.red
                    : params?.row?.transcript_status_with_days_count
                        ?.toLowerCase()
                        ?.includes('before')
                    ? redtheme.palette.box.green
                    : redtheme.palette.box.gray,
                }}
              >
                <span>{params?.row?.transcript_status_with_days_count}</span>
              </Box>
            </Box>
          );
        } else {
          if (params.row?.days_overdue !== 0) {
            return (
              <Box
                display='flex'
                flexDirection='column'
                sx={{ fontFamily: '"JohnsonText-Regular"' }}
              >
                <span> {params?.value ? moment(params?.value)?.format('DD-MMM-YYYY') : ''}</span>
                <Box sx={{ color: redtheme.palette.box.red }}>
                  <span>{params?.row?.days_overdue} Days Overdue</span>
                </Box>
              </Box>
            );
          }
          if (params.row?.days_overdue === 0) {
            return (
              <Box
                display='flex'
                flexDirection='column'
                sx={{ fontFamily: '"JohnsonText-Regular"' }}
              >
                <span> {params?.value ? moment(params?.value)?.format('DD-MMM-YYYY') : ''}</span>
                <Box sx={{ color: redtheme.palette.box.gray }}>
                  <span>Coming Due in {Math.abs(params?.row?.days_upcomingdue)} Days</span>
                </Box>
              </Box>
            );
          }
        }
      },
    },
  ];

  const isPopupOpenedForCompliance = () =>
    popupHeader === 'Total Non-Compliant' ||
    popupHeader === 'Total At Risk' ||
    popupHeader === 'Overall Compliance Profile' ||
    popupHeader === 'Job Description' ||
    popupHeader === 'Summary of Experience' ||
    popupHeader === 'Individual Training Plan' ||
    popupHeader === 'Training Completed On Time' ||
    popupHeader === 'Training Completed Overall';

  const complianceColumns = [
    {
      field: 'full_name',
      width: screen.resolveStyles({
        mobile: 180,
        tablet: 180,
        desktop: null,
      }),
      renderHeader: () => (
        <Box>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Team Member
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.box.gray,
              fontWeight: fontWeightStyles.fw7.fontWeight,
            }}
          >
            WWID
          </Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 1,
      disableColumnMenu: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          overdue: ['complete overdue'].includes(params.value.toLowerCase()),
        });
      },
      renderCell: (params) => {
        return (
          <Box display='flex' flexDirection='column' sx={{ fontFamily: '"JohnsonText-Regular"' }}>
            <span> {params.value}</span>
            <Box sx={{ color: redtheme.palette.box.gray }}>
              <span>{params.row?.user_id}</span>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'complianceElement',
      width: screen.resolveStyles({
        mobile: 220,
        tablet: 220,
        desktop: null,
      }),

      renderHeader: () =>
        statusMapper[popupHeader].split('/')[2] !== 'overallBarData' ? (
          <Box>
            <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Compliance Element</Typography>
          </Box>
        ) : null,
      flex: !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        statusMapper[popupHeader].split('/')[2] !== 'overallBarData' ? (
          <Typography sx={{ fontFamily: '"JohnsonText-Regular"' }}>
            {params.row?.complianceElement || ''}
          </Typography>
        ) : null;
      },
    },
    {
      field: 'complianceStatus',
      width: screen.resolveStyles({
        mobile: 220,
        tablet: 220,
        desktop: null,
      }),

      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Compliance Status</Typography>
        </Box>
      ),
      flex: !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        const complianceStatus = (
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Regular"',
              color: isGenericPopupOpenForDonutChart
                ? getColorAsPerIndicator(
                    statusMapper[popupHeader].split('/')[2] === 'overallBarData'
                      ? params.row?.colorIndicator
                      : params.row?.inspectionReadiness_Indicator,
                  )
                : getComplianceStatusTextColor(params.row?.complianceStatus),
            }}
          >
            {statusTranform(params.row?.complianceStatus) || ''}
          </Typography>
        );
        return (
          <ToolTip
            title={complianceStatus}
            placement='top'
            header='Compliance Status'
            noOfLetters={50}
          />
        );
      },
    },
  ];

  const getTaglineForDonutChart = () => {
    if (activeFilter === myOrgPerform.one) {
      return '*Current year plus the past 3 years';
    } else if (activeFilter === myOrgPerform.two) {
      return '*Current quarter plus the past 4 quarters';
    } else {
      return '*Current month plus the past 11 months';
    }
  };

  /**
   * LEGENDS_TRAINING_COMPLETED_ON_TIME.
   * Tarining completed on Time Legend data & settings
   */
  const LEGENDS_TRAINING_COMPLETED_ON_TIME = {
    data: [{ title: 'Completed On Time', color: redtheme.palette.box.green }],
    settings: {
      spacing: spacing.sp6,
      gap: getDimensionWithUnit(gap.gap4, 'px'),
      paddingTop: getDimensionWithUnit(padding.pad3, 'px'),
      width: getDimensionWithUnit(width.wid150, 'px'),
      circleHeight: getDimensionWithUnit(height.h10, 'px'),
      circleWidth: getDimensionWithUnit(width.wid10, 'px'),
      textTransform: textTransform.cap,
      fontFamily: fontFamily.regular,
      fontWeight: fontWeightStyles.fw4.fontWeight,
      fontSize: fontsize.fs14,
      lineHeight: lineHeight.lH16,
    },
  };
  /**
   * FILTERS_TRAINING_COMPLETED_ON_TIME.
   * Tarining completed on Time filters data & settings
   */
  const FILTERS_TRAINING_COMPLETED_ON_TIME = {
    data: [
      { name: 'Year', id: myOrgPerform.one, testId: 'btn-year' },
      { name: 'Quarter', id: myOrgPerform.two, testId: 'btn-quarterly' },
      { name: 'Month', id: myOrgPerform.three, testId: 'btn-month' },
    ],
    settings: {
      background: redtheme.palette.stack.orange,
      textTransform: textTransform.cap,
      fontFamily: fontFamily.light,
      fontWeight: fontWeightStyles.fw7.fontWeight,
      fontSize: fontsize.fs10,
      lineHeight: lineHeight.lH16,
    },
  };
  /**
   * LEGENDS_OVERALL_COMPLIANCE_PROFILE.
   * Overall Compliance Profile Legend data & settings
   */
  const LEGENDS_OVERALL_COMPLIANCE_PROFILE = {
    data: [
      { title: 'Compliant', color: redtheme.palette.success.main },
      { title: 'At Risk', color: redtheme.palette.warning.main },
      { title: 'Non-Compliant', color: redtheme.palette.error.dark },
    ],
    settings: {
      spacing: spacing.sp2,
      gap: getDimensionWithUnit(gap.gap4, 'px'),
      paddingTop: getDimensionWithUnit(padding.pad3, 'px'),
      width: 'auto',
      circleHeight: getDimensionWithUnit(height.h8, 'px'),
      circleWidth: getDimensionWithUnit(width.wid8, 'px'),
      textTransform: textTransform.cap,
      fontFamily: fontFamily.regular,
      fontWeight: fontWeightStyles.fw4.fontWeight,
      fontSize: fontsize.fs14,
      lineHeight: lineHeight.lH16,
    },
  };

  const graphTileSubHeader = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', margin: '8px' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <ThemeProvider theme={switchTheme}>
                <IOSSwitch
                  id='statusSwitch'
                  onChange={handleSwitch}
                  disabled={isDisabled}
                  data-testid='switch-button'
                />
              </ThemeProvider>
            }
            label={
              <Box sx={{ display: 'flex', flexDirection: 'row', margin: '8px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: fontWeightStyles.fw4.fontWeight,
                      lineHeight: '16px',
                      fontFamily: '"JohnsonText-Regular"',
                    }}
                    data-testid='switch-label'
                  >
                    {`See Completed ${switchState.label}`}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </FormGroup>
        <Box
          sx={{
            display: 'inline',
            cursor: 'default',
            left: '244px',
            top: '35px',
          }}
        >
          <ToolTipWrapper
            title={
              switchState.label === 'Overall'
                ? TapGlobalMessages.myTeamsPerformance.trainingsCompletedOnTimeTooltip
                : TapGlobalMessages.myTeamsPerformance.trainingsCompletedOverAllTooltip
            }
            placement='right'
          >
            <HelpOutlineIcon
              fontSize='medium'
              sx={{
                color: `${RedTheme.palette.common.primaryMainBlue}`,
                marginTop: '5px',
              }}
            ></HelpOutlineIcon>
          </ToolTipWrapper>
        </Box>
      </Box>
    );
  };
  const SubHeader = () => {
    return <Typography> </Typography>;
  };

  return (
    <>
      <ShowLoader></ShowLoader>

      <Box
        className='my-organization-performance'
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          width: '100%',
          padding: screen.resolveStyles({
            mobile: '16px',
            tablet: '0 24px 24px 24px',
            desktop: '0 72px 0 72px',
          }),
        }}
        data-testid='my-org-performance-charts'
      >
        <Grid container>
          <Grid item xs={10.5} sx = {{ maxWidth :  screen.resolveStyles({
                mobile: '65.5%',
                tablet: '83.5% !important',
                desktop: ''
              })  }}>
            <Box sx={{ height: screen.isTablet ? '78px' : '128px', mb: '10px' }}>
              <Typography
                gutterBottom
                sx={{
                  fontSize: '39px',
                  lineHeight: '48px',
                  fontFamily: '"JohnsonDisplay-Bold"',
                  fontWeight: fontWeightStyles.fw7.fontWeight,
                  padding: 0,
                  margin: 0,
                }}
                data-testid='admin-heading'
              >
                Administrative View
              </Typography>
              <Typography
                gutterBottom
                variant='div'
                component='div'
                sx={{
                  fontSize: '14px',
                  lineHeight: '16px',
                  pb: '15px',
                  color: redtheme.palette.fontColor.gray,
                  fontWeight: fontWeightStyles.fw7.fontWeight,
                  fontFamily: '"JohnsonText-Regular"',
                }}
                data-testid='lastRefreshDate'
              >
                Last refresh {lastRefreshed()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1.5} sx = {{ marginTop : screen.isMobile ? '10%' : '' }} >
            <CustomSetAlertButton
              clickHandler={setIsSetAlertPopupOpen}
              isOpen={isSetAlertPopupOpen}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            height: screen.resolveStyles({
              mobile: 'fit-content',
              tablet: '405px',
              desktop: '300px',
            }),
            width: '100%',
            // margin: '0px 72px 12px 72px',
            marginBottom: '12px',
            background: RedTheme.palette.box.white,
            borderLeft: `3px solid ${RedTheme.palette.info.dark}`,
            borderRadius: '16px',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20);',
            display: 'flex',
            justifyContent: 'space-evenly',
            //transform: 'translateY(-40px)',
            paddingLeft: screen.resolveStyles({
              mobile: '10px',
              tablet: '14px',
              desktop: '30px',
            }),
            paddingRight:  screen.resolveStyles({
              mobile: '20px',
              tablet: '57px',
              desktop: '20px',
            }),
            
            // paddingRight: '15px'
          }}
        >
          <Spin spinning={showDownloadLoader}>
            <AdminQuickFilter
              favList={favList}
              selectFav={selectFav}
              dirtyCheckEditMode= {dirtyCheckEditMode}
              handleDirtyCheckFavFilterName= {handleDirtyCheckFavFilterName}
              quickFilterEditMode={quickFilterEditMode}
              disableAddToFavBtn={disableAddToFavBtn}
              favUserInputValidation={favUserInputValidation}
              AddFavoriteFilter={AddFavoriteFilter}
              saveFavHandler={saveFavHandler}
              handleClose={handleClose}
              handleFavDelete={handleFavDelete}
              favName={favName}
              getEqualityCheck={getEqualityCheck}
              handleAdminStatusChange={handleAdminStatusChange}
              legalEntityOptions={legalEntityOptions}
              legalEntityValues={legalEntityValues}
              countryOptions={countryOptions}
              countryValues={countryValues}
              workerTypeOptions={workerTypeOptions}
              workerTypeValues={workerTypeValues}
              partnerCompanyOptions={partnerCompanyOptions}
              partnerCompanyValues={partnerCompanyValues}
              resetSearch={resetSearch}
              applyFilterHandler={applyFilterHandler}
              mrcNameOptions={mrcNameOptions}
              mrcNameValues={mrcNameValues}
              userOptions={userOptions}
              userValues={userValues}
              managerOptions={managerOptions}
              managerValues={managerValues}
              exportData={exportData}
              selectedDate={selectedDate}
              exportTrainingHandler={exportTraining}
              inputFieldHeader={inputFieldHeader}
              handleHierarchySwitchSelect={handleHierarchTraceabilityChange}
              courseNameOptions={courseNameOptions}
              courseSelectedOption={courseSelectedOption}
            />
          </Spin>
        </Box>

        <Grid container spacing={6} sx={{mb: '40px'}} data-testid='adminView-training-completed'>
        <Grid item xs={screen.isMobile ? 12 : 6}>
            <Typography
              sx={{
                fontFamily: '"JohnsonDisplay-Bold"',
                fontSize: '20px',
                lineHeight: '24px',
                fotWeight: fontWeightStyles.fw7.fontWeight,
                mt: '20px',
                mb: '20px',
              }}
            >
              Training Actions
            </Typography>

            <ActionButtonWrapper
              processDataLeft={trainingOverdueData?.trainingOverDueCount}
              processDataRight={trainingUpcomingDueData?.trainingUpcomingDueCount}
              handlePopupButtonClick={handlePopupButtonClick}
              handleSelectedDaysChange={handleSelectedDaysChange}
              selectedDays={selectedDays}
              toolTipHeaderTextLeft={
                TapGlobalMessages.myTeamsPerformance.trainingsOverdueTooltipHeader
              }
              toolTipTitleTextLeft={
                TapGlobalMessages.myTeamsPerformance.trainingsOverdueTooltipBody
              }
              fontcolorLeft={redtheme.palette.button.white}
              toolTipHeaderTextRight={
                TapGlobalMessages.myTeamsPerformance.trainingsComingDueTooltipHeader
              }
              toolTipTitleTextRight={
                TapGlobalMessages.myTeamsPerformance.trainingsComingDueTooltipBody
              }
              fontcolorRight={redtheme.palette.text.secondary}
              actionTypeLeft='TRAININGS'
              tileTypeTextLeft='OVERDUE'
              actionTypeRight='TRAININGS'
              tileTypeTextRight='COMING DUE'
              isComplianceAction={false}
            />
          </Grid>
          <Grid item xs={screen.isMobile ? 12 : 6}>
            <Typography
              sx={{
                fontFamily: '"JohnsonDisplay-Bold"',
                fontSize: '20px',
                lineHeight: '24px',
                fotWeight: fontWeightStyles.fw7.fontWeight,
                mb: '20px',
                mt: '20px',
              }}
            >
              Compliance Actions
            </Typography>

            <ActionButtonWrapper
              processDataLeft={totalNonCompliantData}
              processDataRight={totalAtRiskData}
              handlePopupButtonClick={handlePopupButtonClick}
              handleSelectedDaysChange={handleSelectedDaysChange}
              selectedDays={selectedDays}
              toolTipHeaderTextLeft={''}
              toolTipTitleTextLeft={<NonCompliantTooltipBody />}
              fontcolorLeft={redtheme.palette.button.white}
              toolTipHeaderTextRight={''}
              toolTipTitleTextRight={<AtRiskTooltipBody />}
              fontcolorRight={redtheme.palette.text.secondary}
              actionTypeLeft='TOTAL'
              tileTypeTextLeft='NON-COMPLIANT'
              actionTypeRight='TOTAL'
              tileTypeTextRight='AT RISK'
              isComplianceAction={true}
            />
          </Grid>
          <Grid item xs={screen.isMobile ? 12 : 6}>
            <GraphTile
              tileHeaderText={`Trainings Completed ${trainingState.label}`}
              myOrgCompletedData={myOrgCompletedData}
              donutLabelText={`COMPLETED </br>  ${trainingState?.label?.toUpperCase()}`}
              timeRangeState={timeRangeState}
              donutId={'completionOnTimePieChart'}
              barId={'complianceBarChart'}
              subHeader={graphTileSubHeader()}
              legends={LEGENDS_TRAINING_COMPLETED_ON_TIME}
              filters={FILTERS_TRAINING_COMPLETED_ON_TIME}
              onChangeFilterOuter={handleFilter}
              tagline={getTaglineForDonutChart()}
              margin={'0px'}
              color={
                trainingState === switchStateValues.onTime
                  ? []
                  : [redtheme.palette.common.primaryMainBlue, redtheme.palette.info.light]
              }
              barChartColor={
                trainingState === switchStateValues.onTime
                  ? ''
                  : RedTheme.palette.common.primaryMainBlue
              }
              isMultiColorBarLine={false}
              isMultiColorDonut={false}
              isTaglineVisible={true}
              complianceChartData={AdminTrainingComplianceData}
              handleButtonClick={handleButtonClick}
              activeButton={activeButton}
              DisclaimerToShow={false}
              disclaimerText=''
              pageName={'Admin View'}
              onDonutChartClicked={onDonutChartClicked}
              showLoader={AVCompleteStatus}
            />
          </Grid>

          <Grid item xs={screen.isMobile ? 12 : 6} data-testid='adminView-overall-compliance'>
            <GraphTile
              tileHeaderText='Overall Compliance Profile'
              myOrgCompletedData={myOrgCompletedData}
              donutLabelText={'Overall Compliance </br> profile for my organisation'}
              timeRangeState={timeRangeState}
              donutId={'completionOnTimePieChart2'}
              barId={'complianceBarChart2'}
              legends={LEGENDS_OVERALL_COMPLIANCE_PROFILE}
              subHeader={SubHeader()}
              tagline={' '}
              margin={'30px'}
              color={['#000099', '#E6F3FA']}
              barChartColor={'#6EBD44'}
              isMultiColorBarLine={true}
              isTaglineVisible={false}
              isMultiColorDonut={true}
              complianceChartData={AdminTrainingComplianceData}
              DisclaimerToShow={false}
              disclaimerText={TapGlobalMessages.disclaimertext.admindisclaimertext}
              pageName={'Admin View'}
              onDonutChartClicked={onDonutChartClicked}
            />
          </Grid>

          
        </Grid>
      </Box>
      <GenericPopup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
          setPopUserId('');
          setIsGenericPopupOpenForDonutChart(false);
          setChartYear('');
          setChartQuerter('');
          setChartMonth('');
        }}
        header={popupHeader}
        columns={isPopupOpenedForCompliance() ? complianceColumns : columns}
        buttons={popupButtons}
        autocompletes={
          isPopupOpenedForCompliance() ? complianceautocompleteConfigs : autocompleteConfigs
        }
        setAutocompleteConfigs={
          isPopupOpenedForCompliance() ? setcomplianceautocompleteConfigs : setAutocompleteConfigs
        }
        isPopupForCompliance={isPopupOpenedForCompliance()}
        setDonutChartAutocompleteConfigs={setDonutChartAutocompleteConfigs}
        donutChartAutocomplete={donutChartAutocompleteConfigs}
        isGenericPopupOpenForDonutChart={isGenericPopupOpenForDonutChart}
        popupHeader={popupHeader}
        selectedDays={selectedDays}
        statusMapper={statusMapper}
        popUserId={popUserId}
        popupStatus={popupStatus}
        popUpName={popUpName}
        isCheckboxSelection={isCheckboxSelection}
        isAdmin={true}
        isCompliantPopup={isCompliantPopup}
        workerTypeValues={workerTypeValues}
        partnerCompanyValues={partnerCompanyValues}
        mrcNameValues={mrcName}
        dateRangeParent={quickFilterDateRange}
        legalEntityValues={legalEntity}
        countryValues={country}
        userValues={user}
        managerValues={manager}
        complianceElements={complianceElements}
        timeRangeState={timeRangeState}
        isLevelVisible={popupHeader === 'Trainings Completed On Time'}
        chartMonth={isPopupOpenedForCompliance() ? '' : chartMonth}
        chartQuerter={isPopupOpenedForCompliance() ? '' : chartQuerter}
        chartYear={isPopupOpenedForCompliance() ? '' : chartYear}
        chartStatus={
          isPopupOpenedForCompliance()
            ? ''
            : `Completed ${
                switchState.label === switchStateValues.overall.label
                  ? switchStateValues.onTime.label
                  : switchStateValues.overall.label
              }`
        }
        showWorkerTypeFilter={workerTypeOptions}
        showPartnerCompanyFilter={partnerCompanyOptions}
        newPopupHeader={`Trainings Completed ${trainingState.label}`}
        tagline={getTaglineForDonutChart()}
        hierarchyTraceabilityOn={isHierarchyTraceabilityOn}
        courseSelectedOption={courseSelectedOption}
      />
      <TrainingExportPopup
        popupHeader={openExportPopupContent}
        BtnText='OK'
        open={openExportPopup}
        handleClose={() => setOpenExportPopup(false)}
      />
      <ScheduleReport
        open={openExportTablePopup}
        handleClose={() => setOpenExportTablePopup(false)}
        getEqualityCheck={getEqualityCheck}
        handleAdminStatusChange={handleAdminStatusChange}
        legalEntityOptions={legalEntityOptions}
        legalEntityValues={legalEntityValues}
        countryOptions={countryOptions}
        countryValues={countryValues}
        partnerCompanyOptions={partnerCompanyOptions}
        partnerCompanyValues={partnerCompanyValues}
        resetSearch={resetSearch}
        mrcNameOptions={mrcNameOptions}
        mrcNameValues={mrcNameValues}
        userOptions={userOptions}
        userValues={userValues}
        managerOptions={managerOptions}
        managerValues={managerValues}
        selectedDate={selectedDate}
        isHierarchyOn={isHierarchyTraceabilityOnInScheduleReport}
        handleHierarchySwitchScheduleReportChange={handleHierarchySwitchScheduleReportChange}
      />
      <Toaster
        onClose={triggerDownloadsCompletedEvent}
        hideDuration={NUMBER_ARRAY.tenThousand}
        severity={'warn'}
        show={showLargeDataDownloadInfoMsg}
        message={largeDownloadStartMsg}
      />
      <Toaster
        onClose={onSuccessToasterClose}
        hideDuration={NUMBER_ARRAY.thirtyThousand}
        severity={'success'}
        show={showLargeDataDownloadSuccessMsg}
        message={largeDownloadSuccessMsg}
      />
      <CustomSetAlertPopup clickHandler={setIsSetAlertPopupOpen} open={isSetAlertPopupOpen} showManagerAutocomplete={true} isAdmin={true}/>
    </>
  );
};
