export const CommonActionsReducer = (state = { isAccessDenied: false }, action) => {
  if (action.type === 'ACCESS_DENIED') {
    return {
      ...state,
      isAccessDenied: true,
    };
  } else {
    return state;
  }
};
