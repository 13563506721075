import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Box,
  MenuItem,
  IconButton,
} from '@mui/material';
import { RedTheme } from 'common/global';
import { CustomSetAlertButton } from './CustomSetAlertButton';
import { useState, useEffect } from 'react';
import { GenericAutocomplete } from './genericAutocomplete';
import { AdminQuickFilterAction } from 'TAP/redux/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ReactComponent as PlusIcon } from '../assets/images/plusIcon.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/deleteIcon.svg';
import { INPUT_FIELD_VAL_REGEX, NUMBER_PATTERN_REGEX } from 'common/global/constants';
import { labels } from 'common/global/constants';
import { ReactComponent as DownWardArrowIcon } from '../assets/images/downArrow.svg';
import { NUMBER_ARRAY } from 'common/global/constants';
import { checkConflict, createConflictMessage, checkSingleThresholdConflict } from 'TAP/utils/commonMethods';

export const SetAlerts = ({
  handelAlertSubmit,
  emailValue,
  thresold,
  tresoldVal,
  isAlertEditState,
  alert,
  peopleLeader,
  showManagerAutocomplete,
  resetForm
}) => {
  const thresoldOptions = [
    '> (Greater than)',
    '< (Less than)',
    '>= (Greater than equal to)',
    '<= (Less than equal to)',
  ];

  let redtheme = RedTheme;
  const dispatch = useDispatch();
  const [selectedUserEmailValues, setselectedUserEmailValues] = useState([]);
  const [selectedManagerEmailValue, setselectedManagerEmailValue] = useState([]);
  const [isManagerSwitchOn, setIsManagerSwitchOn] = useState(true);
  const [isUserSwitchOn, setIsUserSwitchOn] = useState(true);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userEmailValidateErrMsg, setUserEmailValidateErrMsg] = useState('');
  const [managerEmailError, setManagerEmailError] = useState(false);
  const [managerEmailValidateErrMsg, setManagerEmailValidateErrMsg] = useState(''); 
  const [alertName, setAlertName] = useState(alert);
  const [selectedThresholds, setSelectedThresholds] = useState([]);
  const [thresholdValues, setThresholdValues] = useState([]);
  const [quickFilterType, setQuickFilterType] = useState('');
  const stateAdminQuickFilter = useSelector((state) => state.adminQuickFilterRd, shallowEqual);
  let quickFilter = useSelector((state) => state.adminQuickFilterRd);
  let quickFilterData = quickFilter?.quickFilterDetails;
  const [audienceEmails, setAudienceEmails] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [inputNameError, setInputNameError] = useState(false);
  const [inputNameValidationMsg, setInputtNameValidationMsg] = useState('');
  const [grids, setGrids] = useState([{ operator: '', value: '' }]);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const alerts = useSelector((state) => state?.alertRd);
  const errorAlert = alerts?.errorCreateAlert?.data;

  useEffect(() =>{
    if(errorAlert){
      setInputNameError(true);
      setInputtNameValidationMsg(errorAlert?.message);
    }
  },[errorAlert]);

  const textStyles = {
    fontSize: '14px',
    lineHeight: '12px',
    fontWeight: NUMBER_ARRAY.fiveHundred,
    fontFamily: 'Johnson Text',
    color: redtheme.palette.fontColor.very_dark_grey,
  };

  const asterikStyles = {
    fontSize: '14px',
    lineHeight: '12px',
    fontWeight: NUMBER_ARRAY.fiveHundred,
    fontFamily: 'Johnson Text',
    color: redtheme.palette.primary.main,
  };

  const selectLogicalOperatorDropDownStyles = {
    border: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
    borderRadius: '10px',
    height: '35px',
    minWidth: '120px',
    '& .MuiSelect-select': {
      display: 'flex',
      marginLeft: '4px',
    },
    '& .MuiSelect-icon': {
      marginRight: '10px',
      marginTop: '4px',
      color: 'redtheme.palette.fontColor.gray',
    },
    '&:before': {
      border: 'none',
    },
    '&:after': {
      border: 'none',
    },
    '&.Mui-focused:before': {
      display: 'none',
    },
    '&.Mui-focused:after': {
      display: 'none',
    },
    '&:hover:before': {
      display: 'none',
    },
    '&:hover:after': {
      display: 'none',
    },
  };

  const resetAlertForm = () => {
    setAlertName('');
    setSelectedThresholds([]);
    setThresholdValues([]);
    setselectedUserEmailValues([]);
    setselectedManagerEmailValue([]);
  };

  useEffect(() => {
    if (resetForm) {
      resetAlertForm();
      setInputNameError(false);
      setInputtNameValidationMsg('');
    }
  }, [resetForm]);

  const selectLogicalOperatorTextStyles = {
    fontFamily: 'Jhonson-text',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: NUMBER_ARRAY.fourHundred,
    textAlign: 'center',
    color: redtheme.palette.fontColor.gray,
    marginLeft: '4px',
  };

  useEffect(() => {
    const parsedThresholds = thresold ? thresold.split(',') : [];
    const parsedValues = tresoldVal ? tresoldVal.split(',') : [];
    const initialGrids = parsedThresholds.map((threshold, index) => ({
      operator: threshold.trim(),
      value: parsedValues[index]?.trim() || '',
    }));
    setAlertName(alert);
    setGrids(initialGrids.length ? initialGrids : [{ operator: '', value: '' }]);
    setSelectedThresholds(initialGrids.map((item) => item.operator));
    setThresholdValues(initialGrids.map((item) => item.value));
    const hasEmptyValues = parsedValues.some((value) => value.trim() === '');
    const enoughThresholds = parsedThresholds.length >= NUMBER_ARRAY.two;
    setIsAddDisabled(enoughThresholds && !hasEmptyValues);
  }, [thresold, tresoldVal, alert]);

  const [thresholdErrors, setThresholdErrors] = useState(Array(grids.length).fill(''));
  const [isThresholdFocused, setIsThresholdFocused] = useState(Array(grids.length).fill(false));
  const [isTypingThreshold, setIsTypingThreshold] = useState(Array(grids.length).fill(false));
  const [conflictErrors, setConflictErrors] = useState([]);

  const checkForConflicts = (thresholds, values, currentIndex) => {
     setConflictErrors([]);

    if (thresholds.length === NUMBER_ARRAY.one && values.length === NUMBER_ARRAY.one) {
      let errorMessage = checkSingleThresholdConflict(thresholds[NUMBER_ARRAY.zero], values[NUMBER_ARRAY.zero]);
      setConflictErrors((prev) => {
        const newErrors = [...prev];
        newErrors[NUMBER_ARRAY.zero] = errorMessage;
        return newErrors;
      });
      return;
    }

    const hasValidValues =
      thresholds.length > NUMBER_ARRAY.one && values.length > NUMBER_ARRAY.one && !values.every((value) => value.trim() === '');

    if (!hasValidValues) {
      return;
    }

    // Compare each pair of thresholds and values
    for (let i = NUMBER_ARRAY.zero; i < thresholds.length; i++) {
      for (let j = NUMBER_ARRAY.zero; j < thresholds.length; j++) {
        if (i !== j) {
          const operatorA = thresholds[i];
          const operatorB = thresholds[j];
          const valueA = Number(values[i]);
          const valueB = Number(values[j]);

          let isConflict = checkConflict(operatorA, operatorB, valueA, valueB);

          if (isConflict) {
            let conflictMessage = createConflictMessage(operatorA, operatorB, valueA);

            if (j === currentIndex) {
              setConflictErrors((prev) => {
                const newErrors = [...prev];
                newErrors[currentIndex] = conflictMessage;
                return newErrors;
              });
            }
          }
        }
      }
    }
  };

  const handleThresholdValueChange = (value, index) => {
    const newValues = [...thresholdValues];
    const numberValue = Number(value);

    newValues[index] = value;

    setIsTypingThreshold((prev) => {
      const newTypingState = [...prev];
      newTypingState[index] = value !== '';
      return newTypingState;
    });

    if (value !== '') {
      if (!NUMBER_PATTERN_REGEX.test(numberValue) || !Number.isInteger(numberValue)) {
        setThresholdErrors((prev) => {
          const newErrors = [...prev];
          newErrors[index] = Number.isInteger(numberValue)
            ? labels.thersholdValidationMessage
            : labels.thersholdWholeNumberValidationMessage;
          return newErrors;
        });
      } else {
        setThresholdErrors((prev) => {
          const newErrors = [...prev];
          newErrors[index] = '';
          return newErrors;
        });
      }
    } else {
      setThresholdErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index] = '';
        return newErrors;
      });
    }
    setThresholdValues(newValues);
    checkForConflicts(selectedThresholds, newValues, index);
  };

  const handleAddGrid = () => {
    setGrids([...grids, { operator: '', value: '' }]);
    setSelectedThresholds([...selectedThresholds, '']);
    setThresholdValues([...thresholdValues, '']);
    setIsAddDisabled(true);
  };

  const handleDeleteGrid = (index) => {
    if (grids.length > NUMBER_ARRAY.one) {
      const newGrids = grids.filter((_, idx) => idx !== index);
      setGrids(newGrids);
      const updatedSelectedThresholds = selectedThresholds.filter((_, idx) => idx !== index);
      const updatedThresholdValues = thresholdValues.filter((_, idx) => idx !== index);
      setSelectedThresholds(updatedSelectedThresholds);
      setThresholdValues(updatedThresholdValues);
      setConflictErrors(Array(newGrids.length).fill(''));
      setIsAddDisabled(newGrids.length >= grids.length);
    }
  };

  const handleThresholdChange = (value, index) => {
    const newThresholds = [...selectedThresholds];
    newThresholds[index] = value;
    setSelectedThresholds(newThresholds);
    checkForConflicts(newThresholds, thresholdValues, index);

    const newGrids = [...grids];
    newGrids[index].operator = value;
    setGrids(newGrids);
  };

  useEffect(() => {
    if (quickFilterData?.searchType && quickFilterData?.data?.length >= NUMBER_ARRAY.zero) {
      if (quickFilterData.searchType === 'userRole2And3') {
        setManagerOptions(quickFilterData.data);
      }
    }
  }, [quickFilterData]);

  const getAdminQuickFilterAPI = (searchType) => {
    dispatch(
      AdminQuickFilterAction({
        keys: '',
        searchType: searchType,
        country: '',
        mrcName: '',
        legalEntity: '',
        patnarCompany: '',
        userIDNameArray: '',
        managerIDNameArray: '',
        dateRange: '',
      }),
    ).then(() => {
      setQuickFilterType(searchType);
    });
  };

  const audienceEmailFullObj = (userEmail, emialObjList) => {
    const oneAudience = emialObjList?.find((audienceObj) => audienceObj.user_email === userEmail);
    return oneAudience;
  };

  const managerEmailFullObj = (userEmail, emialObjList) => {
    const oneAudience = emialObjList?.find(
      (managerObj) => managerObj.manager_user_email === userEmail,
    );
    return oneAudience;
  };

  const formatEmailObj = (multipleEmialIds, emialObjList, isManagerList = false) => {
    return multipleEmialIds.map((item) => {
      const audienceObj = isManagerList
        ? managerEmailFullObj(item, emialObjList)
        : audienceEmailFullObj(item, emialObjList);
      return isManagerList
        ? {
            manager_ful_lname: audienceObj?.manager_ful_lname,
            manager_user_email: audienceObj?.manager_user_email,
            manager_id: audienceObj?.manager_id,
          }
        : {
            full_name: audienceObj?.full_name,
            user_email: audienceObj?.user_email,
            user_id: audienceObj?.user_id,
          };
    });
  };

  useEffect(() => {
    if (emailValue?.length > NUMBER_ARRAY.zero && audienceEmails.length > NUMBER_ARRAY.zero) {
      const listofEmailObj = formatEmailObj(emailValue, audienceEmails);
      setselectedUserEmailValues(listofEmailObj);
    }
  }, [emailValue, audienceEmails]);

  useEffect(() => {
    if (
      peopleLeader?.length > NUMBER_ARRAY.zero &&
      showManagerAutocomplete &&
      managerOptions.length > NUMBER_ARRAY.zero
    ) {
      const listofEmailObj = formatEmailObj(peopleLeader, managerOptions, true);
      setselectedManagerEmailValue(listofEmailObj);
    }
  }, [showManagerAutocomplete, peopleLeader, managerOptions]);

  useEffect(() => {
    const data = stateAdminQuickFilter?.quickFilterDetails?.data;
    if (
      quickFilterType === 'useremail' &&
      data &&
      data.length > NUMBER_ARRAY.zero &&
      Object.prototype.hasOwnProperty.call(data[NUMBER_ARRAY.zero], 'user_email')
    ) {
      setAudienceEmails(data);
    }
  }, [quickFilterType, stateAdminQuickFilter]);

  useEffect(() => {
    if (audienceEmails.length === NUMBER_ARRAY.zero) {
      getAdminQuickFilterAPI('useremail');
    } else {
      getAdminQuickFilterAPI('userRole2And3');
    }
  }, [audienceEmails]);

  const handleFocus = (index) => {
    const newFocusState = [...isThresholdFocused];
    newFocusState[index] = true;
    setIsThresholdFocused(newFocusState);
  };

  const handleBlur = (index) => {
    const newFocusState = [...isThresholdFocused];
    newFocusState[index] = false;
    setIsThresholdFocused(newFocusState);
  };

  const getLabeledOptions = (option, type) => {
    if (type === 'user') {
      return `(${option.user_id}) ${option.full_name}`;
    } else if (type === 'manager') {
      return `(${option.manager_id}) ${option.manager_ful_lname}`;
    } else if (type === 'mrc') {
      return option.mrc !== undefined ? option.mrc : option;
    } else if (type === 'user_mail') {
      return `(${option.user_id}) ${option.user_email}`;
    }
  };

  const MAX_ALERT_NAME_LENGTH = NUMBER_ARRAY.twoFifty;

  const handleNameChange = (event) => {
    const value = event.target.value;
    if (!INPUT_FIELD_VAL_REGEX.test(value) || value.length > MAX_ALERT_NAME_LENGTH) {
      setInputNameError(true);
      setInputtNameValidationMsg(labels.validationErrorMessage);
    } else {
      setInputNameError(false);
      setInputtNameValidationMsg('');
    }
  };

  const handleEmailValidation = (event) => {
    const emailCount = event.length > NUMBER_ARRAY.fortyFive;
    setUserEmailError(emailCount);
    if (emailCount) {
      setUserEmailValidateErrMsg(labels.emailValidationMessage);
    } else {
      setUserEmailValidateErrMsg('');
    }
  };

  const handleManagerEmailVaidation = (event) => {
    const emailCount = event.length > NUMBER_ARRAY.fortyFive;
    setManagerEmailError(emailCount);
    if (emailCount) {
      setManagerEmailValidateErrMsg(labels.emailValidationMessage);
    } else {
      setManagerEmailValidateErrMsg('');
    }
  };

  const getAudienceEmailEqualityCheck = (option, value, type) => {
    return (
      type === 'user_mail' &&
      (value === undefined || value === null || option?.user_id === value?.user_id)
    );
  };

  const getManagerEmailEqualityCheck = (option, value, type) => {
    return (
        type === 'manager' &&
        (value === undefined || value === null || option?.manager_id === value?.manager_id)
    );
  };

  const isButtonDisabled =
    selectedThresholds.every((threshold) => threshold.trim() === '') ||
    thresholdValues.every((value) => value.trim() === '') ||
    alertName.trim() === '' ||
    inputNameError ||
    userEmailError ||
    thresholdValues.some((value) => Number(value) > NUMBER_ARRAY.hundred) ||
    !thresholdValues.every((value) => Number.isInteger(Number(value))) ||
    thresholdValues.some((value) => value.includes('.')) ||
    (conflictErrors?.length > NUMBER_ARRAY.zero && conflictErrors[NUMBER_ARRAY.zero]?.length > NUMBER_ARRAY.zero) ||
    (showManagerAutocomplete ? managerEmailError : false) ||
    (showManagerAutocomplete ? selectedManagerEmailValue.length === NUMBER_ARRAY.zero : false);

  const handleSelectAudienceChange = (event, type) => {
    if (type === 'user_mail') {
      setselectedUserEmailValues(event);
    }
  };

  const handleSelectManagerChange = (event, type) => {
    if (type === 'manager') {
      setselectedManagerEmailValue(event);
    }
  };

  return (
    <Grid container>
      <Grid container xs={NUMBER_ARRAY.eleven} columnSpacing={NUMBER_ARRAY.twelve}>
        {showManagerAutocomplete && (
          <Grid item xs={NUMBER_ARRAY.six}>
            <GenericAutocomplete
              heading={
                <span style={textStyles}>
                  Select manager/People Leader
                  <span style={asterikStyles}>*</span>
                </span>
              }
              options={managerOptions}
              values={selectedManagerEmailValue}
              switchChangeHandler={() => setIsManagerSwitchOn(!isManagerSwitchOn)}
              checked={!isManagerSwitchOn}
              getLabeledOptions={getLabeledOptions}
              handleAdminStatusChange={(event) => handleSelectManagerChange(event, 'manager')}
              handleValidation={handleManagerEmailVaidation}
              validationError={managerEmailError}
              errorMessage={managerEmailValidateErrMsg}
              getEqualityCheck={getManagerEmailEqualityCheck}
              type={'manager'}
              placeholder={
                isManagerSwitchOn
                  ? selectedManagerEmailValue?.length
                    ? `${selectedManagerEmailValue.length} Search`
                    : `Search`
                  : `${selectedManagerEmailValue.length} Selected`
              }
              switchIndicator={isManagerSwitchOn}
              isOpenForSetAlert={true}
            />
          </Grid>
        )}
        <Grid item xs={NUMBER_ARRAY.six}>
          <TextField
            InputProps={{
              shrink: true,
              startAdornment: <InputAdornment position='start'></InputAdornment>,
            }}
            value={alertName}
            onChange={(event) => {
              const value = event.target.value;
              setAlertName(value);
              handleNameChange(event);
            }}
            onBlur={() => {
              if (
                alertName.trim() === '' ||
                !INPUT_FIELD_VAL_REGEX.test(alertName) ||
                alertName.length > MAX_ALERT_NAME_LENGTH
              ) {
                setInputNameError(true);
                setInputtNameValidationMsg(labels.validationErrorMessage);
              } else {
                setInputNameError(false);
                setInputtNameValidationMsg('');
              }
            }}
            error={inputNameError}
            id='standard-error-helper-text'
            label={
              <Grid container>
                <Grid item xs={NUMBER_ARRAY.eleven}>
                  <Typography sx={textStyles}>{'Alert Name'}</Typography>
                </Grid>
                <Grid item xs={NUMBER_ARRAY.one}>
                  <Typography sx={asterikStyles}>{'*'}</Typography>
                </Grid>
              </Grid>
            }
            variant='standard'
            margin='normal'
            placeholder='Enter name'
            sx={{
              borderBottom: '1px solid black',
              width: '100%',
              '&.Mui-error': {
                borderBottom: '1px solid red',
              },
            }}
          />
          {inputNameError && (
            <Typography
              sx={{
                fontSize: '12px',
                color: '#EB1700',
              }}
            >
              {inputNameValidationMsg}
            </Typography>
          )}
        </Grid>
        <Grid item xs={NUMBER_ARRAY.six}>
          <GenericAutocomplete
            heading={<span style={textStyles}>Select CC Recipient</span>}
            options={audienceEmails}
            values={selectedUserEmailValues}
            switchChangeHandler={() => setIsUserSwitchOn(!isUserSwitchOn)}
            checked={!isUserSwitchOn}
            getLabeledOptions={getLabeledOptions}
            handleAdminStatusChange={(event) => handleSelectAudienceChange(event, 'user_mail')}
            handleValidation={handleEmailValidation}
            validationError={userEmailError}
            errorMessage={userEmailValidateErrMsg}
            getEqualityCheck={getAudienceEmailEqualityCheck}
            type={'user_mail'}
            placeholder={
              isUserSwitchOn
                ? selectedUserEmailValues.length
                  ? `${selectedUserEmailValues.length} Search`
                  : `Search`
                : `${selectedUserEmailValues.length} Selected`
            }
            switchIndicator={isUserSwitchOn}
            isOpenForSetAlert={true}
          />
        </Grid>

        <Grid container item xs={NUMBER_ARRAY.six}>
          {grids.map((_, index) => (
            <Grid container key={index} spacing={NUMBER_ARRAY.two} alignItems='center'>
              <Grid item xs={NUMBER_ARRAY.five} sm={NUMBER_ARRAY.six} marginTop={'15px'}>
                <FormControl
                  fullWidth
                  variant='standard'
                  sx={{ fontFamily: '"JohnsonText-Medium"' }}
                >
                  <InputLabel
                    variant='standard'
                    sx={{
                      width: '728px',
                      height: '25px',
                      paddingTop: '5px',
                    }}
                    shrink={true}
                  >
                    <Box display={'flex'}>
                      <Typography sx={textStyles}>{'Select Logical Operator'}</Typography>
                      <Typography sx={asterikStyles}>{'*'}</Typography>
                    </Box>
                  </InputLabel>
                  <Select
                    value={selectedThresholds[index] || ''}
                    id={`select-${index}`}
                    onChange={(e) => handleThresholdChange(e.target.value, index)}
                    renderValue={(selected) => {
                      return selected.length === NUMBER_ARRAY.zero ? (
                        <Typography sx={selectLogicalOperatorTextStyles}>
                          Select Logical Operator
                        </Typography>
                      ) : (
                        selected
                      );
                    }}
                    IconComponent={DownWardArrowIcon}
                    sx={selectLogicalOperatorDropDownStyles}
                    displayEmpty={true}
                  >
                    {thresoldOptions.map((obj, index) => (
                      <MenuItem key={index} value={obj}>
                        {obj}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={NUMBER_ARRAY.five} sm={NUMBER_ARRAY.six}>
                <TextField
                  id={`text-${index}`}
                  value={thresholdValues[index] || ''}
                  onChange={(e) => handleThresholdValueChange(e.target.value, index)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'></InputAdornment>,
                  }}
                  onFocus={() => handleFocus(index)}
                  onBlur={() => handleBlur(index)}
                  label={
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography sx={textStyles}>{'Set Threshold'}</Typography>
                      <Typography sx={asterikStyles}>{'*'}</Typography>

                      <Box display={'flex'} ml={NUMBER_ARRAY.fifteen}>
                        {index > 0 && (
                          <IconButton
                            onClick={() => handleDeleteGrid(index)}
                            sx={{ fontSize: 'small' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {!isAddDisabled && (
                          <IconButton onClick={handleAddGrid} sx={{ fontSize: 'small' }}>
                            <PlusIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  }
                  variant='standard'
                  margin='normal'
                  placeholder='00'
                  error={Boolean(thresholdErrors[index])}
                  sx={{ ml: NUMBER_ARRAY.two }}
                />
                {thresholdErrors[index] && (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#EB1700',
                      marginLeft: '16px',
                    }}
                  >
                    {thresholdErrors[index]}
                  </Typography>
                )}
                {!isTypingThreshold[index] && isThresholdFocused[index] && (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#EB1700',
                      marginLeft: '16px',
                    }}
                  >
                    {labels.setThersholdMessage}
                  </Typography>
                )}
                {conflictErrors[index] && (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#EB1700',
                      marginLeft: '16px',
                    }}
                  >
                    {conflictErrors[index]} {/* Display the actual error message */}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          top: '80%',
          left: '82%',
        }}
        data-testid='submit-alert-button'
      >
        <CustomSetAlertButton
          clickHandler={() => {
            handelAlertSubmit(
              alertName,
              selectedThresholds,
              thresholdValues,
              selectedUserEmailValues,
              selectedManagerEmailValue,
              isAlertEditState,
            );
          }}
          disabled={isButtonDisabled}
        />
      </Box>
    </Grid>
  );
};
