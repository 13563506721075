import { NUMBER_ARRAY } from 'common/global/constants';
import {
  GET_ALERTS_MY_TEAMS_PERFORMANCE,
  POST_ALERTS_MY_TEAMS_PERFORMANCE,
  GET_EDIT_ALERTS,
  DELETE_ALERTS,
  POST_MUTE_ALERTS,
  POST_ALERTS_ADMIN_VIEW,
  GET_ALERTS_ADMIN_VIEW,
  DELETE_ADMIN_ALERTS,
  GET_EDIT_ADMIN_ALERTS,
  POST_ADMIN_MUTE_ALERTS,
} from './apiPathConstants';
import apiAction from 'common/redux/actions/apiAction';
import { logError } from 'TAP/utils/commonMethods';

const getAlertsMyTeamPerformance = (data) => {
  return {
    type: 'GET_ALERTS',
    payload: data,
  };
};

const getAlertsAdminView = (data) => {
  return {
    type: 'GET_ADMIN_ALERTS',
    payload: data,
  };
};

const getEditedAlerts = (data) => {
  return {
    type: 'GET_EDITED_ALERTS',
    payload: data,
  };
};

const createAlerts = (data) => {
  return {
    type: 'CREATE_ALERT',
    payload: data,
  };
};

const createAdminAlerts = (data) => {
  return {
    type: 'CREATE_ADMIN_ALERT',
    payload: data,
  };
};

const getEditedAdminAlerts = (data) => {
  return {
    type: 'GET_EDITED_ADMIN_ALERTS',
    payload: data,
  };
};

const muteAlertAction = (data) => {
  return {
    type: 'MUTE_ALERT',
    payload: data,
  };
};

const muteAdminAlerts = (data) => {
  return {
    type: 'MUTE_ADMIN_ALERT',
    payload: data,
  };
};

const getDeleteAlerts = (data) => {
  return {
    type: 'DELETE_ALERTS',
    payload: data,
  };
};

const getAdminDeletedAlerts = (data) => {
  return {
    type: 'DELETE_ADMIN_ALERTS',
    payload: data,
  };
};

const handleCreateAlertsError = (data) => {
  if (data?.status === NUMBER_ARRAY.fourHundredNine) {
    return {
      type: 'ERROR_CREATE_ALERT',
      payload: data,
    };
  } else {
    logError('Error Create Alert.:');
  }
};

export const MyTeamsPerformaceGetAlerts = () => {
  return apiAction.getAPI({
    url: GET_ALERTS_MY_TEAMS_PERFORMANCE(),
    onSuccess: getAlertsMyTeamPerformance,
    onFailure: (err) => logError('Error Showing Alert Data.:', err),
    label: 'GET_ALL_ALERTS',
  });
};

export const AdminViewGetAllerts = () => {
  return apiAction.getAPI({
    url: GET_ALERTS_ADMIN_VIEW(),
    onSuccess: getAlertsAdminView,
    onFailure: (err) => logError('Error Creating Alert.:', err),
    label: 'GET_ALL_ADMIN_ALERTS',
  });
};

export const MyTeamsPerformanceSetAlerts = (payload, userId = '') => {
  return apiAction.postAPI({
    url: POST_ALERTS_MY_TEAMS_PERFORMANCE(userId),
    data: payload,
    onSuccess: createAlerts,
    onFailure: err => handleCreateAlertsError(err),
    label: 'CREATE_ALERT',
  });
};

export const AdminViewSetAlerts = (payload, userId = '') => {
  return apiAction.postAPI({
    url: POST_ALERTS_ADMIN_VIEW(userId),
    data: payload,
    onSuccess: createAdminAlerts,
    onFailure: err => handleCreateAlertsError(err),
    label: 'CREATE_ADMIN_ALERT',
  });
};

export const EditAlerts = (params) => {
  return apiAction.getAPI({
    url: GET_EDIT_ALERTS(params),
    onSuccess: getEditedAlerts,
    onFailure: (err) => logError('Error Editing Alert.:', err),
    label: 'EDIT_ALERT',
  });
};

export const EditAdminAlerts = (params) => {
  return apiAction.getAPI({
    url: GET_EDIT_ADMIN_ALERTS(params),
    onSuccess: getEditedAdminAlerts,
    onFailure: (err) => logError('Error Editing Alert.:', err),
    label: 'EDIT_ADMIN_ALERT',
  });
};

export const DeleteAlerts = (params) => {
  return apiAction.deleteAPI({
    url: DELETE_ALERTS(params),
    onSuccess: getDeleteAlerts,
    onFailure: (err) => logError('Error Editing Alert.:', err),
    label: 'DELETE_ALERT',
  });
};

export const DeleteAdminAlerts = (params) => {
  return apiAction.deleteAPI({
    url: DELETE_ADMIN_ALERTS(params),
    onSuccess: getAdminDeletedAlerts,
    onFailure: (err) => logError('Error Editing Alert.:', err),
    label: 'DELETE_ADMIN_ALERT',
  });
};

export const MyTeamsPerformaceMuteAlerts = (payload, userId = '') => {
  return apiAction.putAPI({
    url: POST_MUTE_ALERTS(userId),
    data: payload,
    onSuccess: muteAlertAction,
    onFailure: (err) => logError('Error Muting Alert:', err),
    label: 'MUTE_ALERTS',
  });
};

export const AdminMuteAlerts = (payload, userId = '') => {
  return apiAction.putAPI({
    url: POST_ADMIN_MUTE_ALERTS(userId),
    data: payload,
    onSuccess: muteAdminAlerts,
    onFailure: (err) => logError('Error Muting Alert:', err),
    label: 'MUTE_ADMIN_ALERTS',
  });
};
