import apiAction from 'common/redux/actions/apiAction';
import {
  POST_TRAININGVIEW_TRAININGS_POPUP_URL,
  POST_TRAININGS_POPUP_LARGE_DOWNLOAD_URL,
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * Get Training View Plan - Trainigs Popup Data
 ****/
export const GetTrainingsPopupData = (configParams) => {
  return apiAction.postAPI({
    url: POST_TRAININGVIEW_TRAININGS_POPUP_URL(),
    data: configParams,
    onSuccess: fetchTrainingsPopupData,
    onFailure: (err) => logError('Error occured in fetching GetTrainingsPopupData data:', err),
    label: 'GET_TRAININGS_POPUP_DATA',
  });
};

const fetchTrainingsPopupData = (data) => {
  return {
    type: 'FETCH_TRAININGS_POPUP_DATA',
    payload: data,
  };
};

/****
 * Get Trainings Suggestions in Trainings Popup Search
 ****/
export const GetTrainingsSuggestionsInPopup = (configParams) => {
  return apiAction.postAPI({
    url: POST_TRAININGVIEW_TRAININGS_POPUP_URL(),
    data: configParams,
    onSuccess: getTrainingsSuggestionsData,
    onFailure: (err) =>
      logError('Error occured in fetching GetTrainingsSuggestionsInPopup data:', err),
    label: 'GET_TRAININGS_SUGGESTIONS_IN_POPUP',
  });
};

const getTrainingsSuggestionsData = (data) => {
  return {
    type: 'GET_TRAININGS_SUGGESTIONS_DATA',
    payload: data,
  };
};

export const getTPVPopupLargeReportDownload = (data) => {
  return {
    type: 'POST_TRAINING_PLAN_POPUP_LARGE_REPORT_DOWNLOAD',
    payload: data,
  };
};

export const TrainingsLargeReportDownloadAction = (configParams) => {
  return apiAction.postAPI({
    url: POST_TRAININGS_POPUP_LARGE_DOWNLOAD_URL(configParams.teamType),
    data: configParams,
    onSuccess: getTPVPopupLargeReportDownload,
    onFailure: (err) => logError('Error occurred in fetching Large Report Data for download.', err),
    label: 'FETCH_TRAININGS_POPUP_LARGE_REPORT_DOWNLOAD',
  });
};
