import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NUMBER_ARRAY } from 'common/global/constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: NUMBER_ARRAY.fourHundred,
    bgcolor: 'background.paper',
    boxShadow: NUMBER_ARRAY.twentyFour,
    p: NUMBER_ARRAY.four,
    borderRadius: NUMBER_ARRAY.two,
};

export const ConfirmationPopup = ({ 
    open = false, 
    onClose, 
    onConfirm, 
    title = "", 
    message = "",
    rightButtonText = "",
    leftButtonText = ""
}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" component="h2" fontFamily={'"JohnsonDisplay-Bold"'}>
                        {title}
                    </Typography>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography sx={{ mt:  NUMBER_ARRAY.two }} fontFamily={'"JohnsonText-Regular"'}> 
                    {message}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: NUMBER_ARRAY.three }} fontFamily={'"JohnsonText-Regular"'}>
                    <Button onClick={onClose} color="secondary" sx={{ mr:  NUMBER_ARRAY.two }}>
                        {leftButtonText}
                    </Button>
                    <Button onClick={onConfirm} variant="contained" color="primary">
                        {rightButtonText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};