import apiAction from 'common/redux/actions/apiAction';
import { GET_ADMIN_LIST_TO_SHARE_FAVOURITE_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getAdminList = (data) => {
  return {
    type: 'GET_ADMIN_LIST',
    payload: data,
  };
};

export const AdminListToShareFavWithAction = configParams => {
  return apiAction.getAPI({
    url: GET_ADMIN_LIST_TO_SHARE_FAVOURITE_FILTER(),
    data: configParams,
    onSuccess:  getAdminList,
    onFailure: err => logError('Error occured in fetching Admin List:', err),
    label: 'GET_ADMIN_LIST_STATUS',
  });
};
