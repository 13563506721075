import {
  Modal,
  Box,
  Typography,
  IconButton,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NUMBER_ARRAY } from 'common/global/constants';

import { RedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights';

export const ComplianceOverviewPopup = ({ open, handleClose, complianceData }) => {
  let redtheme = RedTheme;
  const screen = useScreen();

  const donutChartData = complianceData?.orgComplianceDetails?.data?.donutChartData?.[NUMBER_ARRAY.zero];
  const barChartData = complianceData?.orgComplianceDetails?.data?.barChartData;


  const getColorBasedOnPercentage = (percentage) => {
    if (percentage >= NUMBER_ARRAY.ninetyFive) {
      return redtheme.palette.success.main;
    } else if (percentage >= NUMBER_ARRAY.eightyFive) {
      return redtheme.palette.warning.main;
    } else {
      return redtheme.palette.error.dark;
    }
  };

  return (
    <Modal open={open} sx={{ outline: 'none', boxShadow: 'none' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: redtheme.palette.box.white,
          padding: screen.isMobile ? '16px' : '40px 30px',
          gap: '48px',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          width: screen.resolveStyles({
            mobile: '360px',
            tablet: '818px',
            desktop: '918px',
          }),
          borderRadius: '16px',
        }}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={NUMBER_ARRAY.two}>
          <Typography
            variant='h2'
            component='div'
            sx={{ weight: fontWeightStyles.fw9.fontWeight, fontFamily: '"JohnsonDisplay-Bold"' }}
          >
            Compliance Overview
          </Typography>
          <IconButton
            data-testid='btn-close'
            className='btn-close-popup'
            onClick={handleClose}
            size='large'
          >
            <CloseIcon fontSize='large' />
          </IconButton>
        </Stack>
        <Typography
          id='modal-modal-description'
          sx={{
            mt: NUMBER_ARRAY.two,
            weight: fontWeightStyles.fw9.fontWeight,
            fontFamily: '"JohnsonDisplay-Bold"',
          }}
        >
          {' '}
          OVERALL COMPLIANCE PROFILE FOR MY TEAM:{' '}
          <Typography
            component='span'
            sx={{
              color: getColorBasedOnPercentage(donutChartData?.compliantPercent),
              fontSize: '26px',
            }}
          >
            {`${donutChartData?.compliantPercent}%`}
          </Typography>
        </Typography>

        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  align='left'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '16px',
                  }}
                ></TableCell>
                <TableCell
                  align='left'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '16px',
                  }}
                >
                  JD
                </TableCell>
                <TableCell
                  align='left'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '16px',
                  }}
                >
                  SOE
                </TableCell>
                <TableCell
                  align='left'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '16px',
                  }}
                >
                  ITP
                </TableCell>
                <TableCell
                  align='left'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '16px',
                  }}
                >
                  TCoT%
                </TableCell>
                <TableCell
                  align='left'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '16px',
                  }}
                >
                  C%
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: '#f5f5f5',
                  borderTop: '2px solid #ddd',
                  borderBottom: '2px solid #ddd',
                }}
              >
                <TableCell
                  align='left'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                    fontSize: '20px',
                  }}
                >
                  Compliance %
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ color: getColorBasedOnPercentage(barChartData?.[NUMBER_ARRAY.zero]?.[NUMBER_ARRAY.zero]?.compliantPercent) }}
                >
                  {`${barChartData?.[NUMBER_ARRAY.zero]?.[NUMBER_ARRAY.zero]?.compliantPercent}%`}
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ color: getColorBasedOnPercentage(barChartData?.[NUMBER_ARRAY.one]?.[NUMBER_ARRAY.zero]?.compliantPercent) }}
                >
                  {`${barChartData?.[NUMBER_ARRAY.one]?.[NUMBER_ARRAY.zero]?.compliantPercent}%`}
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ color: getColorBasedOnPercentage(barChartData?.[NUMBER_ARRAY.two]?.[NUMBER_ARRAY.zero]?.compliantPercent) }}
                >
                  {`${barChartData?.[NUMBER_ARRAY.two]?.[NUMBER_ARRAY.zero]?.compliantPercent}%`}
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ color: getColorBasedOnPercentage(barChartData?.[NUMBER_ARRAY.three]?.[NUMBER_ARRAY.zero]?.compliantPercent) }}
                >
                  {`${barChartData?.[NUMBER_ARRAY.three]?.[NUMBER_ARRAY.zero]?.compliantPercent}%`}
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ color: getColorBasedOnPercentage(barChartData?.[NUMBER_ARRAY.four]?.[NUMBER_ARRAY.zero]?.compliantPercent) }}
                >
                  {`${barChartData?.[NUMBER_ARRAY.four]?.[NUMBER_ARRAY.zero]?.compliantPercent}%`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction='row' spacing={NUMBER_ARRAY.two} sx={{ mt: NUMBER_ARRAY.three }}>
          <InfoOutlinedIcon />
          <Typography
            sx={{
              weight: fontWeightStyles.fw9.fontWeight,
              fontFamily: '"JohnsonDisplay-Bold"',
            }}
          >
            JD
          </Typography>
          <Typography
            sx={{
              weight: fontWeightStyles.fw9.fontWeight,
              fontFamily: '"JohnsonDisplay-Bold"',
            }}
          >
            SOE
          </Typography>
          <Typography
            sx={{
              weight: fontWeightStyles.fw9.fontWeight,
              fontFamily: '"JohnsonDisplay-Bold"',
            }}
          >
            ITP
          </Typography>
          <Typography
            sx={{
              weight: fontWeightStyles.fw9.fontWeight,
              fontFamily: '"JohnsonDisplay-Bold"',
            }}
          >
            TCOT
          </Typography>
          <Typography
            sx={{
              weight: fontWeightStyles.fw9.fontWeight,
              fontFamily: '"JohnsonDisplay-Bold"',
            }}
          >
            C
          </Typography>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ComplianceOverviewPopup;
