import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Stack,
  Autocomplete,
  TextField,
  Snackbar,
  InputLabel,
  Grid,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { UseDebounce } from 'common/hooks';
import { DateRangePicker, ToolTipWrapper } from 'common/components';
import {
  PopupDetailsData,
  PopupUpFilterData,
  AdminPopupDetailsData,
  PopupAdminFilterData,
  DirectReporteePopupDetailsData,
  DirectReporteePopupFilterData,
  DirectReporteeTrainingsPopupFilterData,
  PopupUpReminder,
  LargeReportDownloadAction,
  SetLargeReporDownloadStateEmpty,
  AdminDownloadData,
} from 'TAP/redux/actions';
import MuiAlert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RedTheme } from 'common/global';
import {
  genericPop, PAGE_SIZES_ARRAY_GENERIC,
  NUMBER_ARRAY, YEARLY_VIEW, QUARTERLY_VIEW,
  MONTHLY_VIEW, MONTHLY_VIEW_FILE_SUBNAME, QUARTERLY_VIEW_FILE_SUBNAME, YEARLY_VIEW_FILE_SUBNAME, TOTAL_NON_COMPLIANT, TOTAL_AT_RISK, OVERALL_COMPLIANCE_PROFILE, OVERALL_BARDATA
} from 'common/global/constants';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights';
import PropTypes from 'prop-types';
import { ReactComponent as PopupInfo } from 'common/assets/images/genricPopUpInfo.svg';
import { GenericPopupTooltip } from 'common/components/genericPopupTooltip';
import { downloadExcelReport, formatUserField, getGAMeasurementId } from 'TAP/utils/commonMethods';
import ReactGA from 'react-ga4';
import { useScreen } from 'common/hooks/useScreen';
import { ReactComponent as Download } from 'common/assets/images/Down_load.svg';
import { tablePaginationClasses } from '@mui/material/TablePagination';
import { Toaster } from 'TAP/components/Toaster';
import { Spin } from 'antd';
import { TapGlobalMessages } from 'TAP/global';
import { ClearPopupRecordForMyOrg } from 'TAP/redux/actions/popUpDetailsViewAction';
import { ClearPopupRecordForDirectReportee } from 'TAP/redux/actions/popUpDirectReporteeViewAction';
import { ClearPopupRecordForAdmin } from 'TAP/redux/actions/adminPopUpDetailsViewAction';


let redtheme = RedTheme;
let arrayValue = [];
let uniqueArray = [];
let arrcheckbox = [];
let checkboxArray = [];
let totalSelectedRecords;
let AllRowIDSData = [];
let selectAllCheckboxValue, deSelectedIds;
let unselectedRows = [];

const largeDownloadSuccessMsg =
  'The report is ready and an email will arrive shortly with a link to the report location where you can access and download.';
const largeDownloadStartMsg =
  'Record count is greater than 100K. You will receive an email with a link to access the report.';
function GenericPopup({
  open,
  onClose,
  selectedDays,
  columns,
  buttons,
  autocompletes,
  statusMapper,
  popupHeader,
  popUserId,
  popupStatus,
  dateRangeParent,
  setAutocompleteConfigs = null,
  isCheckboxSelection = false,
  isAdmin = false,
  buttonDisabled = true,
  selectedTabValue = 0,
  isCompliantPopup = false,
  workerTypeValues,
  partnerCompanyValues,
  legalEntityValues,
  countryValues,
  mrcNameValues,
  userValues,
  courseSelectedOption,
  managerValues,
  isAdminDownloadLargeAPITriggered = false, // This parametere is to make the large data API call in case of test case manually
  donutChartAutocomplete,
  isGenericPopupOpenForDonutChart,
  complianceElements = '',
  timeRangeState,
  isLevelVisible = false,
  chartMonth,
  chartQuerter,
  chartYear,
  chartStatus,
  setDonutChartAutocompleteConfigs,
  isPopupForCompliance = false,
  newPopupHeader = '',
  tagline,
  hierarchyTraceabilityOn = undefined,
}) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const filterText = UseDebounce(searchText, genericPop.nineHundred);
  const [searchTeamMember, setSearchTeamMember] = useState('');
  const teamMemberSearch = UseDebounce(searchTeamMember, genericPop.nineHundred);
  const [searchPartnerCompany, setSearchPartnerCompany] = useState('');
  const partnerCompanySearch = UseDebounce(searchPartnerCompany, genericPop.nineHundred);
  const [searchWorkerType, setSearchWorkerType] = useState('');
  const workerTypeSearch = UseDebounce(searchWorkerType, genericPop.nineHundred);
  const [selectedUserOption, setSelectedUserOption] = useState('');
  const [trainingTitle, setTrainingTitle] = useState('');
  const [trainingStatus, setTrainingStatus] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [noTrainingsAssigned, setNoTrainingsAssigned] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [rowData, setRowData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState(dateRangeParent);
  const [logInUserDetails, setLogInUserDetails] = useState('');
  const [initializer, setInitializer] = useState(false);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    page: 1,
    pageSize: 5,
  });
  const [selectedUsersForDownloadExcel, setSelectedUsersForDownloadExcel] = useState([]);
  const [deSelectedUsersForDownloadExcel, setDeSelectedUsersForDownloadExcel] = useState([]);
  const [sortingTooltip, setSortingTooltip] = useState('');
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [showLargeDataDownloadInfoMsg, setShowLargeDataDownloadInfoMsg] = useState(false);
  const [showLargeDataDownloadSuccessMsg, setShowLargeDataDownloadSuccessMsg] = useState(false);
  const [toasterFlow, setToasterFlow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const CLICK_TO_SORT_ASCENDING = 'Click to sort ascending';
  const CLICK_TO_SORT_DESCENDING = 'Click to sort descending';
  const screen = useScreen();
  const [selectedWorkerTypeView, setSelectedWorkerTypeView] = useState([]);
  const [selectedPartnerCompanyView, setSelectedPartnerCompanyView] = useState([]);

  const apiResponseOfLargeDataDownload = useSelector(
    (state) => state.teamsPerformanceLargeDownloadReducer?.APIResponse?.data,
  );

  const apiResponseOfAdminLargeDataDownload = useSelector(
    (state) => state.adminLargeReportDownloadRd?.APIResponse?.data,
  );

  useEffect(() => {
    ReactGA.initialize(`G-${getGAMeasurementId()}`);
  }, []);

  useEffect(() => {
    setSortingTooltip(CLICK_TO_SORT_ASCENDING);

    setSelectedUsersForDownloadExcel([]);
  }, [open]);

  useEffect(() => {
    if (
      ((apiResponseOfLargeDataDownload === TapGlobalMessages.trainingRequestApiSuccessMessage) ||
        apiResponseOfAdminLargeDataDownload === TapGlobalMessages.trainingRequestApiSuccessMessage) &&
      showLargeDataDownloadInfoMsg === false &&
      toasterFlow === true
    ) {
      dispatch(SetLargeReporDownloadStateEmpty());
      setShowLargeDataDownloadSuccessMsg(true);
    }
  }, [
    apiResponseOfLargeDataDownload,
    apiResponseOfAdminLargeDataDownload,
    showLargeDataDownloadInfoMsg,
  ]);

  useEffect(() => {
    if (!initializer) {
      const userDetails = localStorage.getItem('userDetails');
      if (userDetails && Object.keys(userDetails).length) {
        setLogInUserDetails(JSON.parse(userDetails));
      }

      setInitializer(true);
    }
  }, [initializer]);

  const isLoadingAdminPopupFilterData = useSelector(
    (state) => state.adminPopUpFilterRd?.isLoadingAdminPopupFilterData,
  );
  const isLoadingDirectReporteeTrainingFilterData = useSelector(
    (state) => state.directReporteeTrainingpopUpFilterRd?.isLoadingDirectReporteeTrainingFilterData,
  );
  const isLoadingDirectReporteeActionPopupFilterData = useSelector(
    (state) =>
      state.directReporteeActionpopUpFilterRd?.isLoadingDirectReporteeActionPopupFilterData,
  );
  const isLoadingPopupFilterData = useSelector(
    (state) => state.tappopUpFilterRd?.isLoadingPopupFilterData,
  );
  useEffect(() => {
    !isLoadingAdminPopupFilterData &&
      !isLoadingDirectReporteeTrainingFilterData &&
      !isLoadingDirectReporteeActionPopupFilterData &&
      !isLoadingPopupFilterData &&
      showLoader &&
      setShowLoader(false);
  }, [
    isLoadingAdminPopupFilterData,
    isLoadingDirectReporteeTrainingFilterData,
    isLoadingDirectReporteeActionPopupFilterData,
    isLoadingPopupFilterData,
  ]);

  const handleClose = () => {
    setSearchText('');
    setSelectedUserOption('');
    setSearchTeamMember('');
    setTrainingTitle('');
    setSearchWorkerType('');
    setSearchPartnerCompany('');
    setSelectedRows([]);
    setSelectionModel([]);
    setSelectedPartnerCompanyView([]);
    setSelectedWorkerTypeView([]);
    setDateRangeFilter(dateRangeParent);
    setTrainingStatus('');
    setSelectedUsersForDownloadExcel([]);
    setSelectAllChecked(false);
    selectAllCheckboxValue = false;
    arrayValue = [];
    uniqueArray = [];
    arrcheckbox = [];
    checkboxArray = [];
    unselectedRows = [];
    setDeSelectedUsersForDownloadExcel([]);
    setShowLargeDataDownloadSuccessMsg(false);
    setShowLargeDataDownloadInfoMsg(false);
    setToasterFlow(false);
    setPageState({
      isLoading: false,
      data: [],
      page: 1,
      pageSize: 5,
      trainingTitle: '',
      userName: '',
    });

    setAutocompleteConfigs((previous) => {
      return previous.map((item) => {
        if (
          item.label === 'Team Member' ||
          item.label === 'WWID / Team Member' ||
          item.label === 'Training Name' ||
          item.label === 'Training Status'
        ) {
          item.options = [];
        }
        return item;
      });
    });

    onClose && onClose();

    // Clear reducer data upon popup closure to ensure new data is fetched each time the popup is opened.
    if(isAdmin) {
      // Clear reducer data for AdminView Popups
      dispatch(ClearPopupRecordForAdmin()); 
    } else if(selectedTabValue === NUMBER_ARRAY.one) {
      // Clear reducer data for My Org Popups
      dispatch(ClearPopupRecordForMyOrg()); 
    } else { // selectedTabValue === 0
      // Clear reducer data for My Direct Reportee Popups.
      dispatch(ClearPopupRecordForDirectReportee()); 
    }
  };


  let dashboardData;
  if (isAdmin) {
    dashboardData = useSelector((state) => state.tapAdminPopUpDetailsRd);
  } else if (selectedTabValue === 1) {
    dashboardData = useSelector((state) => state.tapPopUpDetailsRd);
  } else {
    dashboardData = useSelector((state) => state.tapDirectReporteePopUpDetailsRd);
  }

  let dashboardFilter;
  if (isAdmin) {
    dashboardFilter = useSelector((state) => state.adminPopUpFilterRd);
  } else if (selectedTabValue === 1) {
    dashboardFilter = useSelector((state) => state.tappopUpFilterRd);
  } else if (
    statusMapper[popupHeader] !== undefined &&
    statusMapper[popupHeader].split('/')[1] === 'actions'
  ) {
    dashboardFilter = useSelector((state) => state.directReporteeActionpopUpFilterRd);
  } else {
    dashboardFilter = useSelector((state) => state.directReporteeTrainingpopUpFilterRd);
  }

  let DOWNLOAD_EXCEL_BUTTON_BG_COLOR =
    showLoader || pageState.data?.length <= 0
      ? redtheme.palette.button.light_gray
      : redtheme.palette.primary.main;
  let DOWNLOAD_EXCEL_BUTTON_COLOR =
    showLoader || pageState.data?.length <= 0
      ? redtheme.palette.button.gray
      : redtheme.palette.button.white;
  let DOWNLOAD_EXCEL_BUTTON_FONTWEIGHT =
    showLoader || pageState.data?.length <= 0
      ? fontWeightStyles.fw9.fontWeight
      : fontWeightStyles.fw7.fontWeight;

  const ref = useRef();
  ref.current = false;

  useEffect(() => {
    setDateRangeFilter(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (!ref?.current) {
      setDateRangeFilter(dateRangeParent);
      ref.current = true;
    }
  }, [dateRangeParent]);
  const getPayloadForAdminDownloadData = (urlStatus, params) => {
    //common payload for both profiles
    let payload = {
      userName: selectedUserOption, 
      country: params.country,
      mrc: params.mrc,
      legalEntityName: params.legalEntityName,
      workerType: params.workerType,
      partnerCompany: params.partnerCompany,
      userField: params.userField,
      managerField: params.managerField,
      userArray: params.userArray,
      exclUserArray: params.exclUserArray,
      isHierarchyOn: params.isHierarchyOn
    };

    if(isCompliantPopup) { // complianceProfile
      payload = {
        ...payload,
        reportType: 'complianceProfile',
        dataOf: urlStatus.split('/')[NUMBER_ARRAY.two],
        complianceElements: complianceElements,
      }
    } else { // trainingProfile
      payload = {
        ...payload,
        reportType: 'trainingProfile',
        days: selectedDays,
        trainingTitle: trainingTitle,
        startDate: dateRangeFilter[NUMBER_ARRAY.zero],
        endDate: dateRangeFilter[NUMBER_ARRAY.one],
        dataOf: urlStatus.split('/')[NUMBER_ARRAY.two],
        status: chartStatus, 
        courseCode: courseSelectedOption?.length ? JSON.stringify(courseSelectedOption) : '',
        ...extraPayload(),
      }
    }

    return payload;
  }
  /**
   * @description Payload generation for Leader 100k Download
   * @param {*} urlStatus 
   * @param {*} params 
   * @returns 
   */
  const getPayloadForLeaderDownloadData = (urlStatus, params) => {
    let payLoad = {
      userId: popupStatus ? popUserId : logInUserDetails?.user_id,
      teamType: getTeamType(),
      reportType: getReportType(),
      userName: params.userName,
      dataOf: getDataOf(urlStatus),
      userArray: params.userArray,
      exclUserArray: params.exclUserArray,
      workerType: params.workerType,
      partnerCompany: params.partnerCompany,
      courseCode: params.country,
      popUpType: popupStatus
    }
    if(isCompliantPopup) { // complianceProfile
      payLoad = {
        ...payLoad,
        complianceElements: complianceElements,
      }
    } else { // trainingProfile
      payLoad = {
        ...payLoad,
        days: selectedDays,
        trainingTitle: trainingTitle,
        startDate: (dateRangeFilter && dateRangeFilter.length > 0) ? dateRangeFilter[0] : '',
        endDate: (dateRangeFilter && dateRangeFilter.length > 0) ? dateRangeFilter[1] : '',
        status: chartStatus, 
        ...extraPayload(),
      }
    }
    return payLoad
  }
  const extraPayload = () => {
    return isLevelVisible
      ? {
          level: timeRangeState,
          month: chartMonth,
          year: chartYear,
          quarter: chartQuerter,
        }
      : {};
  };
  const courseCodeForTrainingActionOnly = (downloadApi = false) => {
    return (popupHeader === TOTAL_NON_COMPLIANT || popupHeader === TOTAL_AT_RISK ||
      statusMapper[popupHeader] === OVERALL_COMPLIANCE_PROFILE ||
      statusMapper[popupHeader] === OVERALL_BARDATA) ? {} : {
      courseCode: downloadApi && courseSelectedOption?.length > NUMBER_ARRAY.zero ? JSON.stringify(courseSelectedOption) :
        courseSelectedOption?.length > NUMBER_ARRAY.zero ? courseSelectedOption : '',
    }
  }
  useEffect(() => {
    if (workerTypeValues?.length > NUMBER_ARRAY.zero) {
      setSelectedWorkerTypeView(workerTypeValues);
    }
  }, [workerTypeValues]);
  useEffect(() => {
    if (partnerCompanyValues?.length > NUMBER_ARRAY.zero) {
      setSelectedPartnerCompanyView(partnerCompanyValues);
    }
  }, [partnerCompanyValues]);

  useEffect(() => {
    if (
      statusMapper[popupHeader] !== undefined &&
      statusMapper[popupHeader] !== '' &&
      popUserId &&
      logInUserDetails.user_id !== ''
    ) {
      setPageState((old) => ({ ...old, isLoading: true }));
      const payloadUser = {
        userId: popUserId,
        sortField: pageState.sortField,
        orderBy: pageState.orderBy,
        page: pageState.page,
        limit: pageState.pageSize,
        days: selectedDays,
        userName: searchTeamMember,
        trainingTitle: trainingTitle || '',
        trainingStatus: trainingStatus,
        popUpType: popupStatus,
        urlStatus: statusMapper[popupHeader],
        dateRange: dateRangeFilter,
        loggedInUserId: logInUserDetails.user_id,
        workerType:
          selectedWorkerTypeView?.length > NUMBER_ARRAY.zero ? selectedWorkerTypeView : '',
        partnerCompany:
          selectedPartnerCompanyView?.length > NUMBER_ARRAY.zero ? selectedPartnerCompanyView : '',
        ...extraPayload(),
        complianceElements: complianceElements,
      };
      const adminPayloadUser = {
        userId: popUserId,
        sortField: pageState.sortField,
        orderBy: pageState.orderBy,
        page: pageState.page,
        limit: pageState.pageSize,
        days: selectedDays,
        userName: searchTeamMember,
        trainingTitle: trainingTitle || '',
        trainingStatus: trainingStatus,
        popUpType: popupStatus,
        urlStatus: statusMapper[popupHeader],
        dateRange: dateRangeFilter,
        loggedInUserId: logInUserDetails.user_id,
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        workerType:
          workerTypeValues?.length > NUMBER_ARRAY.zero
            ? workerTypeValues
            : selectedWorkerTypeView?.length > NUMBER_ARRAY.zero
            ? selectedWorkerTypeView
            : '',
        partnerCompany:
          partnerCompanyValues?.length > NUMBER_ARRAY.zero
            ? partnerCompanyValues
            : selectedPartnerCompanyView?.length > NUMBER_ARRAY.zero
            ? selectedPartnerCompanyView
            : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
        complianceElements: complianceElements,
        ...courseCodeForTrainingActionOnly(),
        ...extraPayload(),
      };
      dispatch(
        isAdmin
          ? AdminPopupDetailsData(adminPayloadUser)
          : selectedTabValue === 1
          ? PopupDetailsData(payloadUser)
          : DirectReporteePopupDetailsData(payloadUser),
      );
    }
  }, [
    pageState.page,
    pageState.pageSize,
    pageState.sortField,
    pageState.orderBy,
    pageState.days,
    popUserId,
    statusMapper[popupHeader],
    popupStatus,
    selectedUserOption,
    trainingTitle,
    dateRangeFilter,
    logInUserDetails.user_id,
    timeRangeState,
    selectedWorkerTypeView,
    selectedPartnerCompanyView,
    workerTypeValues,
    partnerCompanyValues,
  ]);

  useEffect(() => {
    setPageState({ ...pageState, isLoading: false });
    if (dashboardData?.popupDetailsData?.data?.records !== undefined) {
      setRowData([...rowData, ...dashboardData.popupDetailsData.data.records]);

      setPageState((old) => ({
        ...old,
        data: popupListingData(),
        total: dashboardData?.popupDetailsData?.data?.totalRecords,
      }));
      totalSelectedRecords = dashboardData?.popupDetailsData?.data?.totalRecords;

      if (selectAllChecked) {
        if (popupHeader === TOTAL_NON_COMPLIANT || popupHeader === TOTAL_AT_RISK) {
          AllRowIDSData = dashboardData?.popupDetailsData?.data?.records.map((item) => {
            return item['user_id'];
          });
          deSelectedIds = unselectedRows?.map((item) => item.user_id);
        } else {
          AllRowIDSData = dashboardData?.popupDetailsData?.data?.records.map((item) => {
            return item['transcript_id'];
          });
          deSelectedIds = unselectedRows?.map((item) => item.transcript_id);
        }

        if (unselectedRows?.length) {
          const selectedIds = AllRowIDSData.filter((item) => !deSelectedIds.includes(item));
          setSelectionModel(selectedIds);
        } else {
          setSelectionModel(AllRowIDSData);
        }

        setSelectedUsersForDownloadExcel([]);
      }

      dashboardData?.popupDetailsData?.data?.records !== undefined &&
      dashboardData?.popupDetailsData?.data?.records.length > 0
        ? setNoTrainingsAssigned(false)
        : setNoTrainingsAssigned(true);
    } else if (dashboardData?.popupDetailsData?.data?.length === 0) {
      setPageState((old) => ({
        ...old,
        data: [],
        total: 0,
      }));
    } else {
      //No valid request
    }
  }, [dashboardData]);


  const popupListingData = () => {
    if (dashboardData?.popupDetailsData?.data?.records?.length >= 0) {
      if (isPopupForCompliance) {
        return dashboardData?.popupDetailsData?.data?.records.map((item) => {
          if (item.complianceElement === 'SOEJD') {
            return {
              ...item,
              complianceElement: 'SOE, JD'
            };
          } else if (item.complianceElement === 'SOEJDTraining') {
            return {
              ...item,
              complianceElement: 'SOE, JD, Training'
            };
          } else if (item.complianceElement === 'SOEITPJDTraining') {
            return {
              ...item,
              complianceElement: 'SOE, ITP, JD, Training'
            };
          } else if (item.complianceElement === 'JDTraining') {
            return {
              ...item,
              complianceElement: 'JD, Training'
            };
          } else if (item.complianceElement === 'ITPTraining') {
            return {
              ...item,
              complianceElement: 'ITP, Training',
            };
          } else if (item.complianceElement === 'SOEITPJD') {
            return {
              ...item,
              complianceElement: 'SOE, ITP, JD'
            };
          }

          return item;
        });
      } else {
        return dashboardData?.popupDetailsData?.data?.records;
      }
    }
  }

  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getTeamType = () => {
    if (
      statusMapper[popupHeader].split('/')[0] === 'directReportTrainings' ||
      statusMapper[popupHeader].split('/')[0] === 'directReport'
    ) {
      return 'myDirectReport';
    } else {
      return 'myOrganisation';
    }
  };

  const getReportType = () => {
    if (popupHeader === TOTAL_NON_COMPLIANT || popupHeader === TOTAL_AT_RISK) {
      return 'complianceProfile';
    } else {
      return 'trainingProfile';
    }
  };

  const getDataOf = (urlStatus) => {
    if (urlStatus === 'directReport/actions/nonCompliant' || 
        urlStatus === 'directReport/actions/overallComplianceProfile' || 
        urlStatus === 'directReport/actions/overallBarData' || 
        urlStatus === 'directReport/actions/atRisk') {
        return  urlStatus.split('/')[NUMBER_ARRAY.two];
    } else {
        return urlStatus.split('/')[NUMBER_ARRAY.one];
    }
  };

  const monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  //false : to enable   &  true :  to disable .(Send Reminder button)
  const setSubName = (month, quarter, year, isPopupHeader = false) => {
    if (quarter && year) {
      return isPopupHeader ? ` Quarter-${quarter} ${year}` : `_Quarter_${quarter}_${year}`;
    } else if (month && year) {
      return isPopupHeader
        ? ` ${monthsArray[Number(month) - 1]} ${year}`
        : `_${monthsArray[Number(month) - 1]}_${year}`;
    } else if (year) {
      return isPopupHeader ? ` ${year}` : `_${year}`;
    } else if (
      !isPopupForCompliance &&
      !chartQuerter &&
      !chartYear &&
      !chartMonth &&
      isGenericPopupOpenForDonutChart
    ) {
      if (timeRangeState === 'Year') {
        return isPopupHeader ? ` ${YEARLY_VIEW}` :  `_${YEARLY_VIEW_FILE_SUBNAME}` ;
      } else if (timeRangeState === 'Quarter') {
        return isPopupHeader ? ` ${QUARTERLY_VIEW}` : `_${QUARTERLY_VIEW_FILE_SUBNAME}`;
      } else if (timeRangeState === 'Month') {
        return isPopupHeader ? ` ${MONTHLY_VIEW}`: `_${MONTHLY_VIEW_FILE_SUBNAME}`;
      }
    }
     else {
      return '';
    }
  };

  const CheckSendReminder = () => {
    let filteredselectedRows = selectedRows.filter((item) => item.complianceElement !== '');
    return !(filteredselectedRows?.length > 0);
  };

  // popUp Reminder
  const handleSendReminder = () => {
    // Capture the event of sending mail for Google Analytics
    captureMailTriggerEvent();

    let arrSelectedRows = _.clone(selectedRows, true);
    arrSelectedRows = arrSelectedRows.filter((item) => item.complianceElement !== '');
    if (isCompliantPopup) {
      selectedRows.forEach((row) => {
        if (selectionModel.indexOf(row.user_id) < 0) {
          let index = arrSelectedRows.indexOf(
            arrSelectedRows.find((item) => item.user_id === row.user_id),
          );
          arrSelectedRows.splice(index, 1);
        }
      });
    } else {
      selectedRows.forEach((row) => {
        if (selectionModel.indexOf(row.transcript_id) < 0) {
          let index = arrSelectedRows.indexOf(
            arrSelectedRows.find((item) => item.transcript_id === row.transcript_id),
          );
          arrSelectedRows.splice(index, 1);
        }
      });
    }
    const arr = statusMapper[popupHeader].split('/');
    const payloadreminderUser = {
      userId: popUserId,
      reminderOf: arr[arr.length - 1],
      username: logInUserDetails?.full_name,
      records: arrSelectedRows,
    };
    const selectAllPayloadRemainderUser = {
      userId: popUserId,
      reminderOf: arr[arr.length - 1],
      username: logInUserDetails?.full_name,
      sendToAll: 'Y',
      screen: isAdmin ? 'adminView' : selectedTabValue === 1 ? 'myOrganisation' : 'myDirectReports',
      records: [],
      exclUserArray:
        selectAllChecked && deSelectedUsersForDownloadExcel?.length
          ? deSelectedUsersForDownloadExcel
          : '',
      page: pageState.page,
      limit: pageState.pageSize,
      days: selectedDays,
      userName: searchTeamMember,
      trainingTitle: trainingTitle || '',
      trainingStatus: trainingStatus,
      popUpType: popupStatus,
      urlStatus: statusMapper[popupHeader],
      dateRange: dateRangeFilter,
      loggedInUserId: logInUserDetails.user_id,
      country: countryValues?.length ? countryValues : '',
      mrc: mrcNameValues?.length ? mrcNameValues : '',
      legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
      workerType: workerTypeValues?.length > NUMBER_ARRAY.zero ? workerTypeValues : '',
      partnerCompany: partnerCompanyValues?.length > NUMBER_ARRAY.zero ? partnerCompanyValues : '',
      userField: userValues?.length ? userValues : '',
      managerField: managerValues?.length ? managerValues : '',
    };

    if (selectAllChecked) {
      dispatch(PopupUpReminder(selectAllPayloadRemainderUser));
    } else {
      dispatch(PopupUpReminder(payloadreminderUser));
    }

    handleSnackbarOpen('Reminder sent successfully!');
    setSnackbarOpen(true);
    setSelectedRows([]);
    setSelectionModel([]);
    setRowData([]);
    setSelectAllChecked(false);
    selectAllCheckboxValue = false;
  };
  const captureMailTriggerEvent = () => {
    let categoryName;
    if (
      statusMapper[popupHeader] !== undefined &&
      statusMapper[popupHeader].split('/')[0] === 'adminView'
    ) {
      categoryName = 'Administrative View';
    } else {
      categoryName = "My Team's Performance";
    }

    ReactGA.event({
      category: categoryName,
      action: 'Email Triggered',
    });
  };

  // Download Excel sheet
  const handleDownloadExcelUrlAddition = (
    urlStatus,
    days,
    first_date,
    second_date,
    isPopupTypeAvailable,
    level,
    month,
    year,
    quarter,
    status,
  ) => {
    let requestURL = '';
    
    if (
      urlStatus !== 'directReportTrainings/completeOnTime' &&
      urlStatus !== 'directReportTrainings/completedOverall' &&
      urlStatus !== 'trainings/completeOnTime' && 
      urlStatus !== 'trainings/completedOverall'
    ) {
      if (
        level !== '' &&
        urlStatus !== 'adminView/actions/overallComplianceProfile' &&
        urlStatus !== 'adminView/actions/overallBarData' &&
        urlStatus !== 'actions/overallComplianceProfile' &&
        urlStatus !== 'actions/overallBarData' &&
        urlStatus !== 'directReport/actions/overallBarData' &&
        urlStatus !== 'adminView/trainings/totalOverDue' && 
        urlStatus !== 'adminView/trainings/upcomingDue' &&
        urlStatus !== 'trainings/upcomingDue' && 
        urlStatus !== 'trainings/totalOverDue' &&
        urlStatus !== 'directReportTrainings/upcomingDue' &&
        urlStatus !== 'directReportTrainings/totalOverDue'
      ) {
        requestURL += `&level=${level}`;
      }
      if (
        urlStatus === 'adminView/actions/overallBarData' ||
        urlStatus === 'actions/overallBarData' ||
        urlStatus === 'directReport/actions/overallBarData'
      ) {
        requestURL += `&complianceElements=${complianceElements}`;
      }
      if (month !== '') {
        requestURL += `&month=${month}`;
      }
      if (year !== '') {
        requestURL += `&year=${year}`;
      }
      if (quarter !== '') {
        requestURL += `&quarter=${quarter}`;
      }
      if (status !== '') {
        requestURL += `&status=${status}`;
      }
      if (urlStatus === 'adminView/trainings/upcomingDue') {
        requestURL += requestURL?.length === NUMBER_ARRAY.zero ? `?days=${days}` : `&days=${days}`;
      }
      if (urlStatus === 'trainings/upcomingDue') {
        requestURL += requestURL?.length === NUMBER_ARRAY.zero ? `?days=${days}` : `&days=${days}`;
      }
      if (urlStatus === 'directReportTrainings/upcomingDue') {
        requestURL += requestURL?.length === NUMBER_ARRAY.zero ? `?days=${days}` : `&days=${days}`;
      }

      if (searchTeamMember?.length) {
        requestURL +=
          requestURL?.length === NUMBER_ARRAY.zero && !isPopupTypeAvailable
            ? `?userName=${searchTeamMember}`
            : `&userName=${searchTeamMember}`;
      }

      if (trainingStatus?.length) {
        requestURL += `&trainingStatus=${trainingStatus}`;
      }
      
    } 

    if (trainingTitle?.length) {
      requestURL += `&trainingTitle=${encodeURIComponent(trainingTitle)}`;
    }

    if (dateRangeFilter?.length) {
      requestURL += `&startDate=${first_date}&endDate=${second_date}`;
    }

    return requestURL;
  };

  const handleDownloadExcel = () => {
    setShowLoader(true);
    const urlStatus = statusMapper[popupHeader];
    const days = selectedDays;
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const first_date = dateRangeFilter?.[0];
    const second_date = dateRangeFilter?.[1];
    let isPopupTypeAvailable = false;

    /**
     * We are not using common method to get the current date and time
     * as its not giving us proper timing (TODO: need to check the RC)
     * so for the time being using this component level code
     */
    const currDate = new Date().toISOString('en-US', { timeZone: 'UTC' }).split('T')[0];
    const currTime = new Date()
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
      })
      .replace(/[^ apm\d]+/gi, '_');

    let fileName = '';
    const name = (popupHeader === 'Trainings Completed On Time' ? newPopupHeader : popupHeader)
      ?.split(' ')
      .join('');
    const subName =  isPopupForCompliance || (
      chartYear === '' && chartQuerter === '' && chartMonth === '' && !isGenericPopupOpenForDonutChart )
        ? ''
        : setSubName(chartMonth, chartQuerter, chartYear) ;
     

    switch (urlStatus) {
      case 'trainings/totalOverDue':
        fileName = `Overdue_Trainings_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/trainings/totalOverDue':
        fileName = `Overdue_Trainings_${currDate}_${currTime}.xlsx`;
        break;
      case 'trainings/upcomingDue':
        fileName = `ComingDue_Trainings_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/trainings/upcomingDue':
        fileName = `ComingDue_Trainings_${currDate}_${currTime}.xlsx`;
        break;
      case 'trainings/incompleteOverDue':
        fileName = `IncompleteOverDue_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/trainings/incompleteOverDue':
        fileName = `IncompleteOverDue_${currDate}_${currTime}.xlsx`;
        break;
      case 'trainings/incomplete':
        fileName = `Incomplete_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/trainings/incomplete':
        fileName = `Incomplete_${currDate}_${currTime}.xlsx`;
        break;
      case 'trainings/completeOnTime':
        fileName = `CompleteOnTime_${currDate}_${currTime}.xlsx`;
        break;
      case 'trainings/completedOverall':
        fileName = `CompleteOverall_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReportTrainings/completeOnTime':
        fileName = `CompleteOnTime_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReportTrainings/completedOverall':
        fileName = `CompleteOverall_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/trainings/completeOnTime':
        fileName = `CompleteOnTime_${currDate}_${currTime}.xlsx`;
        break;
      case 'trainings/completeOverDue':
        fileName = `CompleteOverDue_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/trainings/completeOverDue':
        fileName = `CompleteOverDue_${currDate}_${currTime}.xlsx`;
        break;
      case 'actions/nonCompliant':
        fileName = `NonCompliant_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/actions/nonCompliant':
        fileName = `NonCompliant_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReport/actions/nonCompliant':
        fileName = `NonCompliant_${currDate}_${currTime}.xlsx`;
        break;
      case 'actions/atRisk':
        fileName = `AtRisk_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/actions/atRisk':
        fileName = `AtRisk_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReport/actions/atRisk':
        fileName = `AtRisk_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReportTrainings/totalOverDue':
        fileName = `Overdue_Trainings_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReportTrainings/upcomingDue':
        fileName = `ComingDue_Trainings_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/actions/overallComplianceProfile':
        fileName = `${name}_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/actions/overallBarData':
        fileName = `${name}_${currDate}_${currTime}.xlsx`;
        break;
      case 'actions/overallComplianceProfile':
        fileName = `${name}_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReport/actions/overallComplianceProfile':
        fileName = `${name}_${currDate}_${currTime}.xlsx`;
        break;
      case 'actions/overallBarData':
        fileName = `${name}_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReport/actions/overallBarData':
        fileName = `${name}_${currDate}_${currTime}.xlsx`;
        break;
      case 'adminView/trainings/overallTrainingProfile':
        fileName = `${name}${subName}_${currDate}_${currTime}.xlsx`;
        break;
      case 'trainings/overallTrainingProfile':
        fileName = `${name}${subName}_${currDate}_${currTime}.xlsx`;
        break;
      case 'directReportTrainings/overallTrainingProfile':
        fileName = `${name}${subName}_${currDate}_${currTime}.xlsx`;
        break;
      default:
        // No valid request
        break;
    }

    if (popUserId !== undefined && popupStatus !== undefined) {
      let requestURL = '';
      if (urlStatus === 'actions/nonCompliant' || urlStatus === 'actions/atRisk') {
        requestURL = `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}`;
      } else if (urlStatus === 'trainings/overallTrainingProfile') {
        requestURL = `${baseURL}${urlStatus}/report/${popUserId}/${logInUserDetails.user_id}`;
      } else if (urlStatus === 'directReportTrainings/overallTrainingProfile') {
        requestURL = `${baseURL}${'directReportTrainingsdownload/overallTrainingProfile'}/report/${popUserId}/${
          logInUserDetails.user_id
        }`;
      } else if (
        urlStatus === 'directReport/actions/nonCompliant' ||
        urlStatus === 'directReport/actions/atRisk'
      ) {
        requestURL = `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}`;
      } else if (urlStatus === 'adminView/trainings/totalOverDue') {
        requestURL = `${baseURL}${urlStatus}/report/?popUpType=${popupStatus}`;
        isPopupTypeAvailable = true;
      } else if (urlStatus === 'adminView/trainings/upcomingDue') {
        requestURL = `${baseURL}${urlStatus}/report`;
      } else if (
        urlStatus === 'directReportTrainings/totalOverDue' ||
        urlStatus === 'directReportTrainings/upcomingDue'
      ) {
        requestURL = `${baseURL}directReportTrainingsdownload/${urlStatus.split('/')[1]}/report/${
          logInUserDetails.user_id
        }/${popUserId}?popUpType=${popupStatus}`;
        isPopupTypeAvailable = true;
      } else if (
        urlStatus === 'directReportTrainings/completeOnTime' ||
        urlStatus === 'directReportTrainings/completedOverall'
      ) {
        requestURL = `${baseURL}directReportTrainingsdownload/${urlStatus.split('/')[1]}/report/${
          logInUserDetails.user_id
        }/${popUserId}?popUpType=${popupStatus}`;
        isPopupTypeAvailable = true;
      } else if (
        urlStatus === 'adminView/actions/overallComplinceProfile' ||
        urlStatus === 'adminView/actions/overallBarData'
      ) {
        requestURL = `${baseURL}${urlStatus}/report/${popUserId}?popUpType=${popupStatus}`;
      } else {
        requestURL = !isAdmin
          ? isGenericPopupOpenForDonutChart
            ? `${baseURL}${urlStatus}/report/${popUserId}?popUpType=${popupStatus}`
            : `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}/${popUserId}?popUpType=${popupStatus}`
          : isLevelVisible
          ? `${baseURL}${urlStatus}/report?popUpType=${popupStatus}`
          : `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}?popUpType=${popupStatus}`;
        isPopupTypeAvailable = true;
      }

      const queryRequestURL = handleDownloadExcelUrlAddition(
        urlStatus,
        days,
        first_date,
        second_date,
        isPopupTypeAvailable,
        timeRangeState,
        chartMonth,
        chartYear,
        chartQuerter,
        chartStatus,
      );

      requestURL += queryRequestURL;

      const params = isAdmin
        ? {
            country: countryValues?.length ? JSON.stringify(countryValues) : '',
            mrc: mrcNameValues?.length ? JSON.stringify(mrcNameValues) : '',
            legalEntityName: legalEntityValues?.length ? JSON.stringify(legalEntityValues) : '',
            workerType:
              workerTypeValues?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(workerTypeValues)
                : selectedWorkerTypeView?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(selectedWorkerTypeView)
                : '',
            partnerCompany:
              partnerCompanyValues?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(partnerCompanyValues)
                : selectedPartnerCompanyView?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(selectedPartnerCompanyView)
                : '',
            userField: userValues?.length ? JSON.stringify(userValues) : '',
            managerField: managerValues?.length ? JSON.stringify(managerValues) : '',
            exclUserArray:
              selectAllChecked && deSelectedUsersForDownloadExcel?.length
                ? JSON.stringify(deSelectedUsersForDownloadExcel)
                : '',
            userArray: selectedUsersForDownloadExcel?.length
              ? JSON.stringify(selectedUsersForDownloadExcel)
              : '',
            isHierarchyOn: hierarchyTraceabilityOn,
            ...courseCodeForTrainingActionOnly(true)
          }
        : {
            workerType:
              workerTypeValues?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(workerTypeValues)
                : selectedWorkerTypeView?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(selectedWorkerTypeView)
                : '',
            partnerCompany:
              partnerCompanyValues?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(partnerCompanyValues)
                : selectedPartnerCompanyView?.length > NUMBER_ARRAY.zero
                ? JSON.stringify(selectedPartnerCompanyView)
                : '',
            userField: userValues?.length ? JSON.stringify(userValues) : '',
            userArray: selectedUsersForDownloadExcel?.length
              ? JSON.stringify(selectedUsersForDownloadExcel)
              : '',
            exclUserArray:
              selectAllChecked && deSelectedUsersForDownloadExcel?.length
                ? JSON.stringify(deSelectedUsersForDownloadExcel)
                : '',
          };

      downloadExcelReport(requestURL, fileName, params)
        .then(() => {
          setShowLoader(false);
        })
        .catch((err) => {
          if (
            (urlStatus === 'trainings/totalOverDue' ||
              urlStatus === 'trainings/upcomingDue' ||
              urlStatus === 'actions/nonCompliant' ||
              urlStatus === 'actions/atRisk' ||
              urlStatus === 'directReportTrainings/totalOverDue' ||
              urlStatus === 'directReportTrainings/upcomingDue' ||
              urlStatus === 'directReport/actions/nonCompliant' ||
              urlStatus === 'trainings/overallTrainingProfile' ||
              urlStatus === 'actions/overallComplianceProfile' ||
              urlStatus === 'actions/overallBarData' || 
              urlStatus === 'directReportTrainings/overallTrainingProfile' ||
              urlStatus === 'directReport/actions/overallComplianceProfile' ||
              urlStatus === 'directReport/actions/overallBarData' ||
              urlStatus === 'directReport/actions/atRisk' ||
              urlStatus === 'directReportTrainings/completeOnTime' ||
              urlStatus === 'directReportTrainings/completedOverall' ||
              urlStatus === 'trainings/completeOnTime' ||
              urlStatus === 'trainings/completedOverall') &&
            err?.response?.status === NUMBER_ARRAY.fourHundred
          ) {
            dispatch(
              LargeReportDownloadAction(getPayloadForLeaderDownloadData(urlStatus, params))
            );
            setShowLargeDataDownloadInfoMsg(true);
            setToasterFlow(true);
            setShowLoader(false);
          } else if (
            isAdminDownloadLargeAPITriggered ||
            ((urlStatus === 'adminView/trainings/totalOverDue' ||
              urlStatus === 'adminView/trainings/upcomingDue' ||
              urlStatus === 'adminView/trainings/incompleteOverDue' ||
              urlStatus === 'adminView/trainings/incomplete' ||
              urlStatus === 'adminView/trainings/completeOnTime' ||
              urlStatus === 'adminView/trainings/completeOverDue' ||
              urlStatus === 'adminView/actions/nonCompliant' ||
              urlStatus === 'adminView/actions/atRisk' ||
              urlStatus === 'adminView/actions/overallComplianceProfile' ||
              urlStatus === 'adminView/actions/overallBarData' ||
              urlStatus === 'adminView/trainings/overallTrainingProfile') &&
              err?.response?.status === NUMBER_ARRAY.fourHundred)
          ) {
            dispatch(
              AdminDownloadData(getPayloadForAdminDownloadData(urlStatus, params)),
            );
            setShowLargeDataDownloadInfoMsg(true);
            setToasterFlow(true);
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        });
    }
  };

  // Data Table Sorting
  const handleSortModelChange = useCallback((sortModel) => {
    setPageState((old) => ({
      ...old,
      sortField: sortModel[0]?.field,
      orderBy: sortModel[0]?.sort,
    }));

    if (sortModel[0]?.sort.toUpperCase() === 'ASC') {
      setSortingTooltip(CLICK_TO_SORT_DESCENDING);
    } else if (sortModel[0]?.sort.toUpperCase() === 'DESC') {
      setSortingTooltip(CLICK_TO_SORT_ASCENDING);
    }
  }, []);

  const searchTypeRef = useRef('');
  const trainingStatusSearch = (value) => {
    searchTypeRef.current = 'trainingStatus';
    setTrainingStatus(value);
    setAutocompleteConfigs((previous) => {
      return previous.map((item) => {
        if (item.label === 'Search Training Status') {
          item.options = [];
        }
        return item;
      });
    });
  };

  const trainingSearchWithValue = (value) => {
    setShowLoader(true);
    if (searchTypeRef.current === 'user') {
      setSearchTeamMember(value);
    } else if (searchTypeRef.current === 'partnerCompany') {
      setSearchPartnerCompany(value);
    } else if (searchTypeRef.current === 'workerType') {
      setSearchWorkerType(value);
    } else if (searchTypeRef.current === 'training') {
      setSearchText(value);
    }
  };

  const trainingSearchWithoutValue = () => {
    setShowLoader(false);
    if (searchTypeRef.current === 'user') {
      setSearchTeamMember('');
      setSelectedUserOption(filterText);
      setAutocompleteConfigs((previous) => {
        return previous.map((item) => {
          if (item.label === 'Team Member' || item.label === 'WWID / Team Member') {
            item.options = [];
          }
          return item;
        });
      });
    } else if((searchTypeRef.current === 'training')) {
      setSearchText('');
      setSelectedUserOption(teamMemberSearch);
      setAutocompleteConfigs((previous) => {
        return previous.map((item) => {
          if (item.label === 'Training Name') {
            item.options = [];
          }
          return item;
        });
      });
    }
    setPageState((old) => ({ ...old, searchText: '' }));
  };

  const handleSearchText = (_e, value, label) => {
    if (label === 'Training Name' || label === 'Search Training Name') {
      searchTypeRef.current = 'training';
    } else if (label === 'Partner Company') {
      searchTypeRef.current = 'partnerCompany';
    } else if (label === 'Worker Type') {
      searchTypeRef.current = 'workerType';
    } else {
      searchTypeRef.current = 'user';
    }

    if (label === 'Training Status') {
      trainingStatusSearch(value);
    } else if (value && value.trim() !== '') {
      trainingSearchWithValue(value);
    } else {
      trainingSearchWithoutValue();
    }

    if (!value || value.trim() === '') {
      setShowLoader(false);
    }
  };

  // Filter
  /**
   *
   *getDataOfValue is a function which determines the getDataof parameter in Payload
   */
  const getDataOfValue = (condition) => {
    if (condition) {
      return ' ';
    } else {
      if (selectedTabValue === 1) {
        return statusMapper[popupHeader]?.split('/')[1];
      }
      return statusMapper[popupHeader] !== undefined &&
        statusMapper[popupHeader].split('/')[1] === 'actions'
        ? statusMapper[popupHeader]?.split('/')[NUMBER_ARRAY.two]
        : statusMapper[popupHeader]?.split('/')[1];
    }
  };
  /**
   *
   *actionsTypeValue is a function which determines the actiontype parameter in Payload
   */
  const actionsTypeValue = (condition) => {
    if (condition) {
      return '';
    } else {
      let statusMappervalue;
      if (selectedTabValue === 1) {
        statusMappervalue = statusMapper[popupHeader]?.split('/')[0];
      } else if (
        statusMapper[popupHeader] !== undefined &&
        statusMapper[popupHeader].split('/')[1] === 'actions'
      ) {
        statusMappervalue =
          statusMapper[popupHeader]?.split('/')[0] + '/' + statusMapper[popupHeader]?.split('/')[1];
      } else {
        statusMappervalue = 'trainings';
      }
      return statusMappervalue;
    }
  };
  useEffect(() => {
    if (
      popUserId !== undefined &&
      popUserId !== '' &&
      (filterText !== undefined ||
        teamMemberSearch !== undefined ||
        partnerCompanySearch !== undefined ||
        workerTypeSearch !== undefined)
    ) {
      const keyword = {
        training: filterText,
        user: teamMemberSearch,
        trainingStatus: trainingStatus,
        partnerCompany: partnerCompanySearch,
        workerType: workerTypeSearch,
      };
      let filterPayload = {
        userId: popUserId,
        keyWord: searchTypeRef?.current === undefined ? ' ' : keyword[searchTypeRef.current],
        searchType: searchTypeRef.current,
        getDataOf: isGenericPopupOpenForDonutChart
          ? isCompliantPopup
            ? 'overallComplianceProfile'
            : 'overallTrainingProfile'
          : getDataOfValue(popupHeader === undefined || statusMapper[popupHeader] === undefined),
        days: selectedDays,
        popUpType: popupStatus,
        loggedInUserId: logInUserDetails.user_id,
        dateRange: dateRangeFilter,
        actionsType: actionsTypeValue(
          popupHeader === undefined || statusMapper[popupHeader] === undefined,
        ),
        workerType:
          workerTypeValues?.length > NUMBER_ARRAY.zero
            ? workerTypeValues
            : selectedWorkerTypeView?.length > NUMBER_ARRAY.zero
            ? selectedWorkerTypeView
            : '',
        partnerCompany:
          partnerCompanyValues?.length > NUMBER_ARRAY.zero
            ? partnerCompanyValues
            : selectedPartnerCompanyView?.length > NUMBER_ARRAY.zero
            ? selectedPartnerCompanyView
            : '',
        userField:
          userValues?.length > NUMBER_ARRAY.zero
            ? userValues
            : selectedUserOption?.length > NUMBER_ARRAY.zero
            ? formatUserField(selectedUserOption)
            : '',
        ...extraPayload(),
      };
      let adminFilterPayload = {
        userId: popUserId,
        keyWord: keyword[searchTypeRef.current],
        searchType: searchTypeRef.current,
        getDataOf: statusMapper[popupHeader]
          ? statusMapper[popupHeader]?.split('/')[NUMBER_ARRAY.two]
          : '',
        days: selectedDays,
        popUpType: popupStatus,
        loggedInUserId: logInUserDetails.user_id,
        dateRange: dateRangeFilter,
        actionsType: statusMapper[popupHeader]
          ? statusMapper[popupHeader]?.split('/')[NUMBER_ARRAY.one]
          : '',
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        workerType:
          workerTypeValues?.length > NUMBER_ARRAY.zero
            ? workerTypeValues
            : selectedWorkerTypeView?.length > NUMBER_ARRAY.zero
            ? selectedWorkerTypeView
            : '',
        partnerCompany:
          partnerCompanyValues?.length > NUMBER_ARRAY.zero
            ? partnerCompanyValues
            : selectedPartnerCompanyView?.length > NUMBER_ARRAY.zero
            ? selectedPartnerCompanyView
            : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
        ...extraPayload(),
        ...courseCodeForTrainingActionOnly()
      };
      let adminActionFilterPayload = {
        keyWord: keyword[searchTypeRef.current],
        getDataOf: isGenericPopupOpenForDonutChart
          ? 'overallComplianceProfile'
          : statusMapper[popupHeader]
          ? statusMapper[popupHeader]?.split('/')[NUMBER_ARRAY.two]
          : '',
        actionsType: statusMapper[popupHeader]
          ? statusMapper[popupHeader]?.split('/')[NUMBER_ARRAY.one]
          : '',
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        workerType:
          workerTypeValues?.length > NUMBER_ARRAY.zero
            ? workerTypeValues
            : selectedWorkerTypeView?.length > NUMBER_ARRAY.zero
            ? selectedWorkerTypeView
            : '',
        partnerCompany:
          partnerCompanyValues?.length > NUMBER_ARRAY.zero
            ? partnerCompanyValues
            : selectedPartnerCompanyView?.length > NUMBER_ARRAY.zero
            ? selectedPartnerCompanyView
            : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
        searchType: searchTypeRef.current,
      };
      if (searchTypeRef.current === 'training') {
        filterPayload['userData'] = teamMemberSearch;
        filterPayload['trainingData'] = '';
        filterPayload['trainingStatus'] = trainingStatus;
      } else if (searchTypeRef.current === 'user') {
        filterPayload['userData'] = '';
        filterPayload['trainingData'] = filterText;
        filterPayload['trainingStatus'] = trainingStatus;
      } else if (searchTypeRef.current === 'trainingStatus') {
        filterPayload['userData'] = teamMemberSearch;
        filterPayload['trainingData'] = filterText;
        filterPayload['trainingStatus'] = '';
      } else if (searchTypeRef.current === 'workerType' || searchTypeRef.current === 'partnerCompany') {
        filterPayload['userData'] = teamMemberSearch;
        filterPayload['trainingData'] = filterText;
        filterPayload['trainingStatus'] = trainingStatus;
      }  

      if (isAdmin) {
        dispatch(
          PopupAdminFilterData(
            statusMapper[popupHeader]?.split('/')[1] === 'actions'
              ? adminActionFilterPayload
              : adminFilterPayload,
          ),
        );
      } else {
        if (selectedTabValue === 1) {
          dispatch(PopupUpFilterData(filterPayload));
        } else {
          if (
            statusMapper[popupHeader] !== undefined &&
            statusMapper[popupHeader].split('/')[1] === 'actions'
          ) {
            dispatch(DirectReporteePopupFilterData(filterPayload));
          } else if (filterPayload?.actionsType) {
            dispatch(DirectReporteeTrainingsPopupFilterData(filterPayload));
          }
        }
      }
    }
  }, [
    filterText,
    dateRangeParent,
    teamMemberSearch,
    trainingStatus,
    selectedWorkerTypeView,
    selectedPartnerCompanyView,
    workerTypeValues,
    partnerCompanyValues,
    workerTypeSearch,
    partnerCompanySearch,
    selectedUserOption,
  ]);

  // Date range Picker
  const info = [
    {
      body: 'Job Description',
      placeholder: 'JD',
    },
    {
      body: 'Summary Of Experience',
      placeholder: 'SOE',
    },
    {
      body: 'Individual Training Plan',
      placeholder: 'ITP',
    },
    {
      body: 'See Training Actions > Trainings Overdue for actionable past due trainings.',
      placeholder: 'Training',
    },
  ];
  const getStartDate = (parentDate, date) => {
    return parentDate < date ? date : parentDate;
  };
  const getEndDate = (parentDate, date) => {
    return parentDate > date ? date : parentDate;
  };
  const onChangeOfDateRange = (date) => {
    if (date !== undefined && date.length > 0) {
      if (date[0] !== undefined && date[0] !== '') {
        if (dateRangeParent?.length) {
          setSelectedDateRange([
            getStartDate(dateRangeParent[0], date[0]),
            getEndDate(dateRangeParent[1], date[1]),
          ]);
        } else {
          setSelectedDateRange([date[0], date[1]]);
        }
      } else {
        setDateRangeFilter([]);
      }
    } else {
      setDateRangeFilter([]);
    }
    setPageState((old) => ({
      ...old,
      page: 1,
    }));
  };

  const dateRangeParentFormatted = dateRangeParent
    ? dateRangeParent.map((date) => dayjs(date))
    : [];

  useEffect(() => {
    if (dashboardFilter?.popupFilterData?.data?.length) {
      let nameWithWWID = dashboardFilter?.popupFilterData?.data.map(
        (na) => `(${na.user_id}) ${na.full_name} `,
      );
      // If data is not for user then make the array empty
      if (
        !(
          dashboardFilter?.popupFilterData?.data[0]?.user_id &&
          dashboardFilter?.popupFilterData?.data[0]?.full_name
        )
      ) {
        nameWithWWID = [];
      }

      const title = dashboardFilter?.popupFilterData?.data.map((opt) => opt.training_title);
      const status = dashboardFilter?.popupFilterData?.data.map((opt) => opt.is_active);
      const partnerCompany = dashboardFilter?.popupFilterData?.data.map(
        (opt) => opt.partner_company,
      );
      const workerType = dashboardFilter?.popupFilterData?.data.map((opt) => opt.worker_type);

      let configs = autocompletes;
      configs = configs.map((config) => {
        if (config.label === 'Team Member' || config.label === 'WWID / Team Member') {
          config.options = nameWithWWID;
        }
        if (config.label === 'Training Name') {
          config.options = title;
        }
        if (config.label === 'Training Status') {
          config.options = status;
        }
        return config;
      });
      setAutocompleteConfigs && setAutocompleteConfigs(configs);
      let drillConfig = donutChartAutocomplete;
      drillConfig = drillConfig?.map((config) => {
        if (config.label === 'Partner Company') {
          config.options = partnerCompany;
        }
        if (config.label === 'Worker Type') {
          config.options = workerType;
        }
        return config;
      });

      setDonutChartAutocompleteConfigs && setDonutChartAutocompleteConfigs(drillConfig);
    }
  }, [dashboardFilter]);

  const customNoDataOverlay = () => {
    let overlayMssg =
      selectedDateRange.length === 0
        ? 'No Trainings assigned to you at the moment'
        : 'No Trainings found for the filters applied';

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          background: redtheme.palette.box.very_light_grey,
          fontWeight: '900',
          fontSize: '26px',
          color: redtheme.palette.box.dark_grayish_blue,
          width: '100%',
          height: '100%',
        }}
      >
        {overlayMssg}
      </Box>
    );
  };
  const totalRowCount = () => {
    if (unselectedRows?.length && trainingTitle && selectedUserOption && dateRangeFilter?.length) {
      //Filter the unselected row on all applied filters and subtract it from total record
      const startDate = new Date(dateRangeFilter[0]);
      const endDate = new Date(dateRangeFilter[1]);
      endDate.setHours(23, 59, 59, 999);
      const allFilteredUnSelectedRows = unselectedRows.filter(
        (item) =>
          trainingTitle.includes(item?.training_title) &&
          selectedUserOption.includes(item?.user_id) &&
          new Date(item?.t_due_date) >= startDate &&
          new Date(item?.t_due_date) <= endDate,
      ).length;
      return totalSelectedRecords - allFilteredUnSelectedRows;
    } else if (unselectedRows?.length && trainingTitle && selectedUserOption) {
      //Filter the training and user specific unselected row and subtract it from total record
      const trainingAndUserFilteredUnSelectedRows = unselectedRows.filter(
        (item) =>
          trainingTitle.includes(item?.training_title) &&
          selectedUserOption.includes(item?.user_id),
      ).length;
      return totalSelectedRecords - trainingAndUserFilteredUnSelectedRows;
    } else if (unselectedRows?.length && trainingTitle && dateRangeFilter?.length) {
      //Filter the training and Date specific unselected row and subtract it from total record
      const startDate = new Date(dateRangeFilter[0]);
      const endDate = new Date(dateRangeFilter[1]);
      endDate.setHours(23, 59, 59, 999);
      const trainingAndDateFilteredUnSelectedRows = unselectedRows.filter(
        (item) =>
          trainingTitle.includes(item?.training_title) &&
          new Date(item?.t_due_date) >= startDate &&
          new Date(item?.t_due_date) <= endDate,
      ).length;
      return totalSelectedRecords - trainingAndDateFilteredUnSelectedRows;
    } else if (unselectedRows?.length && selectedUserOption && dateRangeFilter?.length) {
      //Filter the user and Date specific unselected row and subtract it from total record
      const startDate = new Date(dateRangeFilter[0]);
      const endDate = new Date(dateRangeFilter[1]);
      endDate.setHours(23, 59, 59, 999);
      const userAndDateFilteredUnSelectedRows = unselectedRows.filter(
        (item) =>
          selectedUserOption.includes(item?.user_id) &&
          new Date(item?.t_due_date) >= startDate &&
          new Date(item?.t_due_date) <= endDate,
      ).length;
      return totalSelectedRecords - userAndDateFilteredUnSelectedRows;
    } else if (unselectedRows?.length && selectedUserOption) {
      //Filter the user specific unselected row and subtract it from total record
      const userFilteredUnSelectedRows = unselectedRows.filter((item) =>
        selectedUserOption.includes(item?.user_id),
      ).length;
      return totalSelectedRecords - userFilteredUnSelectedRows;
    } else if (unselectedRows?.length && trainingTitle) {
      //Filter the training specific unselected row and subtract it from total record
      const trainingFilteredUnSelectedRows = unselectedRows.filter((item) =>
        trainingTitle.includes(item?.training_title),
      ).length;
      return totalSelectedRecords - trainingFilteredUnSelectedRows;
    } else if (unselectedRows?.length && dateRangeFilter?.length) {
      //Filter the date specific unselected row and subtract it from total record
      const startDate = new Date(dateRangeFilter[0]);
      const endDate = new Date(dateRangeFilter[1]);
      endDate.setHours(23, 59, 59, 999);
      const dateRangeFilteredUnSelectedRows = unselectedRows.filter(
        (item) => new Date(item?.t_due_date) >= startDate && new Date(item?.t_due_date) <= endDate,
      ).length;
      return totalSelectedRecords - dateRangeFilteredUnSelectedRows;
    } else if (unselectedRows?.length) {
      return totalSelectedRecords > unselectedRows?.length
        ? totalSelectedRecords - unselectedRows?.length
        : 0;
    } else {
      return totalSelectedRecords;
    }
  };

  const handleColumnHeaderClick = (params, event) => {
    if (params && event.target.checked) {
      setSelectAllChecked(true);
      selectAllCheckboxValue = true;
    } else {
      setSelectAllChecked(false);
      selectAllCheckboxValue = false;
      setSelectedUsersForDownloadExcel([]);
      setDeSelectedUsersForDownloadExcel([]);
      unselectedRows = [];
    }
  };

  const triggerDownloadsCompletedEvent = () => {
    setShowLargeDataDownloadInfoMsg(false);
    setShowLoader(false);
  };

  const onSuccessToasterClose = () => {
    setShowLargeDataDownloadSuccessMsg(false);
    setToasterFlow(false);
  };

  const handleOnRowSelection = (newRowSelectionModel) => {
    setSelectionModel(newRowSelectionModel);

    let selectedRowsUpdate = selectedRows.filter((item) =>
      newRowSelectionModel.includes(item.user_id),
    );
    let arrSelectedItems = [];

    if (newRowSelectionModel?.length && !selectAllCheckboxValue) {
      if (isCompliantPopup) {
        newRowSelectionModel.forEach((rowId) => {
          let existingItem = selectedRows?.find((row) => row?.user_id === rowId);
          if (existingItem === undefined) {
            let rowItem = pageState?.data?.find((row) => row?.user_id === rowId);
            if (rowItem) {
              let complianceElementArray = rowItem?.complianceElement?.split(', ');
              let complianceArray = '';
              if (complianceElementArray?.includes('SOE')) {
                complianceArray += 'Summary of Experience (SOE), ';
              }
              if (complianceElementArray?.includes('ITP')) {
                complianceArray += 'Individual Training Plan (ITP), ';
              }
              if (complianceElementArray?.includes('JD')) {
                complianceArray += 'Job Description (JD)';
              }
              arrSelectedItems.push({
                complianceElement: complianceArray,
                complianceStatus: rowItem.complianceStatus,
                full_name: rowItem.full_name,
                inspectionReadiness_Indicator: rowItem.inspectionReadiness_Indicator,
                leave_of_absence: rowItem.leave_of_absence,
                user_id: rowItem.user_id,
                user_email: rowItem.user_email,
              });
              //  pushing the selected checkbox elements
              arrcheckbox.push({ user_id: rowItem.user_id, full_name: rowItem.full_name });
            }
          }
        });
        //  filtering the array to remove duplicates user_id
        if (arrcheckbox?.length) {
          uniqueArray = arrcheckbox.filter((o1) =>
            newRowSelectionModel.some((o2) => o1.user_id === o2),
          );
        }
        // filtering the array to remove unselected checkbox value
        if (uniqueArray?.length) {
          arrayValue = uniqueArray.filter(
            (obj, index, self) => index === self.findIndex((o) => o.user_id === obj.user_id),
          );
        }
        // sending the selected checkbox values to excel
        if (arrayValue?.length) {
          setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          setSelectedUsersForDownloadExcel(arrayValue);
        }
      } else {
        newRowSelectionModel.forEach((rowId) => {
          let existingItem = selectedRows.find(
            (row) => row?.transcript_id === rowId || row?.user_id === rowId,
          );
          if (!existingItem) {
            pageState.data.forEach((element) => {
              if (element?.transcript_id === rowId || element?.user_id === rowId) {
                //  pushing the selected checkbox elements
                arrcheckbox.push(element);
              }
            });
          }
        });

        //  filtering the array to remove duplicates transcript_id
        if (arrcheckbox?.length > NUMBER_ARRAY.zero) {
          uniqueArray = arrcheckbox?.filter((o1) =>
            newRowSelectionModel?.some((o2) => o1?.transcript_id === o2 || o1?.user_id === o2),
          );
        }

        // filtering the array to remove unselected checkbox value
        if (uniqueArray?.length) {
          arrayValue = uniqueArray?.filter(
            (obj, index, self) =>
              index === self?.findIndex((o) => o?.transcript_id === obj?.transcript_id),
          );
        }

        if (arrayValue?.length > NUMBER_ARRAY.zero) {
          checkboxArray = arrayValue?.map((element) => {
            arrSelectedItems?.push(element);
            // returning only userid, full name and training id
            return {
              user_id: element.user_id,
              full_name: element.full_name,
              training_id: element.training_id,
            };
          });
        }
        // sending array to download excel
        if (checkboxArray?.length > NUMBER_ARRAY.zero) {
          setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          setSelectedUsersForDownloadExcel(checkboxArray);
        }
      }
    } else if (selectAllCheckboxValue) {
      if (newRowSelectionModel?.length <= pageState.pageSize) {
        if (isCompliantPopup) {
          const deSelectedRows = pageState.data.filter(
            (item) => !newRowSelectionModel.includes(item.user_id),
          );

          const currentPageIds = pageState.data.map((item) => item.user_id);
          unselectedRows = [
            ...unselectedRows.filter((item) => !currentPageIds.includes(item.user_id)),
            ...deSelectedRows,
          ];

          if (unselectedRows?.length) {
            setDeSelectedUsersForDownloadExcel(
              unselectedRows.map((element) => {
                if (element) {
                  let complianceElementArray = element.complianceElement?.split(', ');
                  let complianceArray = '';
                  if (complianceElementArray?.includes('SOE')) {
                    complianceArray += 'Summary of Experience (SOE), ';
                  }
                  if (complianceElementArray?.includes('ITP')) {
                    complianceArray += 'Individual Training Plan (ITP), ';
                  }
                  if (complianceElementArray?.includes('JD')) {
                    complianceArray += 'Job Description (JD)';
                  }
                  arrSelectedItems.push({
                    complianceElement: complianceArray,
                    complianceStatus: element.complianceStatus,
                    full_name: element.full_name,
                    inspectionReadiness_Indicator: element.inspectionReadiness_Indicator,
                    leave_of_absence: element.leave_of_absence,
                    user_id: element.user_id,
                    user_email: element.user_email,
                  });

                  return {
                    user_id: element.user_id,
                    full_name: element.full_name,
                  };
                }
              }),
            );
            setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          }
        } else {
          const deSelectedRows = pageState.data.filter(
            (item) => !newRowSelectionModel.includes(item.transcript_id),
          );
          const currentPageIds = pageState.data.map((item) => item.transcript_id);
          unselectedRows = [
            ...unselectedRows.filter((item) => !currentPageIds.includes(item.transcript_id)),
            ...deSelectedRows,
          ];
          if (unselectedRows?.length) {
            setDeSelectedUsersForDownloadExcel(
              unselectedRows.map((element) => {
                arrSelectedItems.push(element);
                return {
                  user_id: element.user_id,
                  full_name: element.full_name,
                  training_id: element.training_id,
                };
              }),
            );
            setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          }
        }
      } else {
        setSelectedUsersForDownloadExcel([]);
      }
    } else {
      setSelectedRows([]);
      setSelectedUsersForDownloadExcel([]);
    }
  };

  const DownloadExcelButton = {
    // Styles for "Download Excel" button
    backgroundColor: DOWNLOAD_EXCEL_BUTTON_BG_COLOR,
    color: DOWNLOAD_EXCEL_BUTTON_COLOR,
    padding: '9px 30px',
    borderRadius: '2px',
    gap: '10px',
    textTransform: 'none',
    fontWeight: DOWNLOAD_EXCEL_BUTTON_FONTWEIGHT,
    fontSize: screen.isMobile ? '14px' : '16px',
    width: screen.isMobile ? '160px' : '200px',
    height: '38px',
    fontFamily: '"JohnsonText-Medium"',
    '&:hover': {
      backgroundColor: redtheme.palette.button.strong_red,
      color: redtheme.palette.button.white,
    },
    '&:focus': { outline: 'none' },
  };

  const SendReminderButton = {
    color:
      CheckSendReminder() && !selectAllCheckboxValue
        ? redtheme.palette.button.gray
        : redtheme.palette.button.dark_grey,
    border:
      CheckSendReminder() && !selectAllCheckboxValue
        ? ''
        : `1px solid ${redtheme.palette.primary.main}`,
    backgroundColor:
      CheckSendReminder() && !selectAllCheckboxValue
        ? redtheme.palette.button.light_gray
        : 'transparent',
    textTransform: 'none',
    // fontWeight: CheckSendReminder() ? fontWeightStyles.fw9.fontWeight : fontWeightStyles.fw7.fontWeight,
    // fontSize: screen.isMobile? '14px':'16px',
    // width: screen.isMobile? '150px':'200px',
    fontWeight:
      CheckSendReminder() && !selectAllCheckboxValue
        ? fontWeightStyles.fw9.fontWeight
        : fontWeightStyles.fw7.fontWeight,
    fontSize: screen.isMobile ? '14px' : '16px',
    width: screen.isMobile ? '150px' : '200px',
    height: '38px',
    fontFamily: '"JohnsonText-Medium"',
    '&:hover': { backgroundColor: 'transparent' },
    '&:focus': { outline: 'none' },
  };

  const HandelButtonClick = (button) => {
    if (button.label === 'Send Reminder') {
      handleSendReminder();
    }
    if (button.label === 'Download Excel') {
      handleDownloadExcel();
    }
  };

  const autocompleateSection = () => {
    const [opens, setOpens] = useState([]);
    const [loading, setLoading] = useState([]);

    const handleOpenChange = (index, isOpen) => {
      setOpens((prev) => {
        const newOpens = [...prev];
        newOpens[index] = isOpen;
        return newOpens;
      });
    };

    const handleLoadingChange = (index, isLoading) => {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = isLoading;
        return newLoading;
      });
    };

    return (
      autocompletes &&
      autocompletes.map((autocomplete, index) => (
        <Grid
          key={index}
          item
          xs={
            screen.isMobile
              ? autocompletes.length === 2
                ? 6
                : 12
              : isPopupForCompliance
              ? autocompletes.length === 2
                ? 6
                : 4
              : autocompletes.length === 2
              ? 4
              : 6
          }
        >
          <Autocomplete
            open={opens[index] || false}
            loading={loading[index] || false}
            id={`${autocomplete.label.toLowerCase().replace(/ /g, '-')}`}
            data-testid={`autocomplete-${autocomplete.label
              .toLowerCase()
              .replace(/ /g, '-')}-search`}
            key={index}
            options={loading[index] ? [] : autocomplete.options}
            getOptionLabel={autocomplete?.getOptionLabel}
            onInputChange={(e, value) => {
              handleSearchText(e, value, autocomplete.label);
              if (value.length === NUMBER_ARRAY.zero) {
                handleOpenChange(index, false);
                handleLoadingChange(index, false);
              } else {
                handleOpenChange(index, true);
                handleLoadingChange(index, true);
                setTimeout(() => {
                  handleLoadingChange(index, false);
                }, 1000);
              }
            }}
            onChange={(_event, newValue) => {
              const searchType = autocomplete.label === 'Training Name' ? 'training' : 'user';
              if (searchType === 'training') {
                setTrainingTitle(newValue);
              } else {
                setSelectedUserOption(newValue);
              }
              setPageState((old) => ({ ...old, searchType: searchType, page: 1 }));
              handleOpenChange(index, false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  shrink: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '14px',
                      fontWeight: 500,
                      fontFamily: '"JohnsonText-Bold"',
                      color: 'rgba(33, 33, 33, 1)',
                      width: '728px',
                      height: '16px',
                    }}
                  >
                    {autocomplete.label}
                  </Typography>
                }
                variant='standard'
                margin='normal'
                placeholder='Search'
                sx={{
                  mr: autocomplete.mr,
                  borderBottom: '1px solid black',
                  // Merge additional custom styles
                  ...autocomplete.sx,
                }}
              />
            )}
            clearOnBlur
          />
        </Grid>
      ))
    );
  };

  const donutChartAutoCompleateSection = () => {
    const labelMapping = {
      'Partner Company': {
        searchType: 'partnerCompany',
        setSelectedView: setSelectedPartnerCompanyView,
      },
      'Worker Type': {
        searchType: 'workerType',
        setSelectedView: setSelectedWorkerTypeView,
      },
    };

    const [opens, setOpens] = useState([]);
    const [loading, setLoading] = useState([]);

    const handleOpenChange = (index, isOpen) => {
      setOpens((prev) => {
        const newOpens = [...prev];
        newOpens[index] = isOpen;
        return newOpens;
      });
    };

    const handleLoadingChange = (index, isLoading) => {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = isLoading;
        return newLoading;
      });
    };

    const handleSelectChange = (newValue, label) => {
      const { setSelectedView } = labelMapping[label] || {};
      if (setSelectedView) {
        if (newValue !== null) {
          setSelectedView((old) => [...old, newValue]);
        } else {
          setSelectedView([]);
        }
      }
    };

    return (
      donutChartAutocomplete &&
      donutChartAutocomplete.map((autocomplete, index) => (
        <Grid key={index} item xs={!isPopupForCompliance ? 6 : 4}>
          <Autocomplete
            open={opens[index] || false}
            loading={loading[index] || false}
            id={`${autocomplete.label.toLowerCase().replace(/ /g, '-')}`}
            data-testid={`autocomplete-${autocomplete.label
              .toLowerCase()
              .replace(/ /g, '-')}-search`}
            key={index}
            options={loading[index] ? [] : autocomplete.options}
            getOptionLabel={(option) => {
              const label = autocomplete.getOptionLabel(option);
              return label ? label : '';
            }}
            onInputChange={(e, value) => {
              if (value.length === NUMBER_ARRAY.zero) {
                handleOpenChange(index, false);
                handleLoadingChange(index, false);

                if (autocomplete.label === 'Worker Type') {
                  setSearchWorkerType('');
                } else if (autocomplete.label === 'Partner Company') {
                  setSearchPartnerCompany('');
                }
              } else {
                handleOpenChange(index, true);
                handleLoadingChange(index, true);
                setTimeout(() => {
                  handleLoadingChange(index, false);
                }, 1000);
              }
              handleSearchText(e, value, autocomplete.label);
            }}
            onChange={(_event, newValue) => {
              const { searchType, setSelectedView } = labelMapping[autocomplete.label] || {};
              if (newValue) {
                handleSelectChange(newValue, autocomplete.label);
              } else {
                if (setSelectedView) {
                  setSelectedView([]);
                }
              }
              setPageState((old) => ({ ...old, searchType: searchType || 'default', page: 1 }));
              handleOpenChange(index, false);
            }}
            noOptionsText={loading[index] || showLoader ? 'Loading...' : 'No options'}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  shrink: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '14px',
                      fontWeight: 500,
                      fontFamily: '"JohnsonText-Bold"',
                      color: 'rgba(33, 33, 33, 1)',
                      width: '728px',
                      height: '16px',
                    }}
                  >
                    {autocomplete.label}
                  </Typography>
                }
                variant='standard'
                margin='normal'
                placeholder='Search'
                sx={{
                  mr: autocomplete.mr,
                  borderBottom: '1px solid black',
                  ...autocomplete.sx,
                }}
              />
            )}
            clearOnBlur
            onFocus={() => {
              if (autocomplete.label === 'Worker Type') {
                setSearchWorkerType('');
              } else if (autocomplete.label === 'Partner Company') {
                setSearchPartnerCompany('');
              }
            }}
          />
        </Grid>
      ))
    );
  };

  const trainingTable = () => {
    return noTrainingsAssigned ? (
      <DataGrid
        disableVirtualization={process.env.NODE_ENV === 'test'} // virtualization disabled only during tests - generating all columns during tests
        loading={pageState.isLoading}
        rows={pageState.data}
        columns={columns}
        slots={{
          noRowsOverlay: customNoDataOverlay,
        }}
        sx={{
          border: 'none',
          minHeight: '480px',
          '& .MuiDataGrid-columnHeaders': {
            fontSize: 16,
            fontFamily: '"J&J CircularTT Black Web"',
          },
        }}
      />
    ) : (
      <DataGrid
        disableVirtualization={process.env.NODE_ENV === 'test'} // virtualization disabled only during tests - generating all columns during tests
        rows={pageState.data}
        columns={columns}
        getRowId={(row) =>
          isCompliantPopup ? row?.user_id : row?.transcript_id
        }
        loading={pageState.isLoading}
        checkboxSelection={isCheckboxSelection}
        sortingMode='server'
        paginationMode='server'
        localeText={{
          columnHeaderSortIconLabel: sortingTooltip,
          footerRowSelected: (count) => {
            return selectAllCheckboxValue
              ? `${totalRowCount()} rows selected`
              : `${count.toLocaleString()} rows selected`;
          },
        }}
        onSortModelChange={handleSortModelChange}
        setPageState={setPageState}
        rowCount={pageState.total}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          handleOnRowSelection(newRowSelectionModel);
        }}
        onColumnHeaderClick={handleColumnHeaderClick}
        keepNonExistentRowsSelected
        rowSelectionModel={selectionModel}
        slots={{
          noRowsOverlay: customNoDataOverlay,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={PAGE_SIZES_ARRAY_GENERIC}
        onPaginationModelChange={(newPage) => {
          setPageState((old) => ({
            ...old,
            page: newPage.page + 1,
            pageSize: newPage.pageSize,
          }));
        }}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell': {
            fontSize: '14px',
            fontWeight: 450,
            fontFamily: '"J&J CircularTT Book Web"',
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 153, 0.03)',
          },
          '& .MuiDataGrid-row:nth-of-type(odd):hover': {
            backgroundColor: redtheme.palette.dataGrid.very_light_grey,
          },
          '& .MuiDataGrid-columnHeaders': {
            fontSize: 16,
            fontFamily: '"J&J CircularTT Black Web"',
          },
          '& .MuiDataGrid-row.Mui-selected': { backgroundColor: redtheme.palette.dataGrid.white },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: redtheme.palette.dataGrid.white,
          },
          '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 153, 0.03)',
          },
          '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
            backgroundColor: redtheme.palette.dataGrid.very_light_grey,
          },
          '& .MuiDataGrid-rowCount': {
            fontWeight: 'bold',
          },
          '& .MuiTablePagination-selectLabel': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '& .MuiTablePagination-displayedRows': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '& .MuiTablePagination-select': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '26px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cellContent': {
            pointerEvents: 'none',
            fontWeight: '400',
            fontSize: '1rem',
          },
          [`& .${tablePaginationClasses.selectLabel}`]: {
            display: 'block',
          },
          [`& .${tablePaginationClasses.input}`]: {
            display: 'inline-flex',
          },
        }}
      />
    );
  };

  const buttonDisplay = () => {
    return (
      buttons && (
        <Stack direction='row' spacing={2} mt={2}>
          {buttons.map((button, index) =>
            isGenericPopupOpenForDonutChart && button.label === 'Send Reminder' ? null : (
              <Button
                data-testid={button.label === 'Send Reminder' ? 'send-reminder' : 'download-excel'}
                key={index}
                onClick={() => {
                  HandelButtonClick(button);
                }}
                sx={{
                  ...(button.label === 'Send Reminder' ? SendReminderButton : {}),
                  ...(button.label === 'Download Excel' ? DownloadExcelButton : {}),
                  borderRadius: '12px',
                }}
                disabled={
                  (buttonDisabled &&
                    ((button.label === 'Send Reminder' &&
                      selectionModel?.length <= 0 &&
                      !unselectedRows?.length) ||
                      (button.label === 'Send Reminder' &&
                        CheckSendReminder() &&
                        !selectAllCheckboxValue) ||
                      (pageState.data?.length <= 0 && button.label === 'Download Excel'))) ||
                  (showLoader && button.label === 'Download Excel')
                }
              >
                {button.label} {button.label === 'Download Excel' ? <Download /> : ''}
              </Button>
            ),
          )}
        </Stack>
      )
    );
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: redtheme.palette.box.white,
            padding: screen.isMobile ? '16px' : '10px 46px 10px',
            gap: '48px',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: screen.resolveStyles({
              mobile: '360px',
              tablet: '818px',
              desktop: '918px',
            }),
            borderRadius: '16px',
          }}
        >
          <Spin spinning={showLoader && !pageState.isLoading}>
            <IconButton
              data-testid='btn-close'
              className='btn-close-popup'
              sx={{
                position: 'absolute',
                top: '30px',
                right: '30px',
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Box
              sx={{
                cursor: 'pointer',
              }}
            >
              <ToolTipWrapper title={tagline}>
                <Typography
                  variant='h2'
                  component='div'
                  sx={{
                    weight: fontWeightStyles.fw9.fontWeight,
                    fontFamily: '"JohnsonDisplay-Bold"',
                  }}
                >
                  {
                    (popupHeader === 'Trainings Completed On Time' ? newPopupHeader : popupHeader) +
                    setSubName(chartMonth, chartQuerter, chartYear, true)}
                </Typography>
              </ToolTipWrapper>
            </Box>

            <Grid container spacing={2}>
              {autocompleateSection()}
              {!isPopupForCompliance && (
                <Grid item xs={screen.isMobile ? 12 : autocompletes?.length === 2 ? 4 : 6}>
                  <Box>
                    <InputLabel
                      data-testid='date-search'
                      sx={{
                        position: 'absolute',
                        paddingTop: '15px',
                        fontSize: '12px',
                        lineHeight: '12px',
                        fontWeight: 500,
                        fontFamily: '"JohnsonText-Bold"',
                        color: 'rgba(33, 33, 33, 1)',
                      }}
                    >
                      Start Date to End Date
                    </InputLabel>
                    <DateRangePicker
                      data-testid='date-search-rangePicker'
                      onChange={onChangeOfDateRange}
                      defaultValue={dateRangeParentFormatted}
                      width={screen.isMobile ? '330px' : '0px'}
                    />
                  </Box>
                </Grid>
              )}
              {donutChartAutoCompleateSection()}
            </Grid>
            <Box sx={{ height: 270, width: '100%' }}>{trainingTable()}</Box>
            <Box
              sx={{
                marginLeft: '15px',
                width: '300px',
                paddingTop: screen.isMobile ? '20px' : null,
              }}
            >
              {isCompliantPopup ? (
                <Stack direction='row' spacing={2}>
                  <PopupInfo />
                  {info.map((infos, index) => (
                    <GenericPopupTooltip
                      key={index}
                      title={infos.body}
                      placement='top'
                      header={infos.placeholder}
                    />
                  ))}
                </Stack>
              ) : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '-11px',
              }}
            >
              {buttonDisplay()}
            </Box>
            <Toaster
              onClose={triggerDownloadsCompletedEvent}
              hideDuration={NUMBER_ARRAY.tenThousand}
              severity={'warn'}
              show={showLargeDataDownloadInfoMsg}
              message={largeDownloadStartMsg}
            />
            <Toaster
              onClose={onSuccessToasterClose}
              hideDuration={NUMBER_ARRAY.thirtyThousand}
              severity={'success'}
              show={showLargeDataDownloadSuccessMsg}
              message={largeDownloadSuccessMsg}
            />
          </Spin>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity='success'
          elevation={6}
          variant='filled'
          style={{ color: 'white', fontFamily: '"J&J CircularTT Bold Web"' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default memo(GenericPopup);

GenericPopup.propTypes = {
  open: PropTypes.boolean,
  onClose: PropTypes.func,
  selectedDays: PropTypes.integer,
  columns: PropTypes.array,
  buttons: PropTypes.array,
  autocompletes: PropTypes.array,
  statusMapper: PropTypes.Object,
  popupHeader: PropTypes.string,
  popUserId: PropTypes.string,
  popupStatus: PropTypes.string,
  dateRangeParent: PropTypes.array,
  setAutocompleteConfigs: PropTypes.func,
  isCheckboxSelection: PropTypes.boolean,
  buttonDisabled: PropTypes.boolean,
};
