const initialState = {
  alerts: [],
  muteAlerts: [],
  adminMuteAlerts: [],
};

export const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALERTS':
      return {
        ...state,
        alerts: action.payload,
      };
    case 'GET_EDITED_ALERTS':
      return {
        ...state,
        editedAlert: action.payload,
      };
    case 'DELETE_ALERTS':
      return {
        ...state,
        deleteAlert: action.payload,
      };
    case 'MUTE_ALERT':
      return {
        ...state,
        muteAlerts: action.payload,
      };
    case 'GET_ADMIN_ALERTS':
      return {
        ...state,
        adminAlerts: action.payload,
      };
    case 'DELETE_ADMIN_ALERTS':
      return {
        ...state,
        adminDeletedAlerts: action.payload,
      };
    case 'GET_EDITED_ADMIN_ALERTS':
      return {
        ...state,
        adminEditedAlerts: action.payload,
      };
    case 'MUTE_ADMIN_ALERT':
      return {
        ...state,
        adminMuteAlerts: action.payload,
      };
    case 'CREATE_ALERT':
      return {
        ...state,
        createAlert: action.payload,
      };
    case 'CREATE_ADMIN_ALERT':
      return {
        ...state,
        createAdminAlert: action.payload,
      }
    case 'ERROR_CREATE_ALERT':
      return {
        ...state,
        errorCreateAlert: action.payload,
      }
    default:
      return state;
  }
};
