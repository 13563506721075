import apiAction from 'common/redux/actions/apiAction';
import { POST_ADMIN_SHARE_FAVOURITE_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const postAdminFavouriteFilter = (data) => {
  return {
    type: 'POST_ADMIN_SHARE_FAVOURITE_FILTER',
    payload: data,
  };
};

export const AdminShareFavouriteFilterAction = configParams => {
  return apiAction.postAPI({
    url: POST_ADMIN_SHARE_FAVOURITE_FILTER(),
    data: configParams,
    onSuccess:  postAdminFavouriteFilter,
    onFailure: err => logError('Error occured in Sharing Favourite filter:', err),
    label: 'POST_ADMIN_SHARE_FAVOURITE_FILTER_STATUS',
  });
};
