import apiAction from 'common/redux/actions/apiAction';
import { POST_TEAM_PERFORMANCE_POPUP_DATA } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST POPUP LISTING DETAILS
 ****/
const getPopUpDetailsData = data => {
  return {
    type: 'GET_POPUP_LISTING_DATA',
    payload: data,
  };
};

// This function requires to clear the state Value where we have same API with multiple place to avoid wrong data flow.
export const ClearPopupRecordForMyOrg = (data = []) => {
  return getPopUpDetailsData(data);
}

export const PopupDetailsData = configParams => {
  const url = POST_TEAM_PERFORMANCE_POPUP_DATA(configParams.urlStatus);
  return apiAction.postAPI({
    url,
    data: configParams,
    onSuccess: getPopUpDetailsData,
    onFailure: err =>  logError('Error occurred in fetching dashboard data:', err),
    label: 'FETCH_MYPERFORMANCELISTING_DATA',
  });
};

