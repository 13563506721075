import { Box, Autocomplete, TextField, Typography, Grid } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { GenericPopupTooltip } from "common/components/genericPopupTooltip";
import { RedTheme } from "common/global"; 
import { IOSSwitch } from "common/components/switch";
import { ReactComponent as CrossIcon } from 'common/assets/images/CrossIcon.svg';
import { useScreen } from "common/hooks/useScreen";
import { COURSE_CODE, MANAGER, NUMBER_ARRAY, USER } from "common/global/constants";

export const GenericAutocomplete = ({
  options,
  values,
  switchChangeHandler,
  checked,
  getLabeledOptions,
  handleAdminStatusChange,
  getEqualityCheck,
  type,
  placeholder,
  heading,
  switchIndicator,
  isSwitchToShow = true,
  handleValidation,
  validationError = false,
  errorMessage,
  isFullWidth = true,
  isOpenForSetAlert = false,
}) => {
  let redtheme = RedTheme;
  const screen = useScreen();
  const customizeScheduleAudienceStyles = {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "Johnson Text",
    color: redtheme.palette.text.primary
  }
  const defaultStyles = {
    fontSize: screen.isTablet ? '20px' : '14px',
    lineHeight: '12px',
    fontWeight: 500,
    fontFamily: '"JohnsonText-Bold"',
    color: 'rgba(33, 33, 33, 1)',
    width: '728px',
    height: screen.isTablet ? '25px' : '16px',
    paddingTop: screen.isTablet ? '5px' : NUMBER_ARRAY.zero
  }

  /**This will add unique key to all options in Autocomplete */
  const getKeyOptions = (option, type) => {
    if (type === USER) {
      return option.user_id;
    } else if (type === MANAGER) {
      return option.manager_id;
    } else if (type === COURSE_CODE) {
      return option.training_id;
    }
  }

  return (
    <>
      {isSwitchToShow ? (
        <Box
          sx={{
            marginLeft: `${((type === 'user_mail') ) ? (isOpenForSetAlert ? '92%' : '44%') : (isOpenForSetAlert ? '92%' : '80%') }`,
            marginBottom: '-17px',
          }}
        >
          <GenericPopupTooltip title={'Switch to Selected'} placement='top' header={' '}>
            <IOSSwitch checked={checked} onChange={(e) => switchChangeHandler(e, type)} />
          </GenericPopupTooltip>
        </Box>
      ) : null}
      <Autocomplete
        id='search-training-by-title'
        data-testid='search-training-by-title'
        options={switchIndicator ? options : values}
        popupIcon={''}
        getOptionLabel={(option) => getLabeledOptions(option, type)}
        getOptionKey={(option) => getKeyOptions(option, type)}
        isOptionEqualToValue={(option, value) =>
          getEqualityCheck(option, value, type)
        } /* This will show the selected value highlighted on the Search part of Autocomplete */
        renderOption={(props, option) => (
          <li {...props}>
            {!switchIndicator ? (
              <Grid container>
                <Grid item xs={11.5}>
                  {getLabeledOptions(option, type)}
                </Grid>
                <Grid item xs={0.5}>
                  {!switchIndicator ? (
                    <Box>
                      <CrossIcon />
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              getLabeledOptions(option, type)
            )}
          </li>
        )}
        multiple
        value={values}
        onChange={(e, Value) => {
          if (!validationError) {
            handleAdminStatusChange(Value, type);
          }
          if (type === 'user_mail' && isOpenForSetAlert) {
            handleValidation(Value);
            handleAdminStatusChange(Value, type);
          }
          if (type === 'manager' && isOpenForSetAlert) {
            handleValidation(Value);
            handleAdminStatusChange(Value, type);
          }
        }}
        renderInput={(params) => (
          <TextField
            data-testid='trainingNameInput'
            {...params}
            InputProps={{
              ...params.InputProps,
              shrink: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            label={
                            <Typography
                                sx={type === "user_mail" ? customizeScheduleAudienceStyles : defaultStyles}
                            >
                                {heading}
                            </Typography>
                        }
                        sx={[customizeScheduleAudienceStyles, {
                            borderBottom: `${ validationError} ?  1px solid ${redtheme.palette.fontColor.gray_orange} : ''`,
                        }]}
            variant='standard'
            margin='normal'
            error={validationError}
            helperText={validationError ? errorMessage : ''}
          />
        )}
        sx={{ mr: 2, width: `${!isFullWidth ? '50%' : '100%'}` }}
        clearOnBlur
        renderTags={() => {}}
      />
    </>
  );
};
