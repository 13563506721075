import * as React from 'react';

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useValidation, validateDate } from '@mui/x-date-pickers/validation';
import { useSplitFieldProps, useParsedFormat, usePickersContext } from '@mui/x-date-pickers/hooks';
import { CalendarIcon } from '@mui/x-date-pickers/icons';

function ReadOnlyDateField(props) {
  const { internalProps, forwardedProps } = useSplitFieldProps(props, 'date');
  const { value, timezone, format } = internalProps;
  const { InputProps, slotProps, ...other } = forwardedProps;

  const pickersContext = usePickersContext();

  const parsedFormat = useParsedFormat(internalProps);
  const { hasValidationError } = useValidation({
    validator: validateDate,
    value,
    timezone,
    props: internalProps,
  });

  const handleTogglePicker = (event) => {
    if (pickersContext.open) {
      pickersContext.onClose(event);
    } else {
      pickersContext.onOpen(event);
    }
  };

  return (
    <TextField
      {...other}
      disabled = {props.disabled}
      value={value == null ? '' : value.format(format)}
      placeholder={parsedFormat}
      InputProps={{
        ...InputProps,
        readOnly: true,
        endAdornment: <CalendarIcon color='action' />,
        sx: { cursor: 'pointer', '& *': { cursor: 'inherit' } },
      }}
      error={hasValidationError}
      onClick={handleTogglePicker}
      {...slotProps?.textField}
    />
  );
}

function ReadOnlyFieldDatePicker(props) {
  return <DatePicker disabled= {props.disabled} {...props} slots={{ ...props.slots, field: ReadOnlyDateField }} />;
}

export default ReadOnlyFieldDatePicker;
