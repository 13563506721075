import { jwtDecode } from "jwt-decode";

export function getAuthToken() {
  let keys = Object.keys(localStorage);
  let token = '',
    graphToken = '',
    userEmailId = '',
    userName = '';

  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
  
    let val = localStorage.getItem(key);

    if (val && validJSON(val)) {
      let parsedVal = JSON.parse(val);

      if (Object.prototype.hasOwnProperty.call(parsedVal, 'credentialType')) {
        if (parsedVal.credentialType === 'IdToken') {
          token = parsedVal.secret;
        } else if (parsedVal.credentialType === 'AccessToken') {
          graphToken = parsedVal.secret;
        }
      } else if (Object.prototype.hasOwnProperty.call(parsedVal, 'authorityType')) {
        userEmailId = parsedVal.username;
        userName = parsedVal.name;
      }
    }
  }
  return {
    idToken: token,
    graphToken: graphToken,
    userEmailId: userEmailId,
    userName: userName,
  };
}

function validJSON(str) {
  try {
    // eslint-disable-next-line no-unused-vars
    let obj = JSON.parse(str);
    if(obj){
      return true;
    }
  } catch (error) {
    return false;
  }
  return true;
}

export const getDecodedBearerToken = () => {
  let token = getAuthToken();
  try {
    const tokenData = `Bearer ${token.idToken}`;
    if (tokenData) {
      return jwtDecode(tokenData);
    }
  } catch (error) {
    console.error('Failed to decode JWT: ', error);
  }
  };
