export const AdminPopUpDetailsReducer = (state = {}, action) => {
  if (action.type === 'GET_POPUP_LISTING_DATA') {
    return {
      ...state,
      popupDetailsData: action.payload,
    };
  } else if (action.type === 'API_START') {
    if (action.payload === 'FETCH_ADMINVIEWLISTING_DATA') {
      return {
        ...state,
        isLoadingPopupListingData: true,
      };
    } else {
      return state;
    }
  } else if (action.type === 'API_END') {
    if (action.payload === 'FETCH_ADMINVIEWLISTING_DATA') {
      return {
        ...state,
        isLoadingPopupListingData: false,
      };
    } else {
      return state;
    }
  } else {
    return state;
  }
};
