/**
 * FilterPopUpDetailsReducer.
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export const FilterDirectReporteeActionPopUpDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_DIRECT_REPORTEE_ACTION_POPUP_FILTER_DATA':
      return {
        ...state,
        popupFilterData: action.payload,
      };
    case 'API_START':
      if (action.payload === 'FETCHT_DIRECT_REPORTEE_ACTION_POPUP_FILTER_DATA') {
        return {
          ...state,
          isLoadingDirectReporteeActionPopupFilterData: true,
        };
      } else {
        return state;
      }
    case 'API_END':
      if (action.payload === 'FETCHT_DIRECT_REPORTEE_ACTION_POPUP_FILTER_DATA') {
        return {
          ...state,
          isLoadingDirectReporteeActionPopupFilterData: false,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
